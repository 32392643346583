import './Dashboard.css';
import { getAuth, signOut } from "firebase/auth";
import react, { useState, useEffect } from 'react';
import { collection, doc, getDoc } from 'firebase/firestore';
import {db} from '../FirebaseInit';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import StoreOverview from '../components/StoreOverview';
import Example from '../components/Charts/AnalyticsChart';
import ChartComponent from '../components/Charts/AnalyticsChart';
import { ClickAwayListener } from '@mui/material';
import { CalendarMonthOutlined, KeyboardArrowDownRounded } from '@mui/icons-material';
import DateSelector from '../components/DateSelector';
import ProfitByDay from '../components/ProfitByDay';
import TopPerformers from '../components/TopPerformers';
import CalendarIcon from '../assets/Icons/CalendarIcon';
import * as dateFns from 'date-fns';
import { startOfDay, subDays, startOfMonth, endOfMonth, startOfYear, endOfYear } from 'date-fns';
import PlatformComparison from '../components/PlatformComparison';




function Dashboard({serverURL, darkMode, switchedBusinesses}) {
  const navigate = useNavigate()

  const bid = localStorage.getItem('bid');
  const uid = localStorage.getItem('uid');
  
  
  const [dateStart, setDateStart] = useState(startOfDay(subDays(new Date(), 17)));
  const [dateEnd, setDateEnd] = useState(startOfDay(new Date()));  
  const [showDateSelectors, setShowDateSelectors] = useState(false);
  const [dateUpdateTrigger, setDateUpdateTrigger] = useState(false)
  const [showDaily, setShowDaily] = useState(true)
  const [showHourly, setShowHourly] = useState(false)
  const [loadingDashboard, setLoadingDashboard] = useState(true)
  const [loadingPercentage, setLoadingPercentage] = useState(0)  

  const [allData, setAllData] = useState([])

  const [dateArray, setDateArray] = useState([]);
  const [ordersArray, setOrdersArray] = useState([]);
  const [revenueArray, setRevenueArray] = useState([]);
  const [facebookSpendArray, setFacebookSpendArray] = useState([]);
  const [netProfitArray, setNetProfitArray] = useState([]);

  const [compareDateArray, setCompareDateArray] = useState([]);
  const [compareOrdersArray, setCompareOrdersArray] = useState([]);
  const [compareRevenueArray, setCompareRevenueArray] = useState([]);
  const [compareFacebookSpendArray, setCompareFacebookSpendArray] = useState([]);
  const [compareNetProfitArray, setCompareNetProfitArray] = useState([]);


  const [ordersTotal, setOrdersTotal] = useState(0)
  const [revenueTotal, setRevenueTotal] = useState(0)
  const [facebookSpendTotal, setFacebookSpendTotal] = useState(0)
  const [netProfitTotal, setNetProfitTotal] = useState(0)

  const [compareOrdersTotal, setCompareOrdersTotal] = useState(0)
  const [compareRevenueTotal, setCompareRevenueTotal] = useState(0)
  const [compareFacebookSpendTotal, setCompareFacebookSpendTotal] = useState(0)
  const [compareNetProfitTotal, setCompareNetProfitTotal] = useState(0)




  async function getDailyStats () {
      try {
        const API_URL = `${serverURL}/getDailyStats?omni_business_id=${bid}&dateStart=${dateStart.toISOString(0,10)}&dateEnd=${dateEnd.toISOString(0,10)}` ;
        console.log('get daily stats: ', API_URL)
        const response = await axios.get(API_URL)
        console.log('get daily stats response: ', response.data[0])
        const API_RESPONSE = response.data[0]

        setAllData(response.data)

        const data = response.data;
        const dates = data.map(entry => (new Date(entry.date).toISOString().slice(0, 10)));
        const dailyOrders = data.map(entry => (entry.orders * 1));
        const dailyRevenue = data.map(entry => (entry.revenue * 1));
        const dailyFacebookSpend = data.map(entry => (entry.facebook_spend * 1));
        const dailyNetProfit = data.map(entry => (entry.net_profit * 1));
        
        setDateArray(dates);
        setOrdersArray(dailyOrders);
        setRevenueArray(dailyRevenue);
        setFacebookSpendArray(dailyFacebookSpend);
        setNetProfitArray(dailyNetProfit);

        setOrdersTotal(dailyOrders.reduce((a, b) => a + b, 0))
        setRevenueTotal(dailyRevenue.reduce((a, b) => a + b, 0))
        setFacebookSpendTotal(dailyFacebookSpend.reduce((a, b) => a + b, 0))
        setNetProfitTotal(dailyNetProfit.reduce((a, b) => a + b, 0))

      } catch (error) {
        console.log('ERROR loading stats StoreOverview.js', error)
      }
  }


  async function getCompareDailyStats () {
    try {
      setLoadingPercentage(100)
      const daysBetween = Math.round((new Date(dateEnd) - new Date(dateStart)) / (1000 * 60 * 60 * 24));
      
      // Adjusting for the case where dateStart and dateEnd are the same (e.g., yesterday)
      const compareDateStart = dateFns.format(dateFns.subDays(new Date(dateStart), daysBetween || 1), 'yyyy-MM-dd');
      const compareDateEnd = dateFns.format(dateFns.subDays(new Date(dateEnd), daysBetween || 1), 'yyyy-MM-dd');

      const API_URL = `${serverURL}/getDailyStats?omni_business_id=${bid}&dateStart=${compareDateStart}&dateEnd=${compareDateEnd}` ;
      console.log('get daily stats: ', API_URL)
      const response = await axios.get(API_URL)
      console.log('get daily stats response: ', response.data[0])
      const API_RESPONSE = response.data[0]

      setAllData(response.data)

      const data = response.data;
      const dates = data.map(entry => (new Date(entry.date).toISOString().slice(0, 10)));
      const dailyOrders = data.map(entry => (entry.orders * 1));
      const dailyRevenue = data.map(entry => (entry.revenue * 1));
      const dailyFacebookSpend = data.map(entry => (entry.facebook_spend * 1));
      const dailyNetProfit = data.map(entry => (entry.net_profit * 1));
      
      setCompareDateArray(dates);
      setCompareOrdersArray(dailyOrders);
      setCompareRevenueArray(dailyRevenue);
      setCompareFacebookSpendArray(dailyFacebookSpend);
      setCompareNetProfitArray(dailyNetProfit);

      setCompareOrdersTotal(dailyOrders.reduce((a, b) => a + b, 0))
      setCompareRevenueTotal(dailyRevenue.reduce((a, b) => a + b, 0))
      setCompareFacebookSpendTotal(dailyFacebookSpend.reduce((a, b) => a + b, 0))
      setCompareNetProfitTotal(dailyNetProfit.reduce((a, b) => a + b, 0))

    } catch (error) {
      console.log('ERROR loading stats StoreOverview.js', error)
    } finally {
      setLoadingDashboard(false)
      setLoadingPercentage(0)
    }
}


  useEffect(() => {
    setShowDateSelectors(false)
    if (uid !== '') {
      getCompareDailyStats();
      getDailyStats();
    }
  }, [uid, dateUpdateTrigger, switchedBusinesses]);


















  
    return (
      <>
       {loadingDashboard ?
        <div className='dashboardLoadingContainer'>
          <div className='dashboardLoadingProgressParent'>
            <div className='dashboardLoadingProgressChild' style={{width: loadingPercentage + '%'}}></div>
          </div>
          {/* <CircularProgress style={{color: '#635bff', width: 30, height: 30}}/> */}
          <span className='dashboardLoadingText'>Loading Dashboard... </span>
        </div>
      :
        <div className="dashboardContainer">

          <div className='dashboardDateSelectorRow'>
            <DateSelector setShowDateSelectors={setShowDateSelectors} showDateSelectors={showDateSelectors} dateStart={dateStart} setDateStart={setDateStart} dateEnd={dateEnd} setDateEnd={setDateEnd} dateUpdateTrigger={dateUpdateTrigger} setDateUpdateTrigger={setDateUpdateTrigger} />
          </div>

          <StoreOverview
          revenue={revenueTotal} facebookSpend={facebookSpendTotal} profit={netProfitTotal} orders={ordersTotal} 
          compareRevenueTotal={compareRevenueTotal} compareFacebookSpendTotal={compareFacebookSpendTotal} compareNetProfitTotal={compareNetProfitTotal} compareOrdersTotal={compareOrdersTotal}
          dateArray={dateArray} ordersArray={ordersArray} revenueArray={revenueArray} facebookSpendArray={facebookSpendArray} netProfitArray={netProfitArray}
          darkMode={darkMode}/>
          
          
          
          
          <div className='dashboardGrid'>

            <div className='dashboardChartContainer' style={{height: 500}}>
              <ChartComponent chartName="Performance" multiLineData={[revenueArray]} multiLineDataNames={showHourly ? null : dateArray} line2Data={facebookSpendArray} line3Data={netProfitArray} categories={showHourly ? null : dateArray} 
                yAxisIsNumber={true} xAxisIsDate={showDaily} xAxisIsTime={showHourly} chartHeight={400} total="" compareTotal="" 
                line1Name="Revenue" line2Name="Spend" line3Name="Profit" 
                maxTicksX={10} maxTicksY={10} xAxisLabelSize={10} yAxisLabelSize={10} chartContainerStyles={{}} showLegend={true} showAxisLabels={true} showGridLines={true} showCompareData={true} darkMode={darkMode}/>
            </div>

            <div className={'dashboardProfitByDayContainer ' + (darkMode && ' darkModeCard')}>
              <ProfitByDay widthPercent={100} darkMode={darkMode} serverURL={serverURL}
              />
            </div>

            <div className={'dashboardAttributionTableContainer ' + (darkMode && ' darkModeCard')}>
              <TopPerformers widthPercent={100} dateStart={dateStart} dateEnd={dateEnd} dateUpdateTrigger={dateUpdateTrigger} darkMode={darkMode} serverURL={serverURL}
              />
            </div>

            <div className='dashhboardPlatformComparisonContainer'>
              <PlatformComparison widthPercent={100} dateStart={dateStart} dateEnd={dateEnd} dateUpdateTrigger={dateUpdateTrigger} darkMode={darkMode} serverURL={serverURL} />
            </div>
              
          </div>

        



          <br/><br/><br/><br/>

          
        










        </div>

        }
      </>
    );
}

export default Dashboard;