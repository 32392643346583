import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, updateDoc, setDoc, getDoc } from 'firebase/firestore';
import axios from 'axios';




function ProcessKlaviyoRedirect({serverURL}) {

    const firestore = getFirestore();
    const bid = localStorage.getItem('bid');
    const uid = localStorage.getItem('uid');
    const navigate = useNavigate();




    function base64URLEncode(buffer) {
        const bytes = new Uint8Array(buffer);
        let binary = '';
        for (let i = 0; i < bytes.byteLength; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary)
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=+$/, '');
    }
    
    
    async function sha256(buffer) {
        const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
        return hashBuffer;
    }
    
    async function generateCodes() {
        const randomArray = new Uint8Array(32);
        crypto.getRandomValues(randomArray);
    
        // Convert randomArray to base64URL string to get codeVerifier
        const codeVerifier = base64URLEncode(randomArray);
    
        // Encode codeVerifier to a Uint8Array for hashing
        const encoder = new TextEncoder();
        const codeVerifierBytes = encoder.encode(codeVerifier);
    
        // Hash the codeVerifierBytes
        const challengeBuffer = await sha256(codeVerifierBytes);
    
        // Base64URL encode the hash to get codeChallenge
        const codeChallenge = base64URLEncode(challengeBuffer);
    
        return {
            codeVerifier: codeVerifier,
            codeChallenge: codeChallenge
        };
    }
    
    

    





    const client_id = 'dd649373-4378-4c1e-bfc4-efc9d7336171';
    const redirect_uri = 'http://localhost:3011/process-klaviyo-redirect';
    const scopes = 'accounts:read campaigns:read events:read events:write metrics:read campaigns:read flows:read'
    const state = '';

    async function redirectToKlaviyoAuth() {
        const codes = await generateCodes();
        const code_challenge = codes.codeChallenge;
        console.log('CODES:', codes);
        
        const businessRef = doc(firestore, 'businesses', bid);
        await updateDoc(businessRef, {
            klaviyoOAuthCodeChallenge: codes.codeChallenge,
            klaviyoOAuthCodeVerifier: codes.codeVerifier
        });

        const url = `https://www.klaviyo.com/oauth/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scopes}&state=${state}&code_challenge_method=S256&code_challenge=${code_challenge}`;
        console.log('Redirecting to Klaviyo auth:', url);
        window.location.href = url;
    }
    
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        
        if (code) {
            saveCodeToDB(code);
        } else {
            // If no code is present, redirect to Klaviyo auth
            redirectToKlaviyoAuth();
        }
    }, []); 


    async function saveCodeToDB(code) {
        const businessRef = doc(firestore, 'businesses', bid);

        try {
            const docSnapshot = await getDoc(businessRef);
            if (docSnapshot.exists()) {
                await updateDoc(businessRef, {
                    klaviyoOAuthCode: decodeURIComponent(code),
                    klaviyoOAuthURL: redirect_uri,
                    klaviyoOAuthAccessTokenNeverUsed: true,
                });
            
                console.log('Code updated in Firestore successfully');
            } else {
                await setDoc(businessRef, {
                    klaviyoOAuthCode: decodeURIComponent(code),
                    klaviyoOAuthURL: redirect_uri,
                    klaviyoOAuthAccessTokenNeverUsed: true
                });
                console.log('Code saved to Firestore successfully');
            }
            await fetchKlaviyoRefreshToken();

            navigate('/klaviyo-accounts');
        } catch (error) {
            console.error('Error saving/updating code in Firestore:', error);
        }
    }

    
    async function fetchKlaviyoRefreshToken() {
         const businessRef = doc(firestore, 'businesses', bid);
        try {
            const data = {
                omniBusinessID: bid,
            };
            console.log(`${serverURL}/getKlaviyoRefreshToken`, data)
            const response = await axios.post(`${serverURL}/getKlaviyoRefreshToken`, data);
            console.log('Token fetched:', response.data);
            await updateDoc(businessRef, {
                tiktokOAuthAccessTokenNeverUsed: false,
            });
            // Handle additional logic here if needed (e.g., navigate to another route, store data)
        } catch (error) {
            console.error('Error fetching Klaviyo refresh token:', error);
        }
    }
    

    return <div>Loading...</div>;
}

export default ProcessKlaviyoRedirect;
