import React, { useState, useEffect } from 'react';
import styles from './CreativeAnalytics.module.css';
import axios from 'axios';
import { ClickAwayListener } from '@mui/material';
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import dayjs from 'dayjs';
import CalendarIcon from '../../assets/Icons/CalendarIcon';
import DateSelector from '../../components/DateSelector';
import TopCreativesCards from './TopCreativesCards';


function CreativeAnalytics ({ serverURL }) {

    const bid = localStorage.getItem('bid');
    const [creativeAnalyticsData, setCreativeAnalyticsData] = useState([]);

    async function getCreativeAnalytics () {
        try {
            const data = {
                attribution_model: 'last_click',
                attribution_window: '1 day',
                date_start: '2025-01-13T00:00:00.000Z',
                date_end: '2025-01-15T23:59:59.000Z',
                omni_business_id: bid,
            }

            const response = await axios.post(`${serverURL}/getCreativeAttribution`, data);
            console.log('CREATIVE ANALYTICS', response.data);
            setCreativeAnalyticsData(response.data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getCreativeAnalytics();
    }, []);
  

    return (
        <div className={styles.CreativeAnalyticsContainer}>
            <TopCreativesCards serverURL={serverURL} creativeAnalyticsData={creativeAnalyticsData} />
        </div>
    );
};

export default CreativeAnalytics;
