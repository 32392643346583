// ChartComponent.js
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import './ProductDrawerChart.css'

const ProductDrawerChart = ({
  chartName, line1Data, line2Data, line3Data, categories, yAxisIsNumber, xAxisIsDate, xAxisIsTime, 
  chartHeight, total, compareTotal, line1Name, line2Name, line3Name, titleType, chartType,
  maxTicksX, maxTicksY, xAxisLabelSize, yAxisLabelSize, chartContainerStyles, darkMode
}) => {
  // Example data
  const series = [{
    name: line1Name,
    data: line1Data,
  }];

  if (line2Data) {
    series.push({
      name: line2Name,
      data: line2Data
    })
  }

  if (line3Data) {
    series.push({
      name: line3Name,
      data: line3Data
    })
  }


  // console.log(categories, 'categories')


  function formatDollarAmount(amount) {
    if (amount >= 1e6) {
        return `$${(amount / 1e6).toFixed(1)}M`;
    } else if (amount >= 1e3) {
        return `$${(amount / 1e3).toFixed(1)}K`;
    } else {
        return `$${amount.toFixed(2)}`;
    }
  }


  function formatDateShort(dateString) {
    const date = new Date(dateString);
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    return `${month} ${day}`;
  }


  function formatTime(hour) {
    if (hour >= 0 && hour <= 11) {
        return `${hour === 0 ? 12 : hour}:00 AM`; // Convert 0 to 12 for AM
    } else if (hour === 12) {
        return `12:00 PM`; // 12 PM is noon
    } else {
        return `${hour === 24 ? 12 : hour % 12}:00 PM`; // Convert 24-hour to 12-hour PM format
    }
}




  //const categories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep']


  const options = {
    chart: {
      background: darkMode ? '#1c252e' : '#fff',
      foreColor: '#666',
      toolbar: {
        show: false
      },
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 3,
        opacity: 0.2
      },
      sparkline: {
        enabled: false
      },
      zoom: {
        enabled: false // This disables the zoom feature
      },
      selection: {
        enabled: false // This disables the selection feature
      }
    },
    //colors: ['#635bff', '#ffab00'],
    colors: ['#0874fc', '#0874fc80', '#0874fc20'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 2, // Adjust the line width if needed
    },
    plotOptions: {
      bar: {
        borderRadius: 0, // Adjust the border radius as needed
      }
    },
    xaxis: {
      categories: categories,
      labels: {
        rotate: 0,
        style: {
          colors: '#666',
          fontSize: xAxisLabelSize ? xAxisLabelSize : '12px',
          fontFamily: 'Helvetica, Arial, sans-serif'
        },
        formatter: function (value) {
          if (xAxisIsDate) {
            return formatDateShort(value);
          } else if (xAxisIsTime) {
            return formatTime(categories[value ]);
          } else {
            return `${value}`;
          }
        },
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      tickAmount: maxTicksX ? maxTicksX : 5,
    },
    yaxis: {
      min: 0,
      max: line2Data ? (Math.max(...line1Data) * 1.1 > Math.max(...line2Data) * 1.1 ? Math.max(...line1Data) * 1.1 : Math.max(...line2Data) * 1.1) :  Math.max(...line1Data) * 1.1 , // Adjust the multiplier to change the maximum y-axis value
      labels: {
        style: {
          colors: '#666',
          fontSize: yAxisLabelSize ? yAxisLabelSize : '12px',
          fontFamily: 'Helvetica, Arial, sans-serif'
        },
        formatter: function (value) {
          if (yAxisIsNumber) {
            return formatDollarAmount(value); 
          } else {
            return value; // Keep original formatting
          }
        },
      },
      tickAmount: maxTicksY ? maxTicksY : 5,
    },
    // fill: {
    //   type: 'gradient',
    //   gradient: {
    //     shadeIntensity: 1,
    //     inverseColors: false,
    //     opacityFrom: 0,
    //     opacityTo: 0,
    //     stops: [0, 90, 100]
    //   },
    // },
    grid: {
      borderColor: darkMode ? '#444' : '#e7e7e7',
      strokeDashArray: 3,
      row: {
        colors: ['transparent', 'transparent'],
        opacity: 0.5
      },
      column: {
        colors: ['transparent', 'transparent'],
        opacity: 0.5
      }
    },
    tooltip: {
        theme: darkMode ? 'dark' : 'light',
        style: {
          fontSize: '14px',
          fontFamily: 'Helvetica, Arial, sans-serif',
        },
        color: '#666',
        onDatasetHover: {
          highlightDataSeries: true
        },
        x: {
          show: true,
          format: 'dd MMM',
          formatter: function (val) {
            if (xAxisIsDate) {
              return formatDateShort(categories[val ]);
            } else if (xAxisIsTime) {
              return formatTime(categories[val - 1]);
            } else {
              return `${val}`;
            }
          },
          style: {
            color: '#999' // This sets the color for the x-axis tooltip text
          },
          textAlignment: 'center'
        },
        y: {
          formatter: function (val) {
            return `${val}`;
          },
          title: {
            formatter: function (seriesName) {
              return `${seriesName}:`;
            },
            style: {
              color: '#999' // This sets the color for the y-axis title in the tooltip
            }
          },
          style: {
            color: '#999' // This sets the color for the y-axis tooltip text
          }
        },
        marker: {
          show: true,
          //fillColors: ['#635bff'] // This sets the color for the markers in the tooltip
        },
        items: {
          display: 'flex'
        },
        fixed: {
          enabled: false,
          position: 'topRight',
          offsetX: 0,
          offsetY: 0,
        },
       
      },
      
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'right', 
      floating: false,
      fontSize: '11px',
      fontFamily: 'Helvetica, Arial, sans-serif',
      offsetY: 0,
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: '#fff',
        radius: 12,
        offsetX: -5,
        offsetY: 1,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },
      onItemClick: {
        toggleDataSeries: true
      },
      onItemHover: {
        highlightDataSeries: true
      },
      labels: {
        useSeriesColors: false // Set to true to use the series colors for legend labels
      },
    }
  };

  
  
  

  return (
    <div style={chartContainerStyles}>
      <span className='chartTitle'>{chartName}</span>
      <span className='chartTotal'>
       {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)} 

      </span>
      <ReactApexChart options={options} series={series} type={chartType} height={chartHeight} />
    </div>
  );
};

export default ProductDrawerChart;
