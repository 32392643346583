
import { ClickAwayListener } from '@mui/material';
import shopify_logo from '../../assets/shopify_logo.png';
import { KeyboardArrowRight } from '@mui/icons-material';


function ShopifyStoreSelector ({setShowBusinessDropdown, showBusinessDropdown, businessNameFromLocalStorage, businesses, setSelectedBusinessId, selectedBusinessId, setSwitchedBusinesses, switchedBusinesses, userEmailAddress, collapsed, darkMode}) {

    return (
        <div className='navbarRowDropdownButton ' onClick={() => setShowBusinessDropdown(!showBusinessDropdown)}>

            <div className={collapsed ? 'navbarBusinessDropdownButtonContainerCollapsed': 'navbarBusinessDropdownButtonContainer'}>
                <div className='navbarContentFilterRowDropdownRowLeft' >
                    <img className='navbarContentFilterRowDropdownRowImage' src={shopify_logo}/>

                    {!collapsed &&
                        <span className='navbarContentFilterRowDropdownRowText' style={{color: '#222'}}>
                            {selectedBusinessId?.businessName || businessNameFromLocalStorage}
                        </span>
                    }

                </div>
                {!collapsed && <KeyboardArrowRight style={{ color: darkMode ? '#fff' : '#000', width: 15, marginLeft: 0, color: '#999' }}/>}
            </div>


            {showBusinessDropdown && (
                <ClickAwayListener onClickAway={() => setShowBusinessDropdown(false)}>
                    <div className='navbarRowDropdownContainer'>
                        <span className='navbarRowDropdownContainerEmailAddress'>{userEmailAddress}</span>
                        {businesses.map((x) => (
                            <div className='navbarContentFilterRowDropdownRow' key={x.id} onClick={() => {
                                setSelectedBusinessId(x);
                                setShowBusinessDropdown(false);
                                localStorage.setItem('bid', x.id); // Update localStorage with the selected business ID
                                localStorage.setItem('businessName', x.businessName); // Update localStorage with the selected business ID
                                setSwitchedBusinesses(!switchedBusinesses)
                            }}>
                                <div className='navbarContentFilterRowDropdownRowLeft'>
                                    <img className='navbarContentFilterRowDropdownRowImage' src={shopify_logo}/>
                                    <span className='navbarContentFilterRowDropdownRowText'>{x.businessName}</span>
                                </div>

                            </div>
                        ))}
                        <div className='navbarRowDropdownContainerFooter'>
                            These are all the businesses you have access to
                        </div>
                    </div>
                </ClickAwayListener>
            )}

        </div>
    )
}



export default ShopifyStoreSelector;