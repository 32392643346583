


import { AddchartRounded, DashboardRounded, DeleteForeverRounded, DensitySmallRounded, EditRounded, ExpandMoreRounded, LegendToggleRounded, LineAxisRounded, SaveOutlined, ShareRounded, ShowChartRounded, StackedLineChartRounded, StarRounded, WidthWideRounded } from '@mui/icons-material';
import { ClickAwayListener } from '@mui/material';
import React, { useState } from 'react';
import TextInput from '../../components/TextInput';

function AnalyticsDropdown({showHeaderDropdown, setShowHeaderDropdown, saveReport, currentReportId, setReportAsDefault, deleteReport, setShowRenameReportPopup, setShowShareReportPopup, showCompareData, setShowCompareData, showLegend, setShowLegend, showAxisLabels, setShowAxisLabels, showGridLines, setShowGridLines, customizeLayout, setCustomizeLayout, narrowMode, setNarrowMode, setShowCustomMetricPopup}) {

    const [text, setText] = useState('');

    return (
        <ClickAwayListener onClickAway={() => setShowHeaderDropdown(false)}>
            <div className='AnalyticsDropdownButtonDropdownContainer'>
                <div className='AnalyticsDropdownButtonDropdownContainerButton' onClick={() => {setShowHeaderDropdown(!showHeaderDropdown)}}>
                    <span style={{paddingRight: 5}}>Customize</span>
                <div className='AnalyticsDropdownButtonDropdownContainerButtonArrow' style={{transform: showHeaderDropdown && 'rotate(180deg)'}}><ExpandMoreRounded style={{height: 20, width: 20}}/></div>
                </div>
                
                {showHeaderDropdown && (
                <div className='AnalyticsDropdownButtonDropdownContainerContent'>


                    <div className='AnalyticsDropdownDropdownInputRow'>
                        
                        <TextInput placeholder='Report Name' type='text' value={text} onChange={() => {setText()}} widthPercent={100} marginBottom={10}/>
                    </div>



                    <div className='AnalyticsDropdownDropdownButton' onClick={() => {saveReport(); setShowHeaderDropdown(false)}}>
                        <SaveOutlined style={{height: 16, width: 16, paddingRight: 10, color: '#aaa'}}/>
                        <span>Save Report</span>
                        <span className='AnalyticsDropdownDropdownButtonKeyCommand'>&#x2318;+&#x21E7;+S </span>
                    </div>
                    {currentReportId && (
                        <div className='AnalyticsDropdownDropdownButton' onClick={() => {setReportAsDefault(currentReportId); setShowHeaderDropdown(false)}}>
                            <StarRounded style={{height: 16, width: 16, paddingRight: 10, color: '#aaa'}}/>
                            <span>Set as Default</span>
                            <span className='AnalyticsDropdownDropdownButtonKeyCommand'>&#x2318;+&#x21E7;+D</span>
                        </div>
                    )}
                    {currentReportId && (
                        <div className='AnalyticsDropdownDropdownButton' onClick={() => deleteReport()}>
                            <DeleteForeverRounded style={{height: 16, width: 16, paddingRight: 10, color: '#aaa'}}/>
                            <span>Delete Report</span>
                            <span className='AnalyticsDropdownDropdownButtonKeyCommand'>&#x2318;+&#x21E7;+&#x232B;</span>
                        </div>
                    )}
                    <div className='AnalyticsDropdownDropdownButton' onClick={() => setShowRenameReportPopup(true)}>
                        <EditRounded style={{height: 16, width: 16, paddingRight: 10, color: '#aaa'}}/>
                        <span>Rename Report</span>
                        <span className='AnalyticsDropdownDropdownButtonKeyCommand'>&#x2318;+&#x21E7;+R</span>
                    </div>
                    <div className='AnalyticsDropdownDropdownButton' onClick={() => setShowShareReportPopup(true)}>
                        <ShareRounded style={{height: 16, width: 16, paddingRight: 10, color: '#aaa'}}/>
                        <span>Share Report</span>
                        <span className='AnalyticsDropdownDropdownButtonKeyCommand'>&#x2318;+&#x21E7;+S</span>
                    </div>
                    <div className='AnalyticsDropdownDropdownButton' onClick={() => setShowCompareData(!showCompareData)}>
                        {showCompareData ? <StackedLineChartRounded style={{height: 16, width: 16, paddingRight: 10, color: '#aaa'}}/> : <ShowChartRounded style={{height: 16, width: 16, paddingRight: 10, color: '#aaa'}}/>}
                        <span>{showCompareData ? 'Hide' : 'Show'} Compare Data</span>
                        <span className='AnalyticsDropdownDropdownButtonKeyCommand'>&#x2318;+&#x21E7;+C</span>
                    </div>
                    <div className='AnalyticsDropdownDropdownButton' onClick={() => setShowLegend(!showLegend)}>
                        <LegendToggleRounded style={{height: 16, width: 16, paddingRight: 10, color: '#aaa'}}/>
                        <span> {showLegend ? 'Hide' : 'Show'} Legend</span>
                        <span className='AnalyticsDropdownDropdownButtonKeyCommand'>&#x2318;+&#x21E7;+K</span>
                    </div>
                    <div className='AnalyticsDropdownDropdownButton' onClick={() => setShowAxisLabels(!showAxisLabels)}>
                        <LineAxisRounded style={{height: 16, width: 16, paddingRight: 10, color: '#aaa'}}/>
                        <span> {showAxisLabels ? 'Hide' : 'Show'} Axis Labels</span>
                        <span className='AnalyticsDropdownDropdownButtonKeyCommand'>&#x2318;+&#x21E7;+L</span>
                    </div>
                    <div className='AnalyticsDropdownDropdownButton' onClick={() => setShowGridLines(!showGridLines)}>
                        <DensitySmallRounded style={{height: 16, width: 16, paddingRight: 10, color: '#aaa'}}/>
                        <span> {showGridLines ? 'Hide' : 'Show'} Grid Lines</span>
                        <span className='AnalyticsDropdownDropdownButtonKeyCommand'>&#x2318;+&#x21E7;+G</span>
                    </div>
                    <div className='AnalyticsDropdownDropdownButton' onClick={() => setCustomizeLayout(!customizeLayout)}>
                        <DashboardRounded style={{height: 16, width: 16, paddingRight: 10, color: '#aaa'}}/>
                        <span>Customize Layout</span>
                        <span className='AnalyticsDropdownDropdownButtonKeyCommand'>&#x2318;+&#x21E7;+P</span>
                    </div>
                    <div className='AnalyticsDropdownDropdownButton' onClick={() => setNarrowMode(!narrowMode)}>
                        <WidthWideRounded style={{height: 16, width: 16, paddingRight: 10, color: '#aaa'}}/>
                        <span>{narrowMode ? 'Wide Mode' : 'Narrow Mode'}</span>
                        <span className='AnalyticsDropdownDropdownButtonKeyCommand'>&#x2318;+&#x21E7;+A</span>
                    </div>
                    <div className='AnalyticsDropdownDropdownButton' onClick={() => setShowCustomMetricPopup(true)}>
                        <AddchartRounded style={{height: 16, width: 16, paddingRight: 10, color: '#aaa'}}/>
                        <span> Create Chart</span>
                        <span className='AnalyticsDropdownDropdownButtonKeyCommand'>&#x2318;+&#x21E7;+M</span>
                    </div>
                </div>
                )}

            </div>
        </ClickAwayListener>
    );
}


export default AnalyticsDropdown;