

export function processFormula(data, formulaTokens) {
    // Define operator precedence
    const precedence = {
        '+': 1,
        '-': 1,
        '*': 2,
        '/': 2,
        '(': 0,
        ')': 0
    };

    // Convert infix tokens to Reverse Polish Notation (RPN) using the Shunting Yard algorithm
    function toRPN(tokens) {
        const outputQueue = [];
        const operatorStack = [];

        tokens.forEach(token => {
            if (isNumeric(token) || isVariable(token)) {
                outputQueue.push(token);
            } else if (isOperator(token)) {
                while (
                    operatorStack.length > 0 &&
                    precedence[operatorStack[operatorStack.length - 1]] >= precedence[token]
                ) {
                    outputQueue.push(operatorStack.pop());
                }
                operatorStack.push(token);
            } else if (token === '(') {
                operatorStack.push(token);
            } else if (token === ')') {
                while (
                    operatorStack.length > 0 &&
                    operatorStack[operatorStack.length - 1] !== '('
                ) {
                    outputQueue.push(operatorStack.pop());
                }
                operatorStack.pop(); // Remove '(' from stack
            }
        });

        while (operatorStack.length > 0) {
            outputQueue.push(operatorStack.pop());
        }

        return outputQueue;
    }

    // Helper functions
    function isOperator(token) {
        return ['+', '-', '*', '/'].includes(token);
    }

    function isNumeric(token) {
        return !isNaN(parseFloat(token)) && isFinite(token);
    }

    function isVariable(token) {
        return /^[a-zA-Z_][a-zA-Z0-9_]*$/.test(token);
    }

    // Evaluate RPN expression with given variables
    function evaluateRPN(rpn, variables) {
        const stack = [];

        rpn.forEach(token => {
            if (isNumeric(token)) {
                stack.push(parseFloat(token));
            } else if (isVariable(token)) {
                const value = variables[token];
                if (value === undefined) {
                    throw new Error(`Undefined variable: ${token}`);
                }
                stack.push(parseFloat(value));
            } else if (isOperator(token)) {
                const b = stack.pop();
                const a = stack.pop();
                let result;
                switch (token) {
                    case '+':
                        result = a + b;
                        break;
                    case '-':
                        result = a - b;
                        break;
                    case '*':
                        result = a * b;
                        break;
                    case '/':
                        if (b === 0) {
                            throw new Error('Division by zero');
                        }
                        result = a / b;
                        break;
                    default:
                        throw new Error(`Unsupported operator: ${token}`);
                }
                stack.push(result);
            }
        });

        if (stack.length !== 1) {
            throw new Error('Invalid expression');
        }

        return stack[0];
    }

    // Convert formula to RPN once
    const rpnFormula = toRPN(formulaTokens);

    const results = [];

    const numDates = data.dates?.length;

    for (let i = 0; i < numDates; i++) {
        // Build variables for the current date
        const variables = {};
        for (const key in data) {
            if (key !== 'dates') {
                variables[key] = data[key][i] || 0; // Default to 0 if undefined
            }
        }

        try {
            const result = evaluateRPN(rpnFormula, variables);
            results.push(result);
        } catch (error) {
            console.error(`Error evaluating formula on date ${data.dates[i]}:`, error.message);
            results.push({
                date: data.dates[i],
                result: null // or any default value or error indicator
            });
        }
    }

    return results;
}
