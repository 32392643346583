import * as React from "react";


const AnalyticsPlusIcon = (props) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" >
    <path d="M8 1.5C8 0.946875 7.55313 0.5 7 0.5C6.44687 0.5 6 0.946875 6 1.5V6H1.5C0.946875 6 0.5 6.44687 0.5 7C0.5 7.55313 0.946875 8 1.5 8H6V12.5C6 13.0531 6.44687 13.5 7 13.5C7.55313 13.5 8 13.0531 8 12.5V8H12.5C13.0531 8 13.5 7.55313 13.5 7C13.5 6.44687 13.0531 6 12.5 6H8V1.5Z" fill="#232323" />
  </svg>
);

const AnalyticsSubtractIcon = (props) => (
  <svg width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg" >
    <path d="M13.5 1C13.5 1.55313 13.0531 2 12.5 2H1.5C0.946875 2 0.5 1.55313 0.5 1C0.5 0.446875 0.946875 0 1.5 0H12.5C13.0531 0 13.5 0.446875 13.5 1Z" fill="#232323" />
  </svg>
);

const AnalyticsMultiplyIcon = (props) => (
  <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
    <path d="M10.7066 4.70664C11.0973 4.31602 11.0973 3.68164 10.7066 3.29102C10.316 2.90039 9.68164 2.90039 9.29102 3.29102L6.00039 6.58477L2.70664 3.29414C2.31602 2.90352 1.68164 2.90352 1.29102 3.29414C0.900391 3.68477 0.900391 4.31914 1.29102 4.70977L4.58477 8.00039L1.29414 11.2941C0.903516 11.6848 0.903516 12.3191 1.29414 12.7098C1.68477 13.1004 2.31914 13.1004 2.70977 12.7098L6.00039 9.41602L9.29414 12.7066C9.68477 13.0973 10.3191 13.0973 10.7098 12.7066C11.1004 12.316 11.1004 11.6816 10.7098 11.291L7.41602 8.00039L10.7066 4.70664Z" fill="#232323" />
  </svg>
);

const AnalyticsDivideIcon = (props) => (
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
    <path d="M8.5 3C8.5 2.80302 8.4612 2.60796 8.38582 2.42597C8.31044 2.24399 8.19995 2.07863 8.06066 1.93934C7.92137 1.80005 7.75601 1.68956 7.57403 1.61418C7.39204 1.5388 7.19698 1.5 7 1.5C6.80302 1.5 6.60796 1.5388 6.42597 1.61418C6.24399 1.68956 6.07863 1.80005 5.93934 1.93934C5.80005 2.07863 5.68956 2.24399 5.61418 2.42597C5.5388 2.60796 5.5 2.80302 5.5 3C5.5 3.19698 5.5388 3.39204 5.61418 3.57403C5.68956 3.75601 5.80005 3.92137 5.93934 4.06066C6.07863 4.19995 6.24399 4.31044 6.42597 4.38582C6.60796 4.4612 6.80302 4.5 7 4.5C7.19698 4.5 7.39204 4.4612 7.57403 4.38582C7.75601 4.31044 7.92137 4.19995 8.06066 4.06066C8.19995 3.92137 8.31044 3.75601 8.38582 3.57403C8.4612 3.39204 8.5 3.19698 8.5 3ZM8.5 13C8.5 12.803 8.4612 12.608 8.38582 12.426C8.31044 12.244 8.19995 12.0786 8.06066 11.9393C7.92137 11.8001 7.75601 11.6896 7.57403 11.6142C7.39204 11.5388 7.19698 11.5 7 11.5C6.80302 11.5 6.60796 11.5388 6.42597 11.6142C6.24399 11.6896 6.07863 11.8001 5.93934 11.9393C5.80005 12.0786 5.68956 12.244 5.61418 12.426C5.5388 12.608 5.5 12.803 5.5 13C5.5 13.197 5.5388 13.392 5.61418 13.574C5.68956 13.756 5.80005 13.9214 5.93934 14.0607C6.07863 14.1999 6.24399 14.3104 6.42597 14.3858C6.60796 14.4612 6.80302 14.5 7 14.5C7.19698 14.5 7.39204 14.4612 7.57403 14.3858C7.75601 14.3104 7.92137 14.1999 8.06066 14.0607C8.19995 13.9214 8.31044 13.756 8.38582 13.574C8.4612 13.392 8.5 13.197 8.5 13ZM12.5 9C13.0531 9 13.5 8.55312 13.5 8C13.5 7.44688 13.0531 7 12.5 7H1.5C0.946875 7 0.5 7.44688 0.5 8C0.5 8.55312 0.946875 9 1.5 9H12.5Z" fill="#232323" />
  </svg>
);

const AnalyticsParenthesisOpenIcon = (props) => (
  <svg width="5" height="15" viewBox="0 0 5 15" fill="none" xmlns="http://www.w3.org/2000/svg" >
    <path d="M4 1C1.3127 6.12862 1.23196 9.00411 4 14" stroke="#232323" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

const AnalyticsParenthesisCloseIcon = (props) => (
  <svg width="5" height="15" viewBox="0 0 5 15" fill="none" xmlns="http://www.w3.org/2000/svg" >
    <path d="M1 1C3.6873 6.12862 3.76804 9.00411 0.999999 14" stroke="#232323" strokeWidth="2" strokeLinecap="round" />
  </svg>
);









export { AnalyticsPlusIcon, AnalyticsSubtractIcon, AnalyticsMultiplyIcon, AnalyticsDivideIcon, AnalyticsParenthesisOpenIcon, AnalyticsParenthesisCloseIcon };
