import React, { useState, useEffect } from 'react';
import styles from './CreativeAnalytics.module.css';
import axios from 'axios';


function TopCreativesCards ({ serverURL, creativeAnalyticsData }) {

    const bid = localStorage.getItem('bid');

   
  

    return (
        <div className={styles.TopCreativesCardsContainer}>
           
            <div className={styles.TopCreativesCardsRow}>
                {creativeAnalyticsData.slice(0,50).map((x, index) => (
                    <div key={index} className={styles.TopCreativesCard}>
                        <div className={styles.TopCreativesCardImageContainer} >
                            <div className={styles.TopCreativesCardMediaType}>{x.media_type}</div>
                            {/* <img className={styles.TopCreativesCardImage} src={x.media_image_src} alt={x.name} /> */}
                            <video className={styles.TopCreativesCardImage} src={x.tp_media_source} alt={x.name} controls={true} />
                            
                        </div>

                        <div className={styles.TopCreativesCardInfo}>
                            <div className={styles.TopCreativesCardName}>{x.tp_title} {x?.ad_ids?.length}</div>

                            <div className={styles.TopCreativesCardInfoRow}>
                                <div className={styles.TopCreativesCardInfoRowTitle}>Impressions</div>
                                <div className={styles.TopCreativesCardInfoRowValue}>{x.tp_impressions}</div>
                            </div>

                            <div className={styles.TopCreativesCardInfoRow}>
                                <div className={styles.TopCreativesCardInfoRowTitle}>Clicks</div>
                                <div className={styles.TopCreativesCardInfoRowValue}>{x.tp_clicks}</div>
                            </div>

                            <div className={styles.TopCreativesCardInfoRow}>
                                <div className={styles.TopCreativesCardInfoRowTitle}>CTR</div>
                                <div className={styles.TopCreativesCardInfoRowValue}>{x.tp_ctr}</div>
                            </div>

                            <div className={styles.TopCreativesCardInfoRow}>
                                <div className={styles.TopCreativesCardInfoRowTitle}>Spend</div>
                                <div className={styles.TopCreativesCardInfoRowValue}>{x.tp_spend}</div>
                            </div>

                            <div className={styles.TopCreativesCardInfoRow}>
                                <div className={styles.TopCreativesCardInfoRowTitle}>Purchases</div>
                                <div className={styles.TopCreativesCardInfoRowValue}>{x.tp_orders}</div>
                            </div>

                            <div className={styles.TopCreativesCardInfoRow}>
                                <div className={styles.TopCreativesCardInfoRowTitle}>Revenue</div>
                                <div className={styles.TopCreativesCardInfoRowValue}>{x.tp_revenue}</div>
                            </div>

            
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TopCreativesCards;
