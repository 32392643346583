// ChartComponent.js
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import './AnalyticsChart.css'
import dayjs from 'dayjs';

const ChartComponent = ({
  chartName, multiLineData, multiLineDataNames, line1Data, line2Data, line3Data, categories, yAxisIsNumber, xAxisIsDate, xAxisIsTime, 
  chartHeight, total, compareTotal, line1Name, line2Name, line3Name, titleType, chartType,
  maxTicksX, maxTicksY, xAxisLabelSize, yAxisLabelSize, chartContainerStyles, showLegend, showAxisLabels, showGridLines, showCompareData, 
  horizontalBars, colors, darkMode
}) => {
  

  console.log('multiLineData', multiLineData)
  console.log('multiLineDataNames', multiLineDataNames)
  console.log('line2Data', line2Data)

  // const maxLine1 = Math.max(...line1Data.filter(isFinite)) || 0;
  // const maxLine2 = line2Data ? Math.max(...line2Data.filter(isFinite)) || 0 : 0;
  // const yAxisMax = Math.max(maxLine1, maxLine2) * 1.02;


  if (showCompareData) {
    multiLineDataNames = [line1Name, line2Name]
    colors = [colors[0], colors[0].slice(0,7) + '20']
  }
  
  const series = [];

  if (multiLineData?.length == 1 && showCompareData) {
    series.push({
      name: line2Name,
      data: line2Data
    })
  }

  if (multiLineData) {
    for (let i = 0; i < multiLineData.length; i++) {
      series.push({
        name: multiLineDataNames[i],
        data: multiLineData[i]
      })
    }
  } 



  


  // console.log('series', series)
  console.log('categories', categories)



  // Calculate the maximum y-axis value based on the data
  const rawMax = Math.max(
    ...series.flatMap(s => s.data?.filter(isFinite)) // Flatten all data arrays
  ) * 1.02;
  // Calculate step size based on the magnitude of rawMax
  const magnitude = Math.pow(10, Math.floor(Math.log10(rawMax)));
  const stepSize = magnitude / 2; // You can adjust this (e.g., magnitude, magnitude/2, etc.)
  // Round rawMax up to the nearest step size
  const roundedMax = Math.ceil(rawMax / stepSize) * stepSize;

  


  function formatDollarAmount(amount) {
    try {
      if (amount >= 1e6) {
          return `$${(amount / 1e6)?.toFixed(1)}M`;
      } else if (amount >= 1e3) {
          return `$${(amount / 1e3)?.toFixed(1)}K`;
      } else {
          return `$${amount?.toFixed(2)}`;
      }
    } catch (error) {
      console.error('Error formatting dollar amount:', error);
      return '$0.00';
    }
  }


  function formatDateShort(dateString) {
    const date = new Date(dateString + "T00:00:00Z"); // Treat the date as UTC

    if (date == 'Invalid Date') {
      console.error('Invalid date:', dateString);
      return '';
    } else {
      const month = date.toLocaleString('en-US', { month: 'short', timeZone: 'UTC' });
      const day = date.getUTCDate(); // Use UTC day
  
      return `${month} ${day}`;
    }

   
  }
  


  function formatTime(hour) {
    if (hour >= 0 && hour <= 11) {
        return `${hour === 0 ? 12 : hour}:00 AM`; // Convert 0 to 12 for AM
    } else if (hour === 12) {
        return `12:00 PM`; // 12 PM is noon
    } else {
        return `${hour === 24 ? 12 : hour % 12}:00 PM`; // Convert 24-hour to 12-hour PM format
    }
  }


  function formatYAxisLabel(value) {
    if (yAxisIsNumber) {
      return formatDollarAmount(value);
    } else {
      return `${value}`;
    }
  }







  // Common label styles
const labelStyle = {
  colors: '#222',
  fontSize: xAxisLabelSize || '12px',
  fontFamily: 'Montserrat !important',
};

// X-Axis Formatter
const getXFormatter = (horizontal) => {
  if (horizontal) return formatYAxisLabel;
  return (value) => {
    if (xAxisIsDate) return formatDateShort(value);
    if (xAxisIsTime) return formatTime(categories[value]);
    return `${value}`;
  };
};

// Y-Axis Formatter
const getYFormatter = (horizontal) => {
  if (horizontal) {
    return (val) => (formatDateShort(val));
  }
  return formatYAxisLabel;
};

// Base Axis Options
const baseAxisOptions = {
  labels: {
    show: showAxisLabels,
    rotate: 0,
    style: labelStyle,
  },
  axisBorder: { show: false },
  axisTicks: { show: false },
  tickAmount: (horizontalBars ? maxTicksX : maxTicksY) || 5,
};

// X-Axis Options
const xAxisOptions = {
  ...baseAxisOptions,
  ...(horizontalBars
    ? {}
    : { categories }),
  labels: {
    ...baseAxisOptions.labels,
    formatter: getXFormatter(horizontalBars),
  },
};

// Y-Axis Options
const yAxisOptions = {
  ...baseAxisOptions,
  ...(horizontalBars
    ? { categories }
    : { min: 0, max: roundedMax }),
  labels: {
    ...baseAxisOptions.labels,
    fontSize: yAxisLabelSize || '12px',
    formatter: getYFormatter(horizontalBars),
  },
};





  //const categories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep']


  const options = {
    chart: {
      background: darkMode ? '#1c252e' : '#fff',
      foreColor: '#666',
      toolbar: {
        show: false
      },
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 3,
        opacity: 0.2
      },
      sparkline: {
        enabled: false
      },
      zoom: {
        enabled: false
      },
      selection: {
        enabled: false
      }
    },
    colors: colors || ['#0874fc', '#0874fc80', '#0874fc20'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 2,
      connectNulls: true,
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: horizontalBars || false,
      }
    },
    xaxis: xAxisOptions,
    yaxis: yAxisOptions,
    grid: {
      borderColor: !showGridLines ? '#ffffff00' : '#e7e7e7',
      strokeDashArray: 3,
      row: {
        colors: ['transparent', 'transparent'],
        opacity: 0.5
      },
      column: {
        colors: ['transparent', 'transparent'],
        opacity: 0.5
      }
    },
    tooltip: {
      theme: darkMode ? 'dark' : 'light',
      style: {
        fontSize: '12px',
        fontFamily: 'Montserrat',
      },
      color: '#666',
      onDatasetHover: {
        highlightDataSeries: true
      },
      x: {
        show: true,
        format: 'dd MMM',
        formatter: function (seriesName, val) {
          if (showCompareData) {
            if (val.seriesIndex === 1) {
              const dateLength = categories.length;
              const date = dayjs(categories[val.dataPointIndex], 'YYYY-MM-DD');
              const newDate = date.subtract(dateLength, 'day');
              return formatDateShort(newDate.format('YYYY-MM-DD'));
            } else {
              return formatDateShort(categories[val.dataPointIndex]);
            }
          } else {
            if (xAxisIsDate) {
              return formatDateShort(categories[val.dataPointIndex]);
            } else if (xAxisIsTime) {
              return formatTime(categories[val.dataPointIndex]);
            } else {
              return `${val}`;
            }
          }
        },
        style: {
          color: '#999'
        },
        textAlignment: 'center'
      },
      y: {
        formatter: function (val) {
          return `${val}`;
        },
        title: {
          formatter: function (seriesName, val) {
            if (showCompareData) {
              if (val.seriesIndex === 1) {
                return `Last period: `;
              } else {
                return `This period: `;
              }
            } else {
              return `${seriesName}: `;
            }
          },
          style: {
            color: '#999'
          }
        },
        style: {
          color: '#999'
        }
      },
      marker: {
        show: true,
      },
      items: {
        display: 'flex'
      },
      fixed: {
        enabled: false,
        position: 'topRight',
        offsetX: 0,
        offsetY: 0,
      },
    },
    legend: {
      show: showLegend,
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      fontSize: '12px',
      fontFamily: 'Montserrat',
      colors: '#222',
      fontWeight: 600,
      offsetY: -35,
      showForSingleSeries: true,
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: '#fff',
        radius: 12,
        offsetX: -5,
        offsetY: 1,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },
      onItemClick: {
        toggleDataSeries: true
      },
      onItemHover: {
        highlightDataSeries: true
      },
      labels: {
        useSeriesColors: false
      },
    }
  };


  if (chartType === 'area') {
    options.fill = {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.3,
        opacityTo: 0.4,
        stops: [0, 100]
      }
    }
  }
  
  

  function getPercentChange(oldNumber, newNumber){
    let decreaseValue = newNumber - oldNumber;
    return (decreaseValue / oldNumber);
  }





  return (
    <div className={`chart ${darkMode ? "darkModeCard" : ""}`} style={chartContainerStyles}>
      <span className='chartTitle'>{chartName}</span>

      <div className='chartTotalRow'>
        <span className='chartTotal'>
          {titleType === 'dollar' && new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}
          {titleType === 'percent' && new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2 }).format(total)}
          {titleType === 'number' && new Intl.NumberFormat('en-US').format(total)}
          {titleType === 'none' && ''}
        </span>
        <span className='chartChangePercent' style={{ color: getPercentChange(compareTotal, total) < 0 ? 'red' : 'green' }}>
          {new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2 }).format(getPercentChange(compareTotal, total))}
        </span>
      </div>

      <div style={{height: 'calc(100% - 75px)'}}>
        <ReactApexChart options={options} series={series} type={chartType} height={'100%'} />
      </div>
    </div>
  );
};

export default ChartComponent;
