
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { ChevronRightRounded, SearchRounded } from '@mui/icons-material';
import { collection, getDocs, query } from 'firebase/firestore';
import { db } from '../../FirebaseInit';


function WhatsNew ({showWhatsNew, setShowWhatsNew}) {
    const [omniUpdates, setOmniUpdates] = useState([])


    async function getOmniUpdates() {

        const q = query(collection(db, "omniUpdates"));

        const omniUpdatesTemp = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            omniUpdatesTemp.push({ id: doc.id, ...doc.data() });
        });
        setOmniUpdates(omniUpdatesTemp);
    }

    useEffect(() => {
      getOmniUpdates();
    }, [])



    return (
        <div className={`navWhatsNewRightPane ${showWhatsNew ? 'open' : ''}`}>
        <div className='navWhatsNewRightPaneHeaderContainer'>
            <span className='navWhatsNewRightPaneHeaderCollapseIcon' onClick={() => { setShowWhatsNew(false) }}>
                <ChevronRightRounded />
            </span>
            <span className='navWhatsNewRightPaneHeaderTitle'>What's New</span>
            <span className='navWhatsNewRightPaneHeaderCollapseIcon' style={{ opacity: 0 }}>
                <ChevronRightRounded />
            </span>
        </div>
        <div className='navWhatsNewRightPaneContent'>
            {omniUpdates.map((x, index) => { return ( 
                <div className='navWhatsNewRightPaneContentItem' key={x.id}>
                    <div className='navWhatsNewRightPaneContentItemTopRow'>
                        <span className='navWhatsNewRightPaneContentItemType'>{x.type}</span>
                        <span className='navWhatsNewRightPaneContentItemDate'>{(new Date(x.date.seconds * 1000)).toLocaleDateString('en-US', {month: 'long', day: 'numeric', year: 'numeric'})}</span>
                    </div>
                    <span className='navWhatsNewRightPaneContentItemTitle'>{(new Date(x.date.seconds * 1000)).toLocaleDateString('en-US', {month: 'long', day: 'numeric'})} | {x.title}</span>
                    <span className='navWhatsNewRightPaneContentItemDescription'>{x.description}</span>
                    <img className='navWhatsNewRightPaneContentItemMedia' src={x.mediaURL} />
                </div>
            )})}
        </div>
    </div>
    )
}





export default WhatsNew;