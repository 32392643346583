import react, { useState, useEffect } from 'react';
import axios from 'axios';
import './TopPerformers.css'
import dayjs from 'dayjs';
import { TrendingUpRounded } from '@mui/icons-material';
import ChartComponent from './Charts/AnalyticsChart';

function TopPerformers({widthPercent, dateStart, dateEnd, dateUpdateTrigger, darkMode, serverURL}) {
    const uid = localStorage.getItem('uid');
    const bid = localStorage.getItem('bid');


    const [allData, setAllData] = useState([])
    const [adAccountNames, setAdAccountNames] = useState([])
    const [adAccountTotals, setAdAccountTotals] = useState([])
    const [campaignNames, setCampaignNames] = useState([])
    const [campaignTotals, setCampaignTotals] = useState([])






    async function getTopPerformers () {
        try {
            const API_URL = `${serverURL}/getTopPerformers?omni_business_id=${bid}&dateStart=${dateStart.toISOString(0,10)}&dateEnd=${dateEnd.toISOString(0,10)}` ;
            console.log('get top performers: ', API_URL)
            const response = await axios.get(API_URL)
            setAllData(response.data)
    
            const adAccountData = response.data.adAccounts;
            const campaignData = response.data.campaigns;
        
            const topAdAccountNames = adAccountData.map(entry => (entry.name));
            const topAdAccountTotals = adAccountData.map(entry => (entry.spend * 1));
            const topCampaignNames = campaignData.map(entry => (entry.name));
            const topCampaignTotals = campaignData.map(entry => (entry.spend * 1));

            setAdAccountNames(topAdAccountNames)
            setAdAccountTotals(topAdAccountTotals)
            setCampaignNames(topCampaignNames)
            setCampaignTotals(topCampaignTotals)
            
            
  
        } catch (error) {
            console.log('ERROR loading stats TopPerformers.js', error)
        }
    }
  
    useEffect(() => {
      if (uid !== '') {
        getTopPerformers();
      }
    }, [uid, dateUpdateTrigger]);


    return ( 
      
        <div className={'TopPerformersContainer ' + (darkMode && ' darkModeCard')} style={{width: widthPercent + '%'}}>
           <span className='ProfitByDayTitle'>Top Performers</span>
           <span className='ProfitByDayDescription'>You have spent the most here</span>

                <div className='TopPerformersChartsContainer'>
                    <div className='TopPerformersChart ' style={{borderRadius: "10px 0px 0px 10px", borderRight: 0}}>
                    <ChartComponent chartName="Ad Accounts" line1Data={adAccountTotals} categories={adAccountNames} 
                            yAxisIsNumber={true} xAxisIsDate={false} xAxisIsTime={false} chartHeight={200} total="" compareTotal="" 
                            line1Name="Revenue" line2Name="" 
                            titleType='none' chartType='bar' xAxisLabelSize='14px' yAxisLabelSize='12px' 
                            chartContainerStyles={{boxShadow: 'none', border: 'none'}} darkMode={darkMode}
                            />
                    </div>
                    {/* <div className='TopPerformersChart' style={{borderRadius: "0px 10px 10px 0px", border: darkMode ? '1px solid #333' : '1px solid #e7e7e7'}}>
                        <ChartComponent chartName="Campaigns" line1Data={campaignTotals} categories={campaignNames} 
                            yAxisIsNumber={true} xAxisIsDate={false} xAxisIsTime={false} chartHeight={400} total="" compareTotal="" 
                            line1Name="Revenue" line2Name="" 
                            titleType='none' chartType='bar' xAxisLabelSize='14px' yAxisLabelSize='12px' 
                            chartContainerStyles={{boxShadow: 'none', border: 'none'}} darkMode={darkMode}
                            />
                    </div> */}
                    
                </div>
                
           
           
            

        </div>
        
    );
}

export default TopPerformers;