import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, updateDoc, setDoc, getDoc } from 'firebase/firestore';
import axios from 'axios';




function ProcessTiktokRedirect({serverURL}) {

    const firestore = getFirestore();
    const bid = localStorage.getItem('bid');
    const uid = localStorage.getItem('uid');


    const navigate = useNavigate();

    function redirectToTiktokAuth() {
        const url = `https://business-api.tiktok.com/portal/auth?app_id=7394265783863345169&state=your_custom_params&redirect_uri=http%3A%2F%2Flocalhost%3A3011%2Fprocess-tiktok-redirect`;
        console.log('Redirecting to Tiktok auth:', url);
        window.location.href = url;
    }
    
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        
        if (code) {
            saveCodeToDB(code);
        } else {
            // If no code is present, redirect to Tiktok auth
            redirectToTiktokAuth();
        }
    }, []); 


    async function saveCodeToDB(code) {
        const businessRef = doc(firestore, 'businesses', bid);

        try {
            const docSnapshot = await getDoc(businessRef);
            if (docSnapshot.exists()) {
                await updateDoc(businessRef, {
                    tiktokOAuthCode: decodeURIComponent(code),
                    tiktokOAuthAccessTokenNeverUsed: true
                });
            
                console.log('Code updated in Firestore successfully');
            } else {
                await setDoc(businessRef, {
                    tiktokOAuthCode: decodeURIComponent(code),
                    tiktokOAuthAccessTokenNeverUsed: true
                });
                console.log('Code saved to Firestore successfully');
            }
            await fetchTiktokRefreshToken();

            navigate('/tiktok-accounts');
        } catch (error) {
            console.error('Error saving/updating code in Firestore:', error);
        }
    }

    
    async function fetchTiktokRefreshToken() {
         const businessRef = doc(firestore, 'businesses', bid);
        try {
            const data = {
                omniBusinessID: bid,
            };
            console.log(`${serverURL}/getTiktokRefreshToken`, data)
            const response = await axios.post(`${serverURL}/getTiktokRefreshToken`, data);
            console.log('Token fetched:', response.data);
            await updateDoc(businessRef, {
                tiktokOAuthAccessTokenNeverUsed: false,
            });
            // Handle additional logic here if needed (e.g., navigate to another route, store data)
        } catch (error) {
            console.error('Error fetching Tiktok refresh token:', error);
        }
    }
    

    return <div>Loading...</div>;
}

export default ProcessTiktokRedirect;
