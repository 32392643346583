import './AdsManager.css';
import React, { useState, useEffect } from 'react';
import { getAuth } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import FolderIcon from '../../assets/Icons/Folder';
import CampaignIcon from '../../assets/Icons/Campaign';
import { ChevronRightRounded, CloseRounded, Check, CheckRounded } from '@mui/icons-material';
import CampaignGrid from './Facebook/Campaigns/CampaignGrid';
import { CircularProgress, ClickAwayListener } from '@mui/material';
import DateSelector from '../../components/DateSelector';
import dayjs from 'dayjs';
import CustomizeColumnButton from '../../components/CustomizeColumnsPopup';
import { addDoc, collection, deleteDoc, doc, getDocs, onSnapshot, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../../FirebaseInit';
import CalendarIcon from '../../assets/Icons/CalendarIcon';
import EditIcon from '../../assets/Icons/EditIcon';
import Switch from "react-switch";
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import AdsetGrid from './Facebook/Adsets/AdsetGrid';
import AdGrid from './Facebook/Ads/AdGrid';
import AdAccountGrid from './AdAccounts/AdAccountGrid';
import PurchasesPopup from './PurchasesPopup';
import SortableItem from './DragAndDropItem';


const getStartOfDay = (date) => {
  const startOfDay = new Date(date);
  startOfDay.setHours(0, 0, 0, 0); // Set time to 00:00:00.000
  return startOfDay;
};

const getDateDaysAgo = (days) => {
    const date = new Date();
    date.setDate(date.getDate() - days); // Subtract the specified number of days
    return date;
};



function Ads({serverURL, pythonServerURL, switchedBusinesses, setSnackbarInfo}) {
  
  const uid = localStorage.getItem('uid');
  const bid = localStorage.getItem('bid');

  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0)

  const [loadingAdAccounts, setLoadingAdAccounts] = useState(true);
  
  const [selectedSource, setSelectedSource] = useState('fb');

  const [selectedAdAccounts, setSelectedAdAccounts] = useState([])
  const [selectedCampaigns, setSelectedCampaigns] = useState([])
  const [selectedAdsets, setSelectedAdsets] = useState([])
  const [selectedAds, setSelectedAds] = useState([])

  const [selectedAdForDetails, setSelectedAdForDetails] = useState({})
  const [showAdAccountDropdown, setShowAdAccountDropdown] = useState(false)
  const [showAttributionDropdown, setAttributionDropdown] = useState(false)
  const [selectedAdAccount, setSelectedAdAccount] = useState({account_id: 'act_1424400171449948'})
  const [showStatusDropdown, setShowStatusDropdown] = useState(false)
  const [filterStatus, setFilterStatus] = useState('ANY')
  const [showAdDetailsPane, setShowAdDetailsPane] = useState(false)
  const [selectedAdSetId, setSelectedAdsetId] = useState([]);

  const [adAccounts, setAdAccounts] = useState([])
  const [campaigns, setCampaigns] = useState([]);
  const [adsets, setAdsets] = useState([]);
  const [ads, setAds] = useState([]);
  const [googleCampaigns, setGoogleCampaigns] = useState([]);

  const [attributionModel, setAttributionModel] = useState('first_click');
  const [attributionWindow, setAttributionWindow] = useState('7 days');

  
  const [selectedAdAccountForPurchasesPopup, setSelectedAdAccountForPurchasesPopup] = useState(null);
  const [selectedCampaignForPurchasesPopup, setSelectedCampaignForPurchasesPopup] = useState(null);
  const [selectedAdsetForPurchasesPopup, setSelectedAdsetForPurchasesPopup] = useState(null);
  const [selectedAdForPurchasesPopup, setSelectedAdForPurchasesPopup] = useState(null);

  const [showSelectColumnsPopup, setShowSelectColumnsPopup] = useState(false)
  const [selectedColumns, setSelectedColumns] = useState('performance');
  const [selectedAdLevel, setSelectedAdLevel] = useState(0);
  const [showAttributionsWindowDropdown, setShowAttributionsWindowDropdown] = useState(false);
  const [showDateSelectors, setShowDateSelectors] = useState(false);
  const [omniBusinessId, setOmniBusinessID] = useState(bid);

  const [dateStart, setDateStart] = useState(getStartOfDay(getDateDaysAgo(1)));
  const [dateEnd, setDateEnd] = useState(getStartOfDay(getDateDaysAgo(1)));
  
  const [dateUpdateTrigger, setDateUpdateTrigger] = useState(false); 
  const [refreshDataTrigger, setRefreshDataTrigger] = useState(false);
  
  const [selectedPreset, setSelectedPreset] = useState('');
  const [columnPresets, setColumnPresets] = useState([]);
  const [showNewPresetNameInput, setShowNewPresetNameInput] = useState(false);
  const [presetName, setPresetName] = useState('');
  const [presetDescription, setPresetDescription] = useState('');
  const [showPurchasesPopup, setShowPurchasesPopup] = useState(false)

  const [adAccountColumns, setAdAccountColumns] = useState([])
  const [campaignColumns, setCampaignColumns] = useState([])
  const [adsetColumns, setAdsetColumns] = useState([])
  const [adColumns, setAdColumns] = useState([])


  const columnSettings = [
    { column: '', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: '', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'tp_status', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'tp_name', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'tp_daily_budget', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'tp_delivery', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'omni_bounce_rate', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'tp_outbound_ctr', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'tp_outbound_clicks', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'tp_aov', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'tp_cpa', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'tp_cpm', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'tp_ctr', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'tp_cpc', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'tp_impressions', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'tp_clicks', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'tp_roas', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'tp_cvr', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'omni_avg_session_duration', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'omni_avg_page_views_per_session', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'omni_new_visitor_percent', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'revenue_diff', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'tp_revenue', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'omni_profit', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'omni_cogs', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'omni_new_customer_cvr', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'omni_cvr', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'omni_aov', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'omni_cpa', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'omni_cost_per_atc', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'omni_add_to_cart', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'omni_new_visitor_cost', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'omni_new_visitor', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'omni_cost_per_visitor', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'omni_unique_sessions', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'omni_sessions', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'omni_revenue', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'omni_roas', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'tp_orders', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'omni_new_customer_order_percent', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'omni_orders', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
    { column: 'tp_spend', places: ['ad_accounts', 'fb_campaigns', 'fb_adsets', 'fb_ad', 'go_campaigns', 'go_adsets', 'go_ads', 'tt_campaigns', 'tt_adsets', 'tt_ads', 'klaviyo', 'snap_campaigns, snap_adsets, snap_ads'] },
  ];
  const [availableColumns, setAvailableColumns] = useState(columnSettings);
  const [columnIndexesNotAppliedYet, setColumnIndexesNotAppliedYet] = useState([]);
  const [columnIndexes, setColumnIndexes] = useState([]);
  




 


 
  













  useEffect(() => {
    async function getAttribution () {
      setLoadingPercentage(20);
      try {
        const data = {
          type: '',
          selectedAdLevel: selectedAdLevel,
          selectedSource: selectedSource,
          attribution_model: attributionModel,
          attribution_window: attributionWindow,
          date_start: new Date(dateStart).toISOString().slice(0, 10) + 'T00:00:00',
          date_end: new Date(dateEnd).toISOString().slice(0, 10) + 'T23:59:59',
          omni_business_id: omniBusinessId,
          campaign_ids: selectedCampaigns,
          adset_ids: selectedAdsets,
          ad_ids: selectedAds,
        }
        const response = await axios.post(`${serverURL}/getAttribution`, data);
        console.log('ATTRIBUTION RESPONSE', response.data)

        if (selectedAdLevel === 0) {
          setAdAccounts(response.data || []);
        } else if (selectedAdLevel === 1) {
          setCampaigns(response.data || []);
        } else if (selectedAdLevel === 2) {
          setAdsets(response.data || []);
        } else if (selectedAdLevel === 3) {
          setAds(response.data || []);
        }

        setLoadingPercentage(0);
        setLoadingAdAccounts(false);
      } catch (error) {
        console.error('ERROR loading attribution data', error);
        setLoadingAdAccounts(false);
        setLoadingPercentage(0);
      }
    };
    getAttribution();
  }, [switchedBusinesses, selectedAdAccount, attributionModel, attributionWindow, refreshDataTrigger, dateUpdateTrigger, selectedAdLevel]);






  useEffect(() => {
    setCampaigns([]);
    setAdsets([]);
    setAds([]);
  }, [selectedSource])















  function adjustSelectedAdAccounts(adID) {
    let selectedAdAccountsTemp;

    if (selectedAdAccounts.includes(adID)) {
      selectedAdAccountsTemp = selectedAdAccounts.filter(item => item !== adID.toString());
    } else {
      selectedAdAccountsTemp = [...selectedAdAccounts, adID];
    }
    setSelectedAdAccounts(selectedAdAccountsTemp);
  }


  function adjustSelectedCampaigns(adID) {
    setSelectedCampaigns((prevSelected) => {
      if (prevSelected.includes(adID)) {
        return prevSelected.filter(item => item !== adID.toString());
      } else {
        return [...prevSelected, adID];
      }
    });
  }
  
  

  function adjustSelectedAdsets(adID) {
    let selectedAdsetsTemp;

    if (selectedAdsets.includes(adID)) {
      selectedAdsetsTemp = selectedAdsets.filter(item => item !== adID.toString());
    } else {
      selectedAdsetsTemp = [...selectedAdsets, adID];
    }
    setSelectedAdsets(selectedAdsetsTemp);
  }



  function adjustSelectedAds(adID) {
    let selectedAdsTemp;

    if (selectedAds.includes(adID)) {
      selectedAdsTemp = selectedAds.filter(item => item !== adID.toString());
    } else {
      selectedAdsTemp = [...selectedAds, adID];
    }
    setSelectedAds(selectedAdsTemp);
  }












  function toggleColumnVisibility(column) {
    const columnIndexesTemp = { ...columnIndexesNotAppliedYet };
  
    if (!columnIndexesTemp[column.key]) {
      // ADD COLUMN
      columnIndexesTemp[column.key] = Math.max(...Object.values(columnIndexesTemp)) + 1;
    } else {
      // REMOVE COLUMN
      if (column.key !== 'tp_status' && column.key !== 'tp_name') {
        delete columnIndexesTemp[column.key];
      }
    }
  
    // Ensure `tp_status` and `tp_name` are always present and in the correct order
    if (!columnIndexesTemp['tp_status']) {
      columnIndexesTemp['tp_status'] = 0;
    }
    if (!columnIndexesTemp['tp_name']) {
      columnIndexesTemp['tp_name'] = 1;
    }
  
    // Reorder `tp_status` and `tp_name` to the beginning
    const orderedColumnIndexes = {
      tp_status: columnIndexesTemp['tp_status'],
      tp_name: columnIndexesTemp['tp_name'],
      ...Object.fromEntries(
        Object.entries(columnIndexesTemp).filter(
          ([key]) => key !== 'tp_status' && key !== 'tp_name'
        )
      ),
    };
  
    setColumnIndexesNotAppliedYet(orderedColumnIndexes);
    console.log(orderedColumnIndexes);
  }
  
  
  


  // Get column preset object from user collection 
  async function getColumnPreset() {
    const lastUsedColumnPreset = localStorage.getItem('lastUsedColumnPreset');

      const columnPresetsTemp = [];
      // Query for showForEveryone
      const q1 = query(collection(db, "adsManagerColumnPresets"), where("showForEveryone", "==", true));
      const querySnapshot1 = await getDocs(q1);
      querySnapshot1.forEach((doc) => {
        columnPresetsTemp.push({
          id: doc.id,
          ...doc.data()
        });
      });
      // Query for uid
      const q2 = query(collection(db, "adsManagerColumnPresets"), where("uid", "==", uid), where("showForEveryone", "==", false));
      const querySnapshot2 = await getDocs(q2);
      querySnapshot2.forEach((doc) => {

        columnPresetsTemp.push({
          id: doc.id,
          ...doc.data()
        }); 
        
      });
      setColumnPresets(columnPresetsTemp);


      if (lastUsedColumnPreset) {
        const selectedPresetTemp = columnPresetsTemp.find((x) => x.id === lastUsedColumnPreset);
        setSelectedPreset(selectedPresetTemp);
        setColumnIndexes(selectedPresetTemp? selectedPresetTemp.presets : columnSettings);
        setColumnIndexesNotAppliedYet(selectedPresetTemp? selectedPresetTemp.presets : columnSettings);
      } else {
        setSelectedPreset(columnPresetsTemp ? columnPresetsTemp[0] : columnSettings );
      }

      // setAvailableColumns(columnPresetsTemp[0]?.presets);
  }

  useEffect(() => {
    getColumnPreset();
  }, []);


  useEffect(() => {
    if (selectedPreset) {
      localStorage.setItem('lastUsedColumnPreset', selectedPreset.id);
    }
  }, [selectedPreset])
  






  async function saveToCurrentPreset () {

    console.log('saveToCurrentPreset');

    const columnPresetRef = doc(db, "adsManagerColumnPresets", selectedPreset.id);
    await updateDoc(columnPresetRef, {
      presets: columnIndexesNotAppliedYet
    });
    getColumnPreset();
    setSelectedPreset(columnPresets[0]);
  }
 

  async function saveAsNewPreset () {
    const columnPresetRef = collection(db, "adsManagerColumnPresets");
    await addDoc(columnPresetRef, {
      description: presetDescription,
      name: presetName,
      presets: columnIndexesNotAppliedYet,
      showForEveryone: false,
      uid: uid,

    });
    setPresetName('');
    setPresetDescription('');
    getColumnPreset();
    setShowNewPresetNameInput(false);
  }

  async function deletePreset () {
    const columnPresetRef = doc(db, "adsManagerColumnPresets", selectedPreset.id);
    await deleteDoc(columnPresetRef);
    getColumnPreset();
  }



  const handleDragEnd = async ({ active, over }) => {
    // If the dragged or target items are `tp_status` or `tp_name`, ignore the drag event
    if (
      active.id !== 'tp_status' &&
      active.id !== 'tp_name' &&
      over.id !== 'tp_status' &&
      over.id !== 'tp_name'
    ) {
      if (active.id !== over.id) {
        async function reorderColumns() {
          // Find the positions of `active` and `over` in the `campaignColumns` array
          let columnsVariable = {};

          if (selectedAdLevel == 0) {
            columnsVariable = adAccountColumns;
          } else if (selectedAdLevel == 1) {
            columnsVariable = campaignColumns;
          } else if (selectedAdLevel == 2) {
            columnsVariable = adsetColumns;
          } else if (selectedAdLevel == 3) {
            columnsVariable = adColumns;
          }

          const oldIndex = columnsVariable.findIndex((item) => item?.key === active.id);
          const newIndex = columnsVariable.findIndex((item) => item?.key === over.id);
  
          // Move the item in the array, excluding `tp_status` and `tp_name`
          const nonFixedColumns = columnsVariable.filter(
            (item) => item?.key !== 'tp_status' && item?.key !== 'tp_name'
          );
          const movedNonFixedColumns = arrayMove(nonFixedColumns, oldIndex - 2, newIndex - 2);
  
          // Rebuild the array with `tp_status` first, `tp_name` second, followed by the reordered items
          return [
            columnsVariable.find((item) => item?.key === 'tp_status'),
            columnsVariable.find((item) => item?.key === 'tp_name'),
            ...movedNonFixedColumns
          ];
        }
  
        // Get the reordered columns with fixed positions for `tp_status` and `tp_name`
        const reorderedColumns = await reorderColumns();
  
        // Update campaign columns to reflect the new order
        // setCampaignColumnsNotAppliedYet(reorderedColumns);
        if (selectedAdLevel == 0) {
          setAdAccountColumns(reorderedColumns);
        } else if (selectedAdLevel == 1) {
          setCampaignColumns(reorderedColumns);
        } else if (selectedAdLevel == 2) {
          setAdsetColumns(reorderedColumns);
        } else if (selectedAdLevel == 3) {
          setAdColumns(reorderedColumns);
        }

  
        // Rebuild columnIndexesTemp with fixed indexes for `tp_status` and `tp_name`
        const columnIndexesTemp = {
          tp_status: 1, // Fixed index for `tp_status`
          tp_name: 2,   // Fixed index for `tp_name`
        };
  
        // Assign indexes for the reordered columns starting from 2
        reorderedColumns.forEach((col, index) => {
          if (col.key !== 'tp_status' && col.key !== 'tp_name' && columnIndexesNotAppliedYet[col.key]) {
            columnIndexesTemp[col.key] = index;
          }
        });
  
        console.log(columnIndexesTemp);
        setColumnIndexesNotAppliedYet(columnIndexesTemp);
      }
    }
  };
  
  
  












  return (
    <div className='AdsManagerContainer'>
      <PurchasesPopup
        showPurchasesPopup={showPurchasesPopup}
        setShowPurchasesPopup={setShowPurchasesPopup}
        selectedAdAccountForPurchasesPopup={selectedAdAccountForPurchasesPopup}
        selectedCampaignForPurchasesPopup={selectedCampaignForPurchasesPopup}
        selectedAdsetForPurchasesPopup={selectedAdsetForPurchasesPopup}
        selectedAdForPurchasesPopup={selectedAdForPurchasesPopup}
        omniBusinessId={omniBusinessId}
        serverURL={serverURL}
        dateStart={dateStart}
        dateEnd={dateEnd}
        attributionModel={attributionModel}
        attributionWindow={attributionWindow}
        selectedSource={selectedSource}
        selectedAdLevel={selectedAdLevel}
      />

      {loadingPage ?
        <div className='AdsManagerLoadingContainer'>
          <div className='AdsManagerLoadingProgressParent'>
            <div className='AdsManagerLoadingProgressChild' style={{width: loadingPercentage + '%'}}></div>
          </div>
          <span className='AdsManagerLoadingText'>Loading Ads Manager... </span>
        </div>
      :

        <div className='AdsManagerSubContainer'>
          <div className='AdsManagerTopRow'>
            <DateSelector setShowDateSelectors={setShowDateSelectors} showDateSelectors={showDateSelectors} dateStart={dateStart} setDateStart={setDateStart} dateEnd={dateEnd} setDateEnd={setDateEnd} dateUpdateTrigger={dateUpdateTrigger} setDateUpdateTrigger={setDateUpdateTrigger} />
          </div>


          <div className='AdsManagerTableLevelSelectorContainer'>
            <div className={selectedAdLevel == 0 ? 'AdsManagerTableLevelButtonSelected' : 'AdsManagerTableLevelButton'} onClick={() => {setSelectedAdLevel(0)}}>

              <div className='AdsManagerTableLevelSelectorContainerLeftRow'>
                <svg xmlns="http://www.w3.org/2000/svg" height="16px" width="16px" version="1.1" id="_x32_" viewBox="0 0 512 512" >
                  <g>
                    <path fill="currentColor" class="st0" d="M332.482,0H179.517c-20.036,0-36.276,16.241-36.276,36.285v439.429c0,20.045,16.24,36.286,36.276,36.286   h152.964c20.036,0,36.285-16.241,36.285-36.286V36.285C368.767,16.241,352.517,0,332.482,0z M256,463.545   c-28.786,0-52.116-23.339-52.116-52.116c0-28.777,23.33-52.116,52.116-52.116c28.786,0,52.116,23.339,52.116,52.116   C308.116,440.206,284.785,463.545,256,463.545z M256,308.116c-28.786,0-52.116-23.339-52.116-52.116s23.33-52.116,52.116-52.116   c28.786,0,52.116,23.339,52.116,52.116S284.785,308.116,256,308.116z M256,152.688c-28.786,0-52.116-23.339-52.116-52.116   c0-28.777,23.33-52.116,52.116-52.116c28.786,0,52.116,23.339,52.116,52.116C308.116,129.348,284.785,152.688,256,152.688z"/>
                    <path fill="currentColor" class="st0" d="M44.804,73.276c0,30.349,19.553,57.242,48.428,66.581l29.286,9.473V44.188H44.804V73.276z"/>
                    <path fill="currentColor" class="st0" d="M44.804,233.276c0,30.349,19.553,57.242,48.428,66.581l29.286,9.473V204.188H44.804V233.276z"/>
                    <path fill="currentColor" class="st0" d="M44.804,393.276c0,30.349,19.553,57.242,48.428,66.581l29.286,9.473V364.188H44.804V393.276z"/>
                    <path fill="currentColor" class="st0" d="M389.482,44.188V149.33l29.285-9.473c28.875-9.339,48.429-36.232,48.429-66.581V44.188H389.482z"/>
                    <path fill="currentColor" class="st0" d="M389.482,309.33l29.285-9.473c28.875-9.339,48.429-36.232,48.429-66.581v-29.089h-77.714V309.33z"/>
                    <path fill="currentColor" class="st0" d="M389.482,469.33l29.285-9.473c28.875-9.339,48.429-36.232,48.429-66.581v-29.089h-77.714V469.33z"/>
                  </g>
                </svg>
                <span className='AdsManagerTableLevelSelectorContainerTitle'>Traffic Sources</span>
              </div>
            </div>


            <div className={selectedAdLevel == 1 ? 'AdsManagerTableLevelButtonSelected' : 'AdsManagerTableLevelButton'} onClick={() => {setSelectedAdLevel(1)}}>
              <div className='AdsManagerTableLevelSelectorContainerLeftRow'>
                <svg viewBox="0 0 48 48" width="20px" height="20px" fill="currentColor"><path d="M40.5 10H23.74c-1.08 0-2.03-.69-2.37-1.71s-.18-.53-.18-.53A5.496 5.496 0 0 0 15.97 4H6.5C4.02 4 2 6.02 2 8.5v30C2 41.53 4.47 44 7.5 44h33c3.03 0 5.5-2.47 5.5-5.5v-23c0-3.03-2.47-5.5-5.5-5.5zm-9.83 23.73c-.2.18-.46.27-.72.27-.17 0-.35-.04-.51-.13L24 30.98l-5.44 2.89c-.4.21-.89.15-1.23-.14a.98.98 0 0 1-.23-1.16l5.95-12c.17-.35.54-.57.95-.57s.77.22.95.57l5.95 12c.19.39.1.86-.23 1.16z"></path></svg>
                {selectedSource == 'klaviyo' && <span className='AdsManagerTableLevelSelectorContainerTitle'>Campaigns/Flows</span>}
                {selectedSource != 'klaviyo' && <span className='AdsManagerTableLevelSelectorContainerTitle'>Campaigns</span>}
              </div>
              {selectedCampaigns.length > 0 && (
                <div className='AdsManagerTableLevelButtonSelectedContainer' onClick={() => { setSelectedCampaigns([]) }} >
                  {selectedCampaigns.length} selected
                  <CloseRounded style={{width: 12}}/>
                </div>
              )}
            </div>


            {selectedSource != 'klaviyo' && (
              <div className={selectedAdLevel == 2 ? 'AdsManagerTableLevelButtonSelected' : 'AdsManagerTableLevelButton'} onClick={() => {setSelectedAdLevel(2)}}>
                <div className='AdsManagerTableLevelSelectorContainerLeftRow'>
                  <svg viewBox="0 0 48 48" width="20px" height="20px" fill="currentColor"><rect x="26" y="2" width="20" height="20" rx="4.5" ry="4.5"></rect><rect x="2" y="26" width="20" height="20" rx="4.5" ry="4.5"></rect><path d="M17.5 2h-11C4.02 2 2 4.02 2 6.5v11C2 19.98 4.02 22 6.5 22h11c2.48 0 4.5-2.02 4.5-4.5v-11C22 4.02 19.98 2 17.5 2zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zM41.5 26h-11c-2.48 0-4.5 2.02-4.5 4.5v11c0 2.48 2.02 4.5 4.5 4.5h11c2.48 0 4.5-2.02 4.5-4.5v-11c0-2.48-2.02-4.5-4.5-4.5zM36 40c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z"></path></svg>
                  {selectedSource == 'google' && <span className='AdsManagerTableLevelSelectorContainerTitle'>Ad Groups</span>}
                  {selectedSource == 'klaviyo' && <span className='AdsManagerTableLevelSelectorContainerTitle'>Flow Messages</span>}
                  {selectedSource != 'google' && selectedSource != 'klaviyo' && <span className='AdsManagerTableLevelSelectorContainerTitle'>Adsets</span>}
                </div>
                {selectedAdsets.length > 0 && (
                  <div className='AdsManagerTableLevelButtonSelectedContainer' onClick={() => { setSelectedAdsets([]) }} >
                    {selectedAdsets.length} selected
                    <CloseRounded style={{width: 12}}/>
                  </div>
                )}
              </div>
            )}



            {selectedSource != 'klaviyo' && (
              <div className={selectedAdLevel == 3 ? 'AdsManagerTableLevelButtonSelected' : 'AdsManagerTableLevelButton'} onClick={() => {setSelectedAdLevel(3)}}>
                <div className='AdsManagerTableLevelSelectorContainerLeftRow'>
                  <svg viewBox="0 0 16 16" width="20px" height="20px" fill="currentColor"><g dataName="Layer 2"><path d="M13.25 1H2.75A1.76 1.76 0 0 0 1 2.75v10.5A1.76 1.76 0 0 0 2.75 15h10.5A1.76 1.76 0 0 0 15 13.25V2.75A1.76 1.76 0 0 0 13.25 1zM4.5 5.5a1 1 0 1 1 1-1 1 1 0 0 1-1 1zm8-.5h-5a.5.5 0 0 1 0-1h5a.5.5 0 0 1 0 1z" dataName="16"></path></g></svg>
                  <span className='AdsManagerTableLevelSelectorContainerTitle'>Ads</span>
                </div>
                {selectedAds.length > 0 && (
                  <div className='AdsManagerTableLevelButtonSelectedContainer' onClick={() => { setSelectedAds([]) }} >
                    {selectedAds.length} selected
                    <CloseRounded style={{width: 12}}/>
                  </div>
                )}
              </div>
            )}
           
          </div>




          {selectedAdLevel == 0 && (
            <AdAccountGrid 
              adjustSelectedAdAccounts={adjustSelectedAdAccounts}
              adAccounts={adAccounts} 
              setSelectedAdAccountForPurchasesPopup={setSelectedAdAccountForPurchasesPopup}
              selectedAdAccounts={selectedAdAccounts}
              selectedColumns={selectedColumns}
              filterStatus={filterStatus}
              serverURL={serverURL}
              omni_business_id={bid}
              refreshDataTrigger={refreshDataTrigger}
              setRefreshDataTrigger={setRefreshDataTrigger}
              availableColumns={availableColumns}
              columnIndexes={columnIndexes}
              setShowAttributionsWindowDropdown={setShowAttributionsWindowDropdown}
              showAttributionsWindowDropdown={showAttributionsWindowDropdown} 
              setAttributionWindow={setAttributionWindow}
              attributionWindow={attributionWindow}
              setShowSelectColumnsPopup={setShowSelectColumnsPopup}
              showSelectColumnsPopup={showSelectColumnsPopup}
              setAttributionDropdown={setAttributionDropdown} 
              showAttributionDropdown={showAttributionDropdown}
              attributionModel={attributionModel}
              setAttributionModel={setAttributionModel}
              setShowStatusDropdown={setShowStatusDropdown}
              showStatusDropdown={showStatusDropdown}
              setFilterStatus={setFilterStatus}
              setShowPurchasesPopup={setShowPurchasesPopup} 
              setAdAccountColumns={setAdAccountColumns}
              adAccountColumns={adAccountColumns}
              setSelectedAdLevel={setSelectedAdLevel}
              setSelectedSource={setSelectedSource}
              loadingAdAccounts={loadingAdAccounts}
              loadingPercentage={loadingPercentage}
          />
          )}

          {selectedAdLevel == 1 && (
            <CampaignGrid 
              adjustSelectedCampaigns={adjustSelectedCampaigns}
              campaigns={campaigns} 
              setSelectedCampaignForPurchasesPopup={setSelectedCampaignForPurchasesPopup}
              selectedCampaigns={selectedCampaigns}
              selectedColumns={selectedColumns}
              filterStatus={filterStatus}
              serverURL={serverURL}
              pythonServerURL={pythonServerURL}
              omni_business_id={bid}
              refreshDataTrigger={refreshDataTrigger}
              setRefreshDataTrigger={setRefreshDataTrigger}
              availableColumns={availableColumns}
              columnIndexes={columnIndexes}
              setShowAttributionsWindowDropdown={setShowAttributionsWindowDropdown}
              showAttributionsWindowDropdown={showAttributionsWindowDropdown} 
              setAttributionWindow={setAttributionWindow}
              attributionWindow={attributionWindow}
              setShowSelectColumnsPopup={setShowSelectColumnsPopup}
              showSelectColumnsPopup={showSelectColumnsPopup}
              setAttributionDropdown={setAttributionDropdown} 
              showAttributionDropdown={showAttributionDropdown}
              attributionModel={attributionModel}
              setAttributionModel={setAttributionModel}
              setShowStatusDropdown={setShowStatusDropdown}
              showStatusDropdown={showStatusDropdown}
              setFilterStatus={setFilterStatus}
              setShowPurchasesPopup={setShowPurchasesPopup} 
              setCampaignColumns={setCampaignColumns}
              campaignColumns={campaignColumns}
              setSelectedAdLevel={setSelectedAdLevel}
              source={selectedSource}
              setSnackbarInfo={setSnackbarInfo}
            />
          )}
          
          {selectedAdLevel == 2 && (
            <AdsetGrid 
              adjustSelectedAdsets={adjustSelectedAdsets}
              adsets={adsets} 
              setSelectedAdsetForPurchasesPopup={setSelectedAdsetForPurchasesPopup}
              selectedCampaigns={selectedCampaigns}
              selectedAdsets={selectedAdsets}
              selectedColumns={selectedColumns}
              filterStatus={filterStatus}
              serverURL={serverURL}
              omni_business_id={bid}
              refreshDataTrigger={refreshDataTrigger}
              setRefreshDataTrigger={setRefreshDataTrigger}
              availableColumns={availableColumns}
              columnIndexes={columnIndexes}
              setShowAttributionsWindowDropdown={setShowAttributionsWindowDropdown}
              showAttributionsWindowDropdown={showAttributionsWindowDropdown} 
              setAttributionWindow={setAttributionWindow}
              attributionWindow={attributionWindow}
              setShowSelectColumnsPopup={setShowSelectColumnsPopup}
              showSelectColumnsPopup={showSelectColumnsPopup}
              setAttributionDropdown={setAttributionDropdown} 
              showAttributionDropdown={showAttributionDropdown}
              attributionModel={attributionModel}
              setAttributionModel={setAttributionModel}
              setShowStatusDropdown={setShowStatusDropdown}
              showStatusDropdown={showStatusDropdown}
              setFilterStatus={setFilterStatus}
              setShowPurchasesPopup={setShowPurchasesPopup} 
              setAdsetColumns={setAdsetColumns}
              adsetColumns={adsetColumns}
              setSelectedAdLevel={setSelectedAdLevel}
              source={selectedSource}
              setSnackbarInfo={setSnackbarInfo}

            />
          )}



          {selectedAdLevel == 3 && (
            <AdGrid
              adjustSelectedAds={adjustSelectedAds}
              ads={ads} 
              setSelectedAdForPurchasesPopup={setSelectedAdForPurchasesPopup}
              selectedCampaigns={selectedCampaigns}
              selectedAdsets={selectedAdsets}
              selectedAds={selectedAds}
              selectedColumns={selectedColumns}
              filterStatus={filterStatus}
              serverURL={serverURL}
              omni_business_id={bid}
              refreshDataTrigger={refreshDataTrigger}
              setRefreshDataTrigger={setRefreshDataTrigger}
              availableColumns={availableColumns}
              columnIndexes={columnIndexes}
              setShowAttributionsWindowDropdown={setShowAttributionsWindowDropdown}
              showAttributionsWindowDropdown={showAttributionsWindowDropdown} 
              setAttributionWindow={setAttributionWindow}
              attributionWindow={attributionWindow}
              setShowSelectColumnsPopup={setShowSelectColumnsPopup}
              showSelectColumnsPopup={showSelectColumnsPopup}
              setAttributionDropdown={setAttributionDropdown} 
              showAttributionDropdown={showAttributionDropdown}
              attributionModel={attributionModel}
              setAttributionModel={setAttributionModel}
              setShowStatusDropdown={setShowStatusDropdown}
              showStatusDropdown={showStatusDropdown}
              setFilterStatus={setFilterStatus}
              setShowPurchasesPopup={setShowPurchasesPopup} 
              setAdColumns={setAdColumns}
              adColumns={adColumns}
              setSelectedAdLevel={setSelectedAdLevel}
              source={selectedSource}

            />
          )}










        </div>
      }

































































        {/* Ad Details Right Pane */}
        <div className={`adsDetailsRightPane ${showAdDetailsPane ? 'open' : ''}`}>
          <div className='adsDetailsRightPaneHeaderContainer'>
            <span className='adsDetailsRightPaneHeaderCollapseIcon' onClick={() => { setShowAdDetailsPane(false) }}>
              <ChevronRightRounded />
            </span>
            <span className='adsDetailsRightPaneHeaderTitle'>Details</span>
            <span className='adsDetailsRightPaneHeaderCollapseIcon' style={{ opacity: 0 }}>
              <ChevronRightRounded />
            </span>
          </div>
          <div className='adsDetailsRightPaneContent'>
            <div className='adsDetailsRightPaneTopSection'>
              <div className='adsDetailsRightPaneTopSectionImage'>{selectedAdForDetails.type == 'campaign' ? <FolderIcon /> : <CampaignIcon />}</div>
              <span className='adsDetailsRightPaneTopSectionName'>{selectedAdForDetails.campaign_name}</span>
              <span className='adsDetailsRightPaneTopSectionStatus'>{selectedAdForDetails.campaign_status}</span>
              <span className='adsDetailsRightPaneTopSectionBudget'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(selectedAdForDetails.campaign_daily_budget)}</span>
            </div>
          </div>
        </div>











        {/* Customize Columns Popup */}
        <div className={`adsColumnsPopupContainer ${showSelectColumnsPopup ? 'open' : ''}`}>
          <div className={`adsColumnsPopup`}>
            <span className='adsColumnsPopupTitle'>Customize Columns</span>

            <div className='adsColumnsPopupContentMainContainer'>

              <div>
                <span className='adsColumnsPopupContentLeftTitle'>Presets</span>
                <div className='adsColumnsPopupContentLeftScrollContainer'>
                  <div className='adsColumnsPopupContentLeft'>

                    {columnPresets.map((x) => { return (
                      <div key={x?.id} className={selectedPreset?.id == x?.id ? 'adsColumnsPopupContentColumnSelected' : 'adsColumnsPopupContentColumn'} onClick={() => { setSelectedPreset(x); setColumnIndexesNotAppliedYet(x.presets) }}>
                        <div>
                          <span className='adsColumnsPopupContentColumnTitle'>{x.name}</span>
                          <span className='adsColumnsPopupContentColumnDescription'>{x.description}</span>
                        </div>
                        <div className='adsColumnsPopupContentColumnSelectedCheckBackground' style={{ opacity: selectedPreset?.id == x?.id ? 100 : 0 }}>
                          <Check style={{ width: 12, color: '#fff' }} />
                        </div>
                      </div>
                    )})}


                  </div>
                </div>
              </div>



              {showNewPresetNameInput && (
                <div className='adsColumnsPopupNewPresetInputsContainer'>
                  <input className='adsColumnsPopupNewPresetInput' placeholder='Enter new preset name' value={presetName} onChange={(e) => { setPresetName(e.target.value) }} />
                  <input className='adsColumnsPopupNewPresetInput' placeholder='Enter new preset description' value={presetDescription} onChange={(e) => { setPresetDescription(e.target.value) }} />
                  <div className='adsColumnsPopupNewPresetInputButtonRow'>
                    <span className='adsColumnsPopupNewPresetInputBackButton' onClick={() => {setShowNewPresetNameInput(false);}}>Go back</span>
                    <span className='adsColumnsPopupNewPresetInputSaveButton' onClick={() => {saveAsNewPreset();}}>Save Preset</span>
                  </div>
                </div>
              )}


              <div style={{display: showNewPresetNameInput ? "none" : ''}}>
                <span className='adsColumnsPopupContentLeftTitle'>Columns</span>
                <div className='adsColumnsPopupContentMiddleScrollContainer'>

                  <div className='adsColumnsPopupContentMiddle'>


                  { availableColumns && (
                    (
                      (selectedAdLevel === 0 && adAccountColumns) ||
                      (selectedAdLevel === 1 && campaignColumns) ||
                      (selectedAdLevel === 2 && adsetColumns) ||
                      (selectedAdLevel === 3 && adColumns) ||
                      []
                    ).map((x, index) => (
                      !columnIndexesNotAppliedYet[x.key] && x.key !== 'hidden' &&  (
                        <CustomizeColumnButton
                          x={x}
                          key={index}
                          isVisible={columnIndexesNotAppliedYet[x.key]}
                          toggleVisibility={() => toggleColumnVisibility(x)}
                          title={x.title}
                          description={x.description}
                        />
                      )
                    ))
                    )
                  }
                  </div>
                </div>
              </div>


              <div style={{display: showNewPresetNameInput ? "none" : ''}}>
                <span className='adsColumnsPopupContentLeftTitle'>Selected</span>
                <div className='adsColumnsPopupContentRightScrollContainer'>
                  <div className='adsColumnsPopupContentRight'>
                    
                    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd} >
                      <SortableContext items={(
                          (selectedAdLevel === 0 && adAccountColumns) ||
                          (selectedAdLevel === 1 && campaignColumns) ||
                          (selectedAdLevel === 2 && adsetColumns) ||
                          (selectedAdLevel === 3 && adColumns) ||
                          []
                        ).map((column) => column.key)} strategy={verticalListSortingStrategy} >


                      { availableColumns && (
                        (
                          (selectedAdLevel === 0 && adAccountColumns) ||
                          (selectedAdLevel === 1 && campaignColumns) ||
                          (selectedAdLevel === 2 && adsetColumns) ||
                          (selectedAdLevel === 3 && adColumns) ||
                          []
                        ).map((x, index) => (
                          columnIndexesNotAppliedYet[x.key] && x.key !== 'hidden' && (
                            <SortableItem
                              key={x.key}
                              id={x.key}
                              x={x}
                              isVisible={columnIndexesNotAppliedYet[x.key]}
                              toggleVisibility={() => toggleColumnVisibility(x)}
                              title={x?.title}
                              description={x?.description}
                            />
                          )
                        
                        )
                        ))}

                      </SortableContext>
                    </DndContext>
                    
                    
                  </div>
                </div>
              </div>





            </div>

            <div className='adsColumnsPopupFooter'>
              <div className='adsColumnsPopupFooterRow'>
                {selectedPreset && !selectedPreset.showForEveryone && (
                <span className='adsColumnsPopupDeleteButton' onClick={() => { deletePreset() }}>Delete preset</span>
                )}
                {JSON.stringify(columnIndexesNotAppliedYet) != JSON.stringify(selectedPreset?.presets) && (<span className='adsColumnsPopupCancelButton' onClick={() => { saveToCurrentPreset() }}>Save changes</span>)}
                {JSON.stringify(columnIndexesNotAppliedYet) != JSON.stringify(selectedPreset?.presets) && (<span className='adsColumnsPopupCancelButton' onClick={() => { setShowNewPresetNameInput(!showNewPresetNameInput) }}>Save as new preset</span>)}
              </div>
              <div className='adsColumnsPopupFooterRow'>
                <span className='adsColumnsPopupCancelButton' onClick={() => { setColumnIndexesNotAppliedYet(columnIndexes); setShowSelectColumnsPopup(false) }}>Cancel</span>
                <span className='adsColumnsPopupContinueButton' onClick={() => { setColumnIndexes(columnIndexesNotAppliedYet); setShowSelectColumnsPopup(false) }}>Apply</span>
              </div>
            </div>
     
          </div>
      </div>




    </div>
  );
}

export default Ads;