import React, { useState } from 'react';
import './TextInput.css';


function TextInput({placeholder, type, value, onChange, widthPercent, widthPixels, multiline, marginBottom}) {   
    
    let width = '100';

    if (widthPercent) {
        width = widthPercent + '%';
    } else if (widthPixels) {
        width = widthPixels + 'px';
    }
    
    return ( 
        <div className='TextInputFieldWrapper' style={{width: width}}>
            { multiline ?
                <textarea className='TextInputFieldInput' id={'inputField' + placeholder} onChange={(e) => {onChange(e.target.value,e.target.name)}} value={value} style={{resize: 'none', marginBottom: marginBottom}}/>
            : 
                <input className='TextInputFieldInput' id={'inputField' + placeholder} placeholder={placeholder} type={type} onChange={(e) => {onChange(e.target.value,e.target.name)}} value={value} style={{marginBottom: marginBottom}}/>
            }
            {/* <label htmlFor={'inputField' + placeholder} className={value ? 'TextInputFieldTextNotEmpty' : 'TextInputFieldText'}>{placeholder}</label> */}
        </div>
    );
}

export default TextInput;