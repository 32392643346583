import './Login.css';
import { getAuth, signInWithEmailAndPassword, signInWithCustomToken, GoogleAuthProvider, signInWithPopup, OAuthProvider, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, getAdditionalUserInfo, createUserWithEmailAndPassword} from "firebase/auth";
import react, { useState,useEffect } from 'react';
import { addDoc, collection, doc, getCountFromServer, getDoc, getDocs, limit, query, serverTimestamp, setDoc, where } from 'firebase/firestore';
import {db} from '../FirebaseInit';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MetaLogo from '../assets/Meta_logo.png';
import GoogleLogo from '../assets/GoogleLogo.png';
import AppleLogo from '../assets/AppleLogo.png';


function Login({serverURL, browserURL, shop_auth_token, setAuthenticated, setSnackbarInfo }) {
  const navigate = useNavigate()

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState(false);
    const [showPasswordField, setShowPasswordField] = useState(false);
    const [userExists, setUserExists] = useState(false);
    const provider = new GoogleAuthProvider();



    // reset message to '' after 5 seconds
    useEffect(()=>{
      if(message){
        setTimeout(()=>{
          setMessage('')
        }, 10000)
      }
    },[message])



    // SIGN IN WITH CUSTOM TOKEN (BUSINESS INVITE)
    useEffect(()=>{
      if(!shop_auth_token) return;
        const fetchData = async () => {
          const auth = getAuth();
          signInWithCustomToken(auth, shop_auth_token)
          .then((userCredential) => {
            // User signed in successfully
            const user = userCredential.user;
            console.log('User authenticated:', user);
          })
          .catch((error) => {
            console.error('Error signing in with custom token:', error);
        });
      };
      fetchData();
    },[shop_auth_token])






    function getTokenFromURL() {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('invitationID');
    }

    async function checkIfInvitationTokenExists() {
      const invitationID = getTokenFromURL();
      if (invitationID) {
        const invitationRef = doc(db, 'businessInvitations', invitationID);
        const docSnap = await getDoc(invitationRef);
        const invitationData = docSnap.data();
        return invitationData;
      } else {
        return false;
      }
    }







    // SIGN IN WITH EMAIL LINK
    useEffect(() => {
      const auth = getAuth();
  
      // Confirm the link is a sign-in with email link.
      if (isSignInWithEmailLink(auth, window.location.href)) {
        console.log('Email link confirmed');
        
        let emailFromLocalStorage = window.localStorage.getItem('emailForSignIn');
        console.log(emailFromLocalStorage);
  
        if (!emailFromLocalStorage) {
          emailFromLocalStorage = window.prompt('Please provide your email for confirmation');
        }
  
        console.log(auth, emailFromLocalStorage, window.location.href);
  
        // Ensure you're passing the correct email
        signInWithEmailLink(auth, emailFromLocalStorage, window.location.href)
          .then(async (userCredential) => {
            // User is signed in
            console.log('Email link sign-in successful');
            setMessage('Email link sign-in successful');
            
            const user = userCredential.user;
            const userID = "u_" + user.uid;
            localStorage.setItem('uid', userID);

            // Get the business IDs associated with the user and set one as selected
            await getBusinessIDs(userID);
            await addDeviceToUserDevices(userID)
            console.log('User authenticated:', user);
            
            const userExists = await checkIfUserExists(emailFromLocalStorage);
            if (!userExists) {
              console.log('User does not exist');

              const invitationInfo = await checkIfInvitationTokenExists();
              const userRef = doc(db, 'users', userID);

              const data = {
                email: email,
                createdAt: new Date(), 
                userID: userID,
                firstName: '',
                lastName: '',
                authMethod: 'emailLinkAuth',
                userType: 'businessUser',
              }
    
              if (invitationInfo) {
                data.push({businessIDs: [invitationInfo.businessID],
                  businessPermissions: [
                    {
                      businessID: invitationInfo.businessID,
                      role: invitationInfo.role,
                    }
                ]})
              }

              setDoc(userRef, data)
              .then(() => console.log('User added successfully'))
              .catch(error => console.error('Error adding user:', error));
            }

          })
          .catch((error) => {
            // Handle sign-in errors
            console.error("Error signing in with email link:", error);
          });
      }
    }, []);




  
    // SIGN IN WITH PASSWORD AUTH
    async function signInWithPassword () {
      setSnackbarInfo({open: true, title: 'Signing in...', message: 'Please wait...', type: 'loading'});
      const auth = getAuth();
      signInWithEmailAndPassword(auth, email, password)
        .then(async(userCredential) => {
          // Signed in 
          const user = userCredential.user;
          const userID = "u_" + user.uid;
          localStorage.setItem('uid', userID);

          // Get the business IDs associated with the user and set one as selected
          await getBusinessIDs(userID);
          await addDeviceToUserDevices(userID)
          setSnackbarInfo({open: false})
          setSnackbarInfo({open: true, title: 'Signed In', message: 'You have successfully signed in', type: 'success'});
          setTimeout(() => {
            navigate('/dashboard');
          }, 2000);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log("Error code:", errorCode);
          console.log("Error message:", errorMessage);
        });
    }



    async function signUpWithPassword() {
      setSnackbarInfo({open: true, title: 'Creating account...', message: 'Please wait...', type: 'loading'});
      const auth = getAuth();
      createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          // Signed up 
          const user = userCredential.user;
          const userID = "u_" + user.uid;
          localStorage.setItem('uid', userID);

          // Get the business IDs associated with the user and set one as selected
          await getBusinessIDs(userID);
          await addDeviceToUserDevices(userID)
          
          const invitationInfo = await checkIfInvitationTokenExists();
          const userRef = doc(db, 'users', userID);

          const data = {
            email: email,
            createdAt: new Date(), 
            userID: userID,
            firstName: '',
            lastName: '',
            authMethod: 'passwordAuth',
            userType: 'businessUser',
          }

          if (invitationInfo) {
            data.push({businessIDs: [invitationInfo.businessID],
              businessPermissions: [
                {
                  businessID: invitationInfo.businessID,
                  role: invitationInfo.role,
                }
            ]})
          }

          setDoc(userRef, data)
          .then(() => {
            console.log('User added successfully')
            setSnackbarInfo({open: false})
            setSnackbarInfo({open: true, title: 'Account created', message: 'You have successfully created an account', type: 'success'});
            navigate('/onboarding');
          })
          .catch(error => console.error('Error adding user:', error));

        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log("Error code:", errorCode);
          console.log("Error message:", errorMessage);
          setMessage(errorMessage);
        });
    }






    // SEND SIGN IN LINK
    async function sendSignInLink() {
      const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: browserURL + '/Login',
        // This must be true.
        handleCodeInApp: true,
      };
      const auth = getAuth();
      sendSignInLinkToEmail(auth, email, actionCodeSettings)
        .then(() => {
          setMessage('Email sent');
          window.localStorage.setItem('emailForSignIn', email);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log("SEND LINK Error code:", errorCode);
          console.log("SEND LINK Error message:", errorMessage);
          if (errorCode === 'auth/invalid-email') {
            setMessage('Please provide a valid email');
          } else if (errorCode === 'auth/missing-email') {
            setMessage('Please provide an email');
          } else if (errorCode === 'auth/user-not-found') {
            setMessage('User not found');
          } else {
            setMessage('Error sending email');
          }
        });
    }







    // SIGN IN WITH GOOGLE
    async function signInWithGoogle() {
      setSnackbarInfo({open: true, title: 'Signing in...', message: 'Please wait...', type: 'loading'});
      const auth = getAuth();
      signInWithPopup(auth, provider)
        .then(async(result) => {
          setAuthenticated(true);
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;
          const userID = "u_" + user.uid;
          console.log("UID: " + userID)
          localStorage.setItem('uid', userID);
          const [firstName = '', lastName = ''] = result?.user?.displayName?.split(" ") || [];

          // Get the business IDs associated with the user and set one as selected
          await getBusinessIDs(userID);
          await addDeviceToUserDevices(userID)
          console.log('User authenticated:', user.email);
          
          const userExists = await checkIfUserExists(user.email);
          if (!userExists) {
            console.log('User does not exist');

            const invitationInfo = await checkIfInvitationTokenExists();
            const userRef = doc(db, 'users', userID);

            const data = {
              email: email,
              createdAt: new Date(), 
              userID: userID,
              firstName: '',
              lastName: '',
              authMethod: 'googleAuth',
              userType: 'businessUser',
            }
  
            if (invitationInfo) {
              data.push({businessIDs: [invitationInfo.businessID],
                businessPermissions: [
                  {
                    businessID: invitationInfo.businessID,
                    role: invitationInfo.role,
                  }
              ]})
            }

            setDoc(userRef, data)
            .then(() => {
              console.log('User added successfully')
              setSnackbarInfo({open: false})
              setSnackbarInfo({open: true, title: 'Account created', message: 'You have successfully created an account', type: 'success'});
              navigate('/onboarding');
            })
            .catch(error => console.error('Error adding user:', error));
          } else {
            console.log('User exists');
            setSnackbarInfo({open: false})
            setSnackbarInfo({open: true, title: 'Signed in', message: 'You are now signed in', type: 'success'});
            navigate('/dashboard');
          }
         
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          console.log("Error code:", errorCode);
          console.log("Error message:", errorMessage);
          console.log("Email:", email);
          console.log("Credential:", credential);
        });
    }







    // SIGN IN WITH APPLE
    async function signInWithApple() {
      // Sign in with Apple
      const provider = new OAuthProvider('apple.com');
      const auth = getAuth();
      signInWithPopup(auth, provider)
        .then(async (result) => {
          // Apple credential
          const credential = OAuthProvider.credentialFromResult(result);
          const accessToken = credential.accessToken;
          const idToken = credential.idToken;


          // The signed-in user info.
          const user = result.user;
          console.log("User:", user);
          console.log('user name:', user.displayName);
          const [firstName = '', lastName = ''] = result?.user?.displayName?.split(" ") || [];

          // The signed-in user info.
          const userID = "u_" + user.uid;
          console.log("UID: " + userID)
          localStorage.setItem('uid', userID);

          // Get the business IDs associated with the user and set one as selected
          await getBusinessIDs(userID);
          await addDeviceToUserDevices(userID)
          console.log('User authenticated:', user.email);
          
          const userExists = await checkIfUserExists(user.email);
          if (!userExists) {
            console.log('User does not exist');

            const invitationInfo = await checkIfInvitationTokenExists();
            const userRef = doc(db, 'users', userID);

            const data = {
              email: email,
              createdAt: new Date(), 
              userID: userID,
              firstName: '',
              lastName: '',
              authMethod: 'appleAuth',
              userType: 'businessUser',
            }
  
            if (invitationInfo) {
              data.push({businessIDs: [invitationInfo.businessID],
                businessPermissions: [
                  {
                    businessID: invitationInfo.businessID,
                    role: invitationInfo.role,
                  }
              ]})
            }
            
            setDoc(userRef, data)
            .then(() => {
              console.log('User added successfully')
              setSnackbarInfo({open: false})
              setSnackbarInfo({open: true, title: 'Account created', message: 'You have successfully created an account', type: 'success'});
              navigate('/onboarding');
            })
            .catch(error => console.error('Error adding user:', error));
          } else {
            console.log('User exists');
            setSnackbarInfo({open: false})
            setSnackbarInfo({open: true, title: 'Signed in', message: 'You are now signed in', type: 'success'});
            navigate('/dashboard');
          }

        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error?.customData?.email;
          // The credential that was used.
          const credential = OAuthProvider.credentialFromError(error);
          console.log("ERROR:", error)
          console.log("Error code:", errorCode);
          console.log("Error message:", errorMessage);
          console.log("Email:", email);
          console.log("Credential:", credential);
        });
    }
    
      






    async function checkIfUserExists(email) {
      if (email !== '' || email !== null) {
        const q = query(collection(db, "users"), where("email", "==", email), limit(1));
        const querySnapshot = await getDocs(q);
        let i = 0;
        querySnapshot.forEach((doc) => {
          i++;
        });
        if (i > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
      
    }




    async function checkIfUserExistsForPasswordAuth(email) {
      setShowPasswordField(true);
      const exists = await checkIfUserExists(email)
      if (exists) {
        setUserExists(true);
      } else {
        setUserExists(false);
      }
    }


  


    async function getDeviceInfo() {
      // Get browser and OS info from userAgent
      const userAgent = navigator.userAgent;
      let browser = "Unknown Browser";
      let os = "Unknown OS";
    
      if (userAgent.includes("Firefox")) {
        browser = "Firefox";
      } else if (userAgent.includes("Edg")) {
        browser = "Edge";
      } else if (userAgent.includes("Chrome") && !userAgent.includes("Edg")) {
        browser = "Chrome";
      } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
        browser = "Safari";
      }
    
      if (userAgent.includes("Windows")) {
        os = "Windows";
      } else if (userAgent.includes("Mac OS")) {
        os = "Mac OS";
      } else if (userAgent.includes("Linux")) {
        os = "Linux";
      } else if (userAgent.includes("Android")) {
        os = "Android";
      } else if (userAgent.includes("iPhone")) {
        os = "iOS";
      }
    
      // Fetch IP and location data
      try {
        const response = await axios.get("https://ipapi.co/json/");
        const data = await response.data;
    
        return {
          ip: data.ip,
          location: `${data.city}, ${data.region}, ${data.country_name}`,
          browser: browser,
          os: os
        };
      } catch (error) {
        console.error("Failed to fetch IP/location data:", error);
        return {
          ip: "Unavailable",
          location: "Unavailable",
          browser: browser,
          os: os
        };
      }
    }





    async function getBusinessIDs(userID) {
      // Get the business IDs associated with the user and set one as selected
      const q = query(collection(db, "businesses"), where("userIDs", "array-contains", userID), limit(1));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        console.log("Selected Business ID", doc.id);
        localStorage.setItem('bid', doc.id);
        localStorage.setItem('businessName', doc.data().businessName);
      });
    }



    async function addDeviceToUserDevices(userID) {
      // Add device info to userDevices collection
      const deviceInfo = await getDeviceInfo()
      const userDevicesQuery = query(collection(db, "userDevices"), where("browser", "==", deviceInfo.browser), where("os", "==", deviceInfo.os), where("ip", "==", deviceInfo.ip), where("location", "==", deviceInfo.location), where("userID", "==", userID));
      const snapshot = await getCountFromServer(userDevicesQuery);
      const matchedDevices = snapshot.data().count || 0;  

      console.log("MATCHED DEVICES: ", matchedDevices)

      if (matchedDevices == 0) {
        const userDeviceCollection = collection(db, 'userDevices');
        addDoc(userDeviceCollection, {
          browser: deviceInfo.browser,
          os: deviceInfo.os,
          ip: deviceInfo.ip,
          location: deviceInfo.location,
          createdAt: serverTimestamp(), // Server-side timestamp for accuracy
          userID: userID
        })
        .then(() => console.log("Device info added successfully"))
        .catch(error => console.error("Error adding device info:", error));
      }
    }
  
  
  
  
    return (
      <div className='LoginContainer'>
        <div className='LoginCardContainer'>
          <div className='LoginCard'>
            <div className='LoginCardTop'>
              <div className='LoginCardImageContainer'>
                <img className='LoginCardLogo' src={MetaLogo}/>
              </div>
              <span className='LoginCardTitle'>Welcome to Omni OS</span>
              <span className='LoginCardSubtitle'>Autonomous Marketing Operating System</span>
            </div>
            <div className='LoginCardBottom'>
              <div className='LoginOauthRow' onClick={() => {signInWithGoogle()}}>
                <img className='LoginOauthRowLogo' src={GoogleLogo}/>
                <span className='LoginOauthRowText'>Continue with Google</span>
              </div>
              <div className='LoginOauthRow' onClick={() => {signInWithApple()}}>
                <img className='LoginOauthRowLogo' src={AppleLogo}/>
                <span className='LoginOauthRowText'>Continue with Apple</span>
              </div>


              {/* <span className='LoginRightCreateText'>New user? <span className='loginRightCreateLink' onClick={() => {navigate('/Signup')}}>Create an account</span></span> */}
              <span className='LoginInputWrapperText'>Email</span>
              <input className='LoginInput' type='email' placeholder='Enter your email address...' value={email} onChange={(event) => setEmail(event.target.value)}/>

              <div style={{overflow: 'hidden', transition: 'all 0.3s', width: '100%', paddingLeft: 30, maxHeight: showPasswordField ? 200 : 0}}>
                <span className='LoginInputWrapperText' style={{marginTop: 0}}>Password</span>
                <input className='LoginInput' type='password' placeholder='Enter your password...' value={password} onChange={(event) => setPassword(event.target.value)}/>
              </div>

              {/* <input className='LoginInput' type='password' placeholder='Password' value={password} onChange={(event) => setPassword(event.target.value)}/> */}
              {!showPasswordField ?
                <button className='LoginButton' onClick={() => {checkIfUserExistsForPasswordAuth(email)}}>Continue with email</button>
              :
                <>
                  {userExists ?
                    <button className='LoginButton' onClick={() => {signInWithPassword()}}>Sign in with password</button>
                  :
                    <button className='LoginButton' onClick={() => {signUpWithPassword()}}>Create account</button>
                  }
                </>
              }

              <button className='LoginSSOButton' onClick={() => {sendSignInLink()}}>or continue with Omni SSO</button>

              <span>{message}</span>
            </div>
          
          </div>
          <span className='LoginDisclaimerText'>By clicking "Sign in" or "Continue" above, you acknowledge that you have read and understood, and agree to Omni's Terms and Privacy.</span>
        </div>
      </div>
    );
}

export default Login;