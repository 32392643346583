import react, { useState, useEffect } from 'react';
import axios from 'axios';
import './StoreOverview.css'
import dayjs from 'dayjs';
import { TrendingDownRounded, TrendingUpRounded } from '@mui/icons-material';
import DashboardMiniChart from './Charts/DashboardMiniChart';

function StoreOverview({widthPercent, revenue, facebookSpend, profit, orders, compareRevenueTotal, compareFacebookSpendTotal, compareNetProfitTotal, compareOrdersTotal, 
  dateArray, ordersArray, revenueArray, facebookSpendArray, netProfitArray, darkMode}) {


  const [revenueChangePercent, setRevenueChangePercent] = useState('');  
  const [facebookSpendChangePercent, setFacebookSpendChangePercent] = useState('');
  const [netProfitChangePercent, setNetProfitChangePercent] = useState('');
  const [roasChangePercent, setRoasChangePercent] = useState('');
  const [ordersChangePercent, setOrdersChangePercent] = useState('');




  function formatPercent(value) {
    return new Intl.NumberFormat('en-US', { style: 'percent' }).format(value);
  }
  
  function getRevenueChangePercent() {
    const oldValue = compareRevenueTotal;
    const newValue = revenue;
  
    if (oldValue === 0) {
      if (newValue === 0) {
        return formatPercent(0); // No change
      } else {
        // Change from zero to non-zero value
        return newValue > 0 ? formatPercent(Infinity) : formatPercent(-Infinity);
      }
    } else {
      const change = (newValue - oldValue) / Math.abs(oldValue);
      return formatPercent(change);
    }
  }
  
  function getFacebookSpendChangePercent() {
    const oldValue = compareFacebookSpendTotal;
    const newValue = facebookSpend;
  
    if (oldValue === 0) {
      if (newValue === 0) {
        return formatPercent(0);
      } else {
        return newValue > 0 ? formatPercent(Infinity) : formatPercent(-Infinity);
      }
    } else {
      const change = (newValue - oldValue) / Math.abs(oldValue);
      return formatPercent(change);
    }
  }
  
  function getNetProfitChangePercent() {
    const oldValue = compareNetProfitTotal;
    const newValue = profit;
  
    if (oldValue === 0) {
      if (newValue === 0) {
        return formatPercent(0);
      } else {
        return newValue > 0 ? formatPercent(Infinity) : formatPercent(-Infinity);
      }
    } else {
      const change = (newValue - oldValue) / Math.abs(oldValue);
      return formatPercent(change);
    }
  }
  
  function getRoasChangePercent() {
    const oldRoas = compareFacebookSpendTotal === 0 ? 0 : compareRevenueTotal / compareFacebookSpendTotal;
    const newRoas = facebookSpend === 0 ? 0 : revenue / facebookSpend;
  
    if (oldRoas === 0) {
      if (newRoas === 0) {
        return formatPercent(0);
      } else {
        return newRoas > 0 ? formatPercent(Infinity) : formatPercent(-Infinity);
      }
    } else {
      const change = (newRoas - oldRoas) / Math.abs(oldRoas);
      return formatPercent(change);
    }
  }
  
  function getOrdersChangePercent() {
    const oldValue = compareOrdersTotal;
    const newValue = orders;
  
    if (oldValue === 0) {
      if (newValue === 0) {
        return formatPercent(0);
      } else {
        return newValue > 0 ? formatPercent(Infinity) : formatPercent(-Infinity);
      }
    } else {
      const change = (newValue - oldValue) / Math.abs(oldValue);
      return formatPercent(change);
    }
  }
  


  useEffect(() => {
    console.log(getRevenueChangePercent())
    setRevenueChangePercent(getRevenueChangePercent());
    setFacebookSpendChangePercent(getFacebookSpendChangePercent());
    setNetProfitChangePercent(getNetProfitChangePercent());
    setRoasChangePercent(getRoasChangePercent());
    setOrdersChangePercent(getOrdersChangePercent());

  }, [revenue, facebookSpend, profit, orders, compareRevenueTotal, compareFacebookSpendTotal, compareNetProfitTotal, compareOrdersTotal])



    return ( 
      
        <div className='StoreOverviewAnalyticsContainer' style={{width: widthPercent + '%'}}>
            <div className='StoreOverviewStatisticsCardRow'>

              <div className={"StoreOverviewStatisticsCard " + (darkMode && ' darkModeCard')}>
                <div className='StoreOverviewStatisticsCardLeft'>
                  <span className={'StoreOverviewStatisticsCardTitle ' + (darkMode && ' darkModeCardText')}>Revenue</span>
                  <span className={'StoreOverviewStatisticsCardAmount ' + (darkMode && ' darkModeCardText')}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(revenue)}</span> 
                  <div className='StoreOverviewStatisticsCardChangeContainer'>
                    {revenueChangePercent.startsWith('-') ?
                      <div className='StoreOverviewStatisticsCardChangeIcon' style={{ backgroundColor: '#ff563029', color: '#b71d18' }}>
                        <TrendingDownRounded fontSize="18"/>
                      </div>
                    :
                      <div className='StoreOverviewStatisticsCardChangeIcon'>
                        <TrendingUpRounded fontSize="18"/>
                      </div>
                    } 
                    <span className={'StoreOverviewStatisticsCardChangePercent ' + (darkMode ? 'darkModeCardText' : '')}>
                      {revenueChangePercent}
                    </span>

                    <span className='StoreOverviewStatisticsCardChangeLabel'>this period</span>
                  </div>
                </div>
                <div className='StoreOverviewStatisticsCardChartContainer'>
                  {/* CHART */}
                  <DashboardMiniChart categories={dateArray} chartType='line' line1Name='Revenue' line1Data={revenueArray} chartHeight={115} darkMode={darkMode}/>
                </div>
              </div>

              <div className={"StoreOverviewStatisticsCard " + (darkMode && ' darkModeCard')}>
                <div className='StoreOverviewStatisticsCardLeft'>
                  <span className={'StoreOverviewStatisticsCardTitle ' + (darkMode && ' darkModeCardText')}>Spend</span>
                  <span className={'StoreOverviewStatisticsCardAmount ' + (darkMode && ' darkModeCardText')}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(facebookSpend)}</span>
                  <div className='StoreOverviewStatisticsCardChangeContainer'>
                    {facebookSpendChangePercent.startsWith('-') ?
                      <div className='StoreOverviewStatisticsCardChangeIcon' style={{ backgroundColor: '#ff563029', color: '#b71d18' }}>
                        <TrendingDownRounded fontSize="18"/>
                      </div>
                    :
                      <div className='StoreOverviewStatisticsCardChangeIcon'>
                        <TrendingUpRounded fontSize="18"/>
                      </div>
                    } 
                    <span className={'StoreOverviewStatisticsCardChangePercent ' + (darkMode && ' darkModeCardText')}>
                      {facebookSpendChangePercent}
                      </span>
                    <span className='StoreOverviewStatisticsCardChangeLabel'>this period</span>
                  </div>
                </div>
                <div className='StoreOverviewStatisticsCardChartContainer'>
                  {/* CHART */}
                  <DashboardMiniChart categories={dateArray} chartType='line' line1Name='Revenue' line1Data={facebookSpendArray} chartHeight={115} darkMode={darkMode}/>
                </div>
              </div>

              <div className={"StoreOverviewStatisticsCard " + (darkMode && ' darkModeCard')}>
                <div className='StoreOverviewStatisticsCardLeft'>
                  <span className={'StoreOverviewStatisticsCardTitle ' + (darkMode && ' darkModeCardText')}>Net Profit</span>
                  <span className={'StoreOverviewStatisticsCardAmount ' + (darkMode && ' darkModeCardText')}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(profit)}</span>
                  <div className='StoreOverviewStatisticsCardChangeContainer'>
                    {netProfitChangePercent.startsWith('-') ?
                      <div className='StoreOverviewStatisticsCardChangeIcon' style={{ backgroundColor: '#ff563029', color: '#b71d18' }}>
                        <TrendingDownRounded fontSize="18"/>
                      </div>
                    :
                      <div className='StoreOverviewStatisticsCardChangeIcon'>
                        <TrendingUpRounded fontSize="18"/>
                      </div>
                    } 
                    <span className={'StoreOverviewStatisticsCardChangePercent ' + (darkMode && ' darkModeCardText')}>
                      {netProfitChangePercent}
                    </span>
                    <span className='StoreOverviewStatisticsCardChangeLabel'>this period</span>
                  </div>
                </div>
                <div className='StoreOverviewStatisticsCardChartContainer'>
                  {/* CHART */}
                  <DashboardMiniChart categories={dateArray} chartType='line' line1Name='Revenue' line1Data={netProfitArray} chartHeight={115} darkMode={darkMode}/>
                </div>
              </div>

              <div className={"StoreOverviewStatisticsCard " + (darkMode && ' darkModeCard')}>
                <div className='StoreOverviewStatisticsCardLeft'>
                  <span className={'StoreOverviewStatisticsCardTitle ' + (darkMode && ' darkModeCardText')}>ROAS</span>
                  <span className={'StoreOverviewStatisticsCardAmount ' + (darkMode && ' darkModeCardText')}>{new Intl.NumberFormat('en-US').format((revenue / facebookSpend)?.toFixed(2))}</span>
                  <div className='StoreOverviewStatisticsCardChangeContainer'>
                    {roasChangePercent.startsWith('-') ?
                      <div className='StoreOverviewStatisticsCardChangeIcon' style={{ backgroundColor: '#ff563029', color: '#b71d18' }}>
                        <TrendingDownRounded fontSize="18"/>
                      </div>
                    :
                      <div className='StoreOverviewStatisticsCardChangeIcon'>
                        <TrendingUpRounded fontSize="18"/>
                      </div>
                    } 
                    <span className={'StoreOverviewStatisticsCardChangePercent ' + (darkMode && ' darkModeCardText')}>
                      {roasChangePercent}
                    </span>
                    <span className='StoreOverviewStatisticsCardChangeLabel'>this period</span>
                  </div>
                </div>
                <div className='StoreOverviewStatisticsCardChartContainer'>
                  {/* CHART */}
                  <DashboardMiniChart categories={dateArray} chartType='line' line1Name='Revenue' line1Data={revenueArray} chartHeight={115} darkMode={darkMode}/>
                </div>
              </div>

              {/* <div className={"StoreOverviewStatisticsCard " + (darkMode && ' darkModeCard')}>
                <div className='StoreOverviewStatisticsCardLeft'>
                  <span className={'StoreOverviewStatisticsCardTitle ' + (darkMode && ' darkModeCardText')}>Orders</span>
                  <span className={'StoreOverviewStatisticsCardAmount ' + (darkMode && ' darkModeCardText')}>{new Intl.NumberFormat('en-US').format(orders)}</span>
                  <div className='StoreOverviewStatisticsCardChangeContainer'>
                    {ordersChangePercent.startsWith('-') ?
                      <div className='StoreOverviewStatisticsCardChangeIcon' style={{ backgroundColor: '#ff563029', color: '#b71d18' }}>
                        <TrendingDownRounded fontSize="18"/>
                      </div>
                    :
                      <div className='StoreOverviewStatisticsCardChangeIcon'>
                        <TrendingUpRounded fontSize="18"/>
                      </div>
                    } 
                    <span className={'StoreOverviewStatisticsCardChangePercent ' + (darkMode && ' darkModeCardText')}>
                      {ordersChangePercent}
                    </span>
                    <span className='StoreOverviewStatisticsCardChangeLabel'>this period</span>
                  </div>
                </div>
                <div className='StoreOverviewStatisticsCardChartContainer'>
                  
                  <DashboardMiniChart categories={dateArray} chartType='line' line1Name='Revenue' line1Data={ordersArray} chartHeight={110} darkMode={darkMode}/>
                </div>
              </div> */}

              

            </div>
        </div>
        
    );
}

export default StoreOverview;