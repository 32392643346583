import React, { useState } from "react";
import './ColorPicker.css';
import { HexColorPicker } from "react-colorful";
import transparent_range_background from '../assets/transparent_range_background.png';
import { ClickAwayListener } from "@mui/material";



function ColorPicker ({color, setColor}) {
    const [showColorPicker, setShowColorPicker] = useState(false);

    const [showGradientMenu, setShowGradientMenu] = useState(false);
    const [transparency, setTransparency] = useState(255);





    // Handle the slider change
    const handleSliderChange = (e) => {
        const value = e.target.value; // This is a string
        const numericValue = Number(value); // Convert to number
    
        // Validate the numeric value (optional but recommended)
        if (isNaN(numericValue) || numericValue < 0 || numericValue > 255) {
            console.error('Invalid slider value:', value);
            return;
        }
    
        setTransparency(numericValue); // Assuming setTransparency expects a number
    
        // Ensure 'color' is in the format '#RRGGBB'
        if (color.length < 7) {
            console.error('Invalid color format:', color);
            return;
        }
    
        const newHex = numberToHex(numericValue); // Pass the number, not the string
        const newColor = color.substring(0, 7) + newHex; // Append the hex value
    
        setColor(newColor);
    };
    
    // Updated numberToHex function with type enforcement
    function numberToHex(num) {
        const number = Number(num); // Ensure it's a number
    
        if (isNaN(number)) {
            console.error('Invalid number for conversion:', num);
            return '00'; // Default to '00' in case of error
        }

        const clamped = Math.max(0, Math.min(255, number));
        return clamped.toString(16).padStart(2, '0').toUpperCase();
    }
  


    return (
        <ClickAwayListener onClickAway={() => setShowColorPicker(false)}>
            <div className="ColorPickerContainer">

                <div className='ColorPickerContainerButton' onClick={() => {setShowColorPicker(!showColorPicker)}}>
                    <div className='ColorPickerContainerButtonColor' style={{backgroundColor: color.toUpperCase()}}></div>
                </div>


                {showColorPicker && (
                    <div className='ColorPickerDropdownContainer'>

                        <div className="ColorPickerMenuSelectorRow">
                            <div className={!showGradientMenu ? 'ColorPickerMenuItemSelected' : 'ColorPickerMenuItem'} onClick={() => setShowGradientMenu(false)} > Solid </div>
                            <div className={showGradientMenu ? 'ColorPickerMenuItemSelected' : 'ColorPickerMenuItem'} onClick={() => setShowGradientMenu(true)} > Gradient </div>
                        </div>

                        <div className="ColorPickerGradientContainer"><HexColorPicker color={color} onChange={setColor} /></div>
                    
                        <div className="ColorPickerSelectorRangeContainer" style={{backgroundImage: `url(${transparent_range_background})` }}>
                        <input
                            type="range"
                            min="0"
                            max="255"
                            value={transparency}
                            onChange={handleSliderChange}
                            className="ColorPickerSelectorRange"
                            style={{background: `linear-gradient(90deg, #FF000000 0%,${color} 100%)`}}
                        />
                        </div>

                        <div className="ColorPickerTextHexContainer">
                            <div style={{backgroundColor: color.toUpperCase(), width: 10, height: 10, marginLeft: 7, marginRight: 7, borderRadius: 30}}></div>
                            <div>{color.toUpperCase()}</div>
                        </div>

                        <div className="ColorPickerColorPresetsRow">
                            <div className="ColorPickerColorPreset" style={{backgroundColor: '#FE6B3FFF'}} onClick={() => {setColor('#FE6B3FFF')}}></div>
                            <div className="ColorPickerColorPreset" style={{backgroundColor: '#FD6488FF'}} onClick={() => {setColor('#FD6488FF')}}></div>
                            <div className="ColorPickerColorPreset" style={{backgroundColor: '#FD60B9FF'}} onClick={() => {setColor('#FD60B9FF')}}></div>
                            <div className="ColorPickerColorPreset" style={{backgroundColor: '#A967D5FF'}} onClick={() => {setColor('#A967D5FF')}}></div>
                            <div className="ColorPickerColorPreset" style={{backgroundColor: '#4749FFFF'}} onClick={() => {setColor('#4749FFFF')}}></div>
                            <div className="ColorPickerColorPreset" style={{backgroundColor: '#50B7FFFF'}} onClick={() => {setColor('#50B7FFFF')}}></div>

                        </div>
                    </div>
                )}

            </div>

        </ClickAwayListener>
    );
};

export default ColorPicker;
