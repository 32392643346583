// ChartComponent.js
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import './AnalyticsChart.css'

const DashboardMiniChart = ({
  line1Data, categories, 
  chartHeight, line1Name, chartType, darkMode
}) => {
  // Example data
  const series = [{
    name: line1Name,
    data: line1Data,
  }];



  const options = {
    chart: {
      background: darkMode ? '#1c252e' : '#ffffff00',
      foreColor: '#666',
      toolbar: {
        show: false
      },
      dropShadow: {
        enabled: false
      },
      sparkline: {
        enabled: false
      },
      zoom: {
        enabled: false
      },
      selection: {
        enabled: false
      }
    },
    colors: ['#635bff20'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 2
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false
      }
    },
    yaxis: {
      min: Math.min(...line1Data),
      max: Math.max(...line1Data),
      labels: {
        show: false
      }
    },
    grid: {
      show: false
    },
    tooltip: {
      enabled: false // Ensure tooltip is fully disabled
    },
    legend: {
      show: false
    },
    markers: {
      hover: {
        size: 0 // No size change on hover
      }
    },
    states: {
      hover: {
        filter: {
          type: 'none' // Disable hover effects
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none' // Disable active effects
        }
      }
    }
  };
  

  
  
  

  return (
    <div style={{height: 300, width: '100%'}}>
      <ReactApexChart options={options} series={series} type={chartType} height={chartHeight} />
    </div>
  );
};

export default DashboardMiniChart;
