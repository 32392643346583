import React, { useState, useEffect } from 'react';
import { ChevronLeftRounded, LocalMall, WatchRounded, FingerprintRounded, NetworkCellRounded, PublicRounded, AccessTimeFilledRounded, FolderRounded, FolderSpecialRounded, TravelExploreRounded, LocalPhoneRounded, ShoppingCart, EmailRounded, CalendarMonthRounded, PersonRounded, MyLocationRounded, KeyboardArrowDownOutlined, LocationOnRounded, AccountCircleOutlined, Facebook, Instagram, AddShoppingCart, Visibility, Event, EventNoteOutlined, ShoppingCartOutlined, ReceiptOutlined, InfoOutlined, X, EditRounded, CheckBox, Check } from '@mui/icons-material';
import { AiFillTikTok } from "react-icons/ai";
import { RiInstagramFill } from "react-icons/ri";
import './ProductDrawer.css';
import axios from 'axios';
import moment from 'moment-timezone';
import fb_logo from '../assets/fb_logo.png';
import shopify_logo from '../assets/shopify_logo.png';
import ig_logo from '../assets/ig_logo.png';
import tiktok_logo from '../assets/tiktok_logo.png';
import FB_Logo_Ext from '../assets/FB_Logo_Ext.png';
import Instagram_logo from '../assets/Instagram_logo.png';
import { ClickAwayListener } from '@mui/material';
import dayjs from 'dayjs';
import ChartComponent from './Charts/AnalyticsChart';
import ProductDrawerChart from './Charts/ProductDrawerChart';




const ProductDrawer = ({ showDrawer, setShowDrawer, selectedProductID, serverURL }) => {
    const bid = localStorage.getItem('bid');
    const [productImages, setProductImages] = useState([]);
    const [productVariantsUnmodified, setProductVariantsUnmodified] = useState([]);
    const [productVariantsModified, setProductVariantsModified] = useState([]);
    const [modifiedOnly, setModifiedOnly] = useState([]);
    const [productOrders, setProductOrders] = useState([]);
    const [showAllProductVariants, setShowAllProductVariants] = useState(false);
    const [loading, setLoading] = useState(true); // Loading state
    const [last7DaysArray, setLast7DaysArray] = useState([]);
    const [pricePerDayArray, setPricePerDayArray] = useState([]);



    async function getProductImages () {
        try {
            const API_URL = `${serverURL}/getShopifyProductImages?product_id=${selectedProductID}` ;
            const response = await axios.get(API_URL)
            console.log('get product images response: ', response.data)
            setProductImages(response.data)
        } catch (error) {
            console.log('ERROR loading product images ProductDrawer.js', error)
        } finally {
            setLoading(false)
        }
    }

    async function getProductVariants () {
        try {
            const API_URL = `${serverURL}/getShopifyProductVariants?product_id=${selectedProductID}` ;
            const response = await axios.get(API_URL)
            console.log('get product variants response: ', response.data)
            const productVariantsCopy = JSON.parse(JSON.stringify(response.data));

            setProductVariantsUnmodified(response.data)
            setProductVariantsModified(productVariantsCopy)
        } catch (error) {
            console.log('ERROR loading product variants ProductDrawer.js', error)
        } finally {
            setLoading(false)
        }
    }

    async function getProductOrders () {
        try {
            const API_URL = `${serverURL}/getShopifyProductOrders?product_id=${selectedProductID}` ;
            const response = await axios.get(API_URL)
            console.log('get product orders response: ', response.data)
            setProductOrders(response.data)
        } catch (error) {
            console.log('ERROR loading product orders ProductDrawer.js', error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (selectedProductID) {
            getProductImages()
            getProductVariants()
            getProductOrders()
        }

    }, [selectedProductID])




    function handleCOGSCostChange (e, index) {
        const newVariants = [...productVariantsModified];
        newVariants[index].cogs = e.target.value;
        setProductVariantsModified(newVariants)
    }

    useEffect(() => {
        const modifiedOnlyTemp = productVariantsModified.filter((x, index) => {
            return x.cogs !== productVariantsUnmodified[index].cogs
        })
        setModifiedOnly(modifiedOnlyTemp)
       
    }, [productVariantsModified])



    async function updateCOGS () {
        try {
            setLoading(true)
            for (let i = 0; i < modifiedOnly.length; i++) {
                const API_URL = `${serverURL}/updateShopifyProductCOGS` ;
                const response = await axios.post(API_URL, {
                    omni_business_id: bid,
                    variant_id: modifiedOnly[i].id,
                    inventory_id: modifiedOnly[i].inventory_id,
                    new_cogs: modifiedOnly[i].cogs
                })
                console.log('update product variants response: ', response.data)
            }
        } catch (error) {
            console.log('ERROR updating product variants ProductDrawer.js', error)
        } finally {
            getProductVariants();
            setLoading(false);
        }
    }



    const iconMapping = {
        'Facebook': <img style={{width: 30}} src={fb_logo} alt='fb_logo' />,
        'fb': <img style={{width: 60}} src={FB_Logo_Ext} alt='fb_logo' />,
        'Instagram': <img style={{width: 30}} src={ig_logo} alt='ig_logo'/>,
        'ig': <img style={{paddingTop: 5, width: 60}} src={Instagram_logo} alt='ig_logo'/>,
        'Check Out': <img style={{width: 30}} src={shopify_logo} alt='fb_logo'/>,
        'Content View': <img style={{width: 30}} src={shopify_logo} alt='fb_logo'/>,
        'Purchase': <img style={{width: 30}} src={shopify_logo} alt='fb_logo'/>,
        'Add To Cart': <img style={{width: 30}} src={shopify_logo} alt='fb_logo'/>,
        'TikTok': <img style={{width: 30}} src={tiktok_logo} alt='tiktok_logo'/>
    };

    const getIconForEventType = (eventType) => {
        return iconMapping[eventType] || null;
    };




    function prepareChartData () {
        const last7DaysTemp = [];
        const pricePerDayTemp = [];
      
        // Generate last 30 days array
        for (let i = 0; i < 30; i++) {
            last7DaysTemp.push(dayjs().subtract(i, 'day').format('YYYY-MM-DD'));
        }
      
        // Calculate prices for each day
        last7DaysTemp.forEach((day) => {
          const ordersForDay = productOrders.filter(
            (order) => dayjs(order.created_at).format('YYYY-MM-DD') === day
          );
      
          const totalPriceForDay = ordersForDay.reduce(
            (acc, order) => acc + parseFloat(order.price),
            0
          );
      
          pricePerDayTemp.push(totalPriceForDay);
        });

        setLast7DaysArray(last7DaysTemp.reverse());
        setPricePerDayArray(pricePerDayTemp.reverse());
    }

    useEffect(() => {
        if (productOrders.length > 0) {
            prepareChartData();
        } else {
            setLast7DaysArray([]);
            setPricePerDayArray([]);
        }
    }, [productOrders])


    console.log('productOrders', productOrders)


    return (
            <div className={`ProductDrawerRightPane ${showDrawer ? 'open' : ''}`}>

                <div className='ProductDrawerHeader'>

                    <span className='ProductDrawerCollapseIcon' onClick={() => setShowDrawer(false)}>
                        <ChevronLeftRounded /><p>Back</p>
                    </span>
                    <div className='ProductDrawerHeaderMiddleContainer'>
                        {/* <span className='ProductDrawerHeaderMiddleContainerCustomerName'>{selectedProductID?.first_name} {selectedProductID?.last_name}</span>
                        <span className='ProductDrawerHeaderMiddleContainerCustomerLocation'>{selectedProductID?.address?.city}, {selectedProductID?.address?.province}</span> */}
                    </div>
                    <span className='ProductDrawerHeaderActiveStatusInactive'>Not active</span>

                </div>

                    <div className='ProductDrawerContentProductImagesRowContainer'>
                        {productImages?.map((x, index) => {
                            return (
                                <div className='ProductDrawerContentProductImageContainer'>
                                    <img  className='ProductDrawerContentProductImage' src={x.src} alt='Product Image' />
                                </div>
                            )
                        })}
                    </div>

                <div className='ProductDrawerContent'>
                   



                    <div className='ProductDrawerLeft'>


                        <div className='ProductDrawerSection'>
                            <div className='ProductDrawerSectionHeader' style={{borderBottom: '1px solid #e7e7e7'}}>
                                <span className='ProductDrawerSectionTitle'>Revenue over last 30 days</span>
                            </div>
                            <ProductDrawerChart chartName="" line1Data={pricePerDayArray} categories={last7DaysArray} 
                                yAxisIsNumber={true} xAxisIsDate={true} xAxisIsTime={false} chartHeight={200} total={productOrders.reduce((acc, order) => acc + parseFloat(order.price), 0)} compareTotal="" 
                                line1Name="Revenue" line2Name="" line3Name="" 
                                titleType='none' chartType='line' xAxisLabelSize='12px' yAxisLabelSize='12px' darkMode={false}/>

                        </div>



                        <div className='ProductDrawerSection'>
                            <div className='ProductDrawerSectionHeader'>
                                <span className='ProductDrawerSectionTitle'>Variants ({productVariantsUnmodified.length})</span>
                            </div>

                            <div className='ProductDrawerSectionRow'>
                                <span className='ProductDrawerSectionRowTitle'>Title</span>
                                <span className='ProductDrawerSectionRowValue'>Price</span>
                                <span className='ProductDrawerSectionRowValue'>Markup</span>
                                <span className='ProductDrawerSectionRowValue' style={{display: 'block', textAlign: 'center'}}>Cost</span>
                            </div>

                            <div className='ProductDrawerSectionContent'>
                                {productVariantsModified.slice(0, showAllProductVariants ? productVariantsModified.length : 5).map((x, index) => (
                                    <div className='ProductDrawerSectionRow' key={index}>
                                        <span className='ProductDrawerSectionRowTitle'>{x.title}</span>
                                        <span className='ProductDrawerSectionRowValue'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.price)}</span>
                                        <span className='ProductDrawerSectionRowValue' style={{textTransform: 'none'}}>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format((x.price - x.cogs) / x.cogs)}x</span>
                                        
                                        <div className='ProductDrawerSectionRowCostContainer'>
                                            <div className='ProductDrawerSectionRowCostInputContainer'>
                                                <span className='ProductDrawerSectionRowCostInputDollarSign'>$</span>
                                                <input type='number' className='ProductDrawerSectionRowCostInput' value={x.cogs} onChange={(event) => {handleCOGSCostChange(event, index)} } />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {productVariantsModified.length > 5 && (
                                <div className='ProductDrawerSectionHeader' style={{borderTop: '1px solid #e7e7e7', cursor: 'pointer'}} onClick={() => setShowAllProductVariants(!showAllProductVariants)}>
                                    <span className='ProductDrawerSectionTitle'>{showAllProductVariants ? "Show Less" : "Show More"}</span>
                                </div>
                            )}
                        </div>






                    </div>


                    <div className='ProductDrawerRight'>
                        {productOrders.slice(0,20).map((x, index) => { return (
                            <div className='ProductDrawerOrderContainer'>
                                <div className='ProductDrawerOrderImageContainer'>
                                    <img src={shopify_logo} className='ProductDrawerOrderImage' />
                                </div>
                                <div>
                                    <span className='ProductDrawerOrderNumber'>#{x.order_number}</span>
                                    <span className='ProductDrawerOrderTime'>{moment(x.created_at).format('MMM D, YYYY')}</span>
                                </div>
                                <span className='ProductDrawerOrderSource'>{getIconForEventType(x.omni_utm_param)}</span>
                            </div>
                        )})}
  
                    </div>
                 
                    {/* <div className='ProductDrawerSectionRowEditCostIconContainer'>
                        <Check style={{width: 20, height: 20}}/>
                    </div> */}

                    {modifiedOnly.length > 0 && (
                        <div className='ProductDrawerContentFAB' onClick={() => {updateCOGS()}}>
                            <img src={shopify_logo} style={{width: 30, height: 30, marginRight: 10}}/>
                            Save Variant Costs
                        </div>
                    )}

                </div>
            </div>
    );
};

export default ProductDrawer;
