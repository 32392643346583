import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Typography, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './PurchasesPopup.css'

// Helper function to format the created_at as "Last Thursday at 7:50 PM"
function formatDateTime(createdAt) {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const now = new Date();
    const date = new Date(createdAt);

    const dayOfWeek = date.getDay();  // Day of the week of the order
    const currentDayOfWeek = now.getDay();

    const isSameDay = now.toDateString() === date.toDateString();
    const timeString = date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    });

    const timeDiffInDays = Math.floor((now - date) / (1000 * 60 * 60 * 24)); // Difference in days

    if (isSameDay) {
        return `Today at ${timeString}`;
    }

    if (timeDiffInDays < 7) {
        let dayDifference = currentDayOfWeek - dayOfWeek;
        if (dayDifference < 0) {
            dayDifference += 7;
        }
        const lastDayString = `${daysOfWeek[dayOfWeek]}`;
        return `${lastDayString} at ${timeString}`;
    }

    const isSameMonth = now.getMonth() === date.getMonth() && now.getFullYear() === date.getFullYear();
    if (timeDiffInDays < 31 && isSameMonth) {
        const dayName = daysOfWeek[dayOfWeek];
        return `${dayName}, ${timeString}`;
    }

    const dateString = date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
    });

    return `${dateString} at ${timeString}`;
}






const PurchasesPopup = ({ showPurchasesPopup, setShowPurchasesPopup, selectedAdAccountForPurchasesPopup, selectedCampaignForPurchasesPopup, selectedAdsetForPurchasesPopup, selectedAdForPurchasesPopup, omniBusinessId, serverURL, dateStart, dateEnd, attributionModel, attributionWindow, selectedSource, selectedAdLevel }) => {
    const [orders, setOrders] = useState([]);  
    const bid = localStorage.getItem('bid')


    useEffect(() => {
        async function fetchPurchasesData(endpoint, data) {
            try {
                setOrders([]);
                const response = await axios.post(endpoint, data);
                console.log(`${endpoint} purchases`, response.data);
                setOrders(response.data);
            } catch (error) {
                console.error(`ERROR loading ${endpoint} purchases data`, error);
                setOrders([]);
            }
        }
    
    
        if (showPurchasesPopup) {
            
            const attributionData = {
                selectedAdLevel: selectedAdLevel,
                selectedSource: selectedSource,
                type: 'purchases',
                attribution_model: attributionModel,
                attribution_window: attributionWindow,
                date_start: new Date(dateStart).toISOString().slice(0, 10) + 'T00:00:00',
                date_end: new Date(dateEnd).toISOString().slice(0, 10) + 'T23:59:59',
                utm_source: selectedAdAccountForPurchasesPopup?.tp_name,
                campaign_id: selectedCampaignForPurchasesPopup?.tp_id,
                adset_id: selectedAdsetForPurchasesPopup?.tp_id,
                ad_id: selectedAdForPurchasesPopup?.tp_id,
                omni_business_id: bid
            };

            //console.log('attributionData', attributionData); 

            console.log('fetching ads manager attribution');
            fetchPurchasesData(`${serverURL}/getAttributionPurchasers`, attributionData);
            
        }
    
    }, [selectedCampaignForPurchasesPopup, selectedAdsetForPurchasesPopup, selectedAdForPurchasesPopup, omniBusinessId, serverURL, selectedSource, selectedAdLevel, dateStart, dateEnd, showPurchasesPopup]);
    


    return (

            <div className={`PurchasesPopupContainer ${showPurchasesPopup ? 'open' : ''}`}>
                <div className='PurchasesPopup'>
                    <span className='PurchasesPopupTitle'>
                        {selectedAdLevel == '1' && selectedCampaignForPurchasesPopup?.tp_name + ' '} 
                        {selectedAdLevel == '2' && selectedAdsetForPurchasesPopup?.tp_name + ' '} 
                        {selectedAdLevel == '3' && selectedAdForPurchasesPopup?.tp_name + ' '} 
                        ({orders?.length} orders)
                    </span>

                    <div className='PurchasesPopupContentScrollContainer'>
                        <div className='PurchasesPopupContent'>

                            <div className='PurchasesPopupOrderRow'>
                                <span>Order</span>
                                <span>Date</span>
                                <span>Fingerprint ID</span>
                                <span>Session ID</span>
                                <span>Actual Session ID</span>
                                <span>Session Array</span>
                                {/* <span>Customer</span> */}
                                {/* <span>Total Price</span> */}
                            </div>

                            <div className='PurchasesPopupOrderList'>
                                {orders.length > 0 ? (
                                    orders.map((order, index) => (
                                        <div key={index} className='PurchasesPopupOrderRow'>
                                            <span>{order.order_number}</span>
                                            <span>{formatDateTime(order.order_date)}</span>
                                            <span>{order.fingerprint_id}</span>
                                            <span>{order.session_id}</span>
                                            <span>{order.actual_session_id}</span>
                                            <span>{order?.session_array?.toString()}</span>

                                            {/* <span>{order.first_name + " " + order.last_name}</span> */}
                                            {/* <span>${order.revenue}</span> */}
                                        </div>
                                    ))
                                ) : (
                                    <div className='PurchasesPopupOrderRow'>
                                        <span className='noOrders'>No orders available</span>
                                    </div>
                                )}
                            </div>



                        
                        </div>
                    </div>
                    <div className='PurchasesPopupFooter'>
                        <span className='PurchasesPopupCloseButton' onClick={() => { setShowPurchasesPopup(false) }}>Close</span>
                        {/* <span className='PurchasesPopupContinueButton' onClick={() => { }}>Okay</span> */}
                    </div>

                </div>
            </div>



    
        
    );
};

export default PurchasesPopup;
