import react, { useState, useEffect } from 'react';
import axios from 'axios';
import './PlatformComparison.css'
import dayjs from 'dayjs';
import Meta_logo from '../assets/Meta_logo.png'
import google_ads_logo from '../assets/google_ads_logo.png'
import tiktok_logo_white_background from '../assets/tiktok_logo_white_background.png'
import { TrendingUpRounded } from '@mui/icons-material';



function PlatformComparison({widthPercent, dateStart, dateEnd, dateUpdateTrigger, darkMode, serverURL}) {
    const uid = localStorage.getItem('uid');
    const bid = localStorage.getItem('bid');


    const [allData, setAllData] = useState([])
    const [highestSpend, setHighestSpend] = useState(0)
    const [totalSpend, setTotalSpend] = useState(0)






    async function getPlatformComparison () {
        try {
            const API_URL = `${serverURL}/getPlatformComparison?omni_business_id=${bid}&dateStart=${dateStart.toISOString().slice(0,10)}&dateEnd=${dateEnd.toISOString().slice(0,10)}` ;
            console.log('get platform comparison: ', API_URL)
            const response = await axios.get(API_URL)
            const platformComparisonData = response.data
            const sortedData = platformComparisonData.sort((a, b) => parseFloat(b.spend) - parseFloat(a.spend));

            setAllData(sortedData)
            setHighestSpend(Number(sortedData[0].spend))
            
            const totalSpend = sortedData.reduce((a, b) => a + parseFloat(b.spend), 0)
            setTotalSpend(totalSpend)
            
  
        } catch (error) {
            console.log('ERROR loading stats PlatformComparison.js', error)
        }
    }
  
    useEffect(() => {
      if (uid !== '') {
        getPlatformComparison();
      }
    }, [uid, dateUpdateTrigger]);


    return ( 
      
        <div className={'PlatformComparisonContainer ' + (darkMode && ' darkModeCard')} style={{width: widthPercent + '%'}}>
           <span className='PlatformComparisonTitle'>Spend Overview</span>
           <span className='PlatformComparisonDescription'>Here are your top expenses</span>

            <div className='PlatformComparisonChartsContainer'>
                {allData.map((x, index) => { return (
                    <div className='PlatformComparisonChart'>
                        
                        <div className='PlatformComparisonChartTopRow'>
                            <div className='PlatformComparisonRowLogoContainer'>
                                {x.platform == 'meta' && <img src={Meta_logo} className='PlatformComparisonRowLogo'></img> }
                                {x.platform == 'google' && <img src={google_ads_logo} className='PlatformComparisonRowLogo'></img> }
                                {x.platform == 'tiktok' && <img src={tiktok_logo_white_background} className='PlatformComparisonRowLogo'></img> }
                            </div>
                            <div className='PlatformComparisonRowAmount'>
                                <span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(x.spend))}</span>
                                <span style={{color: '#888'}}> ({new Intl.NumberFormat('en-US', { style: 'percent' }).format(Number(x.spend) / totalSpend)})</span>
                            </div>
                        </div>
                        {/* <span className='PlatformComparisonRowPlatformName'>{x.platform}</span> */}
                        <div className='PlatformComparisonRowSpendParent'>
                            <div className='PlatformComparisonRowSpend' style={{width: (Number(x.spend) / highestSpend * 100) + '%'}}></div>
                        </div>
                       
                       
                    </div>
                )})}
            </div>
        </div>
        
    );
}

export default PlatformComparison;