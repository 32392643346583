import './CreateAd2.css';
import { getAuth, signOut } from "firebase/auth";
import React, { useState, useEffect } from 'react';
import { collection, doc, getDoc, onSnapshot, setDoc, updateDoc } from 'firebase/firestore';
import {db} from '../../FirebaseInit';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ContentPasteRoundedIcon from '@mui/icons-material/ContentPasteRounded';
import CloseIcon from '@mui/icons-material/Close';
import ProductSearchModal from '../../components/ProductSearchModal'
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import CreateAdSelectProduct from '../../components/Ads/CreateAdStep1';
import CreateAdMakeCreative from '../../components/Ads/CreateAdStep2';
import CreateAdTargeting from '../../components/Ads/CreateAdStep3';
import axios from 'axios';
import { ChevronLeftRounded, ChevronRightRounded, KeyboardArrowDownRounded, KeyboardArrowUpRounded, MoreVertRounded, VisibilityRounded } from '@mui/icons-material';
import ReactSwitch from 'react-switch';
import CreateType from './createType';
import TextInput from '../../components/TextInput';
import {APIProvider, Map, Marker} from '@vis.gl/react-google-maps';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import CreatePreview from './CreatePreview1';
import fileUploadSVG from '../../assets/uploadFile.svg'



function CreateAd2({serverURL}) {
  const navigate = useNavigate()
  const uid = localStorage.getItem('uid');
  const bid = localStorage.getItem('bid');
  const budgetPresets = [5, 10, 20, 50, 100, 200, 500, 1000, 2000, 5000 ];
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const dates = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
  const years = [2024, 2025, 2026, 2027, 2028, 2029, 2030]
  const hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  const minutes = ['00', '15', '30', '45']
  const ampm = ['AM', 'PM']

  const rightNow = new Date();


  const [facebookAccessToken, setFacebookAccessToken] = useState('');
  const [useAI, setUseAI] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [showLevel, setShowLevel] = useState('campaign');
  const [advantagePlusCreation, setAdvantagePlusCreation] = useState(true);
  const [selectedAttribute, setSelectedAttribute] = useState('');
  const [draftID, setDraftID] = useState('');
  const [loadingDraft, setLoadingDraft] = useState(true);
  const [fb_AdAccount, setfb_AdAccount] = useState([]);
  const [fb_AdTitle, setfb_AdTitle] = useState('');
  const [fb_AdsetTitle, setfb_AdsetTitle] = useState('');
  const [fb_Age, setfb_Age] = useState([]);
  const [fb_Budget, setfb_Budget] = useState([]);
  const [fb_CTA, setfb_CTA] = useState('');
  const [fb_CampaignTitle, setfb_CampaignTitle] = useState('');
  const [fb_DescriptionTextOptions, setfb_DescriptionTextOptions] = useState([]);
  const [fb_DescriptionTextSelected, setfb_DescriptionTextSelected] = useState('');
  const [fb_Gender, setfb_Gender] = useState([]);
  const [fb_HeadlineTextOptions, setfb_HeadlineTextOptions] = useState([]);
  const [fb_HeadlineTextSelected, setfb_HeadlineTextSelected] = useState('');
  const [fb_InterestSelected, setfb_InterestSelected] = useState([]);
  const [fb_LocationSelected, setfb_LocationSelected] = useState([]);
  const [fb_LocationSuggestions, setfb_LocationSuggestions] = useState([]);
  const [fb_Page, setfb_Page] = useState([]);
  const [fb_PerformanceGoal, setfb_PerformanceGoal] = useState('');
  const [fb_Pixel, setfb_Pixel] = useState([]);
  const [fb_PrimaryTextSelected, setfb_PrimaryTextSelected] = useState('');
  const [fb_SelectedMedia, setfb_SelectedMedia] = useState('');
  const [fb_StartAt, setfb_StartAt] = useState([]);
  const [fb_VerifiedInterests, setfb_VerifiedInterests] = useState([]);
  const [product_url, setProduct_url] = useState('');
  const [status, setStatus] = useState('');
  const [showPerformanceGoalDropdown, setShowPerformanceGoalDropdown] = useState(false);
  const [pixelOptions, setPixelOptions] = useState([]);
  const [selectedBudgetPresetIndex, setSelectedBudgetPresetIndex] = useState(2);
  const [isEditingBudget, setIsEditingBudget] = useState(false);
  const [selectedMonthPresetIndex, setSelectedMonthPresetIndex] = useState(rightNow.getMonth());
  const [selectedDatePresetIndex, setSelectedDatePresetIndex] = useState(0);
  const [selectedYearPresetIndex, setSelectedYearPresetIndex] = useState(0);
  const [selectedHourPresetIndex, setSelectedHourPresetIndex] = useState(0);
  const [selectedMinutePresetIndex, setSelectedMinutePresetIndex] = useState(0);
  const [selectedAMPMIndex, setSelectedAMPMIndex] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(months[selectedMonthPresetIndex]);
  const [selectedDate, setSelectedDate] = useState(rightNow.getDate());
  const [selectedYear, setSelectedYear] = useState(rightNow.getFullYear());
  const [selectedHour, setSelectedHour] = useState(rightNow.getHours() % 12 || 12);
  const [selectedMinute, setSelectedMinute] = useState(rightNow.getMinutes().toString().padStart(2, '0'));
  const [selectedAMPM, setSelectedAMPM] = useState(rightNow.getHours() >= 12 ? 'PM' : 'AM');
  const [selectedDay, setSelectedDay] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [selectedMediaSource, setSelectedMediaSource] = useState('');
  const [omniCloudMedia, setOmniCloudMedia] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [shopifyMedia, setShopifyMedia] = useState([]);
  const [facebookMedia, setFacebookMedia] = useState([]);
  const [facebookPages, setFacebookPages] = useState([]);
  const [primaryText1, setPrimaryText1] = useState('');
  const [primaryText2, setPrimaryText2] = useState('');
  const [primaryText3, setPrimaryText3] = useState('');
  const [primaryText4, setPrimaryText4] = useState('');
  const [primaryText5, setPrimaryText5] = useState('');
  const [headlineText1, setHeadlineText1] = useState('');
  const [headlineText2, setHeadlineText2] = useState('');
  const [headlineText3, setHeadlineText3] = useState('');
  const [headlineText4, setHeadlineText4] = useState('');
  const [headlineText5, setHeadlineText5] = useState('');
  const [descriptionText1, setDescriptionText1] = useState('');
  const [descriptionText2, setDescriptionText2] = useState('');
  const [descriptionText3, setDescriptionText3] = useState('');
  const [descriptionText4, setDescriptionText4] = useState('');
  const [descriptionText5, setDescriptionText5] = useState('');
  const [audienceSearchText, setAudienceSearchText] = useState('');
  const [searchLocationsText, setSearchLocationsText] = useState('');
  const [searchLocationsOptions, setSearchLocationsOptions] = useState([]);








  async function createDraft(product) {
    try {
      const data = {
        product: product,
      }
      console.log(data)
      const startTime = performance.now();
      const response = await axios.post(`${serverURL}/createDraft?uid=${uid}`, data)
      const endTime = performance.now();
      const timeTaken = endTime - startTime;
      console.log('Time taken to get response:', timeTaken, 'milliseconds');
      console.log('DRAFTID: ', response.data.draftID)
      setDraftID(response.data.draftID);

    } catch (error) {
      console.error('createAd.js: ERROR create draft', error);
    }

  }



  function generateRandomId(length = 18) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomId = '';
  
    for (let i = 0; i < length; i++) {
      randomId += characters.charAt(Math.floor(Math.random() * characters.length));
    }
  
    return randomId;
  }


  async function getBusinessData(db) {
    const userRef = doc(db, "users", uid);
    const userDoc = await getDoc(userRef);
    const businessRef = doc(db, "businesses", userDoc.data().businessID);
    const businessDoc = await getDoc(businessRef);

    setFacebookAccessToken(businessDoc.data().facebookAccessToken)
    return {
      businessID: userDoc.data().businessID,
      lastUsedFacebookAdAccount: userDoc.data().lastUsedFacebookAdAccount,
      lastUsedFacebookPixel: userDoc.data().lastUsedFacebookPixel,
      lastUsedFacebookPage: userDoc.data().lastUsedFacebookPage,
      facebookAccessToken: businessDoc.data().facebookAccessToken,
    }
  }


  async function saveManualDraft() {
    try {
      // Fetch business data from the database
      const businessData = await getBusinessData(db);
  
      // Destructure required properties from the business data
      const {
        businessID,
        lastUsedFacebookAdAccount,
        lastUsedFacebookPixel,
        lastUsedFacebookPage,
        facebookAccessToken
      } = businessData;
  
      // Generate a new draft ID
      const newDraftID = generateRandomId();
  
      // Prepare the draft data
      const draftData = {
        platform: 'Facebook',
        timestamp: new Date(),
        fb_CampaignTitle: 'New Campaign',
        fb_AdsetTitle: 'New Adset',
        fb_AdTitle: 'New Ad',
        fb_PerformanceGoal: '',
        fb_PrimaryText1: '',
        fb_PrimaryText2: '',
        fb_PrimaryText3: '',
        fb_PrimaryText4: '',
        fb_PrimaryText5: '',
        fb_PrimaryTextOptions: [],
        fb_HeadlineText1: '',
        fb_HeadlineText2: '',
        fb_HeadlineText3: '',
        fb_HeadlineText4: '',
        fb_HeadlineText5: '',
        fb_HeadlineTextOptions: [],
        fb_DescriptionText1: '',
        fb_DescriptionText2: '',
        fb_DescriptionText3: '',
        fb_DescriptionText4: '',
        fb_DescriptionText5: '',
        fb_DescriptionTextOptions: [],
        fb_LocationSelected: '',
        fb_LocationSuggestions: [],
        fb_Age: [],
        fb_Gender: '',
        fb_VerifiedInterests: [],
        fb_InterestSelected: {},
        fb_CTA: '',
        fb_AdAccount: Object.keys(lastUsedFacebookAdAccount).length > 0 ? lastUsedFacebookAdAccount : {},
        fb_Pixel: Object.keys(lastUsedFacebookPixel).length > 0 ? lastUsedFacebookPixel : {},
        fb_Page: Object.keys(lastUsedFacebookPage).length > 0 ? lastUsedFacebookPage : {},
        fb_SelectedMedia: '',
        fb_StartAt: new Date(),
        fb_Budget: 20,
        status: 'draft',
        userID: uid,
        businessID: businessID,
        media: '',
        productUrl: '',
        complete_adAccount: Object.keys(lastUsedFacebookAdAccount).length > 0,
        complete_pixel: Object.keys(lastUsedFacebookPixel).length > 0,
        complete_page: Object.keys(lastUsedFacebookPage).length > 0,
        complete_advancedSettings: true,
        productId: '',
        createdWithAI: true
      };
  
      // Save the draft data to the database
      const res = await setDoc(doc(db, "drafts", newDraftID), draftData);
  
      console.log('Draft saved successfully:', newDraftID);
      setDraftID(newDraftID);
    } catch (error) {
      console.error('Error saving draft:', error);
    }
  }
  


console.log(useAI, draftID)

  async function getDraft () {
    const docRef = doc(db, 'drafts', draftID);
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {        
        setfb_AdAccount(docSnap.data().fb_AdAccount);
        setfb_AdTitle(docSnap.data().fb_AdTitle);
        setfb_AdsetTitle(docSnap.data().fb_AdsetTitle);
        setfb_Age(docSnap.data().fb_Age);
        setfb_Budget(docSnap.data().fb_Budget);
        setfb_CTA(docSnap.data().fb_CTA);
        setfb_CampaignTitle(docSnap.data().fb_CampaignTitle);
        setfb_DescriptionTextOptions(docSnap.data().fb_DescriptionTextOptions);
        setfb_DescriptionTextSelected(docSnap.data().fb_DescriptionTextSelected);
        setfb_Gender(docSnap.data().fb_Gender);
        setfb_HeadlineTextOptions(docSnap.data().fb_HeadlineTextOptions);
        setfb_HeadlineTextSelected(docSnap.data().fb_HeadlineTextSelected);
        setfb_InterestSelected(docSnap.data().fb_InterestSelected);
        setfb_LocationSelected(docSnap.data().fb_LocationSelected);
        setfb_LocationSuggestions(docSnap.data().fb_LocationSuggestions);
        setfb_Page(docSnap.data().fb_Page);
        setfb_PerformanceGoal(docSnap.data().fb_PerformanceGoal);
        setfb_Pixel(docSnap.data().fb_Pixel);
        setfb_PrimaryTextSelected(docSnap.data().fb_PrimaryTextSelected);
        setPrimaryText1(docSnap.data().fb_PrimaryText1);
        setPrimaryText2(docSnap.data().fb_PrimaryText2);
        setPrimaryText3(docSnap.data().fb_PrimaryText3);
        setPrimaryText4(docSnap.data().fb_PrimaryText4);
        setPrimaryText5(docSnap.data().fb_PrimaryText5);
        setHeadlineText1(docSnap.data().fb_HeadlineText1);
        setHeadlineText2(docSnap.data().fb_HeadlineText2);
        setHeadlineText3(docSnap.data().fb_HeadlineText3);
        setHeadlineText4(docSnap.data().fb_HeadlineText4);
        setHeadlineText5(docSnap.data().fb_HeadlineText5);
        setDescriptionText1(docSnap.data().fb_DescriptionText1);
        setDescriptionText2(docSnap.data().fb_DescriptionText2);
        setDescriptionText3(docSnap.data().fb_DescriptionText3);
        setDescriptionText4(docSnap.data().fb_DescriptionText4);
        setDescriptionText5(docSnap.data().fb_DescriptionText5);
        setfb_SelectedMedia(docSnap.data().fb_SelectedMedia);
        setfb_StartAt(docSnap.data().fb_StartAt);
        setfb_VerifiedInterests(docSnap.data().fb_VerifiedInterests);
        

        setLoadingDraft(false)
      } else {
        console.log('No such document!');
        setLoadingDraft(false)
      }
    })    
  }
  useEffect(() => {
      if (draftID !== '') {
          getDraft()
      }
  }, [draftID]);



  const position = { lat: 39.8283, lng: -98.5795 };


  async function getPixelsFromFB () {
    try {
      const response = await axios.get(`${serverURL}/getPixelInsights?omniBusinessID=${bid}`)
      setPixelOptions(response.data.adspixels.data)
    } catch (error) {
      console.log('ERROR getting pixels from FB', error)
    }
  }
  async function getPagesFromDB () {
    console.log(`${serverURL}/getPagesByBID?omni_business_id=${bid}`)
    try {
      const response = await axios.get(`${serverURL}/getPagesByBID?omni_business_id=${bid}`)
      console.log(response.data) 
      setFacebookPages(response.data)
    } catch (error) {
      console.log('ERROR getting pixels from FB', error)
    }
  }
  useEffect(() => {
    if (bid !== '') {
      getPixelsFromFB()
      getPagesFromDB()
    }
  }, [bid]);





  function formatPixelInsights (data) {
    const startTimes = data.map(item => (new Date(item.start_time).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true})));
    const viewContent = [];
    for (let i = 0; i < data.length; i++) {
      const insights = data[i].data;
      viewContent.push(insights.find(item => item.value === "ViewContent")?.count || 0)
    }

    console.log({datasets: {label: "View Content", data: viewContent, borderColor: 'rgba(75, 192, 192, 1)', backgroundColor: 'rgba(75, 192, 192, 0.2)'}})

    return {
      labels: startTimes,
      datasets: [
        {label: "View Content", data: viewContent, borderColor: 'rgba(75, 192, 192, 1)', backgroundColor: 'rgba(75, 192, 192, 0.2)', pointRadius: 0,}
      ]
    }
  }


  const chartOptions = {
    plugins: {
      legend: {
        display: false,  // Hide the legend
      },
    },
    scales: {
      x: {
        grid: {
          display: false,  // Hide grid lines on the x-axis
        },
        ticks: {
          display: false,  // Remove ticks on the x-axis
        },
        border: {
          display: false,  // Remove the axis line
        }
      },
      y: {
        grid: {
          display: false,  // Hide grid lines on the y-axis
        },
        ticks: {
          display: false,  // Remove ticks on the y-axis
        },
        border: {
          display: false,  // Remove the axis line
        }
      },
    },
    elements: {
      line: {
        tension: 0.4,  // Make the line more rounded
      },
    },
  };







  //BUDGET 
  const handleBlur = () => {
    setIsEditingBudget(false);
    // Optionally, you can handle the updated budgetValue here
  };

  const handleBudgetClick = () => {
    setIsEditingBudget(true);
  };

  const handleInputChange = (event) => {
    setfb_Budget(event.target.value);
  };

  const handleLeftArrowClick = () => {
    if (selectedBudgetPresetIndex > 0) {
      const newIndex = selectedBudgetPresetIndex - 1;
      setSelectedBudgetPresetIndex(newIndex);
      setfb_Budget(budgetPresets[newIndex]);
    }
  };

  const handleRightArrowClick = () => {
    if (selectedBudgetPresetIndex < (budgetPresets.length - 1)) {
      const newIndex = selectedBudgetPresetIndex + 1;
      setSelectedBudgetPresetIndex(newIndex);
      setfb_Budget(budgetPresets[newIndex]);
    }
  };






  //SCHEDULE
  const handleUpArrowClickMonths = () => {
    if (selectedMonthPresetIndex < (months.length - 1)) {
      const newIndex = selectedMonthPresetIndex + 1;
      setSelectedMonthPresetIndex(newIndex);
      setSelectedMonth(months[newIndex]);
    }
  };
  const handleDownArrowClickMonths = () => {
    if (selectedMonthPresetIndex > 0) {
      const newIndex = selectedMonthPresetIndex - 1;
      setSelectedMonthPresetIndex(newIndex);
      setSelectedMonth(months[newIndex]);
    }
  };


  const handleUpArrowClickDates = () => {
    if (selectedDatePresetIndex < (dates.length - 1)) {
      const newIndex = selectedDatePresetIndex + 1;
      setSelectedDatePresetIndex(newIndex);
      setSelectedDate(dates[newIndex]);
    }
  };
  const handleDownArrowClickDates = () => {
    if (selectedDatePresetIndex > 0) {
      const newIndex = selectedDatePresetIndex - 1;
      setSelectedDatePresetIndex(newIndex);
      setSelectedDate(dates[newIndex]);
    }
  };


  const handleUpArrowClickYears = () => {
    if (selectedYearPresetIndex < (years.length - 1)) {
      const newIndex = selectedYearPresetIndex + 1;
      setSelectedYearPresetIndex(newIndex);
      setSelectedYear(years[newIndex]);
    }
  };
  const handleDownArrowClickYears = () => {
    if (selectedYearPresetIndex > 0) {
      const newIndex = selectedYearPresetIndex - 1;
      setSelectedYearPresetIndex(newIndex);
      setSelectedYear(years[newIndex]);
    }
  };


  const handleUpArrowClickHours = () => {
    if (selectedHourPresetIndex < (hours.length - 1)) {
      const newIndex = selectedHourPresetIndex + 1;
      setSelectedHourPresetIndex(newIndex);
      setSelectedHour(hours[newIndex]);
    }
  };
  const handleDownArrowClickHours = () => {
    if (selectedHourPresetIndex > 0) {
      const newIndex = selectedHourPresetIndex - 1;
      setSelectedHourPresetIndex(newIndex);
      setSelectedHour(hours[newIndex]);
    }
  };


  const handleUpArrowClickMinutes = () => {
    if (selectedMinutePresetIndex < (minutes.length - 1)) {
      const newIndex = selectedMinutePresetIndex + 1;
      setSelectedMinutePresetIndex(newIndex);
      setSelectedMinute(minutes[newIndex]);
    }
  };
  const handleDownArrowClickMinutes = () => {
    if (selectedMinutePresetIndex > 0) {
      const newIndex = selectedMinutePresetIndex - 1;
      setSelectedMinutePresetIndex(newIndex);
      setSelectedMinute(minutes[newIndex]);
    }
  };



  useEffect(() => {
    // Convert to 24-hour format
    let adjustedHour = selectedHour;
    if (selectedAMPM === 'PM' && selectedHour !== 12) {
      adjustedHour += 12;
    } else if (selectedAMPM === 'AM' && selectedHour === 12) {
      adjustedHour = 0;
    }
  
    // Get month index
  
    // Create Date object
    const date = new Date(selectedYear, selectedMonthPresetIndex, selectedDate, adjustedHour, selectedMinute);
    setSelectedDay(days[date.getDay()])


  }, [selectedMonth, selectedDate, selectedYear, selectedHour, selectedMinute, selectedAMPM]);






  async function getFilesByProductID () {
    try {
      const apiUrl = `${serverURL}/getFilesByProductID?product_id=${selectedProduct.product_id}&omni_business_id=${bid}` ;
      const response = await axios.get(apiUrl);
      console.log(response.data)
      setOmniCloudMedia(response.data)
    } catch (error) {
      console.log('ERROR loading product files CreateAd2.js', error)
    }
  }

  async function getShopifyProductImages () {
    try {
      const apiUrl = `${serverURL}/getShopifyProductImages?product_id=${selectedProduct.product_id}&omni_business_id=${bid}` ;
      const response = await axios.get(apiUrl);
      console.log(response.data)
      setShopifyMedia(response.data)
    } catch (error) {
      console.log('ERROR loading shopify images CreateAd2.js', error)
    }
  }

  useEffect(() => {
    if (showLevel == 'ad') {
      getFilesByProductID();
      getShopifyProductImages();
    }
  }, [showLevel])



  function adjustSelectedMedia(src) {
    let updatedMediaSelection = [];
  
      if (selectedMedia.includes(src)) {
        updatedMediaSelection = selectedMedia.filter(item => item !== src);
      } else {
        updatedMediaSelection = [...selectedMedia, src];
      }
    
      setSelectedMedia(updatedMediaSelection);
  }





  async function searchLocationsOptionsFunction() {
    const searchTypes = ['adgeolocation'];
    const results = {};

    for (const type of searchTypes) {
        try {
            const response = await axios.get(
                `https://graph.facebook.com/v18.0/search?location_types=["country","city","region"]`,
                {
                    params: {
                        type: type,
                        q: searchLocationsText,
                        access_token: facebookAccessToken
                    }
                }
            );
            results[type] = response.data.data;
        } catch (error) {
            console.error(`Error fetching ${type} data:`, error?.response?.data || error?.message || error);
        }
    }

console.log(results)
    setSearchLocationsOptions(results);
    return results
}

useEffect(() => {
  searchLocationsOptionsFunction();
}, [searchLocationsText])




return (
  
  <div className="creationContainer">

    
      <CreateType useAI={useAI} setUseAI={setUseAI} selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} createDraft={createDraft} draftID={draftID} saveManualDraft={saveManualDraft}/>
   
      <CreatePreview setShowPreview={setShowPreview} showPreview={showPreview}/>


      <div className='creationContentContainer'>
        <div className='creationContentContainerLeft'>

          {/* {selectedProduct && (
            <div className='creationContentContainerLeftSelectedProduct'>
              <img src={selectedProduct?.img_src} className='creationContentContainerLeftProductImage'/>
              <span className='creationContentContainerLeftProductTitle'>{selectedProduct?.product_title}</span>
              <div className={selectedProduct?.status == 'ACTIVE' ? 'creationContentContainerLeftProductStatusActive' : 'creationContentContainerLeftProductStatusInactive'}>
                <span>{selectedProduct?.status}</span>
              </div>
            </div>
          )} */}


          {/* <span className='creationContentContainerLeftTitle'>Ad Creation</span> */}


          <div className="creationLevelToggle">
            <div className={`creationLevelButton ${showLevel === 'campaign' ? 'active' : ''}`} onClick={() => setShowLevel('campaign')}>Campaign</div>
            {!advantagePlusCreation && (
            <div className={`creationLevelButton ${showLevel === 'adset' ? 'active' : ''}`} onClick={() => setShowLevel('adset')}>Adset</div>
            )}
            <div className={`creationLevelButton ${showLevel === 'ad' ? 'active' : ''}`} onClick={() => setShowLevel('ad')}>Ad</div>
         </div>



        <div className='creationContentContainerLeftCardsScrollContainer'>
          <div className='creationContentContainerLeftCards'>



            {advantagePlusCreation ?
              // ADVANTAGEPLUS
              <>
                {showLevel === 'campaign' && (
                  <>
                    <div className='creationContentContainerLeftAdvantagePlusSelection'>
                      <ReactSwitch
                        onChange={(checked) => setAdvantagePlusCreation(checked)}
                        checked={advantagePlusCreation}
                        offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                      />
                      <span className='creationContentContainerLeftAdvantagePlusSelectionText'>Advantage+ Campaign</span>
                    </div>

                    <div className={selectedAttribute == 'campaignTitle' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('campaignTitle')}}>
                      <span className='creationLeftCardTitle'>Campaign Title</span>
                      <span className='creationLeftCardValue'>{fb_CampaignTitle}</span>
                    </div>

                    <div className={selectedAttribute == 'performanceGoal' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('performanceGoal')}}>
                      <span className='creationLeftCardTitle'>Performance Goal</span>
                      <span className='creationLeftCardValue'>{fb_PerformanceGoal == 'CONVERSIONS' ? 'Conversion Optimization' : 'Conversion Value Optimization'}</span>
                    </div>

                    <div className={selectedAttribute == 'tracking' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('tracking')}}>
                      <span className='creationLeftCardTitle'>Tracking</span>
                      <span className='creationLeftCardValue' >{fb_Pixel.pixel_name}</span>
                      <span className='creationLeftCardValue' style={{fontSize: 10, marginTop: 0}}>{fb_Pixel.pixel_id}</span>
                    </div>

                    <div className={selectedAttribute == 'location' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('location')}}>
                      <span className='creationLeftCardTitle'>Location</span>
                      <div  style={{ height: '150px', width: '100%', borderRadius: 10, overflow: 'hidden' }}>
                        <APIProvider apiKey={'AIzaSyDkBuuNvxo_MUS9nhOzICfF9rQ27lFRjN8'}>
                          <Map defaultCenter={position} zoom={3}>
                            <Marker position={position} />
                          </Map>
                        </APIProvider>
                      </div>
                      <span className='creationLeftCardValue'>{fb_LocationSelected.name}</span>

                    </div>

                    <div className={selectedAttribute == 'campaignBudget' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('campaignBudget')}}>
                      <span className='creationLeftCardTitle'>Budget</span>
                      <span className='creationLeftCardValue'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(fb_Budget)}</span>
                    </div>

                    <div className={selectedAttribute == 'schedule' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('schedule')}}>
                      <span className='creationLeftCardTitle'>Schedule</span>
                      <span className='creationLeftCardValue'>{(new Date(fb_StartAt.seconds * 1000)).toLocaleString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</span>
                    </div>
                  </>
                )}

                {showLevel === 'ad' && (
                  // ADVANTAGEPLUS
                  <>
                    New Ad or select existing ad

                    <div className={selectedAttribute == 'adTitle' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('adTitle')}}>
                      <span className='creationLeftCardTitle'>Ad Title</span>
                      <span className='creationLeftCardValue'>{fb_AdTitle}</span>
                    </div>

                    <div className={selectedAttribute == 'media' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('media')}}>
                      <span className='creationLeftCardTitle'>Media</span>
                      <span className='creationLeftCardValue'>This is the campaign title</span>
                    </div>

                    <div className={selectedAttribute == 'page' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('page')}}>
                      <span className='creationLeftCardTitle'>Facebook Page</span>
                      <span className='creationLeftCardValue'>{fb_Page?.name}</span>
                    </div>

                    <div className={selectedAttribute == 'primary' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('primary')}}>
                      <span className='creationLeftCardTitle'>Primary</span>
                      <span className='creationLeftCardValue' style={{fontSize: 12}}>{primaryText1}</span>
                    </div>

                    <div className={selectedAttribute == 'headline' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('headline')}}>
                      <span className='creationLeftCardTitle'>Headline</span>
                      <span className='creationLeftCardValue'>{headlineText1}</span>
                    </div>

                    <div className={selectedAttribute == 'description' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('description')}}>
                      <span className='creationLeftCardTitle'>Description</span>
                      <span className='creationLeftCardValue' style={{fontSize: 12}}>{descriptionText1}</span>
                    </div>

                    <div className={selectedAttribute == 'cta' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('cta')}}>
                      <span className='creationLeftCardTitle'>Call to action</span>
                      <span className='creationLeftCardValue'>
                        {fb_CTA == 'SHOP_NOW' && 'Shop Now'}
                        {fb_CTA == 'LEARN_MORE' && 'Learn More'}
                        {fb_CTA == 'SIGN_UP' && 'Sign Up'}
                      </span>
                    </div>
                  </>
                )}
              </>
              :
              <>
                {showLevel === 'campaign' && (
                  // NONADVANTAGEPLUS
                  <>
                    <div className='creationContentContainerLeftAdvantagePlusSelection'>
                      <ReactSwitch
                        onChange={(checked) => setAdvantagePlusCreation(checked)}
                        checked={advantagePlusCreation}
                        offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                      />
                      <span className='creationContentContainerLeftAdvantagePlusSelectionText'>Advantage+ Campaign</span>
                    </div>
                    <div className={selectedAttribute == 'campaignTitle' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('campaignTitle')}}>
                      <span className='creationLeftCardTitle'>Campaign Title</span>
                      <span className='creationLeftCardValue'>{fb_CampaignTitle}</span>
                    </div>

                    <div className={selectedAttribute == 'campaignBudget' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('campaignBudget')}}>
                      <span className='creationLeftCardTitle'>Budget</span>
                      <span className='creationLeftCardValue'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(fb_Budget)}</span>
                    </div>

                  </>
                )}

                {showLevel === 'adset' && (
                  // NONADVANTAGEPLUS
                  <>
                    <div className={selectedAttribute == 'adsetTitle' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('adsetTitle')}}>
                      <span className='creationLeftCardTitle'>Adset Title</span>
                      <span className='creationLeftCardValue'>{fb_AdsetTitle}</span>
                    </div>

                    <div className={selectedAttribute == 'performanceGoal' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('performanceGoal')}}>
                      <span className='creationLeftCardTitle'>Performance Goal</span>
                      <span className='creationLeftCardValue'>{fb_PerformanceGoal}</span>
                    </div>

                    <div className={selectedAttribute == 'tracking' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('tracking')}}>
                      <span className='creationLeftCardTitle'>Tracking</span>
                      <span className='creationLeftCardValue'>{fb_Pixel.pixel_name}</span>
                    </div>

                    <div className={selectedAttribute == 'audience' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('audience')}}>
                      <span className='creationLeftCardTitle'>Audience</span>
                      <span className='creationLeftCardValue'>{fb_InterestSelected.name}</span>
                    </div>

                    <div className={selectedAttribute == 'adsetBudget' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('adsetBudget')}}>
                      <span className='creationLeftCardTitle'>Budget</span>
                      <span className='creationLeftCardValue'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(fb_Budget)}</span>
                    </div>

                    <div className={selectedAttribute == 'schedule' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('schedule')}}>
                      <span className='creationLeftCardTitle'>Schedule</span>
                      <span className='creationLeftCardValue'>{(new Date(fb_StartAt.seconds * 1000)).toLocaleString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</span>
                    </div>
                  </>
                )}


                {showLevel === 'ad' && (
                  // NONADVANTAGEPLUS
                  <>
                    <div className={selectedAttribute == 'adTitle' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('adTitle')}}>
                      <span className='creationLeftCardTitle'>Ad Title</span>
                      <span className='creationLeftCardValue'>{fb_AdTitle}</span>
                    </div>

                    <div className={selectedAttribute == 'media' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('media')}}>
                      <span className='creationLeftCardTitle'>Media</span>
                      <span className='creationLeftCardValue'>This is the campaign title</span>
                    </div>

                    <div className={selectedAttribute == 'page' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('page')}}>
                      <span className='creationLeftCardTitle'>Facebook Page</span>
                      <span className='creationLeftCardValue'>{fb_Page?.name}</span>
                    </div>

                    <div className={selectedAttribute == 'primary' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('primary')}}>
                      <span className='creationLeftCardTitle'>Primary Text</span>
                      <span className='creationLeftCardValue' style={{fontSize: 12}}>{fb_PrimaryTextSelected}</span>
                    </div>

                    <div className={selectedAttribute == 'headline' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('headline')}}>
                      <span className='creationLeftCardTitle'>Headline</span>
                      <span className='creationLeftCardValue'>{fb_HeadlineTextSelected}</span>
                    </div>

                    <div className={selectedAttribute == 'description' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('description')}}>
                      <span className='creationLeftCardTitle'>Description</span>
                      <span className='creationLeftCardValue' style={{fontSize: 12}}>{fb_DescriptionTextSelected}</span>
                    </div>

                    <div className={selectedAttribute == 'cta' ? 'creationLeftCardSelected' : 'creationLeftCard'} onClick={() => {setSelectedAttribute('cta')}}>
                      <span className='creationLeftCardTitle'>Call to action</span>
                      <span className='creationLeftCardValue'>
                        {fb_CTA == 'SHOP_NOW' && 'Shop Now'}
                        {fb_CTA == 'LEARN_MORE' && 'Learn More'}
                        {fb_CTA == 'SIGN_UP' && 'Sign Up'}
                      </span>
                    </div>
                  </>
                )}
              </>
            }


          </div>
        </div>

          
          
          <div className='creationViewButtonContainer' onClick={() => {setShowPreview(true)}}>
            <div className='creationViewButtonIcon'><VisibilityRounded/></div>
            <span className='creationViewButtonText'>View</span>
          </div>
        </div>






        <div className='creationContentContainerRight'>


          {selectedAttribute === 'campaignTitle' && (
            <div className='creationContentContainerRightContainer'>
              <span className='creationContentContainerRightTitle'>Campaign Title</span>
              <TextInput placeholder='Campaign Title' type='text' value={fb_CampaignTitle} onChange={setfb_CampaignTitle} widthPercent={100} marginBottom='20px'/>
              <span className='creationContentContainerRightTextLength'>{fb_CampaignTitle.length}/60</span>

             
            </div>
          )}


          {selectedAttribute === 'performanceGoal' && (
            <div className='creationContentContainerRightContainer'>
              <span className='creationContentContainerRightTitle'>Performance Goal </span>

{/* 
              <div
                className='creationDropdownButton'
                onClick={(e) => {
                e.stopPropagation(); // Stop propagation to prevent onClick of parent div
                setShowPerformanceGoalDropdown(!showPerformanceGoalDropdown);
                }}
              >
                {fb_PerformanceGoal === 'CONVERSIONS' && 'Maximize number of conversions'}
                {fb_PerformanceGoal === 'CONVERSION_VALUE' && 'Maximize value of conversions'}
                
                <KeyboardArrowDownRounded/>
                {showPerformanceGoalDropdown && (
                <div className='creationDropdownContainer'>
                  <span className='creationDropdownRow' onClick={() => {setfb_PerformanceGoal('CONVERSIONS')}}>Maximize number of conversions</span>
                  <span className='creationDropdownRow' onClick={() => {setfb_PerformanceGoal('CONVERSION_VALUE')}}>Maximize value of conversions</span>
                </div>
                )}
              </div>
              {fb_PerformanceGoal === 'CONVERSIONS' && "We'll try to show your ads to the people most likely to make a purchase on your website"}
              {fb_PerformanceGoal === 'CONVERSION_VALUE' && "We'll try to show your ads to the people most likely to make higher value purchases on your website"}
 */}

              <div className='creationPerformanceGoalOptionsContainer'>
                <div className='creationPerformanceGoalOptionsRow'>
                  <div className={fb_PerformanceGoal == 'CONVERSIONS' ? 'creationPerformanceGoalOptionCardSelected' : 'creationPerformanceGoalOptionCard'} onClick={() => {setfb_PerformanceGoal('CONVERSIONS')}}>
                    <span className='creationPerformanceGoalOptionCardImage'>(NUMBER GRAPHIC HERE)</span>
                    <span className='creationPerformanceGoalOptionCardTitle'>Maximize number of conversions</span>
                  </div>
                  <div className={fb_PerformanceGoal == 'CONVERSION_VALUE' ? 'creationPerformanceGoalOptionCardSelected' : 'creationPerformanceGoalOptionCard'} onClick={() => {setfb_PerformanceGoal('CONVERSION_VALUE')}}>
                    <span className='creationPerformanceGoalOptionCardImage'>(VALUE GRAPHIC HERE)</span>
                    <span className='creationPerformanceGoalOptionCardTitle'>Maximize value of conversions</span>
                  </div>
                </div>

                {fb_PerformanceGoal == 'CONVERSIONS' && <span className='creationPerformanceGoalOptionCardDescription'>We'll try to show your ads to the people most likely to make a purchase on your website</span>}
                {fb_PerformanceGoal == 'CONVERSION_VALUE' && <span className='creationPerformanceGoalOptionCardDescription'>We'll try to show your ads to the people most likely to make higher value purchases on your website</span>}
              </div>
            </div>
          )}



          {selectedAttribute === 'tracking' && (
            <div className='creationContentContainerRightContainer'>
              <span className='creationContentContainerRightTitle'>Tracking</span>

              <span className='creationPixelsTitle'>Active</span>
              <div className='creationPixelsContainer'>
                {pixelOptions.map((x) => { return (
                  <>
                    {x.stats && (
                      <div className={fb_Pixel.pixel_id == x.id ? 'creationPixelCardSelected' : 'creationPixelCard'} onClick={() => {setfb_Pixel({name: x.name, pixel_id: x.id})}}>
                        <div className='creationPixelCardChartContainer'>
                          <Line data={formatPixelInsights(x.stats.data)} options={chartOptions} />
                        </div>
                        <div className='creationPixelCardContentContainer'>
                          <span className='creationPixelCardTitle'>{x.name}</span>
                          <span className='creationPixelCardID'>{x.id}</span>
                        </div>
                      </div>
                    )}
                  </>
                )})}
              </div>

              <span className='creationPixelsTitle'>Inactive</span>
              <div className='creationPixelsContainer'>
                {pixelOptions.map((x) => { return (
                  <>
                    {!x.stats && (
                      <div className={fb_Pixel.pixel_id == x.id ? 'creationPixelCardSelected' : 'creationPixelCard'} onClick={() => {setfb_Pixel({name: x.name, pixel_id: x.id})}}>
                        <div className='creationPixelCardContentContainer' style={{borderTop: 0}}>
                          <span className='creationPixelCardTitle'>{x.name}</span>
                          <span className='creationPixelCardID'>{x.id}</span>
                        </div>
                      </div>
                    )}
                  </>
                )})}
              </div>

            </div>
          )}







          {selectedAttribute === 'location' && (
            <div className='creationContentContainerRightContainer'>
              <span className='creationContentContainerRightTitle'>Location</span>
              <TextInput placeholder='Search Locations' type='text' value={searchLocationsText} onChange={setSearchLocationsText} widthPercent={100} marginBottom='20px'/>
              <span className='creationContentContainerRightTextLength'>{searchLocationsText.length}/60</span>

              <div  style={{ height: '300px', width: '100%', borderRadius: 20, overflow: 'hidden' }}>
                <APIProvider apiKey={'AIzaSyDkBuuNvxo_MUS9nhOzICfF9rQ27lFRjN8'}>
                  <Map defaultCenter={position} defaultZoom={10}>
                    <Marker position={position} />
                  </Map>
                </APIProvider>
              </div>
            </div>
          )}






          {selectedAttribute === 'campaignBudget' && (
            <div className='creationContentContainerRightContainer'>
              <span className='creationContentContainerRightTitle'>Budget</span>

              <div className='creationBudgetContentContainer'>
                <span>(MONEY GRAPHIC)</span>
                <span className='creationBudgetContentText'>How much do you want to spend daily?</span>
                <div className='creationBudgetContentRow'>
                  <div className='creationBudgetArrow' onClick={handleLeftArrowClick}><ChevronLeftRounded/></div>
                  {isEditingBudget ? (
                    <input
                      type='number'
                      value={fb_Budget}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      autoFocus
                      className='creationBudgetBudgetInput'
                      style={{ '-webkit-appearance': 'none', '-moz-appearance': 'textfield' }} // Apply styles directly if not using external CSS
                    />
                  ) : (
                    <span className='creationBudgetBudgetText' onClick={handleBudgetClick}>${fb_Budget}</span>
                  )}
                  <div className='creationBudgetArrow' onClick={handleRightArrowClick}><ChevronRightRounded/></div>
                </div>
              </div>
            </div>
          )}






          {selectedAttribute === 'schedule' && (
            <div className='creationContentContainerRightContainer'>
              <span className='creationContentContainerRightTitle'>Schedule</span>

              <div className='creationBudgetContentContainer'>
                <span>(AD TIME/SCHEDULE GRAPHIC)</span>
                <span className='creationBudgetContentText'>When do you want your ad to start?</span>
                <div className='creationScheduleContentRow'>
                  <span className='creationScheduleText' style={{marginRight: 20, width: 200, textAlign: 'right'}}>{selectedDay} </span>
                  <div className='creationScheduleContentColumn'>
                    <div className='creationScheduleArrow' onClick={handleUpArrowClickMonths}><KeyboardArrowUpRounded/></div>
                      <span className='creationScheduleText'>{selectedMonth}</span>
                    <div className='creationScheduleArrow' onClick={handleDownArrowClickMonths}><KeyboardArrowDownRounded/></div>
                  </div>
                  <div className='creationScheduleContentColumn'>
                    <div className='creationScheduleArrow' onClick={handleUpArrowClickDates}><KeyboardArrowUpRounded/></div>
                      <span className='creationScheduleText'>{selectedDate}</span>
                    <div className='creationScheduleArrow' onClick={handleDownArrowClickDates}><KeyboardArrowDownRounded/></div>
                  </div>
                  <div className='creationScheduleContentColumn'>
                    <div className='creationScheduleArrow' onClick={handleUpArrowClickYears}><KeyboardArrowUpRounded/></div>
                      <span className='creationScheduleText'>{selectedYear}</span>
                    <div className='creationScheduleArrow' onClick={handleDownArrowClickYears}><KeyboardArrowDownRounded/></div>
                  </div>
                  <div className='creationScheduleContentColumn' style={{paddingRight: 0}}>
                    <div className='creationScheduleArrow' onClick={handleUpArrowClickHours}><KeyboardArrowUpRounded/></div>
                      <span className='creationScheduleText'>{selectedHour}</span>
                    <div className='creationScheduleArrow' onClick={handleDownArrowClickHours}><KeyboardArrowDownRounded/></div>
                  </div>
                  <span className='creationScheduleText' style={{paddingLeft: 0, paddingRight: 0, paddingBottom: 10}}> : </span>
                  <div className='creationScheduleContentColumn' style={{paddingLeft: 0}}>
                    <div className='creationScheduleArrow' onClick={handleUpArrowClickMinutes}><KeyboardArrowUpRounded/></div>
                      <span className='creationScheduleText'>{selectedMinute}</span>
                    <div className='creationScheduleArrow' onClick={handleDownArrowClickMinutes}><KeyboardArrowDownRounded/></div>
                  </div>
                  <div className='creationScheduleContentColumn'>
                    <div className='creationScheduleArrow' onClick={() => {setSelectedAMPM(selectedAMPM == 'AM' ? 'PM' : 'AM')}}><KeyboardArrowUpRounded/></div>
                      <span className='creationScheduleText'>{selectedAMPM}</span>
                    <div className='creationScheduleArrow' onClick={() => {setSelectedAMPM(selectedAMPM == 'AM' ? 'PM' : 'AM')}}><KeyboardArrowDownRounded/></div>
                  </div>
                  
                </div>
              </div>
            </div>
          )}





          {selectedAttribute === 'adTitle' && (
            <div className='creationContentContainerRightContainer'>
              <span className='creationContentContainerRightTitle'>Ad Title</span>
              <TextInput placeholder='Ad Title' type='text' value={fb_AdTitle} onChange={setfb_AdTitle} widthPercent={100} marginBottom='20px'/>
              <span className='creationContentContainerRightTextLength'>{fb_AdTitle.length}/60</span>

             
            </div>
          )}


          
          
          {selectedAttribute === 'media' && (
            <div className='creationContentContainerRightContainer'>
              <span className='creationContentContainerRightTitle'>Media</span>
              
              <div className='creationContentContainerMediaTopRow'>
                <span className={selectedMediaSource == 'upload' ? 'creationContentContainerMediaTopRowItemSelected' : 'creationContentContainerMediaTopRowItem'} onClick={() => {setSelectedMediaSource('upload')}}>Upload</span>
                <span className={selectedMediaSource == 'omni' ? 'creationContentContainerMediaTopRowItemSelected' : 'creationContentContainerMediaTopRowItem'} onClick={() => {setSelectedMediaSource('omni')}}>Omni Cloud</span>
                <span className={selectedMediaSource == 'facebook' ? 'creationContentContainerMediaTopRowItemSelected' : 'creationContentContainerMediaTopRowItem'} onClick={() => {setSelectedMediaSource('facebook')}}>Facebook</span>
                <span className={selectedMediaSource == 'shopify' ? 'creationContentContainerMediaTopRowItemSelected' : 'creationContentContainerMediaTopRowItem'} onClick={() => {setSelectedMediaSource('shopify')}}>Shopify</span>
                <span className={selectedMediaSource == 'midjourney' ? 'creationContentContainerMediaTopRowItemSelected' : 'creationContentContainerMediaTopRowItem'} onClick={() => {setSelectedMediaSource('midjourney')}}>Midjourney</span>
                <span className={selectedMediaSource == 'sora' ? 'creationContentContainerMediaTopRowItemSelected' : 'creationContentContainerMediaTopRowItem'} onClick={() => {setSelectedMediaSource('sora')}}>Sora AI (coming soon)</span>
              </div>

              <div className='creationContentContainerMediaContainer'>
                {selectedMediaSource == 'omni' && (
                  <React.Fragment>
                    {omniCloudMedia.map((x) => { return (
                      <>
                        {x.type == 'image' ?
                          <div className={selectedMedia.includes(x.s3_link) ? 'creationContentContainerMediaSelected' : 'creationContentContainerMedia'} onClick={() => {adjustSelectedMedia(x.s3_link)}}>
                            <img className='creationContentContainerMediaItem' src={x.s3_link}/>
                          </div>
                          :
                          <div className={selectedMedia.includes(x.s3_link) ? 'creationContentContainerMediaSelected' : 'creationContentContainerMedia'} onClick={() => {adjustSelectedMedia(x.s3_link)}}>
                            <video className='creationContentContainerMediaItem' controls>
                              <source src={x.s3_link} type={x.file_type} />
                            </video>
                          </div>
                        }
                      </>
                    )})}
                  </React.Fragment>
                )}

                {selectedMediaSource == 'shopify' && (
                  <React.Fragment>
                    {shopifyMedia.map((x) => { return (
                      <div className={selectedMedia.includes(x.src) ? 'creationContentContainerMediaSelected' : 'creationContentContainerMedia'} onClick={() => {adjustSelectedMedia(x.src)}}>
                        <img className='creationContentContainerMediaItem' src={x.src}/>
                      </div> 
                    )})}
                  </React.Fragment>
                )}

                {selectedMediaSource == 'facebook' && (
                  <React.Fragment>
                    {facebookMedia.map((x) => { return (
                      <>
                        {x.type == 'image' ?
                          <div className='creationContentContainerMedia' onClick={() => {adjustSelectedMedia(x.s3_link)}}>
                            <img className='creationContentContainerMediaItem' src={x.s3_link}/>
                          </div>
                          :
                          <div className='creationContentContainerMedia' onClick={() => {adjustSelectedMedia(x.s3_link)}}>
                            <video className='creationContentContainerMediaItem' controls>
                              <source src={x.s3_link} type={x.file_type} />
                            </video>
                          </div>
                        }
                      </>
                    )})}
                  </React.Fragment>
                )}

                {selectedMediaSource == 'upload' && (
                  <div className='createAdStep2MediaSelectionModalMediaContainer'>
                    <label htmlFor="fileInput">
                      <img className='createAdStep2MediaSelectionModalUploadFileIcon' src={fileUploadSVG}/>
                    </label>
                  </div>
                )}

              </div>
             
            </div>
          )}


          {selectedAttribute === 'page' && (
            <div className='creationContentContainerRightContainer'>
              <span className='creationContentContainerRightTitle'>Facebook Page</span>
              {facebookPages.map((x) => { return (
                <div className={fb_Page.id == x.id ? 'creationContentContainerRightCardSelected' : 'creationContentContainerRightCard'} onClick={() => {setfb_Page(x)}}>
                  <img className='creationContentContainerRightCardImage' src={x.picture}/>
                  <span className='creationContentContainerRightCardTitle'>{x.name}</span>
                </div>
              )})}
            </div>
          )}




          {selectedAttribute === 'primary' && (
            <div className='creationContentContainerRightContainer'>
              <span className='creationContentContainerRightTitle'>Primary Text</span>

                <TextInput placeholder='Option 1' type='text' value={primaryText1} onChange={setPrimaryText1} widthPercent={100} marginBottom='20px'/>
                <span className='creationContentContainerRightTextLength'>{primaryText1.length}/60</span>
                <br/>
                <TextInput placeholder='Option 2' type='text' value={primaryText2} onChange={setPrimaryText2} widthPercent={100} marginBottom='20px'/>
                <span className='creationContentContainerRightTextLength'>{primaryText2.length}/60</span>
                <br/>
                <TextInput placeholder='Option 3' type='text' value={primaryText3} onChange={setPrimaryText3} widthPercent={100} marginBottom='20px'/>
                <span className='creationContentContainerRightTextLength'>{primaryText3.length}/60</span>
                <br/>
                <TextInput placeholder='Option 4' type='text' value={primaryText4} onChange={setPrimaryText4} widthPercent={100} marginBottom='20px'/>
                <span className='creationContentContainerRightTextLength'>{primaryText4.length}/60</span>
                <br/>
                <TextInput placeholder='Option 5' type='text' value={primaryText5} onChange={setPrimaryText5} widthPercent={100} marginBottom='20px'/>
                <span className='creationContentContainerRightTextLength'>{primaryText5.length}/60</span>

            </div>
          )}



          {selectedAttribute === 'headline' && (
            <div className='creationContentContainerRightContainer'>
              <span className='creationContentContainerRightTitle'>Headline Text</span>

                <TextInput placeholder='Option 1' type='text' value={headlineText1} onChange={setHeadlineText1} widthPercent={100} marginBottom='20px'/>
                <span className='creationContentContainerRightTextLength'>{headlineText1.length}/60</span>
                <br/>
                <TextInput placeholder='Option 2' type='text' value={headlineText2} onChange={setHeadlineText2} widthPercent={100} marginBottom='20px'/>
                <span className='creationContentContainerRightTextLength'>{headlineText2.length}/60</span>
                <br/>
                <TextInput placeholder='Option 3' type='text' value={headlineText3} onChange={setHeadlineText3} widthPercent={100} marginBottom='20px'/>
                <span className='creationContentContainerRightTextLength'>{headlineText3.length}/60</span>
                <br/>
                <TextInput placeholder='Option 4' type='text' value={headlineText4} onChange={setHeadlineText4} widthPercent={100} marginBottom='20px'/>
                <span className='creationContentContainerRightTextLength'>{headlineText4.length}/60</span>
                <br/>
                <TextInput placeholder='Option 5' type='text' value={headlineText5} onChange={setHeadlineText5} widthPercent={100} marginBottom='20px'/>
                <span className='creationContentContainerRightTextLength'>{headlineText5.length}/60</span>

            </div>
          )}





          {selectedAttribute === 'description' && (
            <div className='creationContentContainerRightContainer'>
              <span className='creationContentContainerRightTitle'>Description Text</span>

                <TextInput placeholder='Option 1' type='text' value={descriptionText1} onChange={setDescriptionText1} widthPercent={100} marginBottom='20px'/>
                <span className='creationContentContainerRightTextLength'>{descriptionText1.length}/60</span>
                <br/>
                <TextInput placeholder='Option 2' type='text' value={descriptionText2} onChange={setDescriptionText2} widthPercent={100} marginBottom='20px'/>
                <span className='creationContentContainerRightTextLength'>{descriptionText2.length}/60</span>
                <br/>
                <TextInput placeholder='Option 3' type='text' value={descriptionText3} onChange={setDescriptionText3} widthPercent={100} marginBottom='20px'/>
                <span className='creationContentContainerRightTextLength'>{descriptionText3.length}/60</span>
                <br/>
                <TextInput placeholder='Option 4' type='text' value={descriptionText4} onChange={setDescriptionText4} widthPercent={100} marginBottom='20px'/>
                <span className='creationContentContainerRightTextLength'>{descriptionText4.length}/60</span>
                <br/>
                <TextInput placeholder='Option 5' type='text' value={descriptionText5} onChange={setHeadlineText5} widthPercent={100} marginBottom='20px'/>
                <span className='creationContentContainerRightTextLength'>{descriptionText5.length}/60</span>

            </div>
          )}




          {selectedAttribute === 'cta' && (
            <div className='creationContentContainerRightContainer'>
              <span className='creationContentContainerRightTitle'>Click To Action</span>

                <div className='creationContentContainerCTARow'>
                  <span className={fb_CTA == 'SHOP_NOW' ? 'creationContentContainerRightCTASelected' : 'creationContentContainerRightCTA'} onClick={() => {setfb_CTA('SHOP_NOW')}}>Shop Now</span>
                  <span className={fb_CTA == 'LEARN_MORE' ? 'creationContentContainerRightCTASelected' : 'creationContentContainerRightCTA'} onClick={() => {setfb_CTA('LEARN_MORE')}}>Learn More</span>
                  <span className={fb_CTA == 'SIGN_UP' ? 'creationContentContainerRightCTASelected' : 'creationContentContainerRightCTA'} onClick={() => {setfb_CTA('SIGN_UP')}}>Sign Up</span>
                </div>

            </div>
          )}




          {selectedAttribute === 'adsetTitle' && (
            <div className='creationContentContainerRightContainer'>
              <span className='creationContentContainerRightTitle'>Adset Title</span>
              <TextInput placeholder='Adset Title' type='text' value={fb_AdsetTitle} onChange={setfb_AdsetTitle} widthPercent={100} marginBottom='20px'/>
              <span className='creationContentContainerRightTextLength'>{fb_AdsetTitle.length}/60</span>

             
            </div>
          )}





          {selectedAttribute === 'audience' && (
            <div className='creationContentContainerRightContainer'>
              <span className='creationContentContainerRightTitle'>Audience Selection</span>
              <TextInput placeholder='Search Audiences' type='text' value={audienceSearchText} onChange={setAudienceSearchText} widthPercent={100} marginBottom='20px'/>
              <span className='creationContentContainerRightTextLength'>{audienceSearchText.length}/60</span>

             
              {fb_VerifiedInterests.map((x) => { return (
                <div className={fb_InterestSelected.id == x.id ? 'creationContentContainerRightCardSelected' : 'creationContentContainerRightCard'} onClick={() => {setfb_InterestSelected(x)}}>
                  <span className='creationContentContainerRightCardTitle'>{x.name}</span>
                </div>
              )})}

            </div>
          )}







          <div className='creationBottomButtonContainer'>
            {selectedProduct && (
              
              <div className='creationContentContainerLeftSelectedProduct'>
                <img src={selectedProduct?.img_src} className='creationContentContainerLeftProductImage'/>
                <span className='creationContentContainerLeftProductTitle'>{selectedProduct?.product_title}</span>
                <div className={selectedProduct?.status == 'ACTIVE' ? 'creationContentContainerLeftProductStatusActive' : 'creationContentContainerLeftProductStatusInactive'}>
                  <span>{selectedProduct?.status}</span>
                </div>
              </div>
            )}

            <span className='creationBottomCreateButton'>
              Create Ad
            </span>
          </div>


        </div>
      </div>
    </div>
  );
}

export default CreateAd2;