import * as React from "react";


const AnalyticsLineChartIcon = ({selectedChartType}) => (
  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.875 3.875C3.875 2.80332 3.00918 1.9375 1.9375 1.9375C0.86582 1.9375 0 2.80332 0 3.875V24.2188C0 26.8949 2.16758 29.0625 4.84375 29.0625H29.0625C30.1342 29.0625 31 28.1967 31 27.125C31 26.0533 30.1342 25.1875 29.0625 25.1875H4.84375C4.31094 25.1875 3.875 24.7516 3.875 24.2188V3.875ZM28.4934 9.11836C29.2502 8.36152 29.2502 7.13242 28.4934 6.37559C27.7365 5.61875 26.5074 5.61875 25.7506 6.37559L19.375 12.7572L15.8996 9.28184C15.1428 8.525 13.9137 8.525 13.1568 9.28184L6.37559 16.0631C5.61875 16.8199 5.61875 18.049 6.37559 18.8059C7.13242 19.5627 8.36152 19.5627 9.11836 18.8059L14.5312 13.399L18.0066 16.8744C18.7635 17.6313 19.9926 17.6313 20.7494 16.8744L28.4994 9.12441L28.4934 9.11836Z" fill={selectedChartType == 'line' ? '#fff' : "#FD6488"}/>
  </svg>
);

const AnalyticsHorizontalBarChartIcon = ({selectedChartType, horizontalBars}) => (
  <svg width="29" height="31" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.8125 1.9375C2.81504 1.9375 3.625 2.80332 3.625 3.875V24.2188C3.625 24.7516 4.03281 25.1875 4.53125 25.1875H27.1875C28.19 25.1875 29 26.0533 29 27.125C29 28.1967 28.19 29.0625 27.1875 29.0625H4.53125C2.02773 29.0625 0 26.8949 0 24.2188V3.875C0 2.80332 0.809961 1.9375 1.8125 1.9375ZM7.25 7.75C7.25 6.67832 8.05996 5.8125 9.0625 5.8125H19.9375C20.94 5.8125 21.75 6.67832 21.75 7.75C21.75 8.82168 20.94 9.6875 19.9375 9.6875H9.0625C8.05996 9.6875 7.25 8.82168 7.25 7.75ZM9.0625 11.625H16.3125C17.315 11.625 18.125 12.4908 18.125 13.5625C18.125 14.6342 17.315 15.5 16.3125 15.5H9.0625C8.05996 15.5 7.25 14.6342 7.25 13.5625C7.25 12.4908 8.05996 11.625 9.0625 11.625ZM9.0625 17.4375H23.5625C24.565 17.4375 25.375 18.3033 25.375 19.375C25.375 20.4467 24.565 21.3125 23.5625 21.3125H9.0625C8.05996 21.3125 7.25 20.4467 7.25 19.375C7.25 18.3033 8.05996 17.4375 9.0625 17.4375Z" fill={(selectedChartType == 'bar' && horizontalBars == true) ? '#fff' : '#50B7FF'} />
  </svg>
);

const AnalyticsPieChartIcon = ({selectedChartType}) => (
  <svg width="33" height="31" viewBox="0 0 33 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.9442 14.5312V1.00508C16.9442 0.460156 17.3574 0 17.8887 0C25.1904 0 31.1109 6.07285 31.1109 13.5625C31.1109 14.1074 30.6623 14.5312 30.131 14.5312H16.9442ZM0.888672 16.4688C0.888672 9.12441 6.20707 3.04551 13.1074 2.07676C13.6505 1.99805 14.1109 2.44609 14.1109 3.00918V17.4375L23.3487 26.9131C23.7442 27.3188 23.7147 27.9848 23.2602 28.3117C20.9463 30.007 18.113 31 15.0553 31C7.23416 31 0.888672 24.4973 0.888672 16.4688ZM31.9609 17.4375C32.5099 17.4375 32.9408 17.9098 32.8699 18.4668C32.4154 21.8514 30.8276 24.8605 28.5078 27.0826C28.1536 27.4217 27.5987 27.3975 27.2564 27.0402L17.8887 17.4375H31.9609Z" fill={selectedChartType == 'pie' ? '#fff' : '#4749FF'} />
  </svg>
);

const AnalyticsVerticalBarChartIcon = ({selectedChartType, horizontalBars}) => (
  <svg width="31" height="29" viewBox="0 0 31 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.9375 1.8125C3.00918 1.8125 3.875 2.62246 3.875 3.625V22.6562C3.875 23.1547 4.31094 23.5625 4.84375 23.5625H29.0625C30.1342 23.5625 31 24.3725 31 25.375C31 26.3775 30.1342 27.1875 29.0625 27.1875H4.84375C2.16758 27.1875 0 25.1598 0 22.6562V3.625C0 2.62246 0.86582 1.8125 1.9375 1.8125ZM9.6875 12.6875C10.7592 12.6875 11.625 13.4975 11.625 14.5V18.125C11.625 19.1275 10.7592 19.9375 9.6875 19.9375C8.61582 19.9375 7.75 19.1275 7.75 18.125V14.5C7.75 13.4975 8.61582 12.6875 9.6875 12.6875ZM17.4375 9.0625V18.125C17.4375 19.1275 16.5717 19.9375 15.5 19.9375C14.4283 19.9375 13.5625 19.1275 13.5625 18.125V9.0625C13.5625 8.05996 14.4283 7.25 15.5 7.25C16.5717 7.25 17.4375 8.05996 17.4375 9.0625ZM21.3125 10.875C22.3842 10.875 23.25 11.685 23.25 12.6875V18.125C23.25 19.1275 22.3842 19.9375 21.3125 19.9375C20.2408 19.9375 19.375 19.1275 19.375 18.125V12.6875C19.375 11.685 20.2408 10.875 21.3125 10.875ZM29.0625 5.4375V18.125C29.0625 19.1275 28.1967 19.9375 27.125 19.9375C26.0533 19.9375 25.1875 19.1275 25.1875 18.125V5.4375C25.1875 4.43496 26.0533 3.625 27.125 3.625C28.1967 3.625 29.0625 4.43496 29.0625 5.4375Z" fill={(selectedChartType == 'bar' && horizontalBars == false) ? '#fff' : '#A967D5'} />
  </svg>
);

const AnalyticsAreaChartIcon = ({selectedChartType}) => (
  <svg width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.75 2.625C3.75 1.62246 2.91211 0.8125 1.875 0.8125C0.837891 0.8125 0 1.62246 0 2.625V21.6562C0 24.1598 2.09766 26.1875 4.6875 26.1875H28.125C29.1621 26.1875 30 25.3775 30 24.375C30 23.3725 29.1621 22.5625 28.125 22.5625H4.6875C4.17188 22.5625 3.75 22.1547 3.75 21.6562V2.625ZM9.375 18.9375H26.25C27.2871 18.9375 28.125 18.1275 28.125 17.125V13.2621C28.125 12.8316 27.9668 12.4125 27.6738 12.084L23.8184 7.73398C23.1094 6.92969 21.8438 6.88437 21.0703 7.63203L19.8398 8.82148C19.2539 9.38789 18.293 9.34258 17.7656 8.73086L15.4688 6.06875C14.7305 5.21348 13.377 5.19648 12.6152 6.03477L7.96289 11.1777C7.66406 11.5063 7.5 11.9311 7.5 12.3729V17.1307C7.5 18.1332 8.33789 18.9432 9.375 18.9432V18.9375Z" fill={selectedChartType == 'area' ? '#fff' : '#FE6B3F'} />
  </svg>
);

const AnalyticsTableChartIcon = ({selectedChartType}) => (
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.625 14.5V8.6875H12.6875V14.5H3.625ZM3.625 18.375H12.6875V24.1875H3.625V18.375ZM16.3125 24.1875V18.375H25.375V24.1875H16.3125ZM25.375 14.5H16.3125V8.6875H25.375V14.5ZM3.625 0.9375C1.62559 0.9375 0 2.6752 0 4.8125V24.1875C0 26.3248 1.62559 28.0625 3.625 28.0625H25.375C27.3744 28.0625 29 26.3248 29 24.1875V4.8125C29 2.6752 27.3744 0.9375 25.375 0.9375H3.625Z" fill={selectedChartType == 'table' ? '#fff' : '#FD60B9'} />
  </svg>
);









export { AnalyticsLineChartIcon, AnalyticsHorizontalBarChartIcon, AnalyticsPieChartIcon, AnalyticsVerticalBarChartIcon, AnalyticsAreaChartIcon, AnalyticsTableChartIcon };
