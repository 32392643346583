import './Orders.css';
import react, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CircularProgress, ClickAwayListener, Tooltip } from '@mui/material';
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import OrdersDrawer from '../components/OrdersDrawer';
import fb_logo from '../assets/fb_logo.png';
import shopify_logo from '../assets/shopify_logo.png';
import ig_logo from '../assets/ig_logo.png';
import tiktok_logo from '../assets/tiktok_logo.png';
import FB_Logo_Ext from '../assets/FB_Logo_Ext.png';
import Instagram_logo from '../assets/Instagram_logo.png';
import omni_logo_clear_background from '../assets/omni_logo_clear_background.png';
import klaviyo_logo from '../assets/klaviyo_logo.png';
import Meta_logo from '../assets/Meta_logo.png';
import google_ads_logo from '../assets/google_ads_logo.png';




function Orders({ serverURL, darkMode }) {
  const navigate = useNavigate()
  const uid = localStorage.getItem('uid');
  const bid = localStorage.getItem('bid');

  const [loadingOrders, setLoadingOrders] = useState(true)
  const [loadingPercentage, setLoadingPercentage] = useState(0) 
  const [showDrawer, setShowDrawer] = useState(false)
  const [allOrders, setAllOrders] = useState([])
  const [offset, setOffset] = useState(0)
  const [selectedOrder, setSelectedOrder] = useState([])

  async function getOrders() {
    try {
      setLoadingPercentage(100)
      const response = await axios.get(`${serverURL}/getOrdersByBID?omni_business_id=${bid}&offset=${offset}`);
      console.log('getOrdersByBID', response.data)
      setAllOrders(response.data)
      setLoadingOrders(false)
      setLoadingPercentage(0)
    } catch (error) {
      console.log('ERROR loading orders Orders.js', error)
    } finally {
      setLoadingOrders(false)
      setLoadingPercentage(0)
    }
  }


  function formatDate(date) {
    const now = new Date();
    const isToday = now.toDateString() === date.toDateString();

    now.setDate(now.getDate() - 1);
    const isYesterday = now.toDateString() === date.toDateString();

    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    let hours = date.getHours();
    const minutes = date.getMinutes();
    let meridiem = 'AM';
    if (hours === 0) {
      hours = 12; // Convert 0 to 12 for 12 AM
    } else if (hours === 12) {
      meridiem = 'PM'; // Set meridiem to 'PM' for 12 PM
    } else if (hours > 12) {
      hours -= 12; // Convert 24-hour time to 12-hour time for PM
      meridiem = 'PM';
    }
    const paddedMinutes = minutes < 10 ? '0' + minutes : minutes;


    if (isToday) {
      return `Today`;
    } else if (isYesterday) {
      return `Yesterday`;
    } else {
      const dayOfWeek = daysOfWeek[date.getDay()];
      const month = months[date.getMonth()];
      const day = date.getDate();
      return `${dayOfWeek} ${month} ${day}`;
    }
  }


  function formatTime(date) {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    let meridiem = 'AM';
    if (hours === 0) {
      hours = 12; // Convert 0 to 12 for 12 AM
    } else if (hours === 12) {
      meridiem = 'PM'; // Set meridiem to 'PM' for 12 PM
    } else if (hours > 12) {
      hours -= 12; // Convert 24-hour time to 12-hour time for PM
      meridiem = 'PM';
    }
    const paddedMinutes = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${paddedMinutes} ${meridiem}`
  }



  useEffect(() => {
    if (bid) {
      getOrders()
    }  
  }, [uid, offset]);




  return (
    <>
      <OrdersDrawer
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        selectedOrder={selectedOrder}
        setSelectedOrder={setSelectedOrder}
        serverURL={serverURL}
        bid={bid}
      />


      {loadingOrders ?
        <div className='ordersLoadingContainer'>
          <div className='ordersLoadingProgressParent'>
            <div className='ordersLoadingProgressChild' style={{width: loadingPercentage + '%'}}></div>
          </div>
          {/* <CircularProgress style={{color: '#635bff', width: 30, height: 30}}/> */}
          <span className='ordersLoadingText'>Loading Orders... </span>
        </div>
      :

        <div className="ordersContainer">
          <div className='ordersTableContainer'>
            <table className='ordersTable'>
              <thead>
                <tr>
                  <td>Order</td>
                  <td>Date</td>
                  <td>Customer</td>
                  <td>Items</td>
                  <td><div className='ordersFinancialStatusColumn'>Payment Status</div></td>
                  <td>Total</td>
                  <td>COGS</td>
                  <td>Source</td>
                </tr>
              </thead>
              <tbody>
                {allOrders.map((x) => {
                  return (
                    <tr key={x.order_number}>
                      <td><span className='ordersOrderNumber'>#{x.order_number}</span></td>
                      <td>
                        <span className='ordersRowTop'>{formatDate(new Date(x.created_at))}</span>
                        <span className='ordersRowBottom'>{formatTime(new Date(x.created_at))}</span>
                      </td>
                      <td onClick={() => { setSelectedOrder(x); setShowDrawer(true) }}>
                        <span className='ordersRowTop' style={{ color: '#0874fc', cursor: 'pointer' }} >{x?.first_name} {x?.last_name}</span>
                        <span className='ordersRowBottom'>{x?.contact_email}</span>
                      </td>
                      <td>{x.line_items}</td>
                      <td>
                        <div className='ordersFinancialStatusColumn'>
                          {x.financial_status == 'paid' ? <span className='ordersRowPaid'>Paid</span> : null}
                          {x.financial_status == 'pending' ? <span className='ordersRowPending'>Pending</span> : null}
                          {x.financial_status == 'refunded' ? <span className='ordersRowRefunded'>Refunded</span> : null}
                        </div>
                      </td>
                      <td className='ordersRowPrice'>{(x.current_subtotal_price)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                      <td className='ordersRowPrice'>
                        {x.total_cogs !== null && x.total_cogs !== undefined
                          ? (x.total_cogs).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                          : '$0.00'}
                      </td>
                      <td>
                        <Tooltip title={x.utm_source} placement='top'>
                        { x.utm_source?.includes('fb') && <img src={Meta_logo} className='ordersRowReferrerIcon' /> }
                        { x.utm_source?.includes('google') && <img src={google_ads_logo} className='ordersRowReferrerIcon' /> }
                        { x.utm_source?.includes('direct') && <img src={shopify_logo} className='ordersRowReferrerIcon' /> }
                        { x.utm_source?.includes('klaviyo') && <img src={klaviyo_logo} className='ordersRowReferrerIcon' /> }
                       
                        {/* <span>{x.utm_source}</span> */}

                        </Tooltip>
                      </td>
                    </tr>
                  )
                })}
                <tr>
                  <td colSpan={4}>
                    <div className='ordersRowBottomLeftButton'>
                      {allOrders.length} Orders
                    </div>
                  </td>
                  <td></td>
                  <td colSpan={3}>
                    <div className='ordersPaginationArrowsContainer'>
                      {loadingOrders && (<CircularProgress size={20} style={{ color: '#666', marginRight: 10 }} />)}
                      <div className={offset != 0 ? 'ordersPaginationArrow' : 'ordersPaginationArrowDisabled'} onClick={() => { offset >= 100 ? setOffset(offset - 100) : setOffset(0) }}>
                        <KeyboardArrowLeftRounded />
                      </div>
                      <div className='ordersPaginationArrow' onClick={() => { setOffset(offset + 100) }}>
                        <KeyboardArrowRightRounded />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>






        </div>

      }
    </>
  );
}

export default Orders;