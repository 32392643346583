import React, { useEffect, useState } from 'react';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import google_ads_logo from '../../assets/google_ads_logo.png';
import './GoogleAccounts.css';


function GoogleAccountsPage({serverURL, pythonServerURL}) {
    const navigate = useNavigate(); 
    const [accounts, setAccounts] = useState([]);
    const [selectedAccounts, setSelectedAccounts] = useState('');
    const [loadingAccounts, setLoadingAccounts] = useState(true);
    const [loadingPercentage, setLoadingPercentage] = useState(0);
    const db = getFirestore();
    const bid = localStorage.getItem('bid');


    useEffect(() => {
        
        async function getAccounts() {
            try {
                setLoadingPercentage(100)
                const url = `${pythonServerURL}/api/getGoogleAccounts`;
                const data = {
                    omniBusinessID: bid
                }
                const response = await axios.post(url, data);
                console.log('Google Ad Accounts', response.data);
                setAccounts(response.data);
                setLoadingAccounts(false);
                setLoadingPercentage(0);
            } catch (error) {
                console.error('Error getting google accounts:', error); 
            } 
        }
        getAccounts();

    }, []);




    function adjustSelection(accountID) {
        let selectedAccountsTemp;
    
        if (selectedAccounts.includes(accountID)) {
            selectedAccountsTemp = selectedAccounts.filter(item => item !== accountID.toString());
        } else {
            selectedAccountsTemp = [...selectedAccounts, accountID];
        }
        setSelectedAccounts(selectedAccountsTemp);
    }




    async function saveSelectedAccounts() {
        const businessRef = doc(db, 'businesses', bid);
        try {
            await setDoc(businessRef, { googleAdAccountIDs: selectedAccounts }, { merge: true });
            console.log('Accounts saved to Firestore successfully');
            navigate('/Dashboard');
        } catch (error) {
            console.error('Error saving accounts to Firestore:', error);
        }
    }




    return (
        <div className='GoogleAccountsContainer'>
            {loadingAccounts ?
                <div className='GoogleAccountsLoadingContainer'>
                <div className='GoogleAccountsLoadingProgressParent'>
                    <div className='GoogleAccountsLoadingProgressChild' style={{width: loadingPercentage + '%'}}></div>
                </div>
                {/* <CircularProgress style={{color: '#635bff', width: 30, height: 30}}/> */}
                <span className='GoogleAccountsLoadingText'>Loading Google Ad Accounts... </span>
                </div>
            :
                <div>
                    <span className='GoogleAccountsTitle'>Select Google Ad Accounts</span>
                    <span className='GoogleAccountsSubtitle'>Only select the ad accounts that you're actively using</span>
                    <div className='GoogleAccountsAccountsContainer'>
                        {accounts.map((x) => (
                            <div className={selectedAccounts.includes(x.account_id) ? 'GoogleAccountsAccountCardSelected' : 'GoogleAccountsAccountCard'} onClick={() => adjustSelection(x.account_id)}>
                                <img className='GoogleAccountsAccountCardImage' src={google_ads_logo} style={{width: 40}}/>
                                <span className='GoogleAccountsAccountCardTitle'> {x.account_name}</span>
                            </div>
                        ))}
                        <div className='GoogleAccountsContinueButtonContainer' onClick={() => {saveSelectedAccounts()} }>
                            <span className='GoogleAccountsContinueButton'>Link Accounts</span>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default GoogleAccountsPage;
