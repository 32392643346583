import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { enUS } from 'date-fns/locale';
import { startOfDay, subDays, startOfMonth, endOfMonth, startOfYear, endOfYear } from 'date-fns';
import './DateSelector.css';
import { ClickAwayListener } from '@mui/material';
import dayjs from 'dayjs';




function DateSelector({setShowDateSelectors, showDateSelectors, dateStart, setDateStart, dateEnd, setDateEnd, dateUpdateTrigger, setDateUpdateTrigger, onlyShowOneMonth, hidePresets, showFutureDates}) {

    const [selectedPreset, setSelectedPreset] = useState('last7days');



    function determineDates(selectedPreset) {
        let start, end;
        const today = startOfDay(new Date());
        switch (selectedPreset) {
            case 'today':
                start = today;
                end = today;
                break;
            case 'yesterday':
                start = subDays(today, 1);
                end = subDays(today, 1);
                break;
            case 'last7days':
                start = subDays(today, 8);
                end = subDays(today, 1);
                break;
            case 'last30days':
                start = subDays(today, 29);
                end = subDays(today, 1);;
                break;
            case 'thismonth':
                start = startOfMonth(today);
                end = endOfMonth(today);
                break;
            case 'lastmonth':
                start = startOfMonth(subDays(startOfMonth(today), 1));
                end = endOfMonth(subDays(startOfMonth(today), 1));
                break;
            case 'last90days':
                start = subDays(today, 89);
                end = subDays(today, 1);;
                break;
            case 'thisyear':
                start = startOfYear(today);
                end = endOfYear(today);
                break;
            case 'lastyear':
                start = startOfYear(subDays(startOfYear(today), 1));
                end = endOfYear(subDays(startOfYear(today), 1));
                break;
            default:
                start = null;
                end = null;
                break;
        }
        
        if (start && end && start <= end) {
            setDateStart(start);
            setDateEnd(end);
        } else {
            console.error('Invalid date range:', start, end);
        }
    }
    
    
    const selectionRange = {
        startDate: dateStart,
        endDate: dateEnd,
        key: 'selection',
    }



    function getDaysAgo (date) {
        const today = new Date();
        const daysAgo = Math.round((today - date) / (1000 * 60 * 60 * 24) - 1);
        return daysAgo;
    }

    function isExactThisMonth(start, end) {
        const formattedEnd = `${end.getFullYear()}-${String(end.getMonth() + 1).padStart(2, '0')}-${String(end.getDate()).padStart(2, '0')}`;
        const now = new Date();
        const firstDayOfThisMonth = new Date(now.getFullYear(), now.getMonth(), 1)?.toISOString()?.slice(0, 10);
        const lastDayOfThisMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0)?.toISOString()?.slice(0, 10);
        if (start?.toISOString()?.slice(0, 10) == firstDayOfThisMonth && formattedEnd == lastDayOfThisMonth) {
          return true;
        } else {
          return false;
        }
    }
      
      
    function isExactLastMonth(start, end) {
        const formattedEnd = `${end.getFullYear()}-${String(end.getMonth() + 1).padStart(2, '0')}-${String(end.getDate()).padStart(2, '0')}`;
        const now = new Date();
        const firstDayOfLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1)?.toISOString()?.slice(0, 10);
        const lastDayOfLastMonth = new Date(now.getFullYear(), now.getMonth(), 0)?.toISOString()?.slice(0, 10);
        if (start?.toISOString()?.slice(0, 10) == firstDayOfLastMonth && formattedEnd == lastDayOfLastMonth) {
          return true;
        } else {
          return false;
        }
    }
      
      
    function isThisYear(start, end) {
        const formattedEnd = `${end.getFullYear()}-${String(end.getMonth() + 1).padStart(2, '0')}-${String(end.getDate()).padStart(2, '0')}`;
        const now = new Date();
      
        // First day of the current year
        const firstDayOfThisYear = new Date(now.getFullYear(), 0, 1)?.toISOString()?.slice(0, 10);
        // Last day of the current year
        const lastDayOfThisYear = new Date(now.getFullYear(), 11, 31)?.toISOString()?.slice(0, 10);
        
        if (start?.toISOString()?.slice(0, 10) == firstDayOfThisYear && formattedEnd == lastDayOfThisYear) {
          return true;
        } else {
          return false;
        }
    }
      
    function isLastYear(start, end) {
        const formattedEnd = `${end.getFullYear()}-${String(end.getMonth() + 1).padStart(2, '0')}-${String(end.getDate()).padStart(2, '0')}`;
        const now = new Date();
        const lastYear = now.getFullYear() - 1;
      
        // First day of the last year
        const firstDayOfLastYear = new Date(lastYear, 0, 1)?.toISOString()?.slice(0, 10);
        // Last day of the last year
        const lastDayOfLastYear = new Date(lastYear, 11, 31)?.toISOString()?.slice(0, 10);
      
        if (start?.toISOString()?.slice(0, 10) == firstDayOfLastYear && formattedEnd == lastDayOfLastYear) {
          return true;
        } else {
          return false;
        }
    }


    function displayDateRange() {
        const daysAgoStart = getDaysAgo(dateStart);
        const daysAgoEnd = getDaysAgo(dateEnd);
    
        if (daysAgoStart === 0 && daysAgoEnd === 0) {
            setSelectedPreset('today');
        } else if (daysAgoStart === 1 && daysAgoEnd === 1) {
            setSelectedPreset('yesterday');
        } else if (daysAgoStart === 8 && daysAgoEnd === 1) {
            setSelectedPreset('last7days');
        } else if (daysAgoStart === 29 && daysAgoEnd === 1) {
            setSelectedPreset('last30days');
        } else if (daysAgoStart === 89 && daysAgoEnd === 1) {
            setSelectedPreset('last90days');
        } else if (daysAgoStart === 364 && daysAgoEnd === 1) {
            setSelectedPreset('lastyear');
        } else if (isExactThisMonth(dateStart, dateEnd)) {
            setSelectedPreset('thismonth');
        } else if (isExactLastMonth(dateStart, dateEnd)) {
            setSelectedPreset('lastmonth');
        } else if (isThisYear(dateStart, dateEnd)) {
            setSelectedPreset('thisyear');
        } else if (isLastYear(dateStart, dateEnd)) {
            setSelectedPreset('lastyear');
        } else {
            setSelectedPreset('');
        }
    }

    useEffect(() => {
        displayDateRange()
    }, [dateStart, dateEnd])
    
    

    function formatDate(date) {
        return dayjs(date).format('MMM D, YYYY');
    }



    function displayDateRange() {
        const daysAgoStart = getDaysAgo(dateStart);
        const daysAgoEnd = getDaysAgo(dateEnd);
    
        if (daysAgoStart === 0 && daysAgoEnd === 0) {
          return 'Today';
        } else if (daysAgoStart === 1 && daysAgoEnd === 1) {
          return 'Yesterday';
        } else if (daysAgoStart === 14 && daysAgoEnd === 8) {
            return 'Week Before Last';
        } else if (daysAgoStart === 8 && daysAgoEnd === 1) {
            return 'Last 7 Days';
        } else if (daysAgoStart === 29 && daysAgoEnd === 1) {
            return 'Last 30 Days';
        } else if (daysAgoStart === 59 && daysAgoEnd === 1) {
            return 'Last 60 Days';
        } else if (daysAgoStart === 89 && daysAgoEnd === 1) {
            return 'Last 90 Days';
        } else if (daysAgoStart === 179 && daysAgoEnd === 1) {
            return 'Last 180 Days';
        } else if (daysAgoStart === 364 && daysAgoEnd === 1) {
            return 'Last 365 Days';
        } else if (daysAgoStart === 365 && daysAgoEnd === 1) {
            return 'Past Year';
        } else if (daysAgoStart === 0 && daysAgoEnd === 0) {
            return 'Today';
        } else if (daysAgoStart === 30 && daysAgoEnd === 7) {
            return 'This Month to Last Week';
        } else if (daysAgoStart === 90 && daysAgoEnd === 30) {
            return 'Last Quarter';
        } else if (isExactThisMonth(dateStart, dateEnd)) {
            return 'This Month';
        } else if (isExactLastMonth(dateStart, dateEnd)) {
            return 'Last Month';
        } else if (isThisYear(dateStart, dateEnd)) {
          return 'This Year';
        } else if (isLastYear(dateStart, dateEnd)) {
          return 'Last Year';
        } else {
            return `${formatDate(dateStart)} - ${formatDate(dateEnd)}`;
        }
    }



    function isExactThisMonth(start, end) {
        const formattedEnd = `${end.getFullYear()}-${String(end.getMonth() + 1).padStart(2, '0')}-${String(end.getDate()).padStart(2, '0')}`;
        const now = new Date();
        const firstDayOfThisMonth = new Date(now.getFullYear(), now.getMonth(), 1)?.toISOString()?.slice(0, 10);
        const lastDayOfThisMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0)?.toISOString()?.slice(0, 10);
        if (start?.toISOString()?.slice(0, 10) == firstDayOfThisMonth && formattedEnd == lastDayOfThisMonth) {
          return true;
        } else {
          return false;
        }
    }
    
    
      function isExactLastMonth(start, end) {
        const formattedEnd = `${end.getFullYear()}-${String(end.getMonth() + 1).padStart(2, '0')}-${String(end.getDate()).padStart(2, '0')}`;
        const now = new Date();
        const firstDayOfLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1)?.toISOString()?.slice(0, 10);
        const lastDayOfLastMonth = new Date(now.getFullYear(), now.getMonth(), 0)?.toISOString()?.slice(0, 10);
        if (start?.toISOString()?.slice(0, 10) == firstDayOfLastMonth && formattedEnd == lastDayOfLastMonth) {
          return true;
        } else {
          return false;
        }
    }
    
    
      function isThisYear(start, end) {
        const formattedEnd = `${end.getFullYear()}-${String(end.getMonth() + 1).padStart(2, '0')}-${String(end.getDate()).padStart(2, '0')}`;
        const now = new Date();
        const firstDayOfThisYear = new Date(now.getFullYear(), 0, 1)?.toISOString()?.slice(0, 10);
        const lastDayOfThisYear = new Date(now.getFullYear(), 11, 31)?.toISOString()?.slice(0, 10);
        if (start?.toISOString()?.slice(0, 10) == firstDayOfThisYear && formattedEnd == lastDayOfThisYear) {
          return true;
        } else {
          return false;
        }
    }
    
      function isLastYear(start, end) {
        const formattedEnd = `${end.getFullYear()}-${String(end.getMonth() + 1).padStart(2, '0')}-${String(end.getDate()).padStart(2, '0')}`;
        const now = new Date();
        const lastYear = now.getFullYear() - 1;
        const firstDayOfLastYear = new Date(lastYear, 0, 1)?.toISOString()?.slice(0, 10);
        const lastDayOfLastYear = new Date(lastYear, 11, 31)?.toISOString()?.slice(0, 10);
        if (start?.toISOString()?.slice(0, 10) == firstDayOfLastYear && formattedEnd == lastDayOfLastYear) {
          return true;
        } else {
          return false;
        }
    }







    return (
        <ClickAwayListener onClickAway={() => setShowDateSelectors(false)}>
            <div className='DateSelectorButtonBorder'>
                <div className='DateSelectorButton' onClick={() => { !showDateSelectors && setShowDateSelectors(true); }}>
                    <svg width="15" height="15" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_154_1530)">
                        <path d="M2.84375 0C2.95149 0 3.05483 0.0428012 3.13101 0.118988C3.2072 0.195175 3.25 0.298506 3.25 0.40625V0.8125H9.75V0.40625C9.75 0.298506 9.7928 0.195175 9.86899 0.118988C9.94517 0.0428012 10.0485 0 10.1562 0C10.264 0 10.3673 0.0428012 10.4435 0.118988C10.5197 0.195175 10.5625 0.298506 10.5625 0.40625V0.8125H11.375C11.806 0.8125 12.2193 0.983705 12.524 1.28845C12.8288 1.5932 13 2.00652 13 2.4375V11.375C13 11.806 12.8288 12.2193 12.524 12.524C12.2193 12.8288 11.806 13 11.375 13H1.625C1.19402 13 0.780698 12.8288 0.475951 12.524C0.171205 12.2193 0 11.806 0 11.375V2.4375C0 2.00652 0.171205 1.5932 0.475951 1.28845C0.780698 0.983705 1.19402 0.8125 1.625 0.8125H2.4375V0.40625C2.4375 0.298506 2.4803 0.195175 2.55649 0.118988C2.63267 0.0428012 2.73601 0 2.84375 0ZM0.8125 3.25V11.375C0.8125 11.5905 0.898102 11.7972 1.05048 11.9495C1.20285 12.1019 1.40951 12.1875 1.625 12.1875H11.375C11.5905 12.1875 11.7972 12.1019 11.9495 11.9495C12.1019 11.7972 12.1875 11.5905 12.1875 11.375V3.25H0.8125Z" fill="#747474"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_154_1530">
                        <rect width="13" height="13" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>

                    <div className='DateSelectorButtonText'>
                        <span> {displayDateRange()}</span>
                    </div>
                    {showDateSelectors && (
                        <div className='DateSelectorContainer'>
                            <div className='DateSelectorBackground' >
                                <div className='DateSelectorCard'>

                                    {!hidePresets && (
                                        <div className='DateSelectorPresetContainer'>
                                            <span className={selectedPreset == 'today' ? 'DateSelectorPresetSelected' : 'DateSelectorPreset'} onClick={() => {determineDates('today'); setSelectedPreset('today')}}>Today</span>
                                            <span className={selectedPreset == 'yesterday' ? 'DateSelectorPresetSelected' : 'DateSelectorPreset'} onClick={() => {determineDates('yesterday'); setSelectedPreset('yesterday')}}>Yesterday</span>
                                            <span className={selectedPreset == 'last7days' ? 'DateSelectorPresetSelected' : 'DateSelectorPreset'} onClick={() => {determineDates('last7days'); setSelectedPreset('last7days')}}>Last 7 Days</span>
                                            <span className={selectedPreset == 'last30days' ? 'DateSelectorPresetSelected' : 'DateSelectorPreset'} onClick={() => {determineDates('last30days'); setSelectedPreset('last30days')}}>Last 30 Days</span>
                                            <span className={selectedPreset == 'thismonth' ? 'DateSelectorPresetSelected' : 'DateSelectorPreset'} onClick={() => {determineDates('thismonth'); setSelectedPreset('thismonth')}}>This Month</span>
                                            <span className={selectedPreset == 'lastmonth' ? 'DateSelectorPresetSelected' : 'DateSelectorPreset'} onClick={() => {determineDates('lastmonth'); setSelectedPreset('lastmonth')}}>Last Month</span>
                                            <span className={selectedPreset == 'last90days' ? 'DateSelectorPresetSelected' : 'DateSelectorPreset'} onClick={() => {determineDates('last90days'); setSelectedPreset('last90days')}}>Last 90 Days</span>
                                            <span className={selectedPreset == 'thisyear' ? 'DateSelectorPresetSelected' : 'DateSelectorPreset'} onClick={() => {determineDates('thisyear'); setSelectedPreset('thisyear')}}>This Year</span>
                                            <span className={selectedPreset == 'lastyear' ? 'DateSelectorPresetSelected' : 'DateSelectorPreset'} onClick={() => {determineDates('lastyear'); setSelectedPreset('lastyear')}}>Last Year</span>
                                        </div>
                                    )}

                                    <DateRangePicker
                                        className="DateRangePicker"
                                        ranges={[selectionRange]}
                                        onChange={(ranges) => {setDateStart(ranges.selection.startDate); setDateEnd(ranges.selection.endDate)}}
                                        dateFormat={"yyyyMMdd"} 
                                        showYearDropdown 
                                        scrollableMonthYearDropdown
                                        locale={enUS}
                                        maxDate={showFutureDates ? null : new Date()}
                                        months={onlyShowOneMonth ? 1 : 2}
                                        direction="horizontal"
                                        startDatePlaceholder=""
                                        endDatePlaceholder=""
                                        showPreview={true}
                                        showMonthAndYearPickers={false}
                                    />

                                    {hidePresets && (
                                        <div style={{minHeight: '400px'}}></div>
                                    )}
                                </div>
                                <div className='DateSelectorSubmitButtonContainer'>
                                    <span className='DateSelectorCancelButton' onClick={() => {setShowDateSelectors(false)}}> Cancel </span>
                                    <span className='DateSelectorSubmitButton' onClick={() => {setDateUpdateTrigger(!dateUpdateTrigger); setShowDateSelectors(false)}}> Apply </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </ClickAwayListener>



    );
}

export default DateSelector;
