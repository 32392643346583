import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IoIosInformationCircle } from "react-icons/io";
import './Audience.css';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { RxCross2 } from "react-icons/rx";
import { AddRounded, CheckRounded, KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@mui/icons-material';
import fb_logo from '../../assets/fb_logo.png';
import meta_logo from '../../assets/Meta_logo.png';
import TextInput from '../../components/TextInput';
import { CircularProgress, Tooltip } from '@mui/material';



function Audiences({ darkMode, serverURL, setSnackbarInfo }) {
  const [audiences, setAudiences] = useState([]);
  const [filteredAudiences, setFilteredAudiences] = useState([]);
  const [selectedAudience, setSelectedAudience] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [newAudienceName, setNewAudienceName] = useState('');
  const [newAudienceDescription, setNewAudienceDescription] = useState('');
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [showNewAudiencePopup, setShowNewAudiencePopup] = useState(false);
  const [showNewLookalikePopup, setShowNewLookalikePopup] = useState(false);
  const [newAudienceSelectedCountry, setNewAudienceSelectedCountry] = useState('');
  const [priorityAudience, setPriorityAudience] = useState("Prioritize Customer Value");
  const [rangeValue, setRangeValue] = useState(1);
  const [adAccounts, setAdAccounts] = useState([]);
  const [showAll, setShowAll] = useState({});
  const [searchText, setSearchText] = useState('');
  const [createAudienceSelectedAdAccount, setCreateAudienceSelectedAdAccount] = useState('');
  const [facebookAgreeToTermsLink, setFacebookAgreeToTermsLink] = useState('');
  const [omniAudiences, setOmniAudiences] = useState([
    { id: '1', name: 'All Customers', audience_size: 1000 },
    { id: '2', name: 'High Value Customers', audience_size: 1000 },
    { id: '3', name: 'Low Value Customers', audience_size: 1000 },
    { id: '4', name: 'Recent Customers', audience_size: 1000 },
  ]);


  const bid = localStorage.getItem('bid');


  async function getCustomAudiences () {
    setLoadingPercentage(100)
    try {
      const response = await axios.get(`${serverURL}/fetchAllCustomAudiences?omni_business_id=${bid}`);

      response.data.sort((a, b) => b.time_updated - a.time_updated);

      const audienceObject = response.data.reduce((acc, audience) => {
        const { ad_account_id } = audience;
        if (!acc[ad_account_id]) {
          acc[ad_account_id] = [];
        }
        acc[ad_account_id].push(audience);
        return acc;
      }, {});

      setAudiences(audienceObject);
      setFilteredAudiences(audienceObject);
    } catch (error) {
      console.error('ERROR loading audiences data', error);
      setSnackbarInfo({ open: true, title: 'Error', message: error.response?.data?.message || 'Failed to load audiences', type: 'error' });

    } finally {
      setLoading(false);
      setLoadingPercentage(0)
    }
  };

  useEffect(() => {
    getCustomAudiences();
  }, []);



  const handleRangeChange = (event) => {
    setRangeValue(Number(event.target.value));
  };

  const handleStepClick = (value) => {
    setRangeValue(value);
  };

  const handleCheckboxChange = (audience) => {
    if (selectedAudience === audience) {
      setSelectedAudience(null);
      setSelectedRowsCount(0);
    } else {
      setSelectedAudience(audience);
      setSelectedRowsCount(1);
    }
  };




  async function createAudience (audienceType) {
    setFacebookAgreeToTermsLink('');
    const data = audienceType === 'lookalike_audience'
      ? {
        name: 'lookalike_audience',
        lookalikeSourceId: selectedAudience.id,
        lookalikeSourceName: selectedAudience.name,
        country: newAudienceSelectedCountry,
        rangeValue,
        adAccountID: selectedAudience.ad_account_id
      }
      : {
        name: 'custom_audience',
        customAudienceName: newAudienceName,
        description: newAudienceDescription,
        country: newAudienceSelectedCountry,
        priorityAudience,
        adAccountID: createAudienceSelectedAdAccount
      };
  
    try {
      const response = await axios.get(`${serverURL}/creatingFacebookAudience?omni_business_id=${bid}`, { params: data });
      console.log('API Response:', response.data);
  
      if (response.data.success) {
        setSnackbarInfo({ open: true, title: 'Success', message: 'Audience created successfully', type: 'success' });
        await getCustomAudiences();
        setShowNewAudiencePopup(false);
        setShowNewLookalikePopup(false);
      } else {
        setSnackbarInfo({ open: true, title: 'Error', message: response.data.message || 'Submission Failed', type: 'error' });
      }
    } catch (error) {

      if (error.response?.data?.message.includes('please agree to the Custom Audience terms at')) {
        console.error('ERROR creating audience', error);
        setFacebookAgreeToTermsLink(error.response?.data?.message);
      } else {
        setSnackbarInfo({ open: true, title: 'Error', message: error.response?.data?.message || 'Submission Failed', type: 'error' });
        console.error('ERROR creating audience', error);
      }
    }
  
  };
  


  async function getAdAccountsByBID () {
    try {
      const response = await axios.get(`${serverURL}/getAdAccountsByBID?omni_business_id=${bid}&date_start=1970-01-01&date_end=2100-01-01`);

      const adAccountsObject = response.data.reduce((acc, adAccount) => {
        const { account_id } = adAccount;
        if (!acc[account_id]) {
          acc[account_id] = [];
        }
        acc[account_id] = adAccount.name;
        return acc;
      }, {});

      setAdAccounts(adAccountsObject);
      setCreateAudienceSelectedAdAccount(Object.keys(adAccountsObject)[0]);

      setShowAll(Object.keys(adAccountsObject).reduce((acc, ad_account_id) => {
        acc[ad_account_id] = false;
        return acc;
      }, {}));

    } catch (error) {
      console.error('ERROR loading ad accounts data', error);
      setSnackbarInfo({ open: true, title: 'Error', message: error.response?.data?.message || 'Failed to load ad accounts', type: 'error' });

    }
  }

  useEffect(() => {
    getAdAccountsByBID();
  }, [])



  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchText.length > 0) {
        const filteredAudiencesTemp = Object.keys(audiences).reduce((acc, ad_account_id) => {
          acc[ad_account_id] = audiences[ad_account_id].filter(audience => audience.name.toLowerCase().includes(searchText.toLowerCase()));
          return acc;
        }, {});

        setFilteredAudiences(filteredAudiencesTemp);
      } else {
        setFilteredAudiences(audiences);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [searchText])







  return (
    <>
      <div className="AudienceContainer">


        <div className='AudienceHeaderButtonsRow'>
          <span className='AudienceCreateButton' onClick={() => { setShowNewAudiencePopup(true); }}> <AddRounded style={{height: 16, width: 16}}/><span style={{marginLeft: 10}}>Upload to Facebook</span> </span>
          {selectedRowsCount > 0 && ( <span className='AudienceCreateLookaLikeButton' onClick={() => { setShowNewLookalikePopup(true); }}> <AddRounded style={{height: 16, width: 16}}/><span style={{marginLeft: 10}}> Create Lookalike</span> </span> )}
        </div>



        {loading ? 
          <div className='AudienceLoadingContainer'>
            <div className='AudienceLoadingProgressParent'>
              <div className='AudienceLoadingProgressChild' style={{width: loadingPercentage + '%'}}></div>
            </div>
            {/* <CircularProgress style={{color: '#635bff', width: 30, height: 30}}/> */}
            <span className='AudienceLoadingText'>Waiting for Facebook... </span>
          </div>
        :
          <div className='AudienceGridContainerMain'>


            <input type="text" className='AudienceSearchInput' placeholder='Search...' onChange={(e) => {setSearchText(e.target.value)}} value={searchText} />







            <div className='AudienceGridSection'>
              <div className='AudienceGridSectionTitleRow'>
                <img src={meta_logo} className='AudienceGridSectionLogo' />
                <span className='AudienceGridSectionTitle'>Omni</span>
              </div>
              <div className='AudienceGridContainer'>
                {omniAudiences.map((x, index) => (
                    <div key={index} className={selectedAudience?.id == x?.id ? 'AudienceCardSelected' : 'AudienceCard'} onClick={() => { handleCheckboxChange(x)}}>
                      <div className='AudienceCardImageBackground'>
                        <div className='AudienceCardImage'>
                          <svg width="25px" height="25px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M12.5 7.333C12.5 8.62148 11.4555 9.666 10.167 9.666C8.8785 9.666 7.83398 8.62148 7.83398 7.333C7.83398 6.04452 8.8785 5 10.167 5C11.4555 5 12.5 6.04452 12.5 7.333Z" stroke="#635bff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M14.833 15.733C14.833 17.533 12.744 19 10.166 19C7.588 19 5.5 17.537 5.5 15.733C5.5 13.929 7.589 12.467 10.167 12.467C12.745 12.467 14.833 13.929 14.833 15.733Z" stroke="#635bff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M17.439 9.51102C17.439 10.2842 16.8122 10.911 16.039 10.911C15.2658 10.911 14.639 10.2842 14.639 9.51102C14.639 8.73782 15.2658 8.11102 16.039 8.11102C16.4103 8.11102 16.7664 8.25852 17.0289 8.52107C17.2915 8.78362 17.439 9.13972 17.439 9.51102Z" stroke="#635bff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M16.7 18.067C18.1138 18.1831 19.3596 17.1445 19.5 15.733C19.3591 14.3218 18.1134 13.2839 16.7 13.4" stroke="#635bff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                        </div>
                      </div>

                      <div className='AudienceCardStatusActive'></div>

                      <div className='AudienceCardTextColumn'>
                        <span className='AudienceCardName'>{x.name}</span>
                        <span className='AudienceCardDate'>Realtime</span>
                      </div>

                      <span className='AudienceCardSize'>~{new Intl.NumberFormat('en-US').format(x.audience_size)}</span>
                    </div>
                ))}
              </div>
            </div>










            

            {Object.keys(filteredAudiences).map((ad_account_id, index) => (
              <React.Fragment key={index}>
                {filteredAudiences[ad_account_id].length > 0 && (
                  <div className='AudienceGridSection'>
                    <div className='AudienceGridSectionTitleRow'>
                      <img src={meta_logo} className='AudienceGridSectionLogo' />
                      <span className='AudienceGridSectionTitle'>{adAccounts[ad_account_id]}</span>
                    </div>
                    <div className='AudienceGridContainer'>
                      {filteredAudiences[ad_account_id].map((x, index) => (
                        <React.Fragment key={index}>
                          {(index < 20 || showAll[ad_account_id]) && (
                          <div key={index} className={selectedAudience?.id == x?.id ? 'AudienceCardSelected' : 'AudienceCard'} onClick={() => { handleCheckboxChange(x)}}>
                            <div className='AudienceCardImageBackground'>
                              <div className='AudienceCardImage'>
                                {x.subtype == 'LOOKALIKE' && (
                                  <svg width="25px" height="25px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.9238 7.281C14.9227 8.5394 13.9018 9.55874 12.6435 9.558C11.3851 9.55726 10.3654 8.53673 10.3658 7.27833C10.3662 6.01994 11.3864 5 12.6448 5C13.2495 5.00027 13.8293 5.24073 14.2567 5.6685C14.6841 6.09627 14.924 6.67631 14.9238 7.281Z" stroke="#635bff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.9968 12.919H10.2968C8.65471 12.9706 7.35028 14.3166 7.35028 15.9595C7.35028 17.6024 8.65471 18.9484 10.2968 19H14.9968C16.6388 18.9484 17.9432 17.6024 17.9432 15.9595C17.9432 14.3166 16.6388 12.9706 14.9968 12.919V12.919Z" stroke="#635bff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M20.6878 9.02403C20.6872 9.98653 19.9066 10.7664 18.9441 10.766C17.9816 10.7657 17.2016 9.9852 17.2018 9.0227C17.202 8.06019 17.9823 7.28003 18.9448 7.28003C19.4072 7.28003 19.8507 7.4638 20.1776 7.7909C20.5045 8.11799 20.688 8.56158 20.6878 9.02403V9.02403Z" stroke="#635bff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.3338 9.02401C4.3338 9.98664 5.11417 10.767 6.0768 10.767C7.03943 10.767 7.8198 9.98664 7.8198 9.02401C7.8198 8.06137 7.03943 7.28101 6.0768 7.28101C5.11417 7.28101 4.3338 8.06137 4.3338 9.02401V9.02401Z" stroke="#635bff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M19.4368 12.839C19.0226 12.839 18.6868 13.1748 18.6868 13.589C18.6868 14.0032 19.0226 14.339 19.4368 14.339V12.839ZM20.7438 13.589L20.7593 12.8392C20.7541 12.839 20.749 12.839 20.7438 12.839V13.589ZM20.7438 18.24V18.99C20.749 18.99 20.7541 18.9899 20.7593 18.9898L20.7438 18.24ZM19.4368 17.49C19.0226 17.49 18.6868 17.8258 18.6868 18.24C18.6868 18.6542 19.0226 18.99 19.4368 18.99V17.49ZM5.58477 14.339C5.99899 14.339 6.33477 14.0032 6.33477 13.589C6.33477 13.1748 5.99899 12.839 5.58477 12.839V14.339ZM4.27777 13.589V12.839C4.27259 12.839 4.26741 12.839 4.26222 12.8392L4.27777 13.589ZM4.27777 18.24L4.26222 18.9898C4.26741 18.9899 4.27259 18.99 4.27777 18.99V18.24ZM5.58477 18.99C5.99899 18.99 6.33477 18.6542 6.33477 18.24C6.33477 17.8258 5.99899 17.49 5.58477 17.49V18.99ZM19.4368 14.339H20.7438V12.839H19.4368V14.339ZM20.7282 14.3388C21.5857 14.3566 22.2715 15.0568 22.2715 15.9145H23.7715C23.7715 14.2405 22.4329 12.8739 20.7593 12.8392L20.7282 14.3388ZM22.2715 15.9145C22.2715 16.7722 21.5857 17.4724 20.7282 17.4902L20.7593 18.9898C22.4329 18.9551 23.7715 17.5885 23.7715 15.9145H22.2715ZM20.7438 17.49H19.4368V18.99H20.7438V17.49ZM5.58477 12.839H4.27777V14.339H5.58477V12.839ZM4.26222 12.8392C2.58861 12.8739 1.25 14.2405 1.25 15.9145H2.75C2.75 15.0568 3.43584 14.3566 4.29332 14.3388L4.26222 12.8392ZM1.25 15.9145C1.25 17.5885 2.58861 18.9551 4.26222 18.9898L4.29332 17.4902C3.43584 17.4724 2.75 16.7722 2.75 15.9145H1.25ZM4.27777 18.99H5.58477V17.49H4.27777V18.99Z" fill="#635bff"/>
                                  </svg>
                                )}
                                {x.subtype == 'CUSTOM' && (
                                  <svg width="25px" height="25px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.5 7.333C12.5 8.62148 11.4555 9.666 10.167 9.666C8.8785 9.666 7.83398 8.62148 7.83398 7.333C7.83398 6.04452 8.8785 5 10.167 5C11.4555 5 12.5 6.04452 12.5 7.333Z" stroke="#635bff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.833 15.733C14.833 17.533 12.744 19 10.166 19C7.588 19 5.5 17.537 5.5 15.733C5.5 13.929 7.589 12.467 10.167 12.467C12.745 12.467 14.833 13.929 14.833 15.733Z" stroke="#635bff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M17.439 9.51102C17.439 10.2842 16.8122 10.911 16.039 10.911C15.2658 10.911 14.639 10.2842 14.639 9.51102C14.639 8.73782 15.2658 8.11102 16.039 8.11102C16.4103 8.11102 16.7664 8.25852 17.0289 8.52107C17.2915 8.78362 17.439 9.13972 17.439 9.51102Z" stroke="#635bff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M16.7 18.067C18.1138 18.1831 19.3596 17.1445 19.5 15.733C19.3591 14.3218 18.1134 13.2839 16.7 13.4" stroke="#635bff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                  </svg>
                                )}
                                {x.subtype == 'WEBSITE' && (
                                  <svg width="25px" height="25px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.50108 7.72905V12.871C5.45464 14.7169 6.91225 16.2515 8.75808 16.3H16.2431C18.0893 16.2521 19.5475 14.7173 19.5011 12.871V7.72905C19.5475 5.88317 18.0899 4.34858 16.2441 4.30005H8.75808C6.91225 4.34858 5.45464 5.88317 5.50108 7.72905Z" stroke="#635bff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M7.5011 19.3H17.5011" stroke="#635bff" strokeWidth="1.5" strokeLinecap="round"/>
                                  </svg>
                                )}
                                {x.subtype == 'IG_BUSINESS' && (
                                  <svg width="25px" height="25px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M15.5 5H9.5C7.29086 5 5.5 6.79086 5.5 9V15C5.5 17.2091 7.29086 19 9.5 19H15.5C17.7091 19 19.5 17.2091 19.5 15V9C19.5 6.79086 17.7091 5 15.5 5Z" stroke="#635bff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.5 15C10.8431 15 9.5 13.6569 9.5 12C9.5 10.3431 10.8431 9 12.5 9C14.1569 9 15.5 10.3431 15.5 12C15.5 12.7956 15.1839 13.5587 14.6213 14.1213C14.0587 14.6839 13.2956 15 12.5 15Z" stroke="#635bff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <rect x="15.5" y="9" width="2" height="2" rx="1" transform="rotate(-90 15.5 9)" fill="#635bff"/>
                                    <rect x="16" y="8.5" width="1" height="1" rx="0.5" transform="rotate(-90 16 8.5)" stroke="#635bff" strokeLinecap="round"/>
                                  </svg>
                                )}
                                {x.subtype == 'ENGAGEMENT' && (
                                  <svg width="25px" height="25px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.75 19C11.75 19.4142 12.0858 19.75 12.5 19.75C12.9142 19.75 13.25 19.4142 13.25 19H11.75ZM13.25 11C13.25 10.5858 12.9142 10.25 12.5 10.25C12.0858 10.25 11.75 10.5858 11.75 11H13.25ZM15.5 5.75C15.9142 5.75 16.25 5.41421 16.25 5C16.25 4.58579 15.9142 4.25 15.5 4.25V5.75ZM12.5 8H11.75H12.5ZM11.75 11C11.75 11.4142 12.0858 11.75 12.5 11.75C12.9142 11.75 13.25 11.4142 13.25 11H11.75ZM12.5 10.25C12.0858 10.25 11.75 10.5858 11.75 11C11.75 11.4142 12.0858 11.75 12.5 11.75V10.25ZM14.5 11.75C14.9142 11.75 15.25 11.4142 15.25 11C15.25 10.5858 14.9142 10.25 14.5 10.25V11.75ZM12.5 11.75C12.9142 11.75 13.25 11.4142 13.25 11C13.25 10.5858 12.9142 10.25 12.5 10.25V11.75ZM10.5 10.25C10.0858 10.25 9.75 10.5858 9.75 11C9.75 11.4142 10.0858 11.75 10.5 11.75V10.25ZM13.25 19V11H11.75V19H13.25ZM15.5 4.25C13.4289 4.25 11.75 5.92893 11.75 8H13.25C13.25 6.75736 14.2574 5.75 15.5 5.75V4.25ZM11.75 8V11H13.25V8H11.75ZM12.5 11.75H14.5V10.25H12.5V11.75ZM12.5 10.25H10.5V11.75H12.5V10.25Z" fill="#635bff"/>
                                  </svg>
                                )}
                                {x.subtype == 'MULTI_DATA' && (
                                  <svg width="25px" height="25px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.5 5C11.1193 5 9.83858 5.63214 8.96158 6.50914C8.08458 7.38614 7.45244 8.66686 7.45244 10.0476C7.45244 11.4283 8.08458 12.709 8.96158 13.586C9.83858 14.463 11.1193 15.0951 12.5 15.0951C13.8807 15.0951 15.1614 14.463 16.0384 13.586C16.9154 12.709 17.5476 11.4283 17.5476 10.0476C17.5476 8.66686 16.9154 7.38614 16.0384 6.50914C15.1614 5.63214 13.8807 5 12.5 5Z" stroke="#635bff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.5 19C14.9853 19 17.5 16.4853 17.5 14C17.5 11.5147 14.9853 9 12.5 9C10.0147 9 7.5 11.5147 7.5 14C7.5 16.4853 10.0147 19 12.5 19Z" stroke="#635bff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                  </svg>
                                )}

                              </div>
                            </div>

                            {x.delivery_status.code == 200 && (
                              <div className='AudienceCardStatusActive'></div>
                            )}
                            {x.delivery_status.code == 300 && (
                              <Tooltip title="Audience is too small" arrow>
                                <div className='AudienceCardStatusInactive'></div>
                              </Tooltip>
                            )}
                            {x.operation_status.code == 450 && (
                              <Tooltip title="Audience out of date: hasn't been used in an ad for at least 30 days or was created over 90 days ago and has never been used" arrow>
                                <div className='AudienceCardStatusInactive'></div>
                              </Tooltip>
                            )}

                            <div className='AudienceCardTextColumn'>
                              <span className='AudienceCardName'>{x.name}</span>
                              <span className='AudienceCardDate'>
                                {new Date(x.time_updated * 1000).toLocaleDateString('en-US', {
                                  year: 'numeric',
                                  month: 'short',
                                  day: 'numeric',
                                  hour: 'numeric',
                                  minute: '2-digit',
                                  hour12: true
                                })}
                              </span>
                            </div>

                            {x.approximate_count_upper_bound + x.approximate_count_lower_bound != -2 && <span className='AudienceCardSize'>~{new Intl.NumberFormat('en-US').format((x.approximate_count_upper_bound + x.approximate_count_lower_bound) / 2)}</span> }


                          </div>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                    <div className='AudienceGridShowMoreButtonRow'>
                      {filteredAudiences[ad_account_id].length > 20 && !showAll[ad_account_id] ? 
                        <span className='AudienceGridShowMoreButton' onClick={() => {setShowAll({...showAll, [ad_account_id]: !showAll[ad_account_id] })}}>Show All</span>
                        :
                        filteredAudiences[ad_account_id].length > 20 && showAll[ad_account_id] && <span className='AudienceGridShowMoreButton' onClick={() => {setShowAll({...showAll, [ad_account_id]: !showAll[ad_account_id] })}}>Show Less</span> 
                      }
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}


            </div>
        }











        {/* Create Audience Popup */}
        <div className={`AudienceUploadPopupContainer ${showNewAudiencePopup ? 'open' : ''}`}>
          <div className='AudienceUploadPopup'>
            <span className='AudienceUploadPopupTitle'>Upload an Audience</span>

            <div className='AudienceUploadPopupContent'>

              <input className='AudienceUploadPopupTextInput' type='text' placeholder='Audience Name' value={newAudienceName} onChange={(e) => setNewAudienceName(e.target.value)} />
              <input className='AudienceUploadPopupTextInput' type='text' placeholder='Audience Description' value={newAudienceDescription} onChange={(e) => setNewAudienceDescription(e.target.value)} />
              
              <select className='AudienceUploadPopupSelectInput' value={createAudienceSelectedAdAccount} onChange={(e) => setCreateAudienceSelectedAdAccount(e.target.value)}>
                {Object.keys(adAccounts).map((ad_account_id, index) => (
                  <option key={index} value={ad_account_id}>{adAccounts[ad_account_id]}</option>
                ))}
              </select>

              <select className='AudienceUploadPopupSelectInput' value={newAudienceSelectedCountry} onChange={(e) => setNewAudienceSelectedCountry(e.target.value)}>
                <option value="">Select Country</option>
                <option value="US">United States</option>
                <option value="CA">Canada</option>
                <option value="UK">UK</option>
              </select>

              <div className='AudienceCustomerValueRow'>
                <div className={priorityAudience == 'All Customers' ? 'AudienceCustomerValueOptionContainerSelected' : 'AudienceCustomerValueOptionContainer'} onClick={() => {setPriorityAudience('All Customers')}}>
                  <span className='AudienceCustomerValueText'>Do Not Include Customer Value</span>
                  {priorityAudience == 'All Customers' ? (<span className='AudienceCustomerValueSelectedBadge'>Selected</span>) : ''}
                </div>

                <div className={priorityAudience == 'Prioritize Customer Value' ? 'AudienceCustomerValueOptionContainerSelected' : 'AudienceCustomerValueOptionContainer'} onClick={() => {setPriorityAudience('Prioritize Customer Value')}}>
                  <span className='AudienceCustomerValueText'>Include Customer Value</span>
                  {priorityAudience == 'Prioritize Customer Value' ? ( <span className='AudienceCustomerValueSelectedBadge'>Selected</span>) : ''}
                </div>
              </div>

              {facebookAgreeToTermsLink && (
                <span className='AudienceUploadPopupAgreeToFacebookTerms'>{facebookAgreeToTermsLink}</span>  
              )}

            </div>
            <div className='AudienceUploadPopupFooter'>
                <span className='AudienceUploadPopupCancelButton' onClick={() => { setShowNewAudiencePopup(false) }}>Cancel</span>
                <span className='AudienceUploadPopupContinueButton' onClick={() => { createAudience('custom_audience') }}>Send</span>
            </div>
          </div>
        </div>






        {/* Create Lookalike Audience Popup */}
        <div className={`AudienceUploadPopupContainer ${showNewLookalikePopup ? 'open' : ''}`}>
          <div className='AudienceUploadPopup'>
            <span className='AudienceUploadPopupTitle'>Create Lookalike Audience</span>

            <div className='AudienceUploadPopupContent'>
              
              {/* <TextInput placeholder='Audience Name' value={newAudienceName} onChange={setNewAudienceName} marginBottom={20} widthPercent={100} />
              <TextInput placeholder='Audience Description' value={newAudienceDescription} onChange={setNewAudienceDescription} marginBottom={20} widthPercent={100} /> */}

              <select className='AudienceUploadPopupSelectInput' value={newAudienceSelectedCountry} onChange={(e) => setNewAudienceSelectedCountry(e.target.value)}>
                <option value="">Select Country</option>
                <option value="US">United States</option>
                <option value="CA">Canada</option>
                <option value="UK">UK</option>
              </select>

              <label>
                <input
                  id="typeinp"
                  className='AudienceUploadPopupSliderInput'
                  type="range"
                  min="1"
                  max="10"
                  value={rangeValue}
                  onChange={handleRangeChange}
                  step="1"
                />
              </label>
              <div className='AudienceUploadPopupSliderLabelContainer'>
                {Array.from({ length: 10 }, (_, i) => i + 1).map((step) => (
                  <span
                    key={step}
                    className={`AudienceUploadPopupSliderStep ${rangeValue === step ? 'active' : ''}`}
                    onClick={() => handleStepClick(step)}
                  >
                    {step}%
                  </span>
                ))}
              </div>

             

            </div>
            <div className='AudienceUploadPopupFooter'>
                <span className='AudienceUploadPopupCancelButton' onClick={() => { setShowNewLookalikePopup(false) }}>Cancel</span>
                <span className='AudienceUploadPopupContinueButton' onClick={() => { createAudience('lookalike_audience') }}>Send</span>
            </div>
          </div>
        </div>





 


























        
 
      </div>
    </>
  );
}

export default Audiences;
