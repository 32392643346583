import './Settings.css';
import { getAuth, signOut } from "firebase/auth";
import react, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
//import {ProcessGoogleRedirect} from './ProcessGoogleRedirect';
import TextInput from '../components/TextInput';
import { collection, doc, getDoc, getDocs, where, query, addDoc, updateDoc, setDoc, deleteDoc, arrayUnion, arrayRemove, onSnapshot } from 'firebase/firestore';
import { db } from '../FirebaseInit';
import Plan1Icon from '../assets/Icons/Plan1';
import Plan2Icon from '../assets/Icons/Plan2';
import Plan3Icon from '../assets/Icons/Plan3';
import { AccessTimeRounded, AddRounded, CheckRounded, CloseRounded, Delete, DeleteRounded, ImportExportRounded, KeyboardArrowDownRounded, LinkRounded, LockRounded, MenuRounded, MoreVertRounded } from '@mui/icons-material';
import MastercardLogo from '../assets/Icons/MastercardLogo';
import VisaLogo from '../assets/Icons/VisaLogo';
import Switch from "react-switch";
import { RxCross2 } from "react-icons/rx";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { Button, ClickAwayListener, Tooltip } from '@mui/material';
import tiktok_logo_white_background from '../assets/tiktok_logo_white_background.png';
import klaviyo_logo from '../assets/klaviyo_logo.png';
import snapchat_logo from '../assets/snapchat_logo.png';
import shopify_logo from '../assets/shopify_logo.png';
import Meta_logo from '../assets/Meta_logo.png';
import google_ads_logo from '../assets/google_ads_logo.png';
import DateSelector from '../components/DateSelector';
import CalendarIcon from '../assets/Icons/CalendarIcon';
import dayjs from 'dayjs';





function Settings({pythonServerURL, serverURL, setShowSettings, setSnackbarInfo}) {
  const navigate = useNavigate()
  const [selectedSection, setSelectedSection] = useState('general')
  const [selectedIntegration, setSelectedIntegration] = useState('shopify') 

  const [allBusinessInfo, setAllBusinessInfo] = useState({})

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [country, setCountry] = useState('')
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [zip, setZip] = useState('')

  const [firstNameDB, setFirstNameDB] = useState('')
  const [lastNameDB, setLastNameDB] = useState('')
  const [emailDB, setEmailDB] = useState('')
  const [phoneDB, setPhoneDB] = useState('')
  const [addressDB, setAddressDB] = useState('')
  const [countryDB, setCountryDB] = useState('')
  const [stateDB, setStateDB] = useState('')
  const [cityDB, setCityDB] = useState('')
  const [zipDB, setZipDB] = useState('')

  const [selectedPlan, setSelectedPlan] = useState('')
  const [billingName, setBillingName] = useState('')
  const [billingAddress, setBillingAddress] = useState('')
  const [billingPhoneNumber, setBillingPhoneNumber] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')
  const [cardIssuer, setCardIssuer] = useState('');
  const [showNewPaymentMethodPopup, setShowNewPaymentMethodPopup] = useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const [cardHolder, setCardHolder] = useState('');
  const [cardExp, setCardExp] = useState('');
  const [cardCVV, setCardCVV] = useState('');

  //summaries
  const [dailySummaryNotifications, setDailySummaryNotifications] = useState(false);
  const [weeklySummaryNotifications, setWeeklySummaryNotifications] = useState(false);
  const [monthlySummaryNotifications, setMonthlySummaryNotifications] = useState(false);

  //changes
  const [newAdNotifications, setNewAdNotifications] = useState(false);
  const [adChangesNotifications, setAdChangesNotifications] = useState(false);
  const [newOrderNotifications, setNewOrderNotifications] = useState(false);
  const [newProductNotifications, setNewProductNotifications] = useState(false);

  //analytics
  const [highPerformingAdNotifications, setHighPerformingAdNotifications] = useState(false);
  const [lowPerformingAdNotifications, setLowPerformingAdNotifications] = useState(false);

  //omni 
  const [newOmniBusinessUserNotifications, setNewOmniBusinessUserNotifications] = useState(false);
  const [omniPaymentNotifications, setOmniPaymentNotifications] = useState(false);


  const [employees, setEmployees] = useState([]);
  const [showEmployeeRoleDropdown, setShowEmployeeRoleDropdown] = useState(false);
  const [employeeRoleChangeValue, setEmployeeRoleChangeValue] = useState('');
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState(null);

  //pending invitations
  const [invitationsFromBusinesses, setInvitationsFromBusinesses] = useState([]);

  //invite user
  const [showInvitePermissionsPopup, setShowInvitePermissionsPopup] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const [inviteFirstName, setInviteFirstName] = useState('');
  const [inviteLastName, setInviteLastName] = useState('');
  const [selectedInvitePermissions, setSelectedInvitePermissions] = useState('employee');
  const [showInvitePermissionsDropdown, setShowInvitePermissionsDropdown] = useState(false)
  const [pendingInvites, setPendingInvites] = useState([]);


  const [shopifyIntegrationComplete, setShopifyIntegrationComplete] = useState(true);
  const [facebookIntegrationComplete, setFacebookIntegrationComplete] = useState(false);
  const [googleIntegrationComplete, setGoogleIntegrationComplete] = useState(false);
  const [tiktokIntegrationComplete, setTiktokIntegrationComplete] = useState(true);
  const [klaviyoIntegrationComplete, setKlaviyoIntegrationComplete] = useState(true);
  const [snapchatIntegrationComplete, setSnapchatIntegrationComplete] = useState(false);

  const [facebookConversionsAPI, setFacebookConversionsAPI] = useState(true);
  const [googleConversionsAPI, setGoogleConversionsAPI] = useState(true);
  const [tiktokConversionsAPI, setTiktokConversionsAPI] = useState(false);
  const [klaviyoConversionsAPI, setKlaviyoConversionsAPI] = useState(false);
  const [snapchatConversionsAPI, setSnapchatConversionsAPI] = useState(false);

  //custom expenses
  const [customExpenses, setCustomExpenses] = useState([]);
  const [showNewCustomExpensePopup, setShowNewCustomExpensePopup] = useState(false);
  const [customExpenseName, setCustomExpenseName] = useState('');
  const [customExpenseCost, setCustomExpenseCost] = useState('');
  const [showCustomExpensesIntervalDropdown, setShowCustomExpensesIntervalDropdown] = useState(false);
  const [customExpenseIsRecurring, setCustomExpenseIsRecurring] = useState(false);
  const [selectedCustomExpenseRecurringInterval, setSelectedCustomExpenseRecurringInterval] = useState('monthly');
  const [customExpenseRecurringCount, setCustomExpenseRecurringCount] = useState(1);
  const [showDateSelector, setShowDateSelector] = useState(false);
  const [newExpenseDateStart, setNewExpenseDateStart] = useState(formatDate(new Date()));
  const [newExpenseDateEnd, setNewExpenseDateEnd] = useState(formatDate(new Date()));
  const [dateUpdateTrigger, setDateUpdateTrigger] = useState(false);

  //account balances
  const [accountBalances, setAccountBalances] = useState([]);
  const [showRequestAdAccountPopup, setShowRequestAdAccountPopup] = useState(false);
  const [adAccountRequestPlatform, setAdAccountRequestPlatform] = useState('facebook');
  const [adAccountRequests, setAdAccountRequests] = useState([]);




  const uid = localStorage.getItem('uid');
  const bid = localStorage.getItem('bid');
  const businessName = localStorage.getItem('businessName');




  function generateRandomID(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }


  async function sendInviteEmail() {

    const omniUserExists = await checkIfEmailIsAlreadyOmniUser(inviteEmail, bid);
    const alreadyMemberOfBusiness = await checkIfEmailIsAlreadyMemberOfBusiness(inviteEmail, bid);
    const userInvited = await checkIfEmailIsAlreadyInvited(inviteEmail, bid);

    if (alreadyMemberOfBusiness) {
      setSnackbarInfo({open: true, title: 'Error', message: 'User is already a member of this business', type: 'error'});
      console.log("User is already a member of this business:", inviteEmail);
      return;
    }

    if (userInvited) {
      setSnackbarInfo({open: true, title: 'Error', message: 'User has already been invited', type: 'error'});
      console.log("User has already been invited:", inviteEmail);
      return;
    }

    const hexCodes = ["#3d88ff", "#6081ff", "#6081ff", "#199bff", "#0cc30a", "#1cc80c", "#00b8a3", "#ff33eb", "#e4be24", "#07ba71", "#03bf6a", "#02bb8d", "#b7c020", "#29c70d", "#07be64", 
      "#d6be1f", "#00bcf0", "#d1bd1e", "#9ec51b", "#9ec51b", "#9ec51b", "#07ba71", "#5fc611", "#26c708", "#8bc518", "#ff4d73", "#26c708", "#ba42ff"]
  
    function getRandomHex() {
      const randomIndex = Math.floor(Math.random() * hexCodes.length);
      return hexCodes[randomIndex];
    }
    const selectedHex = getRandomHex();
  
    const token = generateRandomID(45);
  
    const data = {
      firstName: inviteFirstName.charAt(0).toUpperCase() + inviteFirstName.slice(1),
      lastName: inviteLastName.charAt(0).toUpperCase() + inviteLastName.slice(1),
      email: inviteEmail,
      invitationID: token,
      businessIDs: [bid],
      businessPermissions: [{
      businessID: bid,
      role: selectedInvitePermissions,
      }], 
      businessName: businessName,
      hexCode: selectedHex,
      omniUserExists: omniUserExists
    };


    await setDoc(doc(db, "businessInvitations", data.invitationID), {
      email: data.email,
      invitationID: data.invitationID,
      businessID: bid,
      firstName: data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1),
      lastName: data.lastName.charAt(0).toUpperCase() + data.lastName.slice(1),
      role: selectedInvitePermissions,
      hexCode: selectedHex,
      businessName: businessName,
      createdAt: new Date()
    });

    //addUserInfo(data);
  
    try {
      const response = await axios.post(`${serverURL}/inviteOmniEmployee`, data );
      console.log('API Response:', response.data);
  
      if (response.data.success) {
        setSnackbarInfo({open: true, title: 'Success', message: 'Invitation sent successfully', type: 'success'});
      } else {
        setSnackbarInfo({open: true, title: 'Error', message: response.data.message || "Submission Failed", type: 'error'});
      }
    } catch (error) {
      setSnackbarInfo({open: true, title: 'Error', message: error.response?.data?.message || "Submission Failed", type: 'error'});
      console.error('ERROR sending data', error);
    }
  
  };


  async function checkIfEmailIsAlreadyOmniUser(email, bid) {
    try {
      const usersCollectionRef = collection(db, "users");
  
      // Query the collection to check if a user with the same email and bid exists
      const q = query(
        usersCollectionRef,
        where("email", "==", email),
      );
      const querySnapshot = await getDocs(q);
  
      return !querySnapshot.empty; // Return true if email exists, false otherwise
    } catch (e) {
      console.error("Error checking email existence:", e);
      return false; // Assume the email does not exist if there's an error
    }
  }
  
  
  async function checkIfEmailIsAlreadyMemberOfBusiness(email, bid) {
    try {
      const usersCollectionRef = collection(db, "users");
  
      // Query the collection to check if a user with the same email and bid exists
      const q = query(
        usersCollectionRef,
        where("email", "==", email),
        where("businessIDs", "array-contains", bid)
      );
      const querySnapshot = await getDocs(q);
  
      return !querySnapshot.empty; // Return true if email exists, false otherwise
    } catch (e) {
      console.error("Error checking email existence:", e);
      return false; // Assume the email does not exist if there's an error
    }
  }



  async function checkIfEmailIsAlreadyInvited(email, bid) {
    try {
      const usersCollectionRef = collection(db, "businessInvitations");
  
      // Query the collection to check if a user with the same email and bid exists
      const q = query(
        usersCollectionRef,
        where("email", "==", email),
        where("businessID", bid)
      );
      const querySnapshot = await getDocs(q);
  
      return !querySnapshot.empty; // Return true if email exists, false otherwise
    } catch (e) {
      console.error("Error checking email existence:", e);
      return false; // Assume the email does not exist if there's an error
    }
  }


  async function deletePendingInvite (invite) {
    try {
      console.log('DELETE INVITE', invite);
      const docRef = doc(db, "businessInvitations", invite.invitationID);
      await deleteDoc(docRef);
      setSnackbarInfo({open: true, title: 'Success', message: 'Invitation deleted successfully', type: 'success'});
    } catch (error) {
      setSnackbarInfo({open: true, title: 'Error', message: 'Error deleting invitation', type: 'error'});
      console.error("Error deleting document:", error);
    }
  }


  async function removeUserFromBusiness (user) {
    const currentBusinessPermissions = user.businessPermissions;
    const index = currentBusinessPermissions.findIndex(permission => permission.businessID === bid);
    currentBusinessPermissions.splice(index, 1);

    const currentBusinessIDs = user.businessIDs;
    const index2 = currentBusinessIDs.indexOf(bid);
    currentBusinessIDs.splice(index2, 1);

    try {
      const userRef = doc(db, "users", user.userID);
      await updateDoc(userRef, {
        businessPermissions: currentBusinessPermissions,
        businessIDs: currentBusinessIDs
      });

      const businessRef = doc(db, "businesses", bid);
      await updateDoc(businessRef, {
        userIDs: arrayRemove(user.userID)
      });

      setSnackbarInfo({open: true, title: 'Success', message: 'User removed successfully', type: 'success'});
    } catch (error) {
      setSnackbarInfo({open: true, title: 'Error', message: 'Error removing user', type: 'error'});
      console.error("Error updating document:", error);
    }
  }



  async function checkForBusinessInvites() {
    const userRef = doc(db, "users", uid);
    const userSnap = await getDoc(userRef);
    const userData = userSnap.data();
    const email = userData.email;

    console.log('Checking for business invites:', email);
    console.log(uid)
    const q = query(collection(db, "businessInvitations"), where("email", "==", email));

    const invitationsTemp = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      invitationsTemp.push(doc.data());
      console.log(doc.id, " => ", doc.data());
    });

    setInvitationsFromBusinesses(invitationsTemp)
  }


  useEffect(() => {
    checkForBusinessInvites();
  }, [])


  async function acceptBusinessInvitation(invite) {
    console.log('Accepting Business Invitation:', invite);
    const userRef = doc(db, "users", uid);
    const userSnap = await getDoc(userRef);
    const userData = userSnap.data();
    const currentBusinessPermissions = userData.businessPermissions || [];
    const currentBusinessIDs = userData.businessIDs || [];

    currentBusinessPermissions.push({
      businessID: invite.businessID,
      role: invite.role
    });

    currentBusinessIDs.push(invite.businessID);

    const businessRef = doc(db, "businesses", invite.businessID);

    await updateDoc(businessRef, {
      userIDs: arrayUnion(uid)
    });

    try {
      await updateDoc(userRef, {
        businessPermissions: currentBusinessPermissions,
        businessIDs: currentBusinessIDs
      });
      const docRef = doc(db, "businessInvitations", invite.invitationID);
      await deleteDoc(docRef);
      setSnackbarInfo({open: true, title: 'Success', message: 'Invitation accepted successfully', type: 'success'});
      await checkForBusinessInvites();
      await deletePendingInvite(invite);
    } catch (error) {
      setSnackbarInfo({open: true, title: 'Error', message: 'Error accepting invitation', type: 'error'});
      console.error("Error updating document:", error);
    }
  }

  
  

  async function addUserInfo(data) {
    try {
      const usersCollectionRef = doc(db, "users", data.userID);

      // If no user exists with the same email, add the new user document
      await setDoc(usersCollectionRef, data);
      console.log("User data successfully added!");
      return { success: true, message: "User data successfully added!" };
    } catch (e) {
      console.error("Error adding document:", e);
      return { success: false, message: "Error adding document." };
    }
  }


  async function getBusinessInfo() {
    try {
      const docRef = doc(db, "businesses", bid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setAllBusinessInfo(docSnap.data())
        setShopifyIntegrationComplete(docSnap.data().shopifyAdminAccessToken ? true : false)
        setFacebookIntegrationComplete(docSnap.data().facebookAccessToken ? true : false)
        setGoogleIntegrationComplete(docSnap.data().googleRefreshToken ? true : false)
        setTiktokIntegrationComplete(docSnap.data().tiktokAccessToken ? true : false)
        setKlaviyoIntegrationComplete(docSnap.data().klaviyoAccessToken ? true : false)
        setSnapchatIntegrationComplete(docSnap.data().snapchatAccessToken ? true : false)
        setFacebookConversionsAPI(docSnap.data().facebookConversionDataSync)
        setGoogleConversionsAPI(docSnap.data().googleConversionDataSync)
        setTiktokConversionsAPI(docSnap.data().tiktokConversionDataSync)
        setKlaviyoConversionsAPI(docSnap.data().klaviyoConversionDataSync)
        setSnapchatConversionsAPI(docSnap.data().snapchatConversionDataSync)
        
        setFirstName(docSnap.data().ownerFirstName)
        setLastName(docSnap.data().ownerLastName)
        setEmail(docSnap.data().ownerEmail)
        setPhone(docSnap.data().ownerPhone)
        setAddress(docSnap.data().ownerAddress)
        setState(docSnap.data().ownerState)
        setCity(docSnap.data().ownerCity)
        setZip(docSnap.data().ownerZip)

        setFirstNameDB(docSnap.data().ownerFirstName)
        setLastNameDB(docSnap.data().ownerLastName)
        setEmailDB(docSnap.data().ownerEmail)
        setPhoneDB(docSnap.data().ownerPhone)
        setAddressDB(docSnap.data().ownerAddress)
        setStateDB(docSnap.data().ownerState)
        setCityDB(docSnap.data().ownerCity)
        setZipDB(docSnap.data().ownerZip)

        setSelectedPlan(docSnap.data().activePlan)
        setBillingName(docSnap.data().billingName)
        setBillingAddress(docSnap.data().billingAddress)
        setBillingPhoneNumber(docSnap.data().billingPhone)
        setPaymentMethod(docSnap.data().paymentMethod)
      } else {
        console.log("No such document!");
      }
    } catch (e) {
      console.error("Error getting document:", e);
    }
  }




  async function updateBusinessInfo() {

    try {
      const businessRef = doc(db, "businesses", bid);
      await updateDoc(businessRef, {
        ownerFirstName: firstName,
        ownerLastName: lastName,
        ownerPhone: phone,
        ownerEmail: email,
        ownerAddress: address,
        ownerCity: city,
        ownerState: state,
        ownerZip: zip,
      });
      setAlertMessage("Business Info Updated");
      setAlertType("success");
      setTimeout(() => {
        setAlertMessage(null);
        setAlertType(null);
      }, 2000);
      getBusinessInfo();
    } catch (error) {
      console.error("Error updating document:", error);
    }
  }




  useEffect(() => {
    const employeesArray = [];
    const invitationsArray = [];

    const unsubscribeEmployees = onSnapshot(
      query(collection(db, "users"), where("businessIDs", "array-contains", bid)),
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added" || change.type === "modified") {
            employeesArray.push(change.doc.data());
          } else if (change.type === "removed") {
            const index = employeesArray.findIndex((emp) => emp.userID === change.doc.id);
            if (index > -1) {
              employeesArray.splice(index, 1);
            }
          }
        });
        setEmployees([...employeesArray]);
      },
      (error) => {
        console.error("Error fetching employees:", error);
      }
    );

    const unsubscribeInvitations = onSnapshot(
      query(collection(db, "businessInvitations"), where("businessID", "==", bid)),
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added" || change.type === "modified") {
            invitationsArray.push(change.doc.data());
          } else if (change.type === "removed") {
            const index = invitationsArray.findIndex((invite) => invite.invitationID === change.doc.id);
            if (index > -1) {
              invitationsArray.splice(index, 1);
            }
          }
        });
        setPendingInvites([...invitationsArray]);
      },
      (error) => {
        console.error("Error fetching invitations:", error);
      }
    );

    return () => {
      unsubscribeEmployees();
      unsubscribeInvitations();
    };
  }, [uid, bid]);

  useEffect(() => {
    getBusinessInfo()
  }, [])



  async function adjustConversionsAPI(platform) {
    try {
      const businessRef = doc(db, "businesses", bid);
      switch (platform) {
        case 'facebook':
          await updateDoc(businessRef, {
            facebookConversionDataSync: !facebookConversionsAPI
          });          
          setFacebookConversionsAPI(!facebookConversionsAPI);
          break;
        case 'google':
          await updateDoc(businessRef, {
            googleConversionDataSync: !googleConversionsAPI
          });
          setGoogleConversionsAPI(!googleConversionsAPI);
          break;
        case 'tiktok':
          await updateDoc(businessRef, {
            tiktokConversionDataSync: !tiktokConversionsAPI
          });
          setTiktokConversionsAPI(!tiktokConversionsAPI);
          break;
        case 'klaviyo':
          await updateDoc(businessRef, {
            klaviyoConversionDataSync: !klaviyoConversionsAPI
          });
          setKlaviyoConversionsAPI(!klaviyoConversionsAPI);
          break;
        case 'snapchat':
          await updateDoc(businessRef, {
            snapchatConversionDataSync: !snapchatConversionsAPI
          });
          setSnapchatConversionsAPI(!snapchatConversionsAPI);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error("Error updating document:", error);
    }
  }




  async function changeUserPermissions (user, newRole) {
    try {

      const index = user.businessPermissions.findIndex(permission => permission.businessID === bid);

      user.businessPermissions[index].role = newRole;

      const userRef = doc(db, "users", user.userID);
      await updateDoc(userRef, {
        businessPermissions: user.businessPermissions
      });
    } catch (error) {
      console.error("Error updating document:", error);
    }
  }








  function getCreditCardCompany(cardNumber) {
    // Remove spaces and dashes from the card number
    cardNumber = cardNumber.replace(/[\s-]/g, '');

    // Check the starting digits to determine the card issuer
    if (/^4/.test(cardNumber)) {
      return "Visa";
    } else if (/^5[1-5]/.test(cardNumber)) {
      return "Mastercard";
    } else if (/^3[47]/.test(cardNumber)) {
      return "American Express";
    } else if (/^6(?:011|5[0-9]{2}|4[4-9]|4[0-9]{3}|5[0-9]{2}|6[0-9]{2}|7[0-9]{2}|8[0-9]{2})$/.test(cardNumber)) {
      return "Discover";
    } else if (/^(?:30[0-5]|3[89]|36|3095|388[5-9])/g.test(cardNumber)) {
      return "Diners Club";
    } else if (/^3/.test(cardNumber)) {
      return "JCB";
    } else {
      return "Unknown"; // If the card issuer cannot be determined
    }
  }





  async function getCustomExpenses() {
    try {
      const response = await axios.get(`${serverURL}/getAllCustomExpenses?omni_business_id=${bid}`);
      console.log('Custom Expenses:', response.data);
      setCustomExpenses(response.data);
    } catch (error) {
      console.error("Error getting custom expenses:", error);
    }
  }

  useEffect(() => {
    if (uid && bid) {
      getCustomExpenses();
    }
  }, [])



  function formatDate(date) {
    const formattedDate = dayjs(date).format('MMM D, YYYY');
    return formattedDate;
  }


  async function addCustomExpense() {
    try {
      const data = {
        omniBusinessID: bid,
        category: customExpenseName,
        cost: customExpenseCost,
        startDate: newExpenseDateStart,
        endDate: newExpenseDateEnd,
        isRecurring: customExpenseIsRecurring,
        recurrenceInterval: selectedCustomExpenseRecurringInterval,
        recurrenceCount: customExpenseRecurringCount,
        includeInPixel: false,
        adSpend: false
      }

      console.log('Adding Custom Expense:', data);

      const response = await axios.post(`${serverURL}/updateCustomExpensesData`, data);
      console.log('Custom Expense Added:', response.data);
      getCustomExpenses();
      setShowNewCustomExpensePopup(false);
      
    } catch (error) {
      console.error("Error adding custom expense:", error);
    }
  }



  async function getAccountBalances() {
    const accountBalancesTemp = [];

    const promises = [];

    if (facebookIntegrationComplete) {
        const FBRequest = axios.get(`${serverURL}/getFacebookAccountBalances?omni_business_id=${bid}`)
            .then(FBresponse => {
                console.log('Account Balances:', FBresponse.data);
                if (!FBresponse.data.error) {
                    accountBalancesTemp.push(...FBresponse.data);
                }
            })
            .catch(error => console.error("Error getting account balances:", error));
        promises.push(FBRequest);
    }

    if (tiktokIntegrationComplete) {
        const TTRequest = axios.get(`${serverURL}/getTiktokAccountBalances?omni_business_id=${bid}`)
            .then(TTresponse => {
                console.log('Account Balances:', TTresponse.data);
                if (!TTresponse.data.error) {
                    accountBalancesTemp.push(...TTresponse.data);
                }
            })
            .catch(error => console.error("Error getting account balances:", error));
        promises.push(TTRequest);
    }

    if (googleIntegrationComplete) {
        const googleRequest = axios.post(`${pythonServerURL}/api/getGoogleAccountBalances`, { omniBusinessID: bid })
            .then(googleResponse => {
                console.log('Account Balances:', googleResponse.data);
                if (!googleResponse.data.error) {
                    accountBalancesTemp.push(...googleResponse.data);
                }
            })
            .catch(error => console.error("Error getting account balances:", error));
        promises.push(googleRequest);
    }

    // Wait for all requests to finish
    await Promise.all(promises);

    // Once all requests are completed, set the account balances
    setAccountBalances(accountBalancesTemp);
  }




  async function getAdAccountRequests() {
    const q = query(collection(db, "accountRequests"), where("omniBusinessID", "==", bid));

    const requestsTemp = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      requestsTemp.push(doc.data());
    });
    setAdAccountRequests(requestsTemp);
  }



  

  useEffect(() => {
    if (selectedSection === 'adAccounts') {
      getAccountBalances();
      getAdAccountRequests();
    }
  }, [selectedSection])




  async function saveAdAccountRequest() { 
    try {
      const storeStats = await getDailyStats();

      //if the user has already submitted a request for the specified platform, don't allow them to submit another one
      const existingRequest = adAccountRequests.find(request => request.platform === adAccountRequestPlatform && request.status === 'pending');
      if (existingRequest) {
        setSnackbarInfo({open: true, title: 'Error', message: 'You have already submitted a pending request for this platform', type: 'error'});
        return;
      }
      
      const url = `${serverURL}/getShopInfo`;
      const shopResponse = await axios.get(url);

      const currency = shopResponse.data[0].currency;
      const timezone = shopResponse.data[0].timezone;
      const country = shopResponse.data[0].country;

      const url2 = `${serverURL}/getPagesByBID?omni_business_id=${bid}`;
      const pagesResponse = await axios.get(url2);

      const pageDetails = []
      for (let i = 0; i < pagesResponse.data.length; i++) {
        pageDetails.push({
          name: pagesResponse.data[i].name,
          verificationStatus: pagesResponse.data[i].verification_status,
          website: pagesResponse.data[i].website,
          pageID: pagesResponse.data[i].promotable_id
        })
      }

      const requestID = 'r_' + generateRandomID(10);
      const requestRef = doc(db, 'accountRequests', requestID);

      const data = { 
        omniBusinessID: bid,
        requestID: requestID,
        platform: adAccountRequestPlatform,
        currency: currency,
        timezone: timezone,
        country: country,
        pages: pageDetails,
        status: 'pending',
        createdAt: new Date,
        uid: uid,
        last30: storeStats,
        firstName: firstName,
        lastName: lastName,
        domains: allBusinessInfo.shopifyDomains,
        businessName: allBusinessInfo.businessName,
        conversationID: null
      }

      if (adAccountRequestPlatform === 'facebook') {
        data.businessManagerID = allBusinessInfo.facebookBusinessID || '';
      }

      if (adAccountRequestPlatform === 'google') {
        data.businessManagerID = allBusinessInfo.googleBusinessID || '';
      }

      if (adAccountRequestPlatform === 'tiktok') {
        data.businessManagerID = allBusinessInfo.tiktokBusinessID || '';
      }

      await setDoc(requestRef, data);

      await getAdAccountRequests();
      setSnackbarInfo({open: true, title: 'Success', message: 'Request submitted successfully', type: 'success'});

    } catch (error) {
      console.error("Error saving ad account request:", error);
    }
  }





  //function get get time ago but in a beatuiful way in either minutes, hours, days, weeks, months and years or now if less than a minute
  function timeAgo(date) {
    const seconds = Math.floor((new Date() - date) / 1000);
    let interval = Math.floor(seconds / 31536000);
    if (interval > 1) {
      return interval + " years ago";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + " months ago";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + " days ago";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + " hours ago";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + " minutes ago";
    }
    return "now";
  }



  async function deleteRequest (requestID) {
    try {
      const docRef = doc(db, "accountRequests", requestID);
      await deleteDoc(docRef);
      setSnackbarInfo({open: true, title: 'Success', message: 'Request deleted successfully', type: 'success'});
      getAdAccountRequests();
    } catch (error) {
      setSnackbarInfo({open: true, title: 'Error', message: 'Error deleting request', type: 'error'});
      console.error("Error deleting document:", error);
    }
  }



  async function getDailyStats () {
    try {

      const dateStart = new Date();
      dateStart.setDate(dateStart.getDate() - 30);
      const dateEnd = new Date();

      const API_URL = `${serverURL}/getDailyStats?omni_business_id=${bid}&dateStart=${dateStart.toISOString(0,10)}&dateEnd=${dateEnd.toISOString(0,10)}` ;
      const response = await axios.get(API_URL)
      console.log('get daily stats response: ', response.data)

      const data = response.data;
      const dailyOrders = data.map(entry => (entry.orders * 1));
      const dailyRevenue = data.map(entry => (entry.revenue * 1));
      const dailyFacebookSpend = data.map(entry => (entry.facebook_spend * 1));
      const dailyGoogleSpend = data.map(entry => (entry.google_spend * 1));
      const dailyTiktokSpend = data.map(entry => (entry.tiktok_spend * 1));
      const dailySnapchatSpend = data.map(entry => (entry.snapchat_spend * 1));

      const ordersTotal = dailyOrders.reduce((a, b) => a + b, 0)
      const revenueTotal = dailyRevenue.reduce((a, b) => a + b, 0)
      const facebookSpendTotal = dailyFacebookSpend.reduce((a, b) => a + b, 0)
      const googleSpendTotal = dailyGoogleSpend.reduce((a, b) => a + b, 0)
      const tiktokSpendTotal = dailyTiktokSpend.reduce((a, b) => a + b, 0)
      const snapchatSpendTotal = dailySnapchatSpend.reduce((a, b) => a + b, 0)

      return {
        ordersTotal,
        revenueTotal,
        facebookSpendTotal,
        googleSpendTotal,
        tiktokSpendTotal,
        snapchatSpendTotal
      }

    } catch (error) {
      console.log('ERROR loading stats StoreOverview.js', error)
    }
}




  return (
    <div className="settingsMainContainer">
      <Snackbar
        open={alertMessage !== null}
        autoHideDuration={7000}
        onClose={() => {
          setAlertMessage(null);
          setAlertType(null);
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {alertMessage && (
          <Alert variant="filled" onClose={() => { setAlertMessage(null); setAlertType(null); }} severity={alertType}>
            {alertMessage}
          </Alert>
        )}
      </Snackbar>

        <div className="settingsContainer">
          <span className='settingsCloseIcon' onClick={() => {setShowSettings(false)}}><CloseRounded/></span>
          <div className='settingsContainerLeft'>
            <span className={selectedSection === 'general' ? 'settingsContainerLeftItemSelected' : 'settingsContainerLeftItem'} onClick={() => setSelectedSection('general')}>Business</span>
            <span className={selectedSection === 'integrations' ? 'settingsContainerLeftItemSelected' : 'settingsContainerLeftItem'} onClick={() => setSelectedSection('integrations')}>Integrations</span>
            <span className={selectedSection === 'billing' ? 'settingsContainerLeftItemSelected' : 'settingsContainerLeftItem'} onClick={() => setSelectedSection('billing')}>Billing</span>
            <span className={selectedSection === 'adAccounts' ? 'settingsContainerLeftItemSelected' : 'settingsContainerLeftItem'} onClick={() => setSelectedSection('adAccounts')}>Ad Accounts</span>
            <span className={selectedSection === 'notifications' ? 'settingsContainerLeftItemSelected' : 'settingsContainerLeftItem'} onClick={() => setSelectedSection('notifications')}>Notifications</span>
            <span className={selectedSection === 'expenses' ? 'settingsContainerLeftItemSelected' : 'settingsContainerLeftItem'} onClick={() => setSelectedSection('expenses')}>Expenses</span>
            <span className={selectedSection === 'invitations' ? 'settingsContainerLeftItemSelected' : 'settingsContainerLeftItem'} onClick={() => setSelectedSection('invitations')}>Invitations</span>
            <span className={selectedSection === 'users' ? 'settingsContainerLeftItemSelected' : 'settingsContainerLeftItem'} onClick={() => setSelectedSection('users')}>Users and Permissions</span>
          </div>
          <div className='settingsContainerRight'>
           
            


        

            {selectedSection == 'general' &&
              <div className='settingsSection'>

                <div className='settingsSectionGeneralGrid'>

                  <div className='settingsSectionGeneralGridLeft'>
                    <div className='settingsSectionGeneralGridLeftAvatarRowContainer'>
                      <div className='settingsSectionGeneralGridLeftshopify_logoContainer'><img src={shopify_logo} className='settingsSectionGeneralGridLeftshopify_logo'/></div>
                      <div >
                        <span className='settingsSectionGeneralGridLeftshopify_logoText'>{allBusinessInfo.businessName}</span>
                        <span className='settingsSectionGeneralGridLeftshopify_logoStatus'>{allBusinessInfo.shopifyDomain}</span>
                        <span className='settingsSectionGeneralGridLeftshopify_logoStatus'>Omni business created on {formatDate(allBusinessInfo?.createdAt?.seconds * 1000)}</span>
                        <span className='settingsSectionGeneralGridLeftshopify_logoStatus'>{employees.length} employees</span>
                      </div>
                    </div>

                  </div>


                  <div className='settingsSectionGeneralGridRight'>
                      <TextInput placeholder='First Name' type='text' value={firstName} onChange={setFirstName} widthPercent={100} widthPixels={0} />
                      <TextInput placeholder='Last Name' type='text' value={lastName} onChange={setLastName} widthPercent={100} widthPixels={0} />
                      <TextInput placeholder='Phone Number' type='text' value={phone} onChange={setPhone} widthPercent={100} widthPixels={0} />
                      <TextInput placeholder='Email Address' type='text' value={email} onChange={setEmail} widthPercent={100} widthPixels={0} />
                      <TextInput placeholder='Address' type='text' value={address} onChange={setAddress} widthPercent={100} widthPixels={0} />
                      <TextInput placeholder='City' type='text' value={city} onChange={setCity} widthPercent={100} widthPixels={0} />
                      <TextInput placeholder='State/Region' type='text' value={state} onChange={setState} widthPercent={100} widthPixels={0} />
                      <TextInput placeholder='Zip Code' type='text' value={zip} onChange={setZip} widthPercent={100} widthPixels={0} />

                  </div>

                </div>


              </div>
            }








            {selectedSection == 'integrations' &&
              <div className='settingsIntegrationsContainer'>
                <div className='settingsIntegrationsLeft'>
                  <span className='settingsIntegrationsTitle'>Active Integrations</span>

                  {shopifyIntegrationComplete &&
                    <div className='settingsIntegrationRow' style={{backgroundColor: selectedIntegration == 'shopify' && '#e9e9e9'}} onClick={() => {setSelectedIntegration('shopify')}}>
                      <img src={shopify_logo} className='settingsIntegrationRowLogo' />
                      <span className='settingsIntegrationRowName'>Shopify</span>
                    </div>
                  } 
                  {facebookIntegrationComplete &&
                    <div className='settingsIntegrationRow' style={{backgroundColor: selectedIntegration == 'facebook' && '#e9e9e9'}} onClick={() => {setSelectedIntegration('facebook')}}>
                      <img src='https://upload.wikimedia.org/wikipedia/commons/6/6c/Facebook_Logo_2023.png' className='settingsIntegrationRowLogo' />
                      <span className='settingsIntegrationRowName'>Facebook</span>
                      {facebookConversionsAPI && <span className='settingsIntegrationRowConversionsAPIStatusOn'><ImportExportRounded style={{width: 20, height: 20}}/></span>}
                    </div>
                  }
                  {googleIntegrationComplete &&
                    <div className='settingsIntegrationRow' style={{backgroundColor: selectedIntegration == 'google' && '#e9e9e9'}} onClick={() => {setSelectedIntegration('google')}}>
                      <img src='https://cdn4.iconfinder.com/data/icons/logos-brands-7/512/google_logo-google_icongoogle-512.png' className='settingsIntegrationRowLogo' />
                      <span className='settingsIntegrationRowName'>Google</span>
                      {googleConversionsAPI && <span className='settingsIntegrationRowConversionsAPIStatusOn'><ImportExportRounded style={{width: 20, height: 20}}/></span>}
                    </div>
                  }
                  {tiktokIntegrationComplete && 
                    <div className='settingsIntegrationRow' style={{backgroundColor: selectedIntegration == 'tiktok' && '#e9e9e9'}} onClick={() => {setSelectedIntegration('tiktok')}}>
                      <img src={tiktok_logo_white_background} className='settingsIntegrationRowLogo' />
                      <span className='settingsIntegrationRowName'>Tiktok</span>
                      {tiktokConversionsAPI && <span className='settingsIntegrationRowConversionsAPIStatusOn'><ImportExportRounded style={{width: 20, height: 20}}/></span>}
                    </div>
                  }
                  {klaviyoIntegrationComplete &&
                    <div className='settingsIntegrationRow' style={{backgroundColor: selectedIntegration == 'klaviyo' && '#e9e9e9'}} onClick={() => {setSelectedIntegration('klaviyo')}}>
                      <img src={klaviyo_logo} className='settingsIntegrationRowLogo' />
                      <span className='settingsIntegrationRowName'>Klaviyo</span>
                    </div>
                  }
                  {snapchatIntegrationComplete &&
                    <div className='settingsIntegrationRow' style={{backgroundColor: selectedIntegration == 'snapchat' && '#e9e9e9'}} onClick={() => {setSelectedIntegration('snapchat')}}>
                      <img src={snapchat_logo} className='settingsIntegrationRowLogo' />
                      <span className='settingsIntegrationRowName'>Snapchat</span>
                      {snapchatConversionsAPI && <span className='settingsIntegrationRowConversionsAPIStatusOn'><ImportExportRounded style={{width: 20, height: 20}}/></span>}
                    </div>
                  }
                  {!shopifyIntegrationComplete && !facebookIntegrationComplete && !googleIntegrationComplete && !tiktokIntegrationComplete && !klaviyoIntegrationComplete && !snapchatIntegrationComplete &&
                    <span className='settingsIntegrationsSubtitle'>No installed integrations yet</span>
                  }

                  <span className='settingsIntegrationsTitle'>Available Integrations</span>
                  
                  {!shopifyIntegrationComplete &&
                    <div className='settingsIntegrationRow' style={{backgroundColor: selectedIntegration == 'shopify' && '#e9e9e9'}} onClick={() => {setSelectedIntegration('shopify')}}>
                      <img src={shopify_logo} className='settingsIntegrationRowLogo' />
                      <span className='settingsIntegrationRowName'>Shopify</span>
                    </div>
                  } 
                  {!facebookIntegrationComplete &&
                    <div className='settingsIntegrationRow' style={{backgroundColor: selectedIntegration == 'facebook' && '#e9e9e9'}} onClick={() => {setSelectedIntegration('facebook')}}>
                      <img src='https://upload.wikimedia.org/wikipedia/commons/6/6c/Facebook_Logo_2023.png' className='settingsIntegrationRowLogo' />
                      <span className='settingsIntegrationRowName'>Facebook</span>
                    </div>
                  }
                  {!googleIntegrationComplete &&
                    <div className='settingsIntegrationRow' style={{backgroundColor: selectedIntegration == 'google' && '#e9e9e9'}} onClick={() => {setSelectedIntegration('google')}}>
                      <img src='https://cdn4.iconfinder.com/data/icons/logos-brands-7/512/google_logo-google_icongoogle-512.png' className='settingsIntegrationRowLogo' />
                      <span className='settingsIntegrationRowName'>Google</span>
                    </div>
                  }
                  {!tiktokIntegrationComplete && 
                    <div className='settingsIntegrationRow' style={{backgroundColor: selectedIntegration == 'tiktok' && '#e9e9e9'}} onClick={() => {setSelectedIntegration('tiktok')}}>
                      <img src={tiktok_logo_white_background} className='settingsIntegrationRowLogo' />
                      <span className='settingsIntegrationRowName'>Tiktok</span>
                    </div>
                  }
                  {!klaviyoIntegrationComplete &&
                    <div className='settingsIntegrationRow' style={{backgroundColor: selectedIntegration == 'klaviyo' && '#e9e9e9'}} onClick={() => {setSelectedIntegration('klaviyo')}}>
                      <img src={klaviyo_logo} className='settingsIntegrationRowLogo' />
                      <span className='settingsIntegrationRowName'>Klaviyo</span>
                    </div>
                  }
                  {!snapchatIntegrationComplete &&
                    <div className='settingsIntegrationRow' style={{backgroundColor: selectedIntegration == 'snapchat' && '#e9e9e9'}} onClick={() => {setSelectedIntegration('snapchat')}}>
                      <img src={snapchat_logo} className='settingsIntegrationRowLogo' />
                      <span className='settingsIntegrationRowName'>Snapchat</span>
                    </div>
                  }

                </div>


                <div className='settingsIntegrationsRight'>

                  {selectedIntegration == 'shopify' &&  
                    <div>
                      <div className='settingsIntegrationsRightLinkButtonContainer' onClick={() => {}}>
                        <AddRounded />
                        <span className='settingsIntegrationsRightLinkButtonText'>Link Shopify</span>
                      </div>

                    </div>
                  } 
                  {selectedIntegration == 'facebook' &&  
                    <div>
                      <div className='settingsIntegrationsRightLinkButtonContainer' onClick={() => {navigate('process-facebook-redirect')}}>
                        <AddRounded />
                        <span className='settingsIntegrationsRightLinkButtonText'>Link Facebook</span>
                      </div>
                      
                      <div className='settingsIntegrationsRightBody'>
                        {!facebookIntegrationComplete ?
                          <span className='settingsIntegrationsRightLinkButtonSubtitle'>To enable Facebook Conversions API, please link your Facebook account first</span>
                        :
                          <div className='settingsIntegrationsRightConversionsAPIRow'>
                            <Switch
                              onChange={(checked) => { adjustConversionsAPI('facebook')}}
                              checked={facebookConversionsAPI}
                              offColor="#d9d9d9" onColor="#635BFF" uncheckedIcon={false} checkedIcon={false}
                              width={35}
                              height={20}
                              handleDiameter={15}
                            />
                            <span className='settingsIntegrationsRightConversionsAPIRowText' style={{color: newOrderNotifications && '#000'}}>Conversions API</span>
                          </div>
                        } 
                      </div>
                        
                    </div>
                  } 
                  {selectedIntegration == 'google' &&
                    <div>
                      <div className='settingsIntegrationsRightLinkButtonContainer' onClick={() => {navigate('process-google-redirect')}}>
                        <AddRounded />
                        <span className='settingsIntegrationsRightLinkButtonText'>Link Google</span>
                      </div>
                                            
                      <div className='settingsIntegrationsRightBody'>
                        {!googleConversionsAPI ?
                          <span className='settingsIntegrationsRightLinkButtonSubtitle'>To enable Google Conversions API, please link your Google account first</span>
                        :
                          <div className='settingsIntegrationsRightConversionsAPIRow'>
                            <Switch
                              onChange={(checked) => { adjustConversionsAPI('google')}}
                              checked={googleConversionsAPI}
                              offColor="#d9d9d9" onColor="#635BFF" uncheckedIcon={false} checkedIcon={false}
                              width={35}
                              height={20}
                              handleDiameter={15}
                            />
                            <span className='settingsIntegrationsRightConversionsAPIRowText' style={{color: newOrderNotifications && '#000'}}>Conversions API</span>
                          </div>
                        } 
                      </div>

                    </div>
                  } 
                  {selectedIntegration == 'tiktok' &&
                    <div>
                      <div className='settingsIntegrationsRightLinkButtonContainer' onClick={() => {navigate('process-tiktok-redirect')}}>
                        <AddRounded />
                        <span className='settingsIntegrationsRightLinkButtonText'>Link Tiktok</span>
                      </div>
                                                                  
                      <div className='settingsIntegrationsRightBody'>
                        {!tiktokConversionsAPI ?
                          <span className='settingsIntegrationsRightLinkButtonSubtitle'>To enable TikTok Conversions API, please link your TikTok account first</span>
                        :
                          <div className='settingsIntegrationsRightConversionsAPIRow'>
                            <Switch
                              onChange={(checked) => { adjustConversionsAPI('tiktok')}}
                              checked={tiktokConversionsAPI}
                              offColor="#d9d9d9" onColor="#635BFF" uncheckedIcon={false} checkedIcon={false}
                              width={35}
                              height={20}
                              handleDiameter={15}
                            />
                            <span className='settingsIntegrationsRightConversionsAPIRowText' style={{color: newOrderNotifications && '#000'}}>Conversions API</span>
                          </div>
                        } 
                      </div>

                    </div>
                  }
                  {selectedIntegration == 'klaviyo' &&
                    <div>
                      <div className='settingsIntegrationsRightLinkButtonContainer' onClick={() => {navigate('process-klaviyo-redirect')}}>
                        <AddRounded />
                        <span className='settingsIntegrationsRightLinkButtonText'>Link Klaviyo</span>
                      </div>
                                                                                        
                      <div className='settingsIntegrationsRightBody'>
                        {!klaviyoConversionsAPI ?
                          <span className='settingsIntegrationsRightLinkButtonSubtitle'>To enable Klaviyo Conversions API, please link your Klaviyo account first</span>
                        :
                          <div className='settingsIntegrationsRightConversionsAPIRow'>
                            <Switch
                              onChange={(checked) => { adjustConversionsAPI('klaviyo')}}
                              checked={klaviyoConversionsAPI}
                              offColor="#d9d9d9" onColor="#635BFF" uncheckedIcon={false} checkedIcon={false}
                              width={35}
                              height={20}
                              handleDiameter={15}
                            />
                            <span className='settingsIntegrationsRightConversionsAPIRowText' style={{color: newOrderNotifications && '#000'}}>Conversions API</span>
                          </div>
                        } 
                      </div>

                    </div>
                  }
                  {selectedIntegration == 'snapchat' &&
                    <div>
                      <div className='settingsIntegrationsRightLinkButtonContainer' onClick={() => {navigate('process-snapchat-redirect')}}>
                        <AddRounded />
                        <span className='settingsIntegrationsRightLinkButtonText'>Link Snapchat</span>
                      </div>
                                                                                                              
                      <div className='settingsIntegrationsRightBody'>
                        {!snapchatConversionsAPI ?
                          <span className='settingsIntegrationsRightLinkButtonSubtitle'>To enable Snapchat Conversions API, please link your Snapchat account first</span>
                        :
                          <div className='settingsIntegrationsRightConversionsAPIRow'>
                            <Switch
                              onChange={(checked) => { adjustConversionsAPI('snapchat')}}
                              checked={snapchatConversionsAPI}
                              offColor="#d9d9d9" onColor="#635BFF" uncheckedIcon={false} checkedIcon={false}
                              width={35}
                              height={20}
                              handleDiameter={15}
                            />
                            <span className='settingsIntegrationsRightConversionsAPIRowText' style={{color: newOrderNotifications && '#000'}}>Conversions API</span>
                          </div>
                        } 
                      </div>

                    </div>
                  }




                </div>
              </div>
            }









            {selectedSection == 'billing' &&
              <div className='settingsSectionBillingContainer'>

                <div className='settingsBillingPlanOptionsRow'>

                  <div className={selectedPlan == 'growth' ? 'settingsBillingPlanOptionSelected' : 'settingsBillingPlanOption'} onClick={() => { setSelectedPlan('growth') }}>
                    <div className='settingsBillingPlanTopRow'>
                      <div className='settingsBillingPlanOptionLogo'><Plan1Icon /></div>
                      <div className='settingsBillingPlanOptionTopRightContainer'>
                        <span className='settingsBillingPlanOptionTitle'>Growth</span>
                        <span className='setingsBillingPlanOptionPrice'>$431 /mo</span>
                      </div>
                    </div>
                    <div className='settingsBillingPlan'>
                      <span className='settingsBillingPlanFeaturesSectionTitle'>Key Features</span>
                      <span className='settingsBillingPlanFeature'>- Profit Dashboard</span>
                      <span className='settingsBillingPlanFeature'>- Customer Journey</span>

                      <span className='settingsBillingPlanFeaturesSectionTitle' style={{marginTop: 20}}>Additional Support</span>
                      <span className='settingsBillingPlanFeature'>- Email & Live Chat Support</span>
                      <span className='settingsBillingPlanFeature'>- Implementation Specialist</span>
                      <span className='settingsBillingPlanFeature'>- Access to Customer Success Team</span>
                      <span className='settingsBillingPlanFeature'>- Strategic Consulting</span>
                    </div>
                  </div>

                  <div className={selectedPlan == 'pro' ? 'settingsBillingPlanOptionSelected' : 'settingsBillingPlanOption'} onClick={() => { setSelectedPlan('pro') }}>
                    <div className='settingsBillingPlanTopRow'>
                      <div className='settingsBillingPlanOptionLogo'><Plan2Icon /></div>
                      <div className='settingsBillingPlanOptionTopRightContainer'>
                        <span className='settingsBillingPlanOptionTitle'>Pro</span>
                        <span className='setingsBillingPlanOptionPrice'>$539 /mo</span>
                      </div>
                    </div>
                    <div className='settingsBillingPlan'>
                      <span className='settingsBillingPlanFeaturesSectionTitle'>Key Features</span>
                      <span className='settingsBillingPlanFeature'>- Profit Dashboard</span>
                      <span className='settingsBillingPlanFeature'>- Customer Journey</span>
                      <span className='settingsBillingPlanFeature'>- Conversion API</span>

                      <span className='settingsBillingPlanFeaturesSectionTitle' style={{marginTop: 20}}>Additional Support</span>
                      <span className='settingsBillingPlanFeature'>- Email & Live Chat Support</span>
                      <span className='settingsBillingPlanFeature'>- Implementation Specialist</span>
                      <span className='settingsBillingPlanFeature'>- Access to Customer Success Team</span>
                      <span className='settingsBillingPlanFeature'>- Strategic Consulting</span>
                    </div>
                  </div>

                  <div className={selectedPlan == 'premium' ? 'settingsBillingPlanOptionSelected' : 'settingsBillingPlanOption'} onClick={() => { setSelectedPlan('premium') }}>
                    <div className='settingsBillingPlanTopRow'>
                      <div className='settingsBillingPlanOptionLogo'><Plan3Icon /></div>
                      <div className='settingsBillingPlanOptionTopRightContainer'>
                        <span className='settingsBillingPlanOptionTitle'>Premium</span>
                        <span className='setingsBillingPlanOptionPrice'>$647 /mo</span>
                      </div>
                    </div>
                    <div className='settingsBillingPlan'>
                      <span className='settingsBillingPlanFeaturesSectionTitle'>Key Features</span>
                      <span className='settingsBillingPlanFeature'>- Profit Dashboard</span>
                      <span className='settingsBillingPlanFeature'>- Customer Journey</span>
                      <span className='settingsBillingPlanFeature'>- Conversion API</span>
                      <span className='settingsBillingPlanFeature'>- Piority Support</span>

                      <span className='settingsBillingPlanFeaturesSectionTitle' style={{marginTop: 20}}>Additional Support</span>
                      <span className='settingsBillingPlanFeature'>- Email & Live Chat Support</span>
                      <span className='settingsBillingPlanFeature'>- Implementation Specialist</span>
                      <span className='settingsBillingPlanFeature'>- Access to Customer Success Team</span>
                      <span className='settingsBillingPlanFeature'>- Strategic Consulting</span>
                    </div>
                  </div>

                </div>





                <div className='settingsBillingGrid'>

                  <div className='settingsBillingActivitySection'>
                    <span className='settingsBillingSectionTitle'>Activity</span>
                    <div className='settingsBillingActivityContent'>
                      There is no payment history yet.
                    </div>
                  </div>

                  

                  <div className='settingsPaymentMethodsSection'>
                    <span className='settingsBillingNewCardButton' onClick={() => { setShowNewPaymentMethodPopup(true) }}><AddRounded /> New Card</span>
                    <span className='settingsBillingSectionTitle'>Payment Method</span>
                    <div className='settingsBillingPaymentMethods'>
                      <div className='settingsBillingPaymentMethod'>
                        <div className='settingsBillingPaymentMethodMoreIcon'><MoreVertRounded /></div>
                        <span className='settingsBillingPaymentMethodSelected'>Default</span>
                        <div className='settingsBillingPaymentMethodCreditCardLogo'>{cardIssuer == 'Visa' ? <VisaLogo /> : <MastercardLogo />}</div>
                        <span className='settingsBillingPaymentMethodNumber'>**** **** **** 1234</span>
                      </div>
                      <div className='settingsBillingPaymentMethod'>
                        <div className='settingsBillingPaymentMethodMoreIcon'><MoreVertRounded /></div>
                        <div className='settingsBillingPaymentMethodCreditCardLogo'>{cardIssuer == 'Visa' ? <VisaLogo /> : <MastercardLogo />}</div>
                        <span className='settingsBillingPaymentMethodNumber'>**** **** **** 1234</span>
                      </div>

                    </div>
                  </div>
                
                </div>

              </div>
            }












            {selectedSection == 'adAccounts' && (
              <div className='settingsSectionAdAccountContainer'>

                <div className='settingsAdAccountBalancesSectionTitle'>Ad Account Balances</div>
                <div className='settingsAdAccountBalancesSectionDescription'>Here you can find the balances of all your connected ad accounts. Note that balances will only appear on prepaid accounts, not applicable to accounts post paid with credit card.</div>

                <div className='settingsSectionAdAccountBalancesTable'>
                  {/* <div className='settingsAdAccountRow' style={{fontWeight: 600}}>
                    
                    <div className='settingsAdAccountRowName'>
                      <div className='settingsAdAccountRowLogo'></div>
                      Account Name
                    </div>
      
                    <span className='settingsAdAccountRowBalance'>Remaining Credit</span>
                  </div> */}
                  {accountBalances.map((account, index) => (
                    <div className='settingsAdAccountRow' key={index}>
                      
                      <div className='settingsAdAccountRowName'>
                        {account.platform == 'facebook' && <div className='settingsAdAccountRowLogoContainer'><img src={Meta_logo} className='settingsAdAccountRowLogo' /></div>}
                        {account.platform == 'tiktok' && <div className='settingsAdAccountRowLogoContainer'><img src={tiktok_logo_white_background} className='settingsAdAccountRowLogo' /></div>}
                        {account.platform == 'google' && <div className='settingsAdAccountRowLogoContainer'><img src={google_ads_logo} className='settingsAdAccountRowLogo' /></div>}
                        {account.name}
                      </div>
                      {/* <span className='settingsAdAccountRowBalance'>{ new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(account.balance / 100) }</span>
                      <span className='settingsAdAccountRowBalance'>{ new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(account.spend_cap / 100 ) }</span> */}
                      {account.platform == 'facebook' && <span className='settingsAdAccountRowBalance'>{ new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format((account.spend_cap - account.balance) / 100 ) }</span>}
                      {account.platform == 'tiktok' && <span className='settingsAdAccountRowBalance'>{ new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format((account.balance) / 100 ) }</span>}
                      {account.platform == 'google' && <span className='settingsAdAccountRowBalance'>{ new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format((account.balance) / 100 ) }</span>}
                      </div>
                  ))}
                </div>












                <div className='settingsAdAccountBalancesSectionTitle' style={{marginTop: 100}}>Agency Ad Account Requests</div>
                <div className='settingsAdAccountBalancesSectionDescription'>Here you can request an ad account to be added to your business managers as well as see pending requests. Note all requests can take up to 48 hours to be reviewed.</div>

                <div className='settingsAdAccountRequestContainer'>
                  {/* <span className='settingsAdAccountRequestButton' onClick={() => {setShowRequestAdAccountPopup(true)}}>Request an Agency Account</span> */}


                  {adAccountRequests.map((x, index) => (
                    <div className='settingsAdAccountRequestRow' key={index}>
                      {x.status == 'pending' && <div className='settingsAdAccountRequestRowTrashContainer' onClick={() => {deleteRequest(x.requestID)}}><CloseRounded style={{width: 20, height: 20}}/></div>}
                        {x.platform == 'facebook' && <div className='settingsAdAccountRequestRowLogoContainer'><img src={Meta_logo} className='settingsAdAccountRequestRowLogo' /></div>}
                        {x.platform == 'tiktok' && <div className='settingsAdAccountRequestRowLogoContainer'><img src={tiktok_logo_white_background} className='settingsAdAccountRequestRowLogo' /></div>}
                        {x.platform == 'google' && <div className='settingsAdAccountRequestRowLogoContainer'><img src={google_ads_logo} className='settingsAdAccountRequestRowLogo' /></div>}
                        {x.platform == 'snapchat' && <div className='settingsAdAccountRequestRowLogoContainer'><img src={snapchat_logo} className='settingsAdAccountRequestRowLogo' /></div>}
                    

                      <div>
                        <span className='settingsAdAccountRequestRowTitle'>{x.platform} Account</span>
                        <span className='settingsAdAccountRequestRowTimestamp'>{timeAgo(x.createdAt?.seconds * 1000)}</span>
                        <span className='settingsAdAccountRequestRowStatus'>{x.status}</span>
                        {/* <div className='settingsAdAccountRequestRowPerksRow'>
                          <span className='settingsAdAccountRequestRowPerk'>1-3.5% Cashback</span>
                          <span className='settingsAdAccountRequestRowPerk'>24 Hour Top-ups</span>
                          <span className='settingsAdAccountRequestRowPerk'>Credit Card Prepay</span>
                        </div> */}
                      </div>
                    </div>
                  ))}

             
                  <div className='settingsAdAccountRequestAdAccountPopupPlatformRadioFormContainerRow'>
                    <div className='settingsAdAccountRequestAdAccountPopupPlatformRadioFormLeft'>
                      New Request
                    </div>
                    <div className='settingsAdAccountRequestAdAccountPopupPlatformRadioFormContainer'>
                      <div className='settingsAdAccountRequestAdAccountPopupPlatformRadioRow' onClick={() => {setAdAccountRequestPlatform('facebook')}}>
                        <div className={adAccountRequestPlatform == 'facebook' ? 'settingsAdAccountRequestAdAccountPopupPlatformRadioInputSelected' : 'settingsAdAccountRequestAdAccountPopupPlatformRadioInput'}></div>
                        <div className='settingsAdAccountRequestAdAccountPopupPlatformRadioLabel'>Facebook</div>
                      </div>
                      <div className='settingsAdAccountRequestAdAccountPopupPlatformRadioRow' onClick={() => {setAdAccountRequestPlatform('google')}}>
                        <div className={adAccountRequestPlatform == 'google' ? 'settingsAdAccountRequestAdAccountPopupPlatformRadioInputSelected' : 'settingsAdAccountRequestAdAccountPopupPlatformRadioInput'}></div>
                        <div className='settingsAdAccountRequestAdAccountPopupPlatformRadioLabel'>Google</div>
                      </div>
                      <div className='settingsAdAccountRequestAdAccountPopupPlatformRadioRow' onClick={() => {setAdAccountRequestPlatform('tiktok')}}>
                        <div className={adAccountRequestPlatform == 'tiktok' ? 'settingsAdAccountRequestAdAccountPopupPlatformRadioInputSelected' : 'settingsAdAccountRequestAdAccountPopupPlatformRadioInput'}></div>
                        <div className='settingsAdAccountRequestAdAccountPopupPlatformRadioLabel'>TikTok</div>
                      </div>
                      <div className='settingsAdAccountRequestAdAccountPopupPlatformRadioRow' onClick={() => {setAdAccountRequestPlatform('snapchat')}}>
                        <div className={adAccountRequestPlatform == 'snapchat' ? 'settingsAdAccountRequestAdAccountPopupPlatformRadioInputSelected' : 'settingsAdAccountRequestAdAccountPopupPlatformRadioInput'}></div>
                        <div className='settingsAdAccountRequestAdAccountPopupPlatformRadioLabel'>Snapchat</div>
                      </div>
                      <span className='settingsAdAccountRequestAdAccountPopupPlatformSubmitButton' onClick={() => {saveAdAccountRequest()}}>Submit Request</span>
                    </div>

                  </div>
                  



                  <div className='settingsAdAccountRequestAdAccountPopupPlatformDetailsContainer'>
                        {adAccountRequestPlatform == 'facebook' && 
                          <div>
                            <span className='settingsAdAccountRequestAdAccountPopupPlatformDetailsTitle'>Facebook Agency Account</span>

                            <div className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkRow'> 
                              <span className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkTitle'>24 Hour Top-ups</span>
                              <span className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkDescription'>Credit your account quickly anytime</span>
                            </div>
                            <div className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkRow'> 
                              <span className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkTitle'>Ban protection</span>
                              <span className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkDescription'>Your ad accounts are much stronger against suspensions and disables</span>
                            </div>
                            <div className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkRow'> 
                              <span className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkTitle'>Lower CPMs</span>
                              <span className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkDescription'>Although not guaranteed, CPMs are seen to be lower on these ad accounts</span>
                            </div>
                            <div className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkRow'> 
                              <span className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkTitle'>1.0% Cashback</span>
                              <span className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkDescription'>If you spend between $30k-150k in a calendar month</span>
                            </div>
                            <div className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkRow'> 
                              <span className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkTitle'>1.5% Cashback</span>
                              <span className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkDescription'>If you spend between $150k-300k in a calendar month</span>
                            </div>
                            <div className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkRow'> 
                              <span className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkTitle'>2.0% Cashback</span>
                              <span className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkDescription'>If you spend between $300k-500k in a calendar month</span>
                            </div>
                            <div className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkRow'> 
                              <span className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkTitle'>2.5% Cashback</span>
                              <span className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkDescription'>If you spend between $500k-1M in a calendar month</span>
                            </div>
                            <div className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkRow'> 
                              <span className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkTitle'>3.0% Cashback</span>
                              <span className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkDescription'>If you spend between $1M-3M in a calendar month</span>
                            </div>
                            <div className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkRow'> 
                              <span className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkTitle'>3.5% Cashback</span>
                              <span className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkDescription'>If you spend $3M+ in a calendar month</span>
                            </div>

                            <div className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkRow'> 
                              <span className='settingsAdAccountRequestAdAccountPopupPlatformDetailsDrawbackTitle'>Prepay Only</span>
                              <span className='settingsAdAccountRequestAdAccountPopupPlatformDetailsPerkDescription'>You must credit the account before you can spend on it</span>
                            </div>

                          </div>
                        }
                        {adAccountRequestPlatform == 'google' && 
                          <div>
                            <span className='settingsAdAccountRequestAdAccountPopupPlatformDetailsTitle'>Google Agency Account</span>
                            <span className='settingsAdAccountRequestAdAccountPopupPlatformDetailsDescription'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget</span>
                          </div>
                        }
                        {adAccountRequestPlatform == 'tiktok' && 
                          <div>
                            <span className='settingsAdAccountRequestAdAccountPopupPlatformDetailsTitle'>Tiktok Agency Account</span>
                            <span className='settingsAdAccountRequestAdAccountPopupPlatformDetailsDescription'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget</span>
                          </div>
                        }
                        {adAccountRequestPlatform == 'snapchat' && 
                          <div>
                            <span className='settingsAdAccountRequestAdAccountPopupPlatformDetailsTitle'>Snapchat Agency Account</span>
                            <span className='settingsAdAccountRequestAdAccountPopupPlatformDetailsDescription'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget</span>
                          </div>
                        }
                      </div>

                </div>


               

              </div>
            )}

















            {selectedSection == 'notifications' &&
              <div className='settingsSectionNotificationsContainer'>


                <div className='settingsNotificationsSection'>
                  <span className='settingsNotificationsSectionTitle'>Summaries</span>
                  <span className='settingsNotificationsSectionTitleDescription'>Notifications will be sent to {email}</span>
                  <div className='settingsNotificationsSectionRow'>
                    <Switch
                      onChange={(checked) => setDailySummaryNotifications(checked)}
                      checked={dailySummaryNotifications}
                      offColor="#d9d9d9" onColor="#635BFF" uncheckedIcon={false} checkedIcon={false} 
                      width={35}
                      height={20}
                      handleDiameter={15}
                    />
                    <span className='settingsNotificationsSectionDescription' style={{color: dailySummaryNotifications && '#000'}}>Receive daily summaries of your store's performance</span>
                  </div>
                  <div className='settingsNotificationsSectionRow'>
                    <Switch
                      onChange={(checked) => setWeeklySummaryNotifications(checked)}
                      checked={weeklySummaryNotifications}
                      offColor="#d9d9d9" onColor="#635BFF" uncheckedIcon={false} checkedIcon={false}
                      width={35}
                      height={20}
                      handleDiameter={15}
                    />
                    <span className='settingsNotificationsSectionDescription' style={{color: weeklySummaryNotifications && '#000'}}>Receive weekly summaries of your store's performance</span>
                  </div>
                  <div className='settingsNotificationsSectionRow'>
                    <Switch
                      onChange={(checked) => setMonthlySummaryNotifications(checked)}
                      checked={monthlySummaryNotifications}
                      offColor="#d9d9d9" onColor="#635BFF" uncheckedIcon={false} checkedIcon={false}
                      width={35}
                      height={20}
                      handleDiameter={15}
                    />
                    <span className='settingsNotificationsSectionDescription' style={{color: monthlySummaryNotifications && '#000'}}>Receive monthly summaries of your store's performance</span>
                  </div>
                </div>




                <div className='settingsNotificationsSection'>
                  <span className='settingsNotificationsSectionTitle'>Changes</span>
                  <div className='settingsNotificationsSectionRow'>
                    <Switch
                      onChange={(checked) => setNewAdNotifications(checked)}
                      checked={newAdNotifications}
                      offColor="#d9d9d9" onColor="#635BFF" uncheckedIcon={false} checkedIcon={false}
                      width={35}
                      height={20}
                      handleDiameter={15}
                    />
                    <span className='settingsNotificationsSectionDescription' style={{color: newAdNotifications && '#000'}}>Receive updates for new ads</span>
                  </div>
                  <div className='settingsNotificationsSectionRow'>
                    <Switch
                      onChange={(checked) => setAdChangesNotifications(checked)}
                      checked={adChangesNotifications}
                      offColor="#d9d9d9" onColor="#635BFF" uncheckedIcon={false} checkedIcon={false}
                      width={35}
                      height={20}
                      handleDiameter={15}
                    />
                    <span className='settingsNotificationsSectionDescription' style={{color: adChangesNotifications && '#000'}}>Receive updates for ad changes</span>
                  </div>
                  <div className='settingsNotificationsSectionRow'>
                    <Switch
                      onChange={(checked) => setNewOrderNotifications(checked)}
                      checked={newOrderNotifications}
                      offColor="#d9d9d9" onColor="#635BFF" uncheckedIcon={false} checkedIcon={false}
                      width={35}
                      height={20}
                      handleDiameter={15}
                    />
                    <span className='settingsNotificationsSectionDescription' style={{color: newOrderNotifications && '#000'}}>Receive updates for new Shopify orders created</span>
                  </div>
                  <div className='settingsNotificationsSectionRow'>
                    <Switch
                      onChange={(checked) => setNewProductNotifications(checked)}
                      checked={newProductNotifications}
                      offColor="#d9d9d9" onColor="#635BFF" uncheckedIcon={false} checkedIcon={false}
                      width={35}
                      height={20}
                      handleDiameter={15}
                    />
                    <span className='settingsNotificationsSectionDescription' style={{color: newProductNotifications && '#000'}}>Receive updates for new Shopify products created</span>
                  </div>
                </div>



                <div className='settingsNotificationsSection'>
                  <span className='settingsNotificationsSectionTitle'>Analytics</span>
                  <div className='settingsNotificationsSectionRow'>
                    <Switch
                      onChange={(checked) => setHighPerformingAdNotifications(checked)}
                      checked={highPerformingAdNotifications}
                      offColor="#d9d9d9" onColor="#635BFF" uncheckedIcon={false} checkedIcon={false}
                      width={35}
                      height={20}
                      handleDiameter={15}
                    />
                    <span className='settingsNotificationsSectionDescription' style={{color: highPerformingAdNotifications && '#000'}}>Receive notifications for high performing ads</span>
                  </div>
                  <div className='settingsNotificationsSectionRow'>
                    <Switch
                      onChange={(checked) => setLowPerformingAdNotifications(checked)}
                      checked={lowPerformingAdNotifications}
                      offColor="#d9d9d9" onColor="#635BFF" uncheckedIcon={false} checkedIcon={false}
                      width={35}
                      height={20}
                      handleDiameter={15}
                    />
                    <span className='settingsNotificationsSectionDescription' style={{color: lowPerformingAdNotifications && '#000'}}>Receive notifications for low performing ads</span>
                  </div>
                </div>




                <div className='settingsNotificationsSection'>
                  <span className='settingsNotificationsSectionTitle'>Omni</span>
                  <div className='settingsNotificationsSectionRow'>
                    <Switch
                      onChange={(checked) => setNewOmniBusinessUserNotifications(checked)}
                      checked={newOmniBusinessUserNotifications}
                      offColor="#d9d9d9" onColor="#635BFF" uncheckedIcon={false} checkedIcon={false}
                      width={35}
                      height={20}
                      handleDiameter={15}
                    />
                    <span className='settingsNotificationsSectionDescription' style={{color: newOmniBusinessUserNotifications && '#000'}}>Receive updates for new business users</span>
                  </div>
                  <div className='settingsNotificationsSectionRow'>
                    <Switch
                      onChange={(checked) => setOmniPaymentNotifications(checked)}
                      checked={omniPaymentNotifications}
                      offColor="#d9d9d9" onColor="#635BFF" uncheckedIcon={false} checkedIcon={false}
                      width={35}
                      height={20}
                      handleDiameter={15}
                    />
                    <span className='settingsNotificationsSectionDescription' style={{color: omniPaymentNotifications && '#000'}}>Receive notifications for low performing ads</span>
                  </div>
                </div>



              </div>
            }



















            {selectedSection == 'expenses' &&
              <div className='settingsSectionCustomExpenseContainer'>

                <span className='settingsSectionCustomExpenseAddButton' onClick={() => { setShowNewCustomExpensePopup(true) }}>New Expense</span>

                <table className='settingsSectionCustomExpenseTable'>
                  <thead>
                    <tr>
                      <td>Expense</td>
                      <td>Cost</td>
                      <td>Start Date</td>
                      <td>End Date</td>
                      <td>Recurring</td>
                      <td>Interval</td>
                      <td>Created At</td>
                      <td># of Recurrences</td>
                      <td>Daily Cost</td>
                    </tr>
                  </thead>
                  <tbody>
                    {customExpenses.map((x, index) => { return (
                      <tr>
                        <td>{x.category}</td>
                        <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.cost)}</td>
                        <td>
                          <div>
                            <span className='settingsSectionCustomExpenseDate'>{new Date(x.start_date).toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric'})}</span>
                          </div>
                        </td>
                        <td>
                          <div>
                            {x.end_date ? <span className='settingsSectionCustomExpenseDate'>{new Date(x.end_date).toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric'})}</span> : ''}
                          </div>
                        </td>
                        <td>{x.is_recurring ? 'Yes' : 'No'}</td>
                        <td style={{textTransform: 'capitalize'}}>{x.recurrence_interval}</td>
                        <td>
                          <div>
                            <span className='settingsSectionCustomExpenseDate'>{new Date(x.created_at).toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric'})}</span>
                            <span className='settingsSectionCustomExpenseTime'>{new Date(x.created_at).toLocaleString('en-US', { hour: 'numeric', minute: '2-digit' })}</span>
                          </div>
                        </td>
                        <td>{x.recurrence_count}</td>
                        <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.daily_cost)}</td>
                      </tr> 
                    )})}
                  </tbody>
                </table>

              </div>
            }














            {selectedSection == 'invitations' &&
              <div className='settingsSectionBusinessContainer' style={{width: '90%'}}>

                {invitationsFromBusinesses.map((x, index) => {
                  return (
                    <div className='settingsBusinessEmployeeRow' style={{borderBottom: invitationsFromBusinesses[index + 1] ? '1px solid #e7e7e7' : 'none'}}>
                      <div className='settingsBusinessEmployeeRowLeft'>
                        <div className='settingsBusinessEmployeeRowAvatar' style={{color: x.hexCode, backgroundColor: x.hexCode + '30'}}>{x.firstName?.charAt(0)}{x.lastName?.charAt(0)}</div>
                        <div>
                          <span className='settingsBusinessEmployeeRowName'>{x.businessName}</span>
                          <span className='settingsBusinessEmployeeRowEmail'>{x.invitationID}</span>
                        </div>
                        <span className='settingsBusinessEmployeeRowInviteStatus'>Pending</span>
                      </div>
                      {/* 
                      <div className='settingsBusinessEmployeeRowRight'>
                        <div className='settingsBusinessEmployeeDropdownButtonText'>
                          <span>{x.role || ""}</span>
                        </div>
                      </div>
                      */}
                      <span className='settingsBusinessEmployeeRowInviteAccept' onClick={() => {acceptBusinessInvitation(x)}}>Accept</span>
                      <span className='settingsBusinessEmployeeRowInviteDecline' onClick={() => {deletePendingInvite(x)}}>Decline</span>
                    </div>
                  )
                })}

                {invitationsFromBusinesses.length == 0 &&
                  <span className='settingsBusinessEmployeeRowEmail'>No pending invitations</span>
                }

              </div>
            }














            {selectedSection == 'users' &&
                <div className='settingsSectionBusinessContainer'>
                  <span className='settingsSectionBusinessContainerTitle'>Employees</span>
                  <span className='settingsBusiessEmployeesSendInviteButton' onClick={() => { setShowInvitePermissionsPopup(true); }}> Invite User </span>
                  <div className='settingsBusinessEmployees'>
                  {pendingInvites.map((x, index) => {
                      return (
                        <div className='settingsBusinessEmployeeRow' style={{borderBottom: '1px solid #e7e7e7'}}>
                          <div className='settingsBusinessEmployeeRowLeft'>
                            <div className='settingsBusinessEmployeeRowAvatar' style={{color: x.hexCode, backgroundColor: x.hexCode + '30'}}>{x.firstName?.charAt(0)}{x.lastName?.charAt(0)}</div>
                            <div>
                              <span className='settingsBusinessEmployeeRowName'>{x.firstName} {x.lastName}</span>
                              <span className='settingsBusinessEmployeeRowEmail'>{x.email}</span>
                            </div>
                            <span className='settingsBusinessEmployeeRowInviteStatus'>Pending</span>
                          </div>
                          <div className='settingsBusinessEmployeeRowRight'>
                            <div className='settingsBusinessEmployeeDropdownButtonText' onClick={() => { 
                              if ((x.businessPermissions?.find(permission => permission.businessID === bid) || {}).role !== "owner") {
                                setShowEmployeeRoleDropdown(showEmployeeRoleDropdown == x.email ? false : x.email) }
                              }}
                            >
                              <span>{x.role || ""}</span>
                              <KeyboardArrowDownRounded style={{transform: showEmployeeRoleDropdown == x.email ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease-in-out'}}/>
                              </div>
                            {showEmployeeRoleDropdown == x.email && (
                              <div className='settingsBusinessEmployeeDropdownContainer'>
                                {/* <span className='settingsBusinessEmployeeDropdownRow' onClick={() => { setEmployeeRoleChangeValue("owner"); setShowEmployeeRoleDropdown(false)}}>Owner</span> */}
                                <span className='settingsBusinessEmployeeDropdownRow' onClick={() => { setEmployeeRoleChangeValue("admin"); setShowEmployeeRoleDropdown(false); changeUserPermissions(x, 'admin') }}>Admin</span>
                                <span className='settingsBusinessEmployeeDropdownRow' onClick={() => { setEmployeeRoleChangeValue("employee"); setShowEmployeeRoleDropdown(false); changeUserPermissions(x, 'employee') }}>Employee</span>
                              </div>
                            )}
                          </div>
                          <DeleteRounded className='settingsBusinessEmployeeDeleteIcon' style={{color: '#666', cursor: 'pointer'}} onClick={() => { deletePendingInvite(x) }}/>
                        </div>
                      )
                    })}

                    {employees.map((x, index) => {
                      return (
                        <div className='settingsBusinessEmployeeRow' style={{borderBottom: employees[index + 1] ? '1px solid #e7e7e7' : 'none'}}>
                          <div className='settingsBusinessEmployeeRowLeft'>
                            <div className='settingsBusinessEmployeeRowAvatar' style={{color: x.hexCode, backgroundColor: x.hexCode + '30'}}>{x.firstName?.charAt(0)}{x.lastName?.charAt(0)}</div>
                            <div>
                              <span className='settingsBusinessEmployeeRowName'>{x.firstName} {x.lastName}</span>
                              <span className='settingsBusinessEmployeeRowEmail'>{x.email}</span>
                            </div>
                            {x.inviteToken && <span className='settingsBusinessEmployeeRowInviteStatus'>Pending</span>}
                          </div>
                          <div className='settingsBusinessEmployeeRowRight'>
                            <div className='settingsBusinessEmployeeDropdownButtonText' onClick={() => { 
                              if ((x.businessPermissions?.find(permission => permission.businessID === bid) || {}).role !== "owner") {
                                setShowEmployeeRoleDropdown(showEmployeeRoleDropdown == x.email ? false : x.email) }
                              }}
                            >
                              <span>{(x.businessPermissions?.find(permission => permission.businessID === bid) || {}).role || ""}</span>
                              {((x.businessPermissions?.find(permission => permission.businessID === bid) || {}).role !== "owner") && <KeyboardArrowDownRounded style={{transform: showEmployeeRoleDropdown == x.email ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease-in-out'}}/>}
                              </div>
                            {showEmployeeRoleDropdown == x.email && (
                              <div className='settingsBusinessEmployeeDropdownContainer'>
                                {/* <span className='settingsBusinessEmployeeDropdownRow' onClick={() => { setEmployeeRoleChangeValue("owner"); setShowEmployeeRoleDropdown(false)}}>Owner</span> */}
                                <span className='settingsBusinessEmployeeDropdownRow' onClick={() => { setEmployeeRoleChangeValue("admin"); setShowEmployeeRoleDropdown(false); changeUserPermissions(x, 'admin') }}>Admin</span>
                                <span className='settingsBusinessEmployeeDropdownRow' onClick={() => { setEmployeeRoleChangeValue("employee"); setShowEmployeeRoleDropdown(false); changeUserPermissions(x, 'employee') }}>Employee</span>
                              </div>
                            )}
                          </div>
                          {((x.businessPermissions?.find(permission => permission.businessID === bid) || {}).role !== "owner") && (
                            <div className='settingsBusinessEmployeeDeleteIcon'><DeleteRounded style={{height: 20, width: 20}} onClick={() => { removeUserFromBusiness(x) }}/></div>
                          )}
                        </div>
                      )
                    })}
                  </div>
                </div>
            }


          </div>
        </div>


          { (firstName !== firstNameDB || lastName !== lastNameDB || phone !== phoneDB || email !== emailDB || address !== addressDB || city !== cityDB || state !== stateDB || zip !== zipDB) &&
          <div className='SettingsBottomRow'>
            <div className='SettingsBottomRowButton' onClick={() => {updateBusinessInfo()}}>
              <span className='SettingsBottomRowButtonText'>Save Changes</span>
              <span className='SettingsBottomRowButtonKeys' ><span style={{lineHeight: '15px', paddingTop: 1}}>&#8984;</span></span>
              <span className='SettingsBottomRowButtonKeys' style={{marginLeft: 3}}>return</span>
            </div>
          </div>
          }



          {showNewPaymentMethodPopup && (
            <div className='settingsNewPaymentMethodPopupBackground'>
              <div className='settingsNewPaymentMethodPopup'>
                <span className='settingsNewPaymentMethodPopupTitle'>New Card</span>
                <div className='settingsNewPaymentMethodPopupBody'>
                
                  <TextInput placeholder='Card Number' type='text' value={cardNumber} onChange={setCardNumber} widthPercent={100} widthPixels={0} marginBottom={20} />
                  <TextInput placeholder='Card Holder' type='text' value={cardHolder} onChange={setCardHolder} widthPercent={100} widthPixels={0} marginBottom={20} />
                  <div className='settingsNewPaymentMethodPopupExpAndCVVRow'>
                    <TextInput placeholder='Expiration Date' type='text' value={cardExp} onChange={setCardExp} widthPercent={48} widthPixels={0} marginBottom={20} />
                    <TextInput placeholder='CVV/CVC' type='text' value={cardCVV} onChange={setCardCVV} widthPercent={48} widthPixels={0} marginBottom={20} />
                  </div>
                  <span className='settingsNewPaymentMethodPopupSSLMessage'><LockRounded style={{width: 16, paddingRight: 5, paddingBottom: 2}}/> Your transaction is secured with SSL encryption</span>

                </div>
                <div className='settingsNewPaymentMethodPopupFooter'>
                  <span className='settingsNewPaymentMethodPopupCancelButton' onClick={() => { setShowNewPaymentMethodPopup(false) }}>Cancel</span>
                  <span className='settingsNewPaymentMethodPopupAddButton'>Add</span>
                </div>
              </div>
            </div>
          )}





          {showInvitePermissionsPopup && (
            <div className="settingsInviteUserPopupBackground">
              <div className="settingsInviteUserPopup" >
                <span className='settingsInviteUserPopupTitle'>Send Invite</span>
                <div className='settingsInviteUserPopupBody'>

                  <TextInput placeholder='Email' type='text' value={inviteEmail} onChange={setInviteEmail} widthPercent={100} widthPixels={0} marginBottom={20} />
                  <TextInput placeholder='First Name' type='text' value={inviteFirstName} onChange={setInviteFirstName} widthPercent={100} widthPixels={0} marginBottom={20} />
                  <TextInput placeholder='Last Name' type='text' value={inviteLastName} onChange={setInviteLastName} widthPercent={100} widthPixels={0} marginBottom={20} />

                  <ClickAwayListener onClickAway={() => setShowInvitePermissionsDropdown(false)}>
                    <div className='SettingsInviteUserDropdownButton' style={{width: 200}} onClick={() => { setShowInvitePermissionsDropdown(!showInvitePermissionsDropdown) }}>
                      <div className='SettingsInviteUserDropdownButtonText'>
                        <span>{selectedInvitePermissions}</span>
                      </div>
                      <KeyboardArrowDownRounded style={{transform: showInvitePermissionsDropdown ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease-in-out'}}/>
                      {showInvitePermissionsDropdown && (
                        <div className='SettingsInviteUserDropdownContainer'>
                          {/* <span className={selectedInvitePermissions == 'owner' ? 'SettingsInviteUserDropdownRowSelected' : 'SettingsInviteUserDropdownRow'} onClick={() => { setShowInvitePermissionsDropdown(false); setSelectedInvitePermissions('owner') }}>Owner</span> */}
                          <span className={selectedInvitePermissions == 'admin' ? 'SettingsInviteUserDropdownRowSelected' : 'SettingsInviteUserDropdownRow'} onClick={() => { setShowInvitePermissionsDropdown(false); setSelectedInvitePermissions('admin') }}>Admin</span>
                          <span className={selectedInvitePermissions == 'employee' ? 'SettingsInviteUserDropdownRowSelected' : 'SettingsInviteUserDropdownRow'} onClick={() => { setShowInvitePermissionsDropdown(false); setSelectedInvitePermissions('employee') }}>Employee</span>
                        </div>
                      )}
                    </div>
                  </ClickAwayListener>

                </div>
                <div className='settingsInviteUserPopupFooter'>
                  <span className='settingsInviteUserPopupCancelButton' onClick={() => { setShowInvitePermissionsPopup(false) }}>Cancel</span>
                  <span className='settingsInviteUserPopupAddButton' onClick={() => { sendInviteEmail(); setShowInvitePermissionsPopup(false); setInviteEmail(''); setInviteFirstName(''); setInviteLastName(''); setSelectedInvitePermissions('employee');  }}>Send Invite</span>
                </div>
              </div>
            </div>
          )}












          {showNewCustomExpensePopup && (
            <div className='settingsNewCustomExpensePopupBackground'>
              <div className='settingsNewCustomExpensePopup'>
                <span className='settingsNewCustomExpensePopupTitle'>New Expense</span>
                <div className='settingsNewCustomExpensePopupBody'>
                
                  <TextInput placeholder='Expense Name' type='text' value={customExpenseName} onChange={setCustomExpenseName} widthPercent={100} widthPixels={0} marginBottom={20} />
                  <TextInput placeholder='Cost' type='text' value={customExpenseCost} onChange={setCustomExpenseCost} widthPercent={100} widthPixels={0} marginBottom={20} />
                  
                  
                  
                  <div className='settingsNewCustomExpensePopupRow' style={{paddingBottom: 15}}>
                    <Switch
                        onChange={() => { setCustomExpenseIsRecurring(!customExpenseIsRecurring) }}
                        checked={customExpenseIsRecurring}
                        offColor="#d9d9d9"
                        onColor="#0874fc"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        width={45}
                        height={25}
                        handleDiameter={20}
                      />
                    <span className='settingsNewCustomExpensePopupRowSwitchDescription'>Recurring Expense</span>
                  </div>




                  {customExpenseIsRecurring && (
                    <div className='settingsNewCustomExpensePopupRow'>
                      <TextInput placeholder='# of intervals' type='text' value={customExpenseRecurringCount} onChange={setCustomExpenseRecurringCount} widthPercent={80} widthPixels={0} marginBottom={20} />

                      {/* RECURRING INTERVAL DROPDOWN */}
                      <ClickAwayListener onClickAway={() => setShowCustomExpensesIntervalDropdown(false)}>
                        <div className='SettingsNewCustomExpensePopupDropdownButton' style={{width: 200}} onClick={() => { setShowCustomExpensesIntervalDropdown(!showCustomExpensesIntervalDropdown) }}>
                          <div className='SettingsNewCustomExpensePopupDropdownButtonText'>
                            <span>{selectedCustomExpenseRecurringInterval}</span>
                          </div>
                          <KeyboardArrowDownRounded style={{transform: showCustomExpensesIntervalDropdown ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease-in-out'}}/>
                          {showCustomExpensesIntervalDropdown && (
                            <div className='SettingsNewCustomExpensePopupDropdownContainer'>
                              <span className={selectedCustomExpenseRecurringInterval == 'monthly' ? 'SettingsNewCustomExpensePopupDropdownRowSelected' : 'SettingsNewCustomExpensePopupDropdownRow'} onClick={() => { setShowCustomExpensesIntervalDropdown(false); setSelectedCustomExpenseRecurringInterval('monthly') }}>Monthly</span>
                              <span className={selectedCustomExpenseRecurringInterval == 'weekly' ? 'SettingsNewCustomExpensePopupDropdownRowSelected' : 'SettingsNewCustomExpensePopupDropdownRow'} onClick={() => { setShowCustomExpensesIntervalDropdown(false); setSelectedCustomExpenseRecurringInterval('weekly') }}>Weekly</span>
                              <span className={selectedCustomExpenseRecurringInterval == 'daily' ? 'SettingsNewCustomExpensePopupDropdownRowSelected' : 'SettingsNewCustomExpensePopupDropdownRow'} onClick={() => { setShowCustomExpensesIntervalDropdown(false); setSelectedCustomExpenseRecurringInterval('daily') }}>Daily</span>
                            </div>
                          )}
                        </div>
                      </ClickAwayListener>
                    </div>
                  )}


                  
                  
                  <div className='settingsNewCustomExpensePopupRow'>
                    <ClickAwayListener onClickAway={() => setShowDateSelector(false)}>
                      <div className={'SettingsNewCustomExpensePopupDateSelectorButton'} style={{width: 220}} onClick={() => { showDateSelector == false && setShowDateSelector(true) }}>
                        <div className='SettingsNewCustomExpensePopupDateSelectorButtonText'>
                        <span>
                          {formatDate(newExpenseDateStart) !== formatDate(newExpenseDateEnd)
                            ? formatDate(newExpenseDateStart) + " - " + formatDate(newExpenseDateEnd)
                            : formatDate(newExpenseDateStart)}
                        </span>
                        </div>
                        <CalendarIcon style={{paddingLeft: 10, color: '#666'}}/>
                        {showDateSelector && (
                          <div className='SettingsNewCustomExpensePopupDateSelectorContainer'>
                            <DateSelector setShowDateSelectors={setShowDateSelector} showDateSelectors={showDateSelector} 
                              dateStart={newExpenseDateStart} setDateStart={setNewExpenseDateStart} dateEnd={newExpenseDateEnd} setDateEnd={setNewExpenseDateEnd}
                              dateUpdateTrigger={dateUpdateTrigger} setDateUpdateTrigger={setDateUpdateTrigger} onlyShowOneMonth={true} hidePresets={true} showFutureDates={true}/>
                          </div>
                        )}
                      </div>
                    </ClickAwayListener>
                  </div>                
                
                
                  


                </div>
                <div className='settingsNewPaymentMethodPopupFooter'>
                  <span className='settingsNewPaymentMethodPopupCancelButton' onClick={() => { setShowNewCustomExpensePopup(false) }}>Cancel</span>
                  <span className='settingsNewPaymentMethodPopupAddButton' onClick={() => { addCustomExpense() }}>Add Expense</span>
                </div>
              </div>
            </div>
          )}



    </div>
  );
}

export default Settings;