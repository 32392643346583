


import { AddchartRounded, DashboardRounded, DeleteForeverRounded, DensitySmallRounded, EditRounded, ExpandMoreRounded, LegendToggleRounded, LineAxisRounded, SaveOutlined, ShareRounded, ShowChartRounded, StackedLineChartRounded, StarRounded, WidthWideRounded } from '@mui/icons-material';
import { ClickAwayListener } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TextInput from '../../components/TextInput';
import shopify_logo from '../../assets/shopify_logo.png';
import Meta_logo from '../../assets/Meta_logo.png';
import tiktok_logo_white_background from '../../assets/tiktok_logo_white_background.png';
import google_ads_logo from '../../assets/google_ads_logo.png';
import omni_logo_clear_background from '../../assets/omni_logo_clear_background.png';

function BuildMetricDropdown({showSourcesDropdown, setShowSourcesDropdown, metrics, selectedMetric, setSelectedMetric, dataVariableIndex, metricNames, lineIndex}) {
    const [searchText, setSearchText] = useState('');  
    const [sortedMetrics, setSortedMetrics] = useState([]);
    const [sortedAndFilteredMetrics, setSortedAndFilteredMetrics] = useState([]);


    useEffect(() => {
        const sortedMetricsTemp = metrics.sort((a, b) => {
            if (a.source < b.source) {
            return -1;
            }
            if (a.source > b.source) {
            return 1;
            }
            return 0;
        });

        setSortedMetrics(sortedMetricsTemp);
        setSortedAndFilteredMetrics(sortedMetricsTemp);
    }, [metrics]);




      
    
    useEffect(() => {
        //filter metrics based on search text and metric name
        if (searchText == '') {
            setSortedAndFilteredMetrics(sortedMetrics);
        } else {
            const filteredMetrics = metrics.filter((metric) => {
                return metric.name.toLowerCase().includes(searchText.toLowerCase());
            });
            setSortedAndFilteredMetrics(filteredMetrics);
        }

    }, [searchText, sortedMetrics]);
    

    
    
    let lastSource = null;  // Variable to track the previous source
    return (
        <ClickAwayListener onClickAway={() => {if (showSourcesDropdown !== -1) {
            setShowSourcesDropdown(false);
          }}}>
            <div className='BuildMetricDropdownButtonDropdownContainer'>
                <div className='BuildMetricDropdownButtonDropdownContainerButton' onClick={(e) => {e.stopPropagation(); setShowSourcesDropdown(dataVariableIndex)}}>
                    <span style={{paddingRight: 5}}>{metricNames[selectedMetric?.lines[lineIndex]?.dataVariables[dataVariableIndex]] || "Customize"}</span>
                    <div className='BuildMetricDropdownButtonDropdownContainerButtonArrow' style={{transform: (showSourcesDropdown === dataVariableIndex) && 'rotate(180deg)'}}><ExpandMoreRounded style={{height: 20, width: 20}}/></div>
                </div>
                
                {(showSourcesDropdown === dataVariableIndex) && (
                <div className='BuildMetricDropdownButtonDropdownContainerContent'>

                    <TextInput placeholder='Search' type='text' value={searchText} onChange={setSearchText} widthPercent={100} marginBottom={10}/>


                    {sortedAndFilteredMetrics
                    .filter((metric) => metric.premade === true)
                    .map((metric) => {
                        // Check if the source is different from the previous one
                        const isNewSource = metric.source !== lastSource;
                        lastSource = metric.source;  // Update lastSource to the current metric's source

                        return (
                        <div key={metric.key}>
                            {isNewSource && 
                                <div className='BuildMetricDropdownButtonDropdownItemSourceRow'>
                                    <img src={
                                        metric.source === 'shopify'
                                        ? shopify_logo
                                        : metric.source === 'omni'
                                        ? omni_logo_clear_background
                                        : metric.source === 'facebook'
                                        ? Meta_logo
                                        : metric.source === 'google'
                                        ? google_ads_logo
                                        : tiktok_logo_white_background
                                        }
                                        alt={`${metric.source} Logo`}
                                        style={{ width: 20, height: 20, paddingRight: 10 }}
                                    />
                                    <span className='BuildMetricDropdownButtonDropdownItemSourceRowText'>{metric.source}</span>
                                </div>
                            }  
                            <div
                            className='BuildMetricDropdownButtonDropdownItem'
                            onClick={() => { 
                                // setShowSourcesDropdown(-1); 

                                //using setSelectedMetric to replace the current dataVariable with the new dataVariable
                                setSelectedMetric((prev) => {
                                    const newSelectedMetric = { ...prev };
                                
                                    // Get the target line and ensure it exists
                                    const targetLine = newSelectedMetric.lines[lineIndex];
                                    if (!targetLine) return prev;
                                
                                    // Update the data variable
                                    targetLine.dataVariables[dataVariableIndex] = metric.dataVariables[0];
                                
                                    // Rebuild the formula as an array of metrics and operations
                                    const formulaArray = targetLine.dataVariables
                                        .filter((variable) => variable) // Ensure no empty or undefined variables
                                        .flatMap((variable, index, arr) => 
                                            index < arr.length - 1 ? [variable, '+'] : variable
                                        ); // Insert '+' between variables
                                
                                    // Update the formula
                                    targetLine.formula = formulaArray;
                                
                                    return newSelectedMetric;
                                });
                                
                                
                                
                            }}
                            >
                            {metric.name}
                            </div>
                        </div>
                        );
                    })
                    }



        
                </div>
                )}

            </div>
        </ClickAwayListener>
    );
}


export default BuildMetricDropdown;