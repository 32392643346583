import './UserSettings.css';
import { getAuth, signOut } from "firebase/auth";
import react, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import TextInput from '../components/TextInput';
import { collection, doc, getDoc, getDocs, where, query, addDoc, updateDoc } from 'firebase/firestore';
import { db } from '../FirebaseInit';
import Switch from "react-switch";
import { Button, ClickAwayListener } from '@mui/material';
import { KeyboardArrowDownRounded } from '@mui/icons-material';



function UserSettings({serverURL}) {

  const uid = localStorage.getItem('uid');
  const bid = localStorage.getItem('bid');

  const [firstNameDB, setFirstNameDB] = useState('');
  const [lastNameDB, setLastNameDB] = useState('');
  const [emailDB, setEmailDB] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [selectedTimezoneDB, setSelectedTimezoneDB] = useState({});
  const [selectedTimezone, setSelectedTimezone] = useState({});
  const [showTimezoneDropdown, setShowTimezoneDropdown] = useState(false);
  const [userDevices, setUserDevices] = useState([]);
  

  const timezones = [
    { "name": "(GMT+00:00) Africa/Abidjan", "offset": 0 },
    { "name": "(GMT+00:00) Africa/Accra", "offset": 0 },
    { "name": "(GMT+03:00) Africa/Addis_Ababa", "offset": 3 },
    { "name": "(GMT+01:00) Africa/Algiers", "offset": 1 },
    { "name": "(GMT+02:00) Africa/Cairo", "offset": 2 },
    { "name": "(GMT+00:00) Africa/Casablanca", "offset": 0 },
    { "name": "(GMT+02:00) Africa/Johannesburg", "offset": 2 },
    { "name": "(GMT+01:00) Africa/Lagos", "offset": 1 },
    { "name": "(GMT+03:00) Africa/Nairobi", "offset": 3 },
    { "name": "(GMT+02:00) Africa/Tripoli", "offset": 2 },
    { "name": "(GMT-09:00) America/Anchorage", "offset": -9 },
    { "name": "(GMT-03:00) America/Argentina/Buenos_Aires", "offset": -3 },
    { "name": "(GMT-04:00) America/Barbados", "offset": -4 },
    { "name": "(GMT-05:00) America/Bogota", "offset": -5 },
    { "name": "(GMT-04:30) America/Caracas", "offset": -4.5 },
    { "name": "(GMT-06:00) America/Chicago", "offset": -6 },
    { "name": "(GMT-07:00) America/Denver", "offset": -7 },
    { "name": "(GMT-07:00) America/Edmonton", "offset": -7 },
    { "name": "(GMT-06:00) America/Guatemala", "offset": -6 },
    { "name": "(GMT-05:00) America/Havana", "offset": -5 },
    { "name": "(GMT-05:00) America/Jamaica", "offset": -5 },
    { "name": "(GMT-08:00) America/Los_Angeles", "offset": -8 },
    { "name": "(GMT-06:00) America/Mexico_City", "offset": -6 },
    { "name": "(GMT-05:00) America/New_York", "offset": -5 },
    { "name": "(GMT-05:00) America/Panama", "offset": -5 },
    { "name": "(GMT-07:00) America/Phoenix", "offset": -7 },
    { "name": "(GMT-03:00) America/Santiago", "offset": -3 },
    { "name": "(GMT-03:00) America/Sao_Paulo", "offset": -3 },
    { "name": "(GMT-05:00) America/Toronto", "offset": -5 },
    { "name": "(GMT-08:00) America/Vancouver", "offset": -8 },
    { "name": "(GMT+06:00) Asia/Almaty", "offset": 6 },
    { "name": "(GMT+03:00) Asia/Amman", "offset": 3 },
    { "name": "(GMT+03:00) Asia/Baghdad", "offset": 3 },
    { "name": "(GMT+07:00) Asia/Bangkok", "offset": 7 },
    { "name": "(GMT+02:00) Asia/Beirut", "offset": 2 },
    { "name": "(GMT+06:00) Asia/Dhaka", "offset": 6 },
    { "name": "(GMT+04:00) Asia/Dubai", "offset": 4 },
    { "name": "(GMT+08:00) Asia/Hong_Kong", "offset": 8 },
    { "name": "(GMT+07:00) Asia/Jakarta", "offset": 7 },
    { "name": "(GMT+02:00) Asia/Jerusalem", "offset": 2 },
    { "name": "(GMT+05:00) Asia/Karachi", "offset": 5 },
    { "name": "(GMT+05:30) Asia/Kolkata", "offset": 5.5 },
    { "name": "(GMT+08:00) Asia/Kuala_Lumpur", "offset": 8 },
    { "name": "(GMT+03:00) Asia/Riyadh", "offset": 3 },
    { "name": "(GMT+09:00) Asia/Seoul", "offset": 9 },
    { "name": "(GMT+08:00) Asia/Shanghai", "offset": 8 },
    { "name": "(GMT+08:00) Asia/Singapore", "offset": 8 },
    { "name": "(GMT+09:00) Asia/Tokyo", "offset": 9 },
    { "name": "(GMT-01:00) Atlantic/Azores", "offset": -1 },
    { "name": "(GMT+10:00) Australia/Sydney", "offset": 10 },
    { "name": "(GMT+01:00) Europe/Amsterdam", "offset": 1 },
    { "name": "(GMT+02:00) Europe/Athens", "offset": 2 },
    { "name": "(GMT+00:00) Europe/London", "offset": 0 }
  ]



  async function getUserInfo() {
    const userRef = doc(db, 'users', uid);
    const userSnap = await getDoc(userRef);
    const userData = userSnap.data();
    console.log(userData);
    setFirstNameDB(userData.firstName);
    setLastNameDB(userData.lastName);
    setEmailDB(userData.email);
    setFirstName(userData.firstName);
    setLastName(userData.lastName);
    setEmail(userData.email);
    setSelectedTimezoneDB(userData.timezone);
    setSelectedTimezone(userData.timezone);
    
  }


  useEffect(() => {
    getUserInfo();
  }, [])




  async function saveChanges() {
    const userRef = doc(db, 'users', uid);

    await updateDoc(userRef, {
      firstName: firstName,
      lastName: lastName,
      email: email,
      timezone: selectedTimezone
    });
    console.log('user info updated');
    getUserInfo();
  }




  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && ((firstName !== firstNameDB) || (lastName !== lastNameDB) || (email !== emailDB) || (selectedTimezone !== selectedTimezoneDB))) {
        saveChanges();
      }
    };
  
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [firstName, lastName, email, firstNameDB, lastNameDB, emailDB]);
  

  async function getUserDevices() {
    
    const q = query(collection(db, "userDevices"), where("userID", "==", uid));
    
    const userDevicesTemp = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      userDevicesTemp.push(doc.data());
    });
    setUserDevices(userDevicesTemp);

  }

  useEffect(() => {
    getUserDevices();
  }, [])




  return (
    <div className='UserSettingsContainer'>
  
      <div className='UserSettingsSubContainer'>
        <span className='UserSettingsSubContainerTitle'>Settings</span>


        <div className='UserSettingsRow'>
          <div className='UserSettingsRowLeft'>
            <span className='UserSettingsRowTitle'>Details</span>
            {/* <span className='UserSettingsRowSubtitle'>Settings</span> */}
          </div>
          <div className='UserSettingsRowRight'>
            <div style={{width: 500}}>
              <TextInput label='' value={firstName} onChange={setFirstName} placeholder='First Name' marginBottom={20}/>
              <TextInput label='' value={lastName} onChange={setLastName} placeholder='Last Name' marginBottom={20}/>
              <TextInput label='' value={email} onChange={setEmail} placeholder='Email' marginBottom={15}/>
            </div>
          </div>
        </div>




        <div className='UserSettingsRow'>
          <div className='UserSettingsRowLeft'>
            <span className='UserSettingsRowTitle'>Timezone</span>
            {/* <span className='UserSettingsRowSubtitle'>Settings</span> */}
          </div>
          <div className='UserSettingsRowRight'>

            <div className='UserSettingsDropdownButton' onClick={() => { setShowTimezoneDropdown(!showTimezoneDropdown) }}>
              <div className='UserSettingsDropdownButtonText'>
                <span>{selectedTimezone?.name || timezones[0].name}</span>
              </div>
              <KeyboardArrowDownRounded style={{paddingLeft: 10}}/>
              {showTimezoneDropdown && (
                <ClickAwayListener onClickAway={() => setShowTimezoneDropdown(false)}>
                  <div className='UserSettingsDropdownContainer'>
                    <div className='UserSettingsDropdownScrollContainer'>
                      {timezones.map((x, index) => { return (
                        <span className={selectedTimezone?.name == x.name ? 'UserSettingsDropdownRowSelected' : 'UserSettingsDropdownRow'} onClick={() => { setShowTimezoneDropdown(false); setSelectedTimezone(x) }}>{x.name}</span>
                      )})}
                    </div>
                  </div>
                </ClickAwayListener>
              )}
            </div>
            
          </div>
        </div>




        <div className='UserSettingsRow'>
          <div className='UserSettingsRowLeft'>
            <span className='UserSettingsRowTitle'>Login Activity</span>
            {/* <span className='UserSettingsRowSubtitle'>Settings</span> */}
          </div>
          <div className='UserSettingsRowRight' style={{padding: 0, width: 540}}>

            {userDevices.map((x, index) => { return (
              <div className='UserSettingsLoginActivityRow' style={{borderBottom: index !== userDevices.length - 1 ? '1px solid #e7e7e7' : 'none'}}>
                <span className='UserSettingsLoginActivityRowBrowser'>{x.browser} on {x.os}</span>
                <span className='UserSettingsLoginActivityRowLocation'>{x.location}</span>
                <div className='UserSettingsLoginActivityRowTime'>
                  <span className='UserSettingsLoginActivityRowTimeTextTop'>{new Date(x.createdAt.seconds * 1000).toLocaleString('en-US', {  year: 'numeric', month: 'short', day: 'numeric'})}</span>
                  <span className='UserSettingsLoginActivityRowTimeTextBottom'>{new Date(x.createdAt.seconds * 1000).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</span>
                </div>
              </div>
            )})}
           
            
          </div>
        </div>


      </div>


      <div className='UserSettingsRowRightBottomRow'>
        <div 
          className={
            ((firstName !== firstNameDB) || (lastName !== lastNameDB) || (email !== emailDB) || (selectedTimezone !== selectedTimezoneDB)) 
          ? 
            'UserSettingsRowRightBottomRowButton' 
          : 
            'UserSettingsRowRightBottomRowButtonDisabled'
          } 
          onClick={() => { 
            ((firstName !== firstNameDB) || (lastName !== lastNameDB) || (email !== emailDB) || (selectedTimezone !== selectedTimezoneDB)) && 
              saveChanges()  
            }}
        >
          <span className='UserSettingsRowRightBottomRowButtonText'>Save Changes</span>
          <span className='UserSettingsRowRightBottomRowButtonKeys' ><span style={{lineHeight: '15px', paddingTop: 1}}>&#8984;</span></span>
          <span className='UserSettingsRowRightBottomRowButtonKeys' style={{marginLeft: 3}}>return</span>
        </div>
      </div>

    </div>
  );
}

export default UserSettings;