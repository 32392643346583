import React, { useEffect, useState } from 'react';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import tiktok_logo from '../../assets/tiktok_logo.png';
import './TiktokAccounts.css';


function TiktokAccountsPage({serverURL, pythonServerURL}) {
    const navigate = useNavigate(); 
    const [accounts, setAccounts] = useState([]);
    const [selectedAccounts, setSelectedAccounts] = useState('');
    const [loadingAccounts, setLoadingAccounts] = useState(true);
    const [loadingPercentage, setLoadingPercentage] = useState(0);
    const db = getFirestore();
    const bid = localStorage.getItem('bid');


    useEffect(() => {
        
        async function getAccounts() {
            try {
                setLoadingPercentage(100)
                const url = `${serverURL}/getTiktokAccounts?omniBusinessID=${bid}`;
                const response = await axios.get(url);
                console.log('Tiktok Ad Accounts', response.data);
                setAccounts(response.data);
                setLoadingAccounts(false);
                setLoadingPercentage(0);
            } catch (error) {
                console.error('Error getting tiktok accounts:', error); 
            } 
        }
        getAccounts();

    }, []);




    function adjustSelection(accountID) {
        let selectedAccountsTemp;
    
        if (selectedAccounts.includes(accountID)) {
            selectedAccountsTemp = selectedAccounts.filter(item => item !== accountID.toString());
        } else {
            selectedAccountsTemp = [...selectedAccounts, accountID];
        }
        setSelectedAccounts(selectedAccountsTemp);
    }




    async function saveSelectedAccounts() {
        const businessRef = doc(db, 'businesses', bid);
        try {
            await setDoc(businessRef, { tiktokAdAccountIDs: selectedAccounts }, { merge: true });
            console.log('Accounts saved to Firestore successfully');
            navigate('/Dashboard');
        } catch (error) {
            console.error('Error saving accounts to Firestore:', error);
        }
    }




    return (
        <div className='TiktokAccountsContainer'>
            {loadingAccounts ?
                <div className='TiktokAccountsLoadingContainer'>
                <div className='TiktokAccountsLoadingProgressParent'>
                    <div className='TiktokAccountsLoadingProgressChild' style={{width: loadingPercentage + '%'}}></div>
                </div>
                {/* <CircularProgress style={{color: '#635bff', width: 30, height: 30}}/> */}
                <span className='TiktokAccountsLoadingText'>Loading Tiktok Ad Accounts... </span>
                </div>
            :
                <div>
                    <span className='TiktokAccountsTitle'>Select Tiktok Ad Accounts</span>
                    <span className='TiktokAccountsSubtitle'>Only select the ad accounts that you're actively using</span>
                    <div className='TiktokAccountsAccountsContainer'>
                        {accounts.map((x) => (
                            <div className={selectedAccounts.includes(x.advertiser_id) ? 'TiktokAccountsAccountCardSelected' : 'TiktokAccountsAccountCard'} onClick={() => adjustSelection(x.advertiser_id)}>
                                <img className='TiktokAccountsAccountCardImage' src={tiktok_logo} style={{width: 40}}/>
                                <span className='TiktokAccountsAccountCardTitle'> {x.advertiser_name}</span>
                            </div>
                        ))}
                        <div className='TiktokAccountsContinueButtonContainer' onClick={() => {saveSelectedAccounts()} }>
                            <span className='TiktokAccountsContinueButton'>Link Accounts</span>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default TiktokAccountsPage;
