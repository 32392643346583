


import { AddchartRounded, DashboardRounded, DeleteForeverRounded, DensitySmallRounded, EditRounded, ExpandMoreRounded, LegendToggleRounded, LineAxisRounded, SaveOutlined, ShareRounded, ShowChartRounded, StackedLineChartRounded, StarRounded, WidthWideRounded } from '@mui/icons-material';
import { ClickAwayListener } from '@mui/material';
import React, { useState } from 'react';
import TextInput from '../../components/TextInput';
import Switch from "react-switch";

function BuildMetricDisplayOptions({showDisplayOptionsMenu, setShowDisplayOptionsMenu, selectedMetric, setSelectedMetric}) {


    return (
        <ClickAwayListener onClickAway={() => setShowDisplayOptionsMenu(false)}>
            <div className='BuildMetricDisplayOptionsContainer'>
                <div className='BuildMetricDisplayOptionsButton' onClick={() => {setShowDisplayOptionsMenu(!showDisplayOptionsMenu)}}>
                    <svg width="4" height="14" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.5 12C3.5 12.3978 3.34196 12.7794 3.06066 13.0607C2.77936 13.342 2.39782 13.5 2 13.5C1.60218 13.5 1.22064 13.342 0.93934 13.0607C0.658035 12.7794 0.5 12.3978 0.5 12C0.5 11.6022 0.658035 11.2206 0.93934 10.9393C1.22064 10.658 1.60218 10.5 2 10.5C2.39782 10.5 2.77936 10.658 3.06066 10.9393C3.34196 11.2206 3.5 11.6022 3.5 12ZM3.5 7C3.5 7.39782 3.34196 7.77936 3.06066 8.06066C2.77936 8.34196 2.39782 8.5 2 8.5C1.60218 8.5 1.22064 8.34196 0.93934 8.06066C0.658035 7.77936 0.5 7.39782 0.5 7C0.5 6.60218 0.658035 6.22064 0.93934 5.93934C1.22064 5.65804 1.60218 5.5 2 5.5C2.39782 5.5 2.77936 5.65804 3.06066 5.93934C3.34196 6.22064 3.5 6.60218 3.5 7ZM3.5 2C3.5 2.39782 3.34196 2.77936 3.06066 3.06066C2.77936 3.34196 2.39782 3.5 2 3.5C1.60218 3.5 1.22064 3.34196 0.93934 3.06066C0.658035 2.77936 0.5 2.39782 0.5 2C0.5 1.60218 0.658035 1.22064 0.93934 0.93934C1.22064 0.658035 1.60218 0.5 2 0.5C2.39782 0.5 2.77936 0.658035 3.06066 0.93934C3.34196 1.22064 3.5 1.60218 3.5 2Z" fill="black"/>
                    </svg>
                </div>
                
                {showDisplayOptionsMenu && (
                <div className='BuildMetricDisplayOptionsContainerContent'>

                    <span className='BuildMetricDisplayOptionsSectionLabel'>Display Options</span>




                    {/* SHOW COMPARE DATA */}
                    <div className='BuildMetricDisplayOptionsContainerContentRow'>
                        <Switch
                            onChange={() => {
                                setSelectedMetric({...selectedMetric, showCompareData: !selectedMetric.showCompareData});
                            }}
                            checked={selectedMetric.showCompareData}
                            offColor="#d9d9d9"
                            disabled={selectedMetric.lines.length > 1 ? true : false}
                            onColor="#635bff"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            width={35}
                            height={20}
                            handleDiameter={15}
                        />
                        <span className='BuildMetricDisplayOptionsRowText'>Compare Data</span>
                    </div>




                    {/* SHOW GRID LINES */}
                    <div className='BuildMetricDisplayOptionsContainerContentRow'>
                        <Switch
                            onChange={() => {
                                setSelectedMetric({...selectedMetric, showGridLines: !selectedMetric.showGridLines});
                            }}
                            checked={selectedMetric.showGridLines}
                            offColor="#d9d9d9"
                            disabled={selectedMetric.lines.length > 1 ? true : false}
                            onColor="#635bff"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            width={35}
                            height={20}
                            handleDiameter={15}
                        />
                        <span className='BuildMetricDisplayOptionsRowText'>Grid Lines</span>
                    </div>





                    {/* SHOW LEGEND */}
                    <div className='BuildMetricDisplayOptionsContainerContentRow'>
                        <Switch
                            onChange={() => {
                                setSelectedMetric({...selectedMetric, showLegend: !selectedMetric.showLegend});
                            }}
                            checked={selectedMetric.showLegend}
                            offColor="#d9d9d9"
                            disabled={selectedMetric.lines.length > 1 ? true : false}
                            onColor="#635bff"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            width={35}
                            height={20}
                            handleDiameter={15}
                        />
                        <span className='BuildMetricDisplayOptionsRowText'>Legend</span>
                    </div>




                    
                    {/* SHOW AXIS LABELS */}
                    <div className='BuildMetricDisplayOptionsContainerContentRow'>
                        <Switch
                            onChange={() => {
                                setSelectedMetric({...selectedMetric, showAxisLabels: !selectedMetric.showAxisLabels});
                            }}
                            checked={selectedMetric.showAxisLabels}
                            offColor="#d9d9d9"
                            disabled={selectedMetric.lines.length > 1 ? true : false}
                            onColor="#635bff"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            width={35}
                            height={20}
                            handleDiameter={15}
                        />
                        <span className='BuildMetricDisplayOptionsRowText'>Axis Labels</span>
                    </div>
                    
                </div>
                )}

            </div>
        </ClickAwayListener>
    );
}


export default BuildMetricDisplayOptions;