import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, updateDoc, setDoc, getDoc } from 'firebase/firestore';
import axios from 'axios';




function ProcessGoogleRedirect({pythonServerURL}) {

    const clientId = '663765932973-prk9mm52q4rq4snrha57qpcvp8otlc6e.apps.googleusercontent.com';
    const redirectUri = `http://localhost:3011/process-google-redirect`;
    const firestore = getFirestore();
    const bid = localStorage.getItem('bid');
    const uid = localStorage.getItem('uid');


    const navigate = useNavigate();

    function redirectToGoogleAuth() {
        const scope = encodeURIComponent('https://www.googleapis.com/auth/adwords');
        const state = encodeURIComponent(Math.random().toString(36).substring(2, 15));
        const url = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?scope=${scope}&access_type=offline&include_granted_scopes=true&response_type=code&state=${state}&redirect_uri=${redirectUri}&client_id=${clientId}&flowName=GeneralOAuthFlow`;
        console.log('Redirecting to Google auth:', url);
        window.location.href = url;
    }
    
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        
        if (code) {
            saveCodeToDB(code);
        } else {
            // If no code is present, redirect to Google auth
            redirectToGoogleAuth();
        }
    }, []); 


    async function saveCodeToDB(code) {
        
        
        console.log('businessID:', bid);
        const businessRef = doc(firestore, 'businesses', bid);

        try {
            const docSnapshot = await getDoc(businessRef);
            if (docSnapshot.exists()) {
                await updateDoc(businessRef, {
                    googleOAuthCode: decodeURIComponent(code),
                    googleOAuthURL: redirectUri,
                    googleOAuthAccessTokenNeverUsed: true
                });
            
                console.log('Code updated in Firestore successfully');
            } else {
                await setDoc(businessRef, {
                    googleOAuthCode: decodeURIComponent(code),
                    googleOAuthURL: redirectUri,
                    googleOAuthAccessTokenNeverUsed: true
                });
                console.log('Code saved to Firestore successfully');
            }
            //await fetchGoogleRefreshToken();

            //navigate('/google-accounts');
        } catch (error) {
            console.error('Error saving/updating code in Firestore:', error);
        }
    }
    async function fetchGoogleRefreshToken() {
         const businessRef = doc(firestore, 'businesses', bid);

        try {
            const data = {
                omniBusinessID: bid,
            };
            console.log(`${pythonServerURL}/api/getGoogleRefreshToken`, data)
            const response = await axios.post(`${pythonServerURL}/api/getGoogleRefreshToken`, data);
            console.log('Token fetched:', response.data);
            await updateDoc(businessRef, {
                googleOAuthAccessTokenNeverUsed: false,
            });
            // Handle additional logic here if needed (e.g., navigate to another route, store data)
        } catch (error) {
            console.error('Error fetching Google refresh token:', error);
        }
    }
    

    return <div>Loading...</div>;
}

export default ProcessGoogleRedirect;
