import { BrowserRouter, Route, Routes, Navigate, NavLink, useLocation, Outlet} from 'react-router-dom';
import SignUp from './pages/SignUp';
import ProcessFacebookRedirect from './pages/OAuth/ProcessFacebookRedirect';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, onIdTokenChanged } from 'firebase/auth';
import Settings from './pages/Settings';
import Orders from './pages/Orders';
import Audience from './pages/Audience/Audience';
import Products from './pages/Products';
import Ads from './pages/AdsManager/AdsManager';
import Analytics from './pages/Analytics/Analytics';
import ProcessGoogleRedirect from './pages/OAuth/ProcessGoogleRedirect';
import GoogleAccountsPage from './pages/OAuth/GoogleAccounts';
import Files from './pages/Files';
import NavbarAndDrawer from './components/Navbar/NavbarAndDrawer';
import CreativeAnalytics from './pages/CreativeAnalytics/CreativeAnalytics';
import CreateAd2 from './pages/CreateAd/CreateAd2';
import CreateAd from './pages/CreateAd/CreateAd';
import FacebookAccountsPage from './pages/OAuth/FacebookAccounts';
import ProcessTiktokRedirect from './pages/OAuth/ProcessTiktokRedirect';
import TiktokAccountsPage from './pages/OAuth/TiktokAccounts';
import KlaviyoAccountsPage from './pages/OAuth/KlaviyoAccounts';
import ProcessKlaviyoRedirect from './pages/OAuth/ProcessKlaviyoRedirect';
import UserSettings from './pages/UserSettings';
import { Check, CheckCircle, CloseRounded } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import Onboarding from './pages/Onboarding';
import AccountSupplier from './components/AccountSupplier';
import axios from 'axios';
import StripeBillingTest from './pages/StripeBillingTest';
import PrivacyPolicy from './pages/Privacy';
 


function App() {
  const [authenticated, setAuthenticated] = useState(true);
  const [loading, setLoading] = useState(true);
  const [darkMode, setDarkMode] = useState(false);
  const [switchedBusinesses, setSwitchedBusinesses] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({open: false, title: '', message: '', type: 'success'});


  const testing = false;

  let serverURL = '';
  let pythonServerURL = '';
  let browserURL = '';

  if (testing) {
    serverURL = 'http://localhost:9443';
    pythonServerURL = 'http://localhost:8001';
    browserURL = 'http://localhost:3011';
  } else {
    serverURL = 'https://api.omnimedia.app';
    pythonServerURL = 'https://gapi.omnimedia.app';
    browserURL = 'https://app.omnios.app';
  }
  

 
   useEffect(() => {
    const auth = getAuth();
  
    // Set initial authentication state based on localStorage
    setAuthenticated(localStorage.getItem('authenticated') === 'true');
  
    // Listen for authentication state changes
    const unsubscribeAuthState = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Add a delay before updating the authentication state
        setTimeout(() => {
          setAuthenticated(true);
          localStorage.setItem('authenticated', true);
        }, 2000);
      } else {
        setAuthenticated(false);
        localStorage.setItem('authenticated', false);
        delete axios.defaults.headers.common["Authorization"];
      }
      setLoading(false);
    });
  
    // Listen for ID token changes
    const unsubscribeIdToken = onIdTokenChanged(auth, async (user) => {
      if (user) {
        try {
          const authToken = await user.getIdToken(/* forceRefresh */ true);
          axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
        } catch (error) {
          console.error("Error fetching ID token:", error);
        }
      }
    });
  
    // Cleanup listeners on component unmount
    return () => {
      unsubscribeAuthState();
      unsubscribeIdToken();
    };
  }, []);

  console.log('authenticated', authenticated);












  useEffect(() => {
    if (snackbarInfo.open) {
      const timer = setTimeout(() => {
        setSnackbarInfo({ ...snackbarInfo, open: false });
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [snackbarInfo]);


  const NavbarLayout = ({ authenticated, ...props }) => {
    return (
      <NavbarAndDrawer {...props}>
        {/* Render the child routes here using Outlet */}
        <Outlet />
      </NavbarAndDrawer>
    );
  };



  return (
    <>



      <BrowserRouter>
        <Routes>
          {/* Unauthenticated routes */}
          <Route path="/" element={<LoginRoute serverURL={serverURL} browserURL={browserURL} setAuthenticated={setAuthenticated} authenticated={authenticated} setSnackbarInfo={setSnackbarInfo}/>} />
          <Route path="/login" element={<LoginRoute serverURL={serverURL} browserURL={browserURL} setAuthenticated={setAuthenticated} authenticated={authenticated} setSnackbarInfo={setSnackbarInfo}/>} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/process-google-redirect" element={<ProcessGoogleRedirect pythonServerURL={pythonServerURL} />} />
          <Route path="/process-facebook-redirect" element={<ProcessFacebookRedirect serverURL={serverURL} />} />
          <Route path="/process-tiktok-redirect" element={<ProcessTiktokRedirect serverURL={serverURL} />} />
          <Route path="/process-klaviyo-redirect" element={<ProcessKlaviyoRedirect serverURL={serverURL} />} />
          <Route path="/onboarding"  element={authenticated ? <Onboarding serverURL={serverURL} browserURL={browserURL} darkMode={darkMode} setSnackbarInfo={setSnackbarInfo}/> : <Navigate to="/Login" />} />
          <Route path="/google-accounts" element={<GoogleAccountsPage serverURL={serverURL} pythonServerURL={pythonServerURL}/>} />
          <Route path="/facebook-accounts" element={<FacebookAccountsPage serverURL={serverURL}/>} />
          <Route path="/tiktok-accounts" element={<TiktokAccountsPage serverURL={serverURL}/>} />
          <Route path="/klaviyo-accounts" element={<KlaviyoAccountsPage serverURL={serverURL}/>} />
          <Route path="/account_supplier" element={<AccountSupplier serverURL={serverURL} setSnackbarInfo={setSnackbarInfo}/>} />
          <Route path="/stripe" element={<StripeBillingTest serverURL={serverURL} setSnackbarInfo={setSnackbarInfo}/>} />
          <Route path="/privacy" element={<PrivacyPolicy serverURL={serverURL} setSnackbarInfo={setSnackbarInfo}/>} />

          {/* Layout route with NavbarAndDrawer for authenticated routes */}
          <Route element={<NavbarLayout authenticated={authenticated} collapsed={false} darkMode={darkMode} setDarkMode={setDarkMode} pythonServerURL={pythonServerURL} serverURL={serverURL} switchedBusinesses={switchedBusinesses} setSwitchedBusinesses={setSwitchedBusinesses} setSnackbarInfo={setSnackbarInfo} />}>
            
            {/* Protected routes */}
            <Route path="/dashboard" element={authenticated ? <Dashboard darkMode={darkMode} serverURL={serverURL} switchedBusinesses={switchedBusinesses}/> : <Navigate to="/Login" />} />
            <Route path="/settings" element={authenticated ? <Settings darkMode={darkMode} serverURL={serverURL}/> : <Navigate to="/Login" />} />
            <Route path="/user_settings" element={authenticated ? <UserSettings darkMode={darkMode} serverURL={serverURL}/> : <Navigate to="/Login" />} />
            <Route path="/orders" element={authenticated ? <Orders darkMode={darkMode} serverURL={serverURL}/> : <Navigate to="/Login" />} />
            <Route path="/products" element={authenticated ? <Products darkMode={darkMode} serverURL={serverURL}/> : <Navigate to="/Login" />} />
            <Route path="/ads" element={authenticated ? <Ads darkMode={darkMode} serverURL={serverURL} pythonServerURL={pythonServerURL} switchedBusinesses={switchedBusinesses} setSnackbarInfo={setSnackbarInfo}/> : <Navigate to="/Login" />} />
            <Route path="/create" element={authenticated ? <CreateAd2 darkMode={darkMode} serverURL={serverURL}/> : <Navigate to="/Login" />} />
            <Route path="/create_ad" element={authenticated ? <CreateAd darkMode={darkMode} serverURL={serverURL}/> : <Navigate to="/Login" />} />
            <Route path="/analytics" element={authenticated ? <Analytics darkMode={darkMode} serverURL={serverURL} setSnackbarInfo={setSnackbarInfo}/> : <Navigate to="/Login" />} />
            <Route path="/cloud" element={authenticated ? <Files darkMode={darkMode} serverURL={serverURL}/> : <Navigate to="/Login" />} />
            <Route path="/audience" element={authenticated ? <Audience darkMode={darkMode} serverURL={serverURL} setSnackbarInfo={setSnackbarInfo} /> : <Navigate to="/Login" />} />
            <Route path="/creative_analytics" element={authenticated ? <CreativeAnalytics darkMode={darkMode} serverURL={serverURL}/> : <Navigate to="/Login" />} />
          </Route>
        </Routes>
      </BrowserRouter>




      
      <div className={snackbarInfo.open ? 'AppSnackbarContainer' : 'AppSnackbarContainerHidden'}>
        <div className='AppSnackbarContent'>
          <div className='AppSnackbarContentLeftColumn'>
            {snackbarInfo.type === 'error' && ( <CloseRounded style={{width: 35, color: '#ff0000'}}/> )}
            {snackbarInfo.type === 'success' && ( <Check style={{width: 35, color: '#635bff'}}/> )}
            {snackbarInfo.type === 'loading' && ( <CircularProgress style={{width: 25, height: 25, color: '#635bff'}}/> )}
          </div>
          <div className='AppSnackbarContentMiddleColumn'>
            {snackbarInfo.title && <div className='AppSnackbarTitle'>{snackbarInfo.title}</div>}
            {snackbarInfo.message && <div className='AppSnackbarMessage'>{snackbarInfo.message}</div>}
          </div>
        </div>
      </div>
    

    </>
  );
}




const LoginRoute = ({ serverURL, browserURL, setAuthenticated, authenticated, setSnackbarInfo }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const shop_auth_token = searchParams.get('firebase_token');
  return <Login serverURL={serverURL} browserURL={browserURL} shop_auth_token={shop_auth_token} setAuthenticated={setAuthenticated} setSnackbarInfo={setSnackbarInfo} />;
};

export default App;
