import './Onboarding.css';
import react, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CircularProgress, ClickAwayListener, Tooltip } from '@mui/material';
import { KeyboardArrowDownRounded, KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import { collection, doc, getDoc, setDoc, query, where, getDocs, updateDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../FirebaseInit';
import { getAuth, signOut } from "firebase/auth";
import shopify_logo from '../assets/shopify_logo.png';

function Onboarding({ serverURL, browserURL, darkMode, setSnackbarInfo }) {
  const navigate = useNavigate();
  const uid = localStorage.getItem('uid');
  const bid = localStorage.getItem('bid');

  const [selectedHex, setSelectedHex] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [businessName, setBusinessName] = useState('');

  const [showTimezoneDropdown, setShowTimezoneDropdown] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const [showStep2, setShowStep2] = useState(false);




  const hexCodes = ["#3d88ff", "#6081ff", "#6081ff", "#199bff", "#0cc30a", "#1cc80c", "#00b8a3", "#ff33eb", "#e4be24", "#07ba71", "#03bf6a", "#02bb8d", "#b7c020", "#29c70d", "#07be64", 
    "#d6be1f", "#00bcf0", "#d1bd1e", "#9ec51b", "#9ec51b", "#9ec51b", "#07ba71", "#5fc611", "#26c708", "#8bc518", "#ff4d73", "#26c708", "#ba42ff"]

  function getRandomHex() {
    const randomIndex = Math.floor(Math.random() * hexCodes.length);
    setSelectedHex(hexCodes[randomIndex]);
  }

  useEffect(() => {
    getRandomHex()
  }, [])


  const timezones = [
    { "name": "(GMT-09:00) America/Anchorage", "offset": -9 },
    { "name": "(GMT-08:00) America/Los_Angeles", "offset": -8 },
    { "name": "(GMT-08:00) America/Vancouver", "offset": -8 },
    { "name": "(GMT-07:00) America/Denver", "offset": -7 },
    { "name": "(GMT-07:00) America/Edmonton", "offset": -7 },
    { "name": "(GMT-07:00) America/Phoenix", "offset": -7 },
    { "name": "(GMT-06:00) America/Chicago", "offset": -6 },
    { "name": "(GMT-06:00) America/Guatemala", "offset": -6 },
    { "name": "(GMT-06:00) America/Mexico_City", "offset": -6 },
    { "name": "(GMT-05:00) America/Bogota", "offset": -5 },
    { "name": "(GMT-05:00) America/Havana", "offset": -5 },
    { "name": "(GMT-05:00) America/Jamaica", "offset": -5 },
    { "name": "(GMT-05:00) America/New_York", "offset": -5 },
    { "name": "(GMT-05:00) America/Panama", "offset": -5 },
    { "name": "(GMT-05:00) America/Toronto", "offset": -5 },
    { "name": "(GMT-04:30) America/Caracas", "offset": -4.5 },
    { "name": "(GMT-04:00) America/Barbados", "offset": -4 },
    { "name": "(GMT-03:00) America/Argentina/Buenos_Aires", "offset": -3 },
    { "name": "(GMT-03:00) America/Santiago", "offset": -3 },
    { "name": "(GMT-03:00) America/Sao_Paulo", "offset": -3 },
    { "name": "(GMT-02:00) Atlantic/Azores", "offset": -1 },
    { "name": "(GMT+00:00) Africa/Abidjan", "offset": 0 },
    { "name": "(GMT+00:00) Africa/Accra", "offset": 0 },
    { "name": "(GMT+00:00) Africa/Casablanca", "offset": 0 },
    { "name": "(GMT+00:00) Europe/London", "offset": 0 },
    { "name": "(GMT+01:00) Africa/Algiers", "offset": 1 },
    { "name": "(GMT+01:00) Africa/Lagos", "offset": 1 },
    { "name": "(GMT+01:00) Europe/Amsterdam", "offset": 1 },
    { "name": "(GMT+02:00) Africa/Cairo", "offset": 2 },
    { "name": "(GMT+02:00) Africa/Johannesburg", "offset": 2 },
    { "name": "(GMT+02:00) Africa/Tripoli", "offset": 2 },
    { "name": "(GMT+02:00) Asia/Beirut", "offset": 2 },
    { "name": "(GMT+02:00) Asia/Jerusalem", "offset": 2 },
    { "name": "(GMT+03:00) Africa/Addis_Ababa", "offset": 3 },
    { "name": "(GMT+03:00) Africa/Nairobi", "offset": 3 },
    { "name": "(GMT+03:00) Asia/Amman", "offset": 3 },
    { "name": "(GMT+03:00) Asia/Baghdad", "offset": 3 },
    { "name": "(GMT+03:00) Asia/Riyadh", "offset": 3 },
    { "name": "(GMT+04:00) Asia/Dubai", "offset": 4 },
    { "name": "(GMT+05:00) Asia/Karachi", "offset": 5 },
    { "name": "(GMT+05:30) Asia/Kolkata", "offset": 5.5 },
    { "name": "(GMT+06:00) Asia/Almaty", "offset": 6 },
    { "name": "(GMT+06:00) Asia/Dhaka", "offset": 6 },
    { "name": "(GMT+07:00) Asia/Bangkok", "offset": 7 },
    { "name": "(GMT+07:00) Asia/Jakarta", "offset": 7 },
    { "name": "(GMT+08:00) Asia/Hong_Kong", "offset": 8 },
    { "name": "(GMT+08:00) Asia/Kuala_Lumpur", "offset": 8 },
    { "name": "(GMT+08:00) Asia/Shanghai", "offset": 8 },
    { "name": "(GMT+08:00) Asia/Singapore", "offset": 8 },
    { "name": "(GMT+09:00) Asia/Seoul", "offset": 9 },
    { "name": "(GMT+09:00) Asia/Tokyo", "offset": 9 },
    { "name": "(GMT+10:00) Australia/Sydney", "offset": 10 }
  ];

  useEffect(() => {
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const matchedTimezone = timezones.find(tz => tz.name.includes(browserTimezone));
    if (matchedTimezone) {
      setSelectedTimezone(matchedTimezone);
    } else {
      setSelectedTimezone(timezones[0]); // Default to the first timezone if no match is found
    }
  }, []);



  const currencies = [
    { "name": "United Arab Emirates Dirham", "code": "AED", "symbol": "د.إ" },
    { "name": "Afghan Afghani", "code": "AFN", "symbol": "؋" },
    { "name": "Albanian Lek", "code": "ALL", "symbol": "L" },
    { "name": "Armenian Dram", "code": "AMD", "symbol": "֏" },
    { "name": "Netherlands Antillean Guilder", "code": "ANG", "symbol": "ƒ" },
    { "name": "Angolan Kwanza", "code": "AOA", "symbol": "Kz" },
    { "name": "Argentine Peso", "code": "ARS", "symbol": "$" },
    { "name": "Australian Dollar", "code": "AUD", "symbol": "$" },
    { "name": "Aruban Florin", "code": "AWG", "symbol": "ƒ" },
    { "name": "Azerbaijani Manat", "code": "AZN", "symbol": "₼" },
    { "name": "Bosnia-Herzegovina Convertible Mark", "code": "BAM", "symbol": "KM" },
    { "name": "Barbadian Dollar", "code": "BBD", "symbol": "$" },
    { "name": "Bangladeshi Taka", "code": "BDT", "symbol": "৳" },
    { "name": "Bulgarian Lev", "code": "BGN", "symbol": "лв" },
    { "name": "Bahraini Dinar", "code": "BHD", "symbol": ".د.ب" },
    { "name": "Burundian Franc", "code": "BIF" },
    { "name": "Bermudan Dollar", "code": "BMD", "symbol": "$" },
    { "name": "Brunei Dollar", "code": "BND", "symbol": "$" },
    { "name": "Bolivian Boliviano", "code": "BOB", "symbol": "Bs." },
    { "name": "Brazilian Real", "code": "BRL", "symbol": "R$" },
    { "name": "Bahamian Dollar", "code": "BSD", "symbol": "$" },
    { "name": "Bitcoin", "code": "BTC", "symbol": "₿" },
    { "name": "Bhutanese Ngultrum", "code": "BTN" },
    { "name": "Botswanan Pula", "code": "BWP", "symbol": "P" },
    { "name": "Belarusian Ruble", "code": "BYN", "symbol": "Br" },
    { "name": "Belize Dollar", "code": "BZD", "symbol": "BZ$" },
    { "name": "Canadian Dollar", "code": "CAD", "symbol": "$" },
    { "name": "Congolese Franc", "code": "CDF" },
    { "name": "Swiss Franc", "code": "CHF", "symbol": "CHF" },
    { "name": "Chilean Peso", "code": "CLP", "symbol": "$" },
    { "name": "Chinese Yuan", "code": "CNY", "symbol": "¥" },
    { "name": "Colombian Peso", "code": "COP", "symbol": "$" },
    { "name": "Costa Rican Colón", "code": "CRC", "symbol": "₡" },
    { "name": "Cuban Convertible Peso", "code": "CUC", "symbol": "$" },
    { "name": "Czech Republic Koruna", "code": "CZK", "symbol": "Kč" },
    { "name": "Danish Krone", "code": "DKK", "symbol": "kr" },
    { "name": "Dominican Peso", "code": "DOP", "symbol": "RD$" },
    { "name": "Egyptian Pound", "code": "EGP", "symbol": "£" },
    { "name": "Euro", "code": "EUR", "symbol": "€" },
    { "name": "Fijian Dollar", "code": "FJD", "symbol": "$" },
    { "name": "British Pound Sterling", "code": "GBP", "symbol": "£" },
    { "name": "Ghanaian Cedi", "code": "GHS", "symbol": "₵" },
    { "name": "Gibraltar Pound", "code": "GIP", "symbol": "£" },
    { "name": "Guatemalan Quetzal", "code": "GTQ", "symbol": "Q" },
    { "name": "Hong Kong Dollar", "code": "HKD", "symbol": "$" },
    { "name": "Hungarian Forint", "code": "HUF", "symbol": "Ft" },
    { "name": "Indian Rupee", "code": "INR", "symbol": "₹" },
    { "name": "Israeli New Sheqel", "code": "ILS", "symbol": "₪" },
    { "name": "Japanese Yen", "code": "JPY", "symbol": "¥" },
    { "name": "Kazakhstani Tenge", "code": "KZT", "symbol": "₸" },
    { "name": "South Korean Won", "code": "KRW", "symbol": "₩" },
    { "name": "Kuwaiti Dinar", "code": "KWD", "symbol": "د.ك" },
    { "name": "Malaysian Ringgit", "code": "MYR", "symbol": "RM" },
    { "name": "Mexican Peso", "code": "MXN", "symbol": "$" },
    { "name": "New Zealand Dollar", "code": "NZD", "symbol": "$" },
    { "name": "Nigerian Naira", "code": "NGN", "symbol": "₦" },
    { "name": "Norwegian Krone", "code": "NOK", "symbol": "kr" },
    { "name": "Omani Rial", "code": "OMR", "symbol": "﷼" },
    { "name": "Pakistani Rupee", "code": "PKR", "symbol": "₨" },
    { "name": "Polish Zloty", "code": "PLN", "symbol": "zł" },
    { "name": "Qatari Rial", "code": "QAR", "symbol": "﷼" },
    { "name": "Romanian Leu", "code": "RON", "symbol": "lei" },
    { "name": "Russian Ruble", "code": "RUB", "symbol": "₽" },
    { "name": "Saudi Riyal", "code": "SAR", "symbol": "﷼" },
    { "name": "Singapore Dollar", "code": "SGD", "symbol": "$" },
    { "name": "South African Rand", "code": "ZAR", "symbol": "R" },
    { "name": "Swedish Krona", "code": "SEK", "symbol": "kr" },
    { "name": "Thai Baht", "code": "THB", "symbol": "฿" },
    { "name": "Turkish Lira", "code": "TRY", "symbol": "₺" },
    { "name": "Ukrainian Hryvnia", "code": "UAH", "symbol": "₴" },
    { "name": "United States Dollar", "code": "USD", "symbol": "$" },
    { "name": "Vietnamese Dong", "code": "VND", "symbol": "₫" }
  ];
  

  useEffect(() => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    const currencyMap = {
      'en-US': 'USD',
      'en-GB': 'GBP',
      'en-CA': 'CAD',
      'en-AU': 'AUD',
      'en-NZ': 'NZD',
      'en-IN': 'INR',
      'en-SG': 'SGD',
      'en-ZA': 'ZAR',
      'en-NG': 'NGN',
      'en-PH': 'PHP',
      'en-HK': 'HKD',
      'en-MY': 'MYR',
      'en-IE': 'EUR',
      'en-JM': 'JMD',
      'en-TT': 'TTD',
      'en-BZ': 'BZD',
      'en-BW': 'BWP',
      'en-GH': 'GHS',
      'en-KE': 'KES',
      'en-LS': 'LSL',
      'en-NA': 'NAD',
      'en-SZ': 'SZL',
      'en-UG': 'UGX',
      'en-ZM': 'ZMW',
      'en-ZW': 'ZWL'
    };

    const currencyCode = currencyMap[browserLanguage] || 'USD';
    const matchedCurrency = currencies.find(currency => currency.code === currencyCode);
    if (matchedCurrency) {
      setSelectedCurrency(matchedCurrency);
    } else {
      setSelectedCurrency(currencies.find(currency => currency.code === 'USD')); // Default to USD if no match is found
    }
  }, []);



  useEffect(() => {
    getUser();
  }, [uid])

  async function getUser () {
    const userRef = doc(db, "users", uid);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
        const userData = userSnap.data();
        setFirstName(userData.firstName)
        setLastName(userData.lastName)
        setEmailAddress(userData.email)
    }
  }




  async function updateUser () {
    try {
      const userDocRef = doc(db, 'users', uid);
      await updateDoc(userDocRef, {
          firstName: firstName,
          lastName: lastName,
          timezone: selectedTimezone,
          currency: selectedCurrency,
          hexCode: selectedHex
  
      }, { merge: true });
      setSnackbarInfo({open: true, title: 'Success', message: 'Profile updated', type: 'success'});

      setShowStep2(true);
    } catch (error) {
      console.error("Error updating document: ", error);
      setSnackbarInfo({open: true, title: 'Error', message: 'An error occurred while updating your account. Please try again.', type: 'error'});
    }
  }



  async function logOut() {
    localStorage.removeItem('uid')
    localStorage.removeItem('bid')
    localStorage.removeItem('businessName')
    

    const auth = getAuth();
    signOut(auth).then(() => {
      navigate('/login');
      setSnackbarInfo({open: true, title: 'Success', message: 'You have been signed out.', type: 'success'});
    }).catch((error) => {
      console.error(error);
      setSnackbarInfo({open: true, title: 'Error', message: 'An error occurred while signing out. Please try again.', type: 'error'});
    });
  }



  useEffect(() => {
    if (bid) {
      try {
        const unsub = onSnapshot(doc(db, "businesses", bid), (doc) => {
          console.log("Current data: ", doc.data());
          if (doc.data().shopifyAdminAccessToken && showStep2) {
            setSnackbarInfo({open: true, title: 'Success', message: 'Shopify store connected', type: 'success'});
            navigate('/dashboard');
          }
        });
        return () => unsub();
      } catch (error) {
        console.error("Error checking if shopify integration is completed:", error);
        setSnackbarInfo({open: true, title: 'Error', message: 'An error occurred while checking if Shopify integration is completed. Please try again.', type: 'error'});
      }
    } else {
      console.log('No business ID in local storage');
    }
  }, [])




  return (
    <div className='OnboardingContainer'>
      
      <div className='OnboardingMessagesContainer'>
      <span onClick={() => {logOut()}} className='OnboardingLogout fade-in'>Signout</span>
      <span className='OnboardingTitle fade-in'>Omni OS</span>
      {(firstName || lastName) && <span className='OnboardingAvatar fade-in' style={{color: selectedHex, backgroundColor: selectedHex + '30'}}>{firstName.charAt(0) + lastName.charAt(0)}</span>}
        <span className="OnboardingMessage1 fade-in-slide">Welcome to Omni OS!</span>
        <span className="OnboardingMessage2 fade-in-slide">Ready to scale to the moon🚀?</span>
        <span className="OnboardingMessage3 fade-in-slide">Let's get you set up!</span>
      </div>

      <div style={{width: 400}}>
        <div className={!showStep2 ? 'OnboardingFormParentFadeIn fade-in' : 'OnboardingFormParentSlideOut slide-out'}> 
          <span className='OnboardingFormTitle'>Let's get your account set up</span>
          <div className='OnboardingFormContainer'> 
            <div className='OnboardingFormInputRow'>

              {/* ONLY SHOW IF EMAIL AUTH */}
              <div>
                <span className='OnboardingFormInputLabel'>First Name</span>
                <input className='OnboardingFormInput' value={firstName} onChange={(e) => {setFirstName(e.target.value)}} placeholder='Enter your first name' />
              </div>

              <div>
                <span className='OnboardingFormInputLabel'>Last Name</span>
                <input className='OnboardingFormInput' value={lastName} onChange={(e) => {setLastName(e.target.value)}} placeholder='Enter your last name' />
              </div>
            </div>

            <span className='OnboardingFormInputLabel'>Timezone</span>
            <div className='OnboardingDropdownButton' onClick={() => { setShowTimezoneDropdown(!showTimezoneDropdown) }}>
              <div className='OnboardingDropdownButtonText'>
                <span>{selectedTimezone?.name || timezones[0].name}</span>
              </div>
              <KeyboardArrowDownRounded style={{paddingLeft: 10}}/>
              {showTimezoneDropdown && (
                <ClickAwayListener onClickAway={() => setShowTimezoneDropdown(false)}>
                  <div className='OnboardingDropdownContainer'>
                    <div className='OnboardingDropdownScrollContainer'>
                      {timezones.map((x, index) => { return (
                        <span key={index} className={selectedTimezone?.name == x.name ? 'OnboardingDropdownRowSelected' : 'OnboardingDropdownRow'} onClick={() => { setShowTimezoneDropdown(false); setSelectedTimezone(x) }}>{x.name}</span>
                      )})}
                    </div>
                  </div>
                </ClickAwayListener>
              )}
            </div>

            <span className='OnboardingFormInputLabel'>Currency</span>
            <div className='OnboardingDropdownButton' onClick={() => { setShowCurrencyDropdown(!showCurrencyDropdown) }}>
              <div className='OnboardingDropdownButtonText'>
                <span>{selectedCurrency?.name || currencies[0].name}</span>
              </div>
              <KeyboardArrowDownRounded style={{paddingLeft: 10}}/>
              {showCurrencyDropdown && (
                <ClickAwayListener onClickAway={() => setShowCurrencyDropdown(false)}>
                  <div className='OnboardingDropdownContainer'>
                    <div className='OnboardingDropdownScrollContainer'>
                      {currencies.map((x, index) => { return (
                        <span key={index} className={selectedCurrency?.name == x.name ? 'OnboardingDropdownRowSelected' : 'OnboardingDropdownRow'} onClick={() => { setShowCurrencyDropdown(false); setSelectedCurrency(x) }}><strong style={{width: 45}}>{x.code}</strong>{x.name}</span>
                      )})}
                    </div>
                  </div>
                </ClickAwayListener>
              )}
            </div>

            <div className='OnboardingFormContinueButtonRow'>
              <span className='OnboardingFormContinueButton' onClick={() => (updateUser())}>
                Continue
                <KeyboardArrowRightRounded style={{paddingLeft: 5, height: 16}}/>  
              </span>
            </div>

          </div>
        </div>
      </div>



      <div className={showStep2 ? 'OnboardingIntegrationFadeIn fade-in' : 'OnboardingIntegrationSlideOut slide-out'}>
        <div className='OnboardingIntegrationConnectShopify' onClick={() => {window.open(`${browserURL}/process-shopify-redirect`, '_blank', 'noopener,noreferrer');}}>
          <img src={shopify_logo} style={{width: 40, height: 40, paddingLeft: 20, paddingRight: 20}}/>
          <div className='OnboardingIntegrationConnectShopifyTextContainer'>
            <span className='OnboardingIntegrationConnectShopifyTitle'>Connect your Shopify store</span>
            <span className='OnboardingIntegrationConnectShopifyDescription'>Connecting your Shopify store allows access for Omni OS to get your orders, products, and session info.</span>
          </div>
        </div>
      </div>



    </div>
  );
}

export default Onboarding;