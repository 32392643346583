import React, { useState, useEffect } from 'react';
import { ChevronLeftRounded, LocalMall, WatchRounded, FingerprintRounded, NetworkCellRounded, PublicRounded, AccessTimeFilledRounded, FolderRounded, FolderSpecialRounded, TravelExploreRounded, LocalPhoneRounded, ShoppingCart, EmailRounded, CalendarMonthRounded, PersonRounded, MyLocationRounded, KeyboardArrowDownOutlined, LocationOnRounded, AccountCircleOutlined, Facebook, Instagram, AddShoppingCart, Visibility, Event, EventNoteOutlined, ShoppingCartOutlined, ReceiptOutlined, InfoOutlined } from '@mui/icons-material';
import { AiFillTikTok } from "react-icons/ai";
import { RiInstagramFill } from "react-icons/ri";
import './OrdersDrawer.css';
import axios from 'axios';
import moment from 'moment-timezone';
import fb_logo from '../assets/fb_logo.png';
import shopify_logo from '../assets/shopify_logo.png';
import ig_logo from '../assets/ig_logo.png';
import tiktok_logo from '../assets/tiktok_logo.png';
import FB_Logo_Ext from '../assets/FB_Logo_Ext.png';
import Instagram_logo from '../assets/Instagram_logo.png';
import omni_logo_clear_background from '../assets/omni_logo_clear_background.png';
import klaviyo_logo from '../assets/klaviyo_logo.png';
import Meta_logo from '../assets/Meta_logo.png';
import { ClickAwayListener } from '@mui/material';




const OrdersDrawer = ({ showDrawer, setShowDrawer, selectedOrder, serverURL, bid }) => {
    const [customerJourney, setCustomerJourney] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state
    const fingerprintID = selectedOrder?.fingerprint_id;
    const [openTransactionDropdowns, setOpenTransactionDropdowns] = useState({});
    const [expandedGroups, setExpandedGroups] = useState({}); // Track expanded/collapsed groups

    useEffect(() => {
        if (fingerprintID) {
            setLoading(true);
            setCustomerJourney({}); // Reset customerJourney before fetching new data
            getUserDataWithOrderID(fingerprintID);
        }
    }, [fingerprintID]);


    async function getUserDataWithOrderID(fingerprintID) {
        console.log('fingerprintID', fingerprintID);
        setCustomerJourney([]);
        try {
            const response = await axios.get(`${serverURL}/getCustomerJourney?omni_business_id=${bid}&fingerprint_id=${fingerprintID}`);
            console.log(response.data);
            setCustomerJourney(response.data);
        } catch (error) {
            console.error('ERROR loading users data', error);
        } finally {
            setLoading(false); // Stop loading once data is fetched
        }
    }

    const toggleTransactionDropdown = (transactionId) => {
        setOpenTransactionDropdowns(prev => ({
            ...prev,
            [transactionId]: !prev[transactionId]
        }));
    };

    const eventTypeDisplayNames = {
        checkout_started: 'Checkout Started',
        product_viewed: 'Product Viewed',
        page_viewed: 'Page Viewed',
        session: 'Session',
        order: 'Purchase',
        checkout_completed: 'Checkout Completed',
        add_to_cart: 'Add To Cart',
    };

    const iconMapping = {
        'fb': <img style={{width: 25}} src={Meta_logo} alt='fb_logo' />,
        'Facebook': <img style={{width: 60}} src={FB_Logo_Ext} alt='fb_logo' />,
        'Instagram': <img style={{width: 20}} src={ig_logo} alt='ig_logo'/>,
        'ig': <img style={{width: 60}} src={Instagram_logo} alt='ig_logo'/>,
        'checkout_started': <img style={{width: 20}} src={shopify_logo} alt='fb_logo'/>,
        'checkout_completed': <img style={{width: 20}} src={shopify_logo} alt='fb_logo'/>,
        'page_viewed': <img style={{width: 20}} src={omni_logo_clear_background} alt='fb_logo'/>,
        'product_viewed': <img style={{width: 20}} src={shopify_logo} alt='fb_logo'/>,
        'add_to_cart': <img style={{width: 20}} src={shopify_logo} alt='fb_logo'/>,
        'direct': <img style={{width: 20}} src={shopify_logo} alt='fb_logo'/>,
        'order': <img style={{width: 20}} src={shopify_logo} alt='fb_logo'/>,
        'session': <img style={{width: 20}} src={shopify_logo} alt='fb_logo'/>,
        'TikTok': <img style={{width: 20}} src={tiktok_logo} alt='tiktok_logo'/>,
        'klaviyo': <img style={{width: 20}} src={klaviyo_logo} alt='klaviyo_logo'/>
    };

    const getIconForEventType = (eventType) => {
        return iconMapping[eventType] ;
    };

    const getDisplayName = (eventType) => {
        return eventTypeDisplayNames[eventType] || eventType;
    };

    const formatDate = (date) => {
        const dateObject = new Date(date); // Convert the string to a Date object
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        }).format(dateObject);
    };

    function formatSecondsIntoMinutesSeconds(seconds) {
        let hours = Math.floor(seconds / 3600);
        let minutes = Math.floor((seconds - (hours * 3600)) / 60);
        let remainingSeconds = seconds % 60;
        return `${hours}h ${minutes}m ${remainingSeconds}s`;
    }

    // Group consecutive events by type
    const groupConsecutiveEvents = (events) => {
        const grouped = [];
        let currentGroup = [];

        events.forEach((event, index) => {
            if (currentGroup.length === 0 || currentGroup[0].type === event.type) {
                currentGroup.push(event);
            } else {
                grouped.push(currentGroup);
                currentGroup = [event];
            }
        });

        if (currentGroup.length > 0) {
            grouped.push(currentGroup);
        }

        return grouped;
    };

    // Toggle expand/collapse state
    const toggleGroup = (index) => {
        setExpandedGroups((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };
    



    return (
            <div className={`OrderDrawerRightPane ${showDrawer ? 'open' : ''}`}>

                <div className='OrderDrawerHeader'>

                    <span className='OrderDrawerCollapseIcon' onClick={() => setShowDrawer(false)}>
                        <ChevronLeftRounded /><p>Back</p>
                    </span>
                    <div className='OrderDrawerHeaderMiddleContainer'>
                        <span className='OrderDrawerHeaderMiddleContainerCustomerName'>{selectedOrder?.first_name} {selectedOrder?.last_name}</span>
                        <span className='OrderDrawerHeaderMiddleContainerCustomerLocation'>{selectedOrder?.address?.city}, {selectedOrder?.address?.province}</span>
                    </div>
                    <span className='OrderDrawerHeaderActiveStatusInactive'>Not active</span>

                </div>

                <div className='OrderDrawerContent'>
                    <div className='OrderDrawerLeft'>
                        <div className='OrderDrawerSection'>
                            <div className='OrderDrawerSectionHeader'>
                                <span className='OrderDrawerSectionTitle'>Customer</span>
                            </div>
                            
                            <div className='OrderDrawerSectionContent'>
                                {/* <div className='OrderDrawerSectionRow'><div className='OrderDrawerSectionRowIcon'><PersonRounded fontSize='small' /></div><span className='OrderDrawerSectionRowTitle'>Full Name</span><span className='OrderDrawerSectionRowValue'> {selectedOrderId?.first_name} {selectedOrderId?.last_name} </span></div> */}
                                <div className='OrderDrawerSectionRow'><div className='OrderDrawerSectionRowLeft'><div className='OrderDrawerSectionRowIcon'><PersonRounded fontSize='small' /></div><span className='OrderDrawerSectionRowTitle'>First Name</span></div><span className='OrderDrawerSectionRowValue'> {selectedOrder?.first_name}</span></div>
                                <div className='OrderDrawerSectionRow'><div className='OrderDrawerSectionRowLeft'><div className='OrderDrawerSectionRowIcon'><PersonRounded fontSize='small' /></div><span className='OrderDrawerSectionRowTitle'>Last Name</span></div><span className='OrderDrawerSectionRowValue'> {selectedOrder?.last_name}</span></div>
                                <div className='OrderDrawerSectionRow'><div className='OrderDrawerSectionRowLeft'><div className='OrderDrawerSectionRowIcon'><EmailRounded fontSize='small' /></div><span className='OrderDrawerSectionRowTitle'>Email</span></div><span className='OrderDrawerSectionRowValue' style={{textTransform: 'none'}}> {selectedOrder?.contact_email}</span></div>
                                <div className='OrderDrawerSectionRow'><div className='OrderDrawerSectionRowLeft'><div className='OrderDrawerSectionRowIcon'><LocalPhoneRounded fontSize='small' /></div><span className='OrderDrawerSectionRowTitle'>Phone</span></div><span className='OrderDrawerSectionRowValue'> {selectedOrder?.phone}</span></div>
                                <div className='OrderDrawerSectionRow'><div className='OrderDrawerSectionRowLeft'><div className='OrderDrawerSectionRowIcon'><MyLocationRounded fontSize='small' /></div><span className='OrderDrawerSectionRowTitle'>City</span></div><span className='OrderDrawerSectionRowValue'> {selectedOrder?.address?.city}</span></div>
                                <div className='OrderDrawerSectionRow'><div className='OrderDrawerSectionRowLeft'><div className='OrderDrawerSectionRowIcon'><LocationOnRounded fontSize='small' /></div><span className='OrderDrawerSectionRowTitle'>State</span></div><span className='OrderDrawerSectionRowValue'> {selectedOrder?.address?.province}</span></div>
                                <div className='OrderDrawerSectionRow'><div className='OrderDrawerSectionRowLeft'><div className='OrderDrawerSectionRowIcon'><PublicRounded fontSize='small' /></div><span className='OrderDrawerSectionRowTitle'>Country</span></div><span className='OrderDrawerSectionRowValue'> {selectedOrder?.address?.country}</span></div>
                                <div className='OrderDrawerSectionRow'><div className='OrderDrawerSectionRowLeft'><div className='OrderDrawerSectionRowIcon'><CalendarMonthRounded fontSize='small' /></div><span className='OrderDrawerSectionRowTitle'>Created At</span></div><span className='OrderDrawerSectionRowValue' style={{textTransform: 'none'}}> {moment(selectedOrder?.customer_created_at).fromNow()}</span></div>
                                <div className='OrderDrawerSectionRow'><div className='OrderDrawerSectionRowLeft'><div className='OrderDrawerSectionRowIcon'><WatchRounded fontSize='small' /></div><span className='OrderDrawerSectionRowTitle'>Visits</span></div><span className='OrderDrawerSectionRowValue'> {customerJourney.length}</span></div>
                                <div className='OrderDrawerSectionRow'><div className='OrderDrawerSectionRowLeft'><div className='OrderDrawerSectionRowIcon'><WatchRounded fontSize='small' /></div><span className='OrderDrawerSectionRowTitle'>Browsing Time</span></div><span className='OrderDrawerSectionRowValue'> {formatSecondsIntoMinutesSeconds(Array.isArray(customerJourney) ? customerJourney.reduce((sum, obj) => sum + Number(obj.user_session_duration || 0), 0) : 0)}</span></div>

                                {customerJourney.latestOrders?.length > 0 && (
                                    <div className='OrderDrawerSectionRow'><div className='OrderDrawerSectionRowLeft'><div className='OrderDrawerSectionRowIcon'><PersonRounded fontSize='small' /></div><span className='OrderDrawerSectionRowTitle'>Lifetime Value </span></div><span className='OrderDrawerSectionRowValue'> ${customerJourney.latestOrders[0]?.total_sum}</span></div>
                                )}
                            </div>

                        </div>
                        <div className='OrderDrawerSection'>
                            <div className='OrderDrawerSectionHeader'>
                                <span className='OrderDrawerSectionTitle'>Source</span>
                            </div>
                            
                            <div className='OrderDrawerSectionContent'>
                                <div className='OrderDrawerSectionRow'><div className='OrderDrawerSectionRowLeft'><div className='OrderDrawerSectionRowIcon'><WatchRounded fontSize='small' /></div><span className='OrderDrawerSectionRowTitle'>Latest Source</span></div><span className='OrderDrawerSectionRowValue'> {getIconForEventType(customerJourney[0]?.cleaned_utm_source)}</span></div>
                                <div className='OrderDrawerSectionRow'><div className='OrderDrawerSectionRowLeft'><div className='OrderDrawerSectionRowIcon'><AccessTimeFilledRounded fontSize='small' /></div><span className='OrderDrawerSectionRowTitle'>Latest Source Date</span></div><span className='OrderDrawerSectionRowValue' style={{fontSize: 12}}> {formatDate(customerJourney[0]?.created_at || 0)}</span></div>
                                <div className='OrderDrawerSectionRow'><div className='OrderDrawerSectionRowLeft'><div className='OrderDrawerSectionRowIcon'><FolderSpecialRounded fontSize='small' /></div><span className='OrderDrawerSectionRowTitle'>Latest Source Category</span></div><span className='OrderDrawerSectionRowValue'> {customerJourney[0]?.utm_medium}</span></div>
                                <div className='OrderDrawerSectionRow'><div className='OrderDrawerSectionRowLeft'><div className='OrderDrawerSectionRowIcon'><TravelExploreRounded fontSize='small' /></div><span className='OrderDrawerSectionRowTitle'>Original Source</span></div><span className='OrderDrawerSectionRowValue'> {getIconForEventType(customerJourney[customerJourney.length - 1]?.cleaned_utm_source)}</span></div>
                                <div className='OrderDrawerSectionRow'><div className='OrderDrawerSectionRowLeft'><div className='OrderDrawerSectionRowIcon'><CalendarMonthRounded fontSize='small' /></div><span className='OrderDrawerSectionRowTitle'>Original Source Date</span></div><span className='OrderDrawerSectionRowValue' style={{fontSize: 12}}> {formatDate(customerJourney[customerJourney.length - 1]?.created_at || 0)}</span></div>
                                <div className='OrderDrawerSectionRow'><div className='OrderDrawerSectionRowLeft'><div className='OrderDrawerSectionRowIcon'><FolderRounded fontSize='small' /></div><span className='OrderDrawerSectionRowTitle'>Original Source Category</span></div><span className='OrderDrawerSectionRowValue'> {customerJourney[customerJourney.length - 1]?.utm_medium}</span></div>
                            </div>
                        </div>
                        <div className='OrderDrawerSection'>
                            <div className='OrderDrawerSectionHeader'>
                                <span className='OrderDrawerSectionTitle'>Most Recent Transaction</span>
                            </div>
                            
                            <div className='OrderDrawerSectionContent'>


                            <div className='MostRecentTransaction'>
                                {customerJourney.latestOrders?.length > 0 && (
                                    <div className="TransactionDetails">

                                        <div className='OrderDrawerSectionRow'><div className='OrderDrawerSectionRowLeft'><div className='OrderDrawerSectionRowIcon'><EventNoteOutlined fontSize='small' /></div><span className='OrderDrawerSectionRowTitle'>Date </span></div><span className='OrderDrawerSectionRowValue'> {formatDate(new Date(customerJourney.latestOrders[0].created_at))}</span></div>
                                        <div className='OrderDrawerSectionRow'><div className='OrderDrawerSectionRowLeft'><div className='OrderDrawerSectionRowIcon'><ShoppingCartOutlined fontSize='small' /></div><span className='OrderDrawerSectionRowTitle'>Amount </span></div><span className='OrderDrawerSectionRowValue'> ${customerJourney.latestOrders[0].total}</span></div>
                                        <div className='OrderDrawerSectionRow'><div className='OrderDrawerSectionRowLeft'><div className='OrderDrawerSectionRowIcon'><ReceiptOutlined fontSize='small' /></div><span className='OrderDrawerSectionRowTitle'>Order Number </span></div><span className='OrderDrawerSectionRowValue'> #{customerJourney.latestOrders[0].order_number || 'Purchase Order'}</span></div>
                                        <div className='OrderDrawerSectionRow'><div className='OrderDrawerSectionRowLeft'><div className='OrderDrawerSectionRowIcon'><InfoOutlined fontSize='small' /></div><span className='OrderDrawerSectionRowTitle'>Order ID </span></div><span className='OrderDrawerSectionRowValue'> {customerJourney.latestOrders[0].order_id}</span></div>
                                    </div>
                                )}
                            </div>
                        </div>
                            
                            {customerJourney.latestOrders?.slice(1).map((order, index) => (
                                <>
                                <span className='OrderDrawerSectionTitle'>Additional Transactions</span>
                                <div key={order.order_id} className="EventDropdown">
                                    <div className="EventDropdownHeader" onClick={() => toggleTransactionDropdown(order.order_id)}>
                                        <div className="EventIcon"><KeyboardArrowDownOutlined /></div>
                                        <div className="EventDetails">
                                            <span className="EventName">{formatDate(new Date(order.created_at))}</span>
                                        </div>
                                    </div>
                                    {openTransactionDropdowns[order.order_id] && (
                                        <div className="EventDropdownContent">
                                            <div className="TransactionDetails">
                                                <div className="TransactionItem">
                                                    <div className='accordion_warp'> <ShoppingCartOutlined /><strong>Transaction:</strong></div> ${order.total}
                                                </div>
                                                <div className="TransactionItem">
                                                    <div className='accordion_warp'><ReceiptOutlined /><strong>Order Name:</strong></div> {order.order_name || 'Purchase Order'}
                                                </div>
                                                <div className="TransactionItem">
                                                    <div className='accordion_warp'> <InfoOutlined /><strong>Order ID:</strong></div> {order.order_id}
                                                </div>
                                            </div>
                                        </div> 
                                    )}
                                </div>
                                </>
                            ))}
                        </div>
                        <div className='OrderDrawerSection'>
                            <div className='OrderDrawerSectionHeader'>
                                <span className='OrderDrawerSectionTitle'>Device</span>
                            </div>
                            
                            <div className='OrderDrawerSectionContent'>
                                <div className='OrderDrawerSectionRow'><div className='OrderDrawerSectionRowLeft'><div className='OrderDrawerSectionRowIcon'><FingerprintRounded fontSize='small'  /></div><span className='OrderDrawerSectionRowTitle'>Fingerprint ID</span></div><span className='OrderDrawerSectionRowValue' style={{fontSize: 12}}> {customerJourney[0]?.fingerprint_id}</span></div>
                                <div className='OrderDrawerSectionRow'><div className='OrderDrawerSectionRowLeft'><div className='OrderDrawerSectionRowIcon'><PublicRounded fontSize='small'  /></div><span className='OrderDrawerSectionRowTitle'>IP</span></div><span className='OrderDrawerSectionRowValue' style={{fontSize: 12}}> {customerJourney[0]?.customer_ip}</span> </div>
                                <div className='OrderDrawerSectionRow'><div className='OrderDrawerSectionRowLeft'><div className='OrderDrawerSectionRowIcon'><NetworkCellRounded fontSize='small'  /></div><span className='OrderDrawerSectionRowTitle'>Network</span></div><span className='OrderDrawerSectionRowValue'> {customerJourney[0]?.network}</span></div>
                                <div className='OrderDrawerSectionRow'><div className='OrderDrawerSectionRowLeft'><div className='OrderDrawerSectionRowIcon'><CalendarMonthRounded fontSize='small' /></div><span className='OrderDrawerSectionRowTitle'>First Active</span></div><span className='OrderDrawerSectionRowValue' style={{textTransform: 'none'}}> {moment(customerJourney[0]?.created_at).fromNow()}</span></div>
                                <div className='OrderDrawerSectionRow'><div className='OrderDrawerSectionRowLeft'><div className='OrderDrawerSectionRowIcon'><CalendarMonthRounded fontSize='small' /></div><span className='OrderDrawerSectionRowTitle'>Last Active</span></div><span className='OrderDrawerSectionRowValue' style={{textTransform: 'none'}}> {moment(customerJourney[customerJourney.length - 1]?.created_at).fromNow()}</span></div>
                            </div>
                        </div>
                    
                    </div>
             

                    <div className='OrderDrawerRight'>
                        <span className='OrderDrawerTimelineTitle'>Timeline</span>
                        {loading ? (
                            <p>Loading...</p>
                        ) : (
                            <>
                                {customerJourney.map((session, sessionIndex) => (
                                    <div key={sessionIndex} style={{ marginBottom: 80 }}>
                                        <div className='OrderDrawerTimelineSessionTitleContainer'>
                                            {getIconForEventType(session?.cleaned_utm_source)}
                                            <span className='OrderDrawerTimelineSessionTitle'>Ad Click</span>
                                        </div>
                                        {groupConsecutiveEvents(session.events).map((group, groupIndex) => (
                                            <div key={groupIndex} className='OrderDrawerTimelineEvent'>
                                                <div
                                                    className='OrderDrawerTimelineEventIcon'
                                                    onClick={() => toggleGroup(`${sessionIndex}-${groupIndex}`)}
                                                    style={{ cursor: group.length > 1 ? 'pointer' : 'default' }}
                                                >
                                                    {group.length > 1 && (
                                                        <div className='OrderDrawerTimelineEventIconEventCount'>
                                                            {group.length}
                                                        </div>
                                                    )}
                                                    {getIconForEventType(group[0].type)}
                                                </div>
                                                <div className='OrderDrawerTimelineEventDetails'>
                                                    <span className='OrderDrawerTimelineEventName'>
                                                        {getDisplayName(group[0].type)}
                                                    </span>
                                                    <span className='OrderDrawerTimelineEventTime'>
                                                        {formatDate(new Date(group[0]?.created_at || 0))}
                                                    </span>
                                                </div>
                                                {/* Render events if expanded */}
                                                {expandedGroups[`${sessionIndex}-${groupIndex}`] &&
                                                    group.length > 1 && (
                                                        <div className='OrderDrawerTimelineEventGroup'>
                                                            {group.map((event, eventIndex) => (
                                                                <div key={eventIndex} className='OrderDrawerTimelineEventDetails'>
                                                                    <span className='OrderDrawerTimelineEventName'>
                                                                        {getDisplayName(event.type)}
                                                                    </span>
                                                                    <span className='OrderDrawerTimelineEventTime'>
                                                                        {formatDate(new Date(event?.created_at || 0))}
                                                                    </span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                            </div>
                                        ))}
                                    </div>
                                ))}


                                
                                

                               
                            </>
                        )}
                    </div>
                
                </div>
            </div>
    );
};

export default OrdersDrawer;
