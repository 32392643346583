import React, { useState, useRef, useEffect } from 'react';
import Switch from "react-switch";
import './AdAccountGrid.css';
import { CheckRounded, KeyboardArrowDownRounded, KeyboardArrowUpRounded, SolarPower, ViewColumn, ViewColumnRounded, ViewWeek } from '@mui/icons-material';
import { dummyTesting } from '../AdsManager'
import EditIcon from '../../../assets/Icons/EditIcon';
import TextInput from '../../../components/TextInput';
import axios from 'axios';
import { CircularProgress, ClickAwayListener } from '@mui/material';
import { FaMeta, FaFloppyDisk } from "react-icons/fa6";
import { db } from '../../../FirebaseInit';
import { doc, getDoc } from 'firebase/firestore';
import firstClick from '../../../assets/attributionModels/firstClick.png';
import lastClick from '../../../assets/attributionModels/lastClick.png';
import linearAll from '../../../assets/attributionModels/linearAll.png';
import linearPaid from '../../../assets/attributionModels/linearPaid.png';
import uShaped from '../../../assets/attributionModels/uShaped.png';
import dayjs from 'dayjs';
import Meta_logo from '../../../assets/Meta_logo.png';
import klaviyo_logo from '../../../assets/klaviyo_logo.png';
import google_ads_logo from '../../../assets/google_ads_logo.png';
import tiktok_logo from '../../../assets/tiktok_logo.png';
import tiktok_logo_white_background from '../../../assets/tiktok_logo_white_background.png';
import onetext_logo from '../../../assets/onetext_logo.png';
import onetext_logo_cropped from '../../../assets/onetext_logo_cropped.png';
import fb_messenger_logo from '../../../assets/fb_messenger_logo.png';
import shopify_email_logo from '../../../assets/shopify_email_logo.png';
import klarna_logo from '../../../assets/klarna_logo.png';
import shop_app_logo from '../../../assets/shop_app_logo.png';
import ig_logo from '../../../assets/ig_logo.png';
import parcel_panel_logo from '../../../assets/parcel_panel_logo.png';
import snapchat_logo from '../../../assets/snapchat_logo.png';
import trustpilot_logo from '../../../assets/trustpilot_logo.png';
import shopify_logo from '../../../assets/shopify_logo.png';
import live_recover_logo from '../../../assets/live_recover_logo.png';
import applovin_logo from '../../../assets/applovin_logo.png';
import chatgpt_logo from '../../../assets/chatgpt_logo.png';






function formatTimeElapsed(seconds) {
  // format time like hh:mm:ss with each always being 2 digits and no decimals for seconds
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const formattedSeconds = Math.floor(seconds % 60);
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${formattedSeconds.toString().padStart(2, '0')}`;
}





function HeaderComponent ({adAccountColumns, sortColumn, sortOrder, handleSort, startResize, availableColumns, columnIndexes, isScrolled}) {
  return (
    <thead>
      <tr>
        {adAccountColumns?.map((col, index) => {
          const columnIndex = columnIndexes[col.key] 
          if (!columnIndex) return null;

          const isSorted = sortColumn === col.variableName;
          const sortIcon = isSorted ? (
            sortOrder === 'asc' ? (
              <KeyboardArrowUpRounded fontSize='small' style={{ color: '#888' }} />
            ) : (
              <KeyboardArrowDownRounded fontSize='small' style={{ color: '#888' }} />
            )
          ) : null;

          return (
            <th
              key={col.key}
              style={{ width: col.width }}
              onClick={() => col.sortable && handleSort(col.variableName)}
            >
              <div 
                className={`AdAccountGridTableHeaderContainer ${(col.key == 'name_meta' || col.key == 'tp_status') && 'AdAccountGridTableSticky'}`} 
                style={{boxShadow: isScrolled && index == 1 ? '0px 0px 7px #00000050' : 'none', borderRight: '1px solid #e7e7e7', boxSizing: 'border-box', width: 'calc(100% + 1px)'}}>
                {col.icon == 'tp' && <FaMeta className='AdAccountGridHeaderFacebookIcon'/>  }
                {col.icon == 'omni' && <FaFloppyDisk className='AdAccountGridHeaderOmniIcon'/>  }
                <div style={{paddingLeft: 10}}>{col.title}</div>
                <div className='AdAccountGridTableHeaderSortIcon'>{sortIcon}</div>
                <div
                  className="resizer"
                  onMouseDown={(e) => {
                    e.stopPropagation(); // Prevents the event from bubbling up to the <th>
                    startResize(index)(e);
                  }}
                  onClick={(e) => e.stopPropagation()} // Prevents click event propagation
                ></div>
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
}






function RowComponent ({ rowIndex, adAccount, adAccountColumns, columnWidths, selectedAdAccounts, adjustSelectedAdAccounts, setShowDrawer, setSelectedAdAccountForPurchasesPopup, showBudgetInput, setShowBudgetInput, 
  newBudgetValue, setNewBudgetValue, updateBudgetLoading, updateAdAccountBudget, updateAdAccountStatus, setShowPurchasesPopup, availableColumns, columnIndexes, isScrolled }) {
  return (
    <tr key={rowIndex}>
      {adAccountColumns?.map((col, index) => {
        const columnIndex = columnIndexes[col.key] 
        if (!columnIndex) return null;


        const style = { width: col.width };

        // If a custom cell renderer is provided, use it
        if (col.renderCell) {
          return col.renderCell({adAccount, columnWidths, selectedAdAccounts, adjustSelectedAdAccounts, setShowDrawer, setSelectedAdAccountForPurchasesPopup, showBudgetInput, setShowBudgetInput, 
                                newBudgetValue, setNewBudgetValue, updateBudgetLoading, updateAdAccountBudget, updateAdAccountStatus, setShowPurchasesPopup, index, isScrolled})
        }

        // Default cell rendering
        const cellData = adAccount[col.dataKey];

        return (
          <td key={col.key} style={style}>
            {cellData}
          </td>
        );
      })}
    </tr>
  );
}






function FooterComponent({ adAccountColumns, sortedAdAccounts, availableColumns, columnIndexes, isScrolled }) {
  return (
    <tr className='sticky-footer-row'>
      {adAccountColumns?.map((col, index) => {
        const columnIndex = columnIndexes[col.key] 
        if (!columnIndex) return null;

        let footerValue = '';
        

        if (col.footerOperation == 'sum') { 
          footerValue = sortedAdAccounts.reduce((accumulator, x) => { return accumulator + Number(x[col.variableName] || 0) }, 0)
        } else if (col.footerOperation == 'divide') {
          const numerator = sortedAdAccounts.reduce((accumulator, x) => { return accumulator + Number(x[col.footerNumerator] || 0) }, 0)
          const denominator = sortedAdAccounts.reduce((accumulator, x) => { return accumulator + Number(x[col.footerDenominator] || 0) }, 0)
          footerValue = numerator / denominator
        } else if (col.footerOperation == false) {
          footerValue = col.footer;
        }

        if (col.multiplyBy) {
          footerValue = footerValue * col.multiplyBy;
        }

        if (col.footerDataType == 'currency') {
          footerValue = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(footerValue);
        } else if (col.footerDataType == 'percent') {
          footerValue = new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(footerValue);
        } else if (col.footerDataType == 'number') {
          footerValue = new Intl.NumberFormat('en-US').format(footerValue);
        } else if (col.footerDataType == 'time') {
          footerValue = formatTimeElapsed(footerValue);
        } else { 
          footerValue = footerValue;
        }


        return (
          <td key={col.key} style={{ width: col.width, fontWeight: 'bold' }}>
            <div className='AdAccountGridTableSticky' style={{ paddingLeft: 10, boxShadow: isScrolled && index == 1 ? '0px 0px 7px #00000050' : 'none' }}>
              {footerValue}
            </div>
          </td>
        );
      })}
    </tr>
  );
}









function AdAccountGrid({ selectedAdAccounts, adjustSelectedAdAccounts, adAccounts, setShowDrawer, setSelectedAdAccountForPurchasesPopup, selectedColumns, filterStatus, serverURL, omni_business_id, 
  refreshDataTrigger, setRefreshDataTrigger, availableColumns, columnIndexes, setShowAttributionsWindowDropdown, showAttributionsWindowDropdown, setAttributionWindow, attributionWindow,
  setShowSelectColumnsPopup, showSelectColumnsPopup, setAttributionDropdown, showAttributionDropdown, attributionModel, setAttributionModel, setShowStatusDropdown, 
  showStatusDropdown, setFilterStatus, setShowPurchasesPopup, adAccountColumns, setAdAccountColumns, setSelectedAdLevel, setSelectedSource, loadingAdAccounts, loadingPercentage}) {

  const bid = localStorage.getItem('bid')


  const [switchStates, setSwitchStates] = useState({});
  const [filteredAdAccounts, setFiltedAdAccounts] = useState(adAccounts);
  const [sortedAdAccounts, setSortedAdAccounts] = useState(adAccounts);
  const [sortColumn, setSortColumn] = useState('status');
  const [sortOrder, setSortOrder] = useState('asc');


  const [showBudgetInput, setShowBudgetInput] = useState('');
  const [newBudgetValue, setNewBudgetValue] = useState('');
  const [updateBudgetLoading, setUpdateBudgetLoading] = useState(false);
  const [updateStatusLoading, setUpdateStatusLoading] = useState(false);
  const [updateStatusAdAccountID, setUpdateStatusAdAccountID] = useState('');

  const [isScrolled, setIsScrolled] = useState(false);
  const tableContainerRef = useRef(null);


  useEffect(() => {
    const columnData = [
      { key: 'tp_status', variableName: 'tp_status', icon: 'none', title: '', description: 'On/Off', sortable: true, width: 100, fixed: true, footerOperation: false, footer: '',
        renderCell: ({adAccount, adjustSelectedAdAccounts, setNewBudgetValue, showBudgetInput, newBudgetValue, updateBudgetLoading, updateAdAccountBudget, setShowBudgetInput, updateAdAccountStatus, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <div className='AdAccountGridTableSticky' >
              <div className='AdAccountGridSelectedAdCheckboxColumnRow'>
                
              {adAccount.tp_name == 'fb' && <img src={Meta_logo} style={{width: 30, borderRadius: 5}}/>}
              {adAccount.tp_name == 'applovin' && <img src={applovin_logo} style={{width: 25, borderRadius: 5}}/>}
              {adAccount.tp_name == 'liverecover' && <img src={live_recover_logo} style={{width: 25, borderRadius: 5}}/>}
              {adAccount.tp_name == 'chatgpt.com' && <img src={chatgpt_logo} style={{width: 25, borderRadius: 5}}/>}
              {adAccount.tp_name == 'klaviyo' && <img src={klaviyo_logo} style={{width: 30, borderRadius: 5}}/>}
              {adAccount.tp_name == 'google' && <img src={google_ads_logo} style={{width: 30, borderRadius: 5}}/>}
              {adAccount.tp_name == 'tiktok' && <img src={tiktok_logo_white_background} style={{width: 30, borderRadius: 5}}/>}
              {adAccount.tp_name == 'onetext' && <img src={onetext_logo_cropped} style={{width: 35, borderRadius: 5}}/>}
              {adAccount.tp_name == 'msg' && <img src={fb_messenger_logo} style={{width: 25, borderRadius: 5}}/>}
              {adAccount.tp_name == 'shopify_email' && <img src={shopify_email_logo} style={{width: 25, borderRadius: 5}}/>}
              {adAccount.tp_name == 'klarna' && <img src={klarna_logo} style={{width: 25, borderRadius: 5}}/>}
              {adAccount.tp_name == 'shop_app' && <img src={shop_app_logo} style={{width: 25, borderRadius: 5}}/>}
              {adAccount.tp_name == 'shop-website' && <img src={shop_app_logo} style={{width: 25, borderRadius: 5}}/>}
              {adAccount.tp_name == 'IGShopping' && <img src={ig_logo} style={{width: 25, borderRadius: 5}}/>}
              {adAccount.tp_name == 'parcelpanel' && <img src={parcel_panel_logo} style={{width: 25, borderRadius: 5}}/>}
              {adAccount.tp_name == 'snapchat' && <img src={snapchat_logo} style={{width: 25, borderRadius: 5}}/>}
              {adAccount.tp_name == 'trustpilot' && <img src={trustpilot_logo} style={{width: 25, borderRadius: 5}}/>}
              {adAccount.tp_name == 'referral' && <img src={shopify_logo} style={{width: 25, borderRadius: 5}}/>}
              {adAccount.tp_name == 'direct' && <img src={shopify_logo} style={{width: 25, borderRadius: 5}}/>}


              </div>
            </div>
          </td>
        ),
      
      },
      { key: 'tp_name', variableName: 'tp_name', icon: 'tp', title: 'Sources', description: 'Source Name', sortable: true, width: 300, fixed: true, footerOperation: false, footer: `${adAccounts?.length} Sources`,
        renderCell: ({adAccount, index, isScrolled}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <div className='AdAccountGridTableSticky' style={{ paddingLeft: 10, boxShadow: isScrolled ? '0px 0px 7px #00000050' : 'none' }} onClick={() => { setSelectedSource(adAccount.tp_name); setSelectedAdLevel(1); }}>
              {adAccount.tp_name == 'fb' && "Facebook"}
              {adAccount.tp_name == 'applovin' && "AppLovin"}
              {adAccount.tp_name == 'liverecover' && "Live Recover"}
              {adAccount.tp_name == 'chatgpt.com' && "ChatGPT"}
              {adAccount.tp_name == 'klaviyo' && "Klaviyo"}
              {adAccount.tp_name == 'google' && "Google Ads"}
              {adAccount.tp_name == 'tiktok' && "TikTok"}
              {adAccount.tp_name == 'onetext' && "OneText"}
              {adAccount.tp_name == 'msg' && "Messenger"}
              {adAccount.tp_name == 'shopify_email' && "Shopify Email"}
              {adAccount.tp_name == 'klarna' && "Klarna"}
              {adAccount.tp_name == 'shop_app' && "Shop App"}
              {adAccount.tp_name == 'shop-website' && "Shop Website"}
              {adAccount.tp_name == 'IGShopping' && "IG Shopping"}
              {adAccount.tp_name == 'parcelpanel' && "Parcel Panel"}
              {adAccount.tp_name == 'snapchat' && "Snapchat"}
              {adAccount.tp_name == 'trustpilot' && "Trustpilot"}
              {adAccount.tp_name == 'referral' && "Referral"}
              {adAccount.tp_name == 'direct' && "Direct"}

              {adAccount.tp_name != 'fb' && adAccount.tp_name != 'klaviyo' && adAccount.tp_name != 'google' && adAccount.tp_name != 'tiktok' && adAccount.tp_name != 'onetext' && adAccount.tp_name != 'msg' && adAccount.tp_name != 'shopify_email' && adAccount.tp_name != 'klarna' && adAccount.tp_name != 'shop_app' && adAccount.tp_name != 'shop-website' && adAccount.tp_name != 'IGShopping' && adAccount.tp_name != 'parcelpanel' && adAccount.tp_name != 'snapchat' && adAccount.tp_name != 'trustpilot' && adAccount.tp_name != 'referral' && adAccount.tp_name != 'direct' && adAccount.tp_name != 'applovin' && adAccount.tp_name != 'liverecover' && adAccount.tp_name != 'chatgpt.com' &&             
                <span style={{maxWidth: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{adAccount.tp_name}</span>
              }
            </div>
          </td>
        ),
      },
      { key: 'tp_daily_budget', variableName: 'tp_daily_budget', icon: 'tp', title: 'Daily Budget', description: 'Daily Budget', sortable: true, width: 150, fixed: false, footerOperation: 'sum', footerDataType: 'currency',  
        renderCell: ({adAccount, adjustSelectedAdAccounts, setNewBudgetValue, showBudgetInput, newBudgetValue, updateBudgetLoading, updateAdAccountBudget, setShowBudgetInput, updateAdAccountStatus, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <div className='AdAccountGridBudgetRow'>
             
                <>
                  {adAccount.tp_daily_budget ? (
                    <span className='AdAccountGridBudgetRowValue'>
                      {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adAccount.tp_daily_budget)}
                    </span>
                  ) : (
                    <span>-</span>
                  )}
                </>
            
            
            </div>
          </td>
        ),
      },
      { key: 'tp_delivery', variableName: 'tp_status', icon: 'tp', title: 'Delivery', description: 'Ad status', sortable: true, width: 120, footerOperation: false, 
        renderCell: ({adAccount, index}) => {
          const statusColor = adAccount.tp_status === 'ACTIVE' ? '#3acf12' : '#e7e7e7';
          return (
            <td key={adAccount.key + "_" + index} style={adAccount.width}>
              <div className='AdAccountGridDeliveryStatusRow'>
                <div className='AdAccountGridDeliveryStatusIcon' style={{ backgroundColor: statusColor }}></div>
              </div>
            </td>
          );
        },
      },
      { key: 'omni_bounce_rate', variableName: 'omni_bounce_rate', icon: 'omni', title: 'Bounce Rate', description: 'Sessions that only viewed 1 page', sortable: true, width: 150, footerOperation: 'divide', footerNumerator: 'omni_bounce_count', footerDenominator: 'omni_sessions', footerDataType: 'percent', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.omni_bounce_rate != 0 && adAccount.omni_bounce_rate != null ? new Intl.NumberFormat('en-US', { style: 'percent' }).format(adAccount.omni_bounce_rate) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_outbound_ctr', variableName: 'tp_outbound_ctr', icon: 'omni', title: 'Outbound CTR', description: 'Link click CTR ', sortable: true, width: 150, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.tp_outbound_ctr != 0 && adAccount.tp_outbound_ctr != null ? new Intl.NumberFormat('en-US').format(adAccount.tp_outbound_ctr) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_outbound_clicks', variableName: 'tp_outbound_clicks', icon: 'tp', title: 'Outbound Clicks', description: 'Link click count', sortable: true, width: 160, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.tp_outbound_clicks != 0 && adAccount.tp_outbound_clicks != null ? new Intl.NumberFormat('en-US').format(adAccount.tp_outbound_clicks) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_aov', variableName: 'tp_aov', icon: 'tp', title: 'AOV', description: 'Average order value', sortable: true, width: 120, footerOperation: 'divide', footerNumerator: 'tp_revenue', footerDenominator: 'tp_orders', footerDataType: 'currency',
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.tp_aov != 0 && adAccount.tp_aov != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adAccount.tp_aov) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_cpa', variableName: 'tp_cpa', icon: 'tp', title: 'CPA', description: 'Cost per acquisition', sortable: true, width: 120, footerOperation: 'divide', footerNumerator: 'tp_spend', footerDenominator: 'tp_orders', footerDataType: 'currency', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.tp_cpa != 0 && adAccount.tp_cpa != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adAccount.tp_cpa) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_cpm', variableName: 'tp_cpm', icon: 'tp', title: 'CPM', description: 'Cost per thousand impressions', sortable: true, width: 120, footerOperation: 'divide', footerNumerator: 'tp_spend', footerDenominator: 'tp_impressions', multiplyBy: 1000, footerDataType: 'currency',
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.tp_cpm != 0 && adAccount.tp_cpm != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adAccount.tp_cpm) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_ctr', variableName: 'tp_ctr', icon: 'tp', title: 'CTR', description: 'Click-through rate', sortable: true, width: 120, footerOperation: 'divide', footerNumerator: 'tp_clicks', footerDenominator: 'tp_impressions', footerDataType: 'percent', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.tp_ctr != 0 && adAccount.tp_ctr != null ? new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(adAccount.tp_ctr) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_cpc', variableName: 'tp_cpc', icon: 'tp', title: 'CPC', description: 'Cost per click', sortable: true, width: 120, footerOperation: 'divide', footerNumerator: 'tp_spend', footerDenominator: 'tp_clicks', footerDataType: 'currency', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.tp_cpc != 0 && adAccount.tp_cpc != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adAccount.tp_cpc) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_impressions', variableName: 'tp_impressions', icon: 'tp', title: 'Impressions', description: 'Number of impressions', sortable: true, width: 150, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.tp_impressions != 0 && adAccount.tp_impressions != null ? new Intl.NumberFormat( 'en-US' ).format(adAccount.tp_impressions) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_clicks', variableName: 'tp_clicks', icon: 'tp', title: 'Clicks', description: 'Total clicks', sortable: true, width: 120, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.tp_clicks != 0 && adAccount.tp_clicks != null ? new Intl.NumberFormat( 'en-US' ).format(adAccount.tp_clicks) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_roas', variableName: 'tp_roas', icon: 'tp', title: 'ROAS', description: 'Return on ad spend', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'tp_revenue', footerDenominator: 'tp_spend', footerDataType: 'number',  
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.tp_roas != 0 && adAccount.tp_roas != null ? new Intl.NumberFormat('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(adAccount.tp_roas) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_cvr', variableName: 'tp_cvr', icon: 'tp', title: 'CVR', description: 'Conversion rate', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'tp_orders', footerDenominator: 'tp_link_click', footerDataType: 'percent',  
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.tp_cvr != 0 && adAccount.tp_cvr != null ? new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(adAccount.tp_cvr) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_avg_session_duration', variableName: 'omni_avg_session_duration', icon: 'omni', title: 'AVG SD', description: 'Average session duration (in seconds)', sortable: true, width: 120, footerOperation: 'divide', footerNumerator: 'omni_total_session_duration', footerDenominator: 'omni_sessions', footerDataType: 'time',
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.omni_avg_session_duration != 0 && adAccount.omni_avg_session_duration != null ? formatTimeElapsed(adAccount.omni_avg_session_duration) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_avg_page_views_per_session', variableName: 'omni_avg_page_views_per_session', icon: 'omni', title: 'AVG VPS', description: 'Average pages viewed per session', sortable: true, width: 120, footerOperation: 'divide', footerNumerator: 'omni_page_viewed', footerDenominator: 'omni_sessions', footerDataType: 'number', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.omni_avg_page_views_per_session != 0 && adAccount.omni_avg_page_views_per_session != null ? new Intl.NumberFormat( 'en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 } ).format(adAccount.omni_avg_page_views_per_session) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_new_visitor_percent', variableName: 'omni_new_visitor_percent', icon: 'omni', title: 'NV %', description: 'Percentage of visitors who are new', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'omni_new_visitor', footerDenominator: 'omni_unique_sessions', footerDataType: 'percent',
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.omni_new_visitor_percent != 0 && adAccount.omni_new_visitor_percent != null ? new Intl.NumberFormat('en-US', { style: 'percent' }).format(adAccount.omni_new_visitor_percent) : '-'}</span>
          </td>
        ),
      },
      { key: 'revenue_diff', variableName: 'revenue_diff', icon: 'omni', title: 'Revenue diff', description: 'Difference in revenue between Omni and Meta', sortable: true, width: 150, footerOperation: 'sum', footerDataType: 'currency', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.revenue_diff != 0 && adAccount.revenue_diff != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adAccount.revenue_diff) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_revenue', variableName: 'tp_revenue', icon: 'tp', title: 'CV', description: 'Conversion Value', sortable: true, width: 130, footerOperation: 'sum', footerDataType: 'currency', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.tp_revenue != 0 && adAccount.tp_revenue != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adAccount.tp_revenue) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_profit', variableName: 'omni_profit', icon: 'omni', title: 'Profit', description: 'Revenue - Ads - COGS', sortable: true, width: 130, footerOperation: 'sum', footerDataType: 'currency', 
        renderCell: ({adAccount, index}) => {
          const profit = Number(adAccount.omni_profit);
          const backgroundColor = profit > 0 ? '#b5ffcf' : profit === 0 ? '#ffffff00' : '#ffaead';
          return (
            <td key={adAccount.key + "_" + index} style={{width: adAccount.width, backgroundColor: backgroundColor}}>
              {profit && profit != 0 ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(profit) : '-'}
            </td>
          );
        },
      },
      { key: 'omni_cogs', variableName: 'omni_cogs', icon: 'omni', title: 'COGS', description: 'Cost of goods sold', sortable: true, width: 120, footerOperation: 'sum', footerDataType: 'currency', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.omni_cogs != 0 && adAccount.omni_cogs != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adAccount.omni_cogs) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_new_customer_cvr', variableName: 'omni_new_customer_cvr', icon: 'omni', title: 'NC CVR', description: 'Conversion rate for new customers', sortable: true, width: 120, footerOperation: 'divide', footerNumerator: 'omni_new_customer_orders', footerDenominator: 'omni_new_visitor', footerDataType: 'percent', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.omni_new_customer_cvr != 0 && adAccount.omni_new_customer_cvr != null ? new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(adAccount.omni_new_customer_cvr) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_cvr', variableName: 'omni_cvr', icon: 'omni', title: 'CVR', description: 'Conversion rate', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'omni_orders', footerDenominator: 'omni_unique_sessions', footerDataType: 'percent', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.omni_cvr != 0 && adAccount.omni_cvr != null ? new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(adAccount.omni_cvr) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_aov', variableName: 'omni_aov', icon: 'omni', title: 'AOV', description: 'Average order value', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'omni_revenue', footerDenominator: 'omni_orders', footerDataType: 'currency', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.omni_aov != 0 && adAccount.omni_aov != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adAccount.omni_aov) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_cpa', variableName: 'omni_cpa', icon: 'omni', title: 'CPA', description: 'Cost per acquisition', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'tp_spend', footerDenominator: 'tp_orders', footerDataType: 'currency', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.omni_cpa != 0 && adAccount.omni_cpa != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adAccount.omni_cpa) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_cost_per_atc', variableName: 'omni_cost_per_atc', icon: 'omni', title: 'Cost per ATC', description: 'Average cost per add-to-cart', sortable: true, width: 150, footerOperation: 'divide', footerNumerator: 'tp_spend', footerDenominator: 'omni_add_to_cart', footerDataType: 'currency', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.omni_cost_per_atc != 0 && adAccount.omni_cost_per_atc != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adAccount.omni_cost_per_atc) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_add_to_cart', variableName: 'omni_add_to_cart', icon: 'omni', title: 'ATC', description: 'Total number of add-to-cart', sortable: true, width: 110, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.omni_add_to_cart != 0 && adAccount.omni_add_to_cart != null ? new Intl.NumberFormat( 'en-US' ).format(adAccount.omni_add_to_cart) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_new_visitor_cost', variableName: 'omni_new_visitor_cost', icon: 'omni', title: 'New visitor cost', description: 'Cost per new visitors', sortable: true, width: 160, footerOperation: 'divide', footerNumerator: 'tp_spend', footerDenominator: 'omni_new_visitor', footerDataType: 'currency', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.omni_new_visitor_cost != 0 && adAccount.omni_new_visitor_cost != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adAccount.omni_new_visitor_cost) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_new_visitor', variableName: 'omni_new_visitor', icon: 'omni', title: 'New visitors', description: 'Total new visitors', sortable: true, width: 150, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.omni_new_visitor != 0 && adAccount.omni_new_visitor != null ? new Intl.NumberFormat( 'en-US' ).format(adAccount.omni_new_visitor) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_cost_per_visitor', variableName: 'omni_cost_per_visitor', icon: 'omni', title: 'Cost per visitor', description: 'Average cost to acquire a visitor', sortable: true, width: 150, footerOperation: 'divide', footerNumerator: 'tp_spend', footerDenominator: 'omni_unique_sessions', footerDataType: 'currency', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.omni_cost_per_visitor != 0 && adAccount.omni_cost_per_visitor != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adAccount.omni_cost_per_visitor) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_unique_sessions', variableName: 'omni_unique_sessions', icon: 'omni', title: 'Unique sessions', description: 'Count of unique sessions', sortable: true, width: 160, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.omni_unique_sessions != 0 && adAccount.omni_unique_sessions != null ? new Intl.NumberFormat( 'en-US' ).format(adAccount.omni_unique_sessions) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_sessions', variableName: 'omni_sessions', icon: 'omni', title: 'Sessions', description: 'Total number of sessions', sortable: true, width: 130, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.omni_sessions != 0 && adAccount.omni_sessions != null ? new Intl.NumberFormat( 'en-US' ).format(adAccount.omni_sessions) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_new_customer_revenue', variableName: 'omni_new_customer_revenue', icon: 'omni', title: 'NCV', description: 'New customer revenue', sortable: true, width: 130, footerOperation: 'sum', footerDataType: 'currency', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.omni_new_customer_revenue != 0 && adAccount.omni_new_customer_revenue != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adAccount.omni_new_customer_revenue) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_revenue', variableName: 'omni_revenue', icon: 'omni', title: 'CV', description: 'Conversion Value', sortable: true, width: 130, footerOperation: 'sum', footerDataType: 'currency', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.omni_revenue != 0 && adAccount.omni_revenue != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adAccount.omni_revenue) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_roas', variableName: 'omni_roas', icon: 'omni', title: 'ROAS', description: 'Return on ad spend', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'omni_revenue', footerDenominator: 'tp_spend', footerDataType: 'number', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.omni_roas != 0 && adAccount.omni_roas != null ? new Intl.NumberFormat( 'en-US' ).format(adAccount.omni_roas) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_new_customer_roas', variableName: 'omni_new_customer_roas', icon: 'omni', title: 'NC ROAS', description: 'New customer return on ad spend', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'omni_new_customer_revenue', footerDenominator: 'tp_spend', footerDataType: 'number', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.omni_new_customer_roas != 0 && adAccount.omni_new_customer_roas != null ? new Intl.NumberFormat( 'en-US' ).format(adAccount.omni_new_customer_roas) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_orders', variableName: 'tp_orders', icon: 'tp', title: 'Purchases', description: 'Total number of Purchases', sortable: true, width: 120, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.tp_orders != 0 && adAccount.tp_orders != null ? new Intl.NumberFormat( 'en-US' ).format(adAccount.tp_orders) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_new_customer_order_percent', variableName: 'omni_new_customer_order_percent', icon: 'omni', title: 'NCO %', description: 'Percentage of purchases from new customers', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'omni_new_customer_orders', footerDenominator: 'omni_orders', footerDataType: 'percent', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.omni_new_customer_order_percent != 0 && adAccount.omni_new_customer_order_percent != null ? new Intl.NumberFormat('en-US', { style: 'percent' }).format(adAccount.omni_new_customer_order_percent) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_new_customer_orders', variableName: 'omni_new_customer_orders', icon: 'omni', title: 'NCP', description: 'Purchases from new customers', sortable: true, width: 110, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.omni_new_customer_orders != 0 && adAccount.omni_new_customer_orders != null ? new Intl.NumberFormat( 'en-US' ).format(adAccount.omni_new_customer_orders) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_new_customer_cpa', variableName: 'omni_new_customer_cpa', icon: 'omni', title: 'NC CPA', description: 'Cost per acquisition', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'tp_spend', footerDenominator: 'omni_new_customer_orders', footerDataType: 'currency', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.omni_new_customer_cpa != 0 && adAccount.omni_new_customer_cpa != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adAccount.omni_new_customer_cpa) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_orders', variableName: 'omni_orders', icon: 'omni', title: 'Purchases', description: 'Total number of purchases', sortable: true, width: 120, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width} onClick={() => { setSelectedAdAccountForPurchasesPopup(adAccount); setShowPurchasesPopup(true); }}>
            <span>{adAccount.omni_orders != 0 && adAccount.omni_orders != null ? new Intl.NumberFormat('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 1}).format(adAccount.omni_orders) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_spend', variableName: 'tp_spend', icon: 'tp', title: 'Spend', description: 'Total advertising spend', sortable: true, width: 130, footerOperation: 'sum', footerDataType: 'currency', 
        renderCell: ({adAccount, index}) => (
          <td key={adAccount.key + "_" + index} style={adAccount.width}>
            <span>{adAccount.tp_spend != 0 && adAccount.tp_spend != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adAccount.tp_spend) : '-'}</span>
          </td>
        ),
      },












    ];





    function sortColumnData(columnData, columnIndexes) {
      return columnData.sort((a, b) => {
        const indexA = columnIndexes[a.key] !== undefined ? columnIndexes[a.key] : Infinity;
        const indexB = columnIndexes[b.key] !== undefined ? columnIndexes[b.key] : Infinity;
        return indexA - indexB;
      });
    }
    
    const sortedColumnData = sortColumnData(columnData, columnIndexes);
    

    setAdAccountColumns(sortedColumnData)
    // setAdAccountColumns(columnDataInOrder);

  }, [columnIndexes, adAccounts]);

  



  const handleScroll = () => {
    const scrollLeft = tableContainerRef.current.scrollLeft;
    setIsScrolled(scrollLeft > 0);
  };

  useEffect(() => {
    const tableContainer = tableContainerRef.current;
    tableContainer.addEventListener('scroll', handleScroll);

    return () => {
      tableContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);





  const startResize = (index) => (e) => {
    console.log('resize')
    const startX = e.clientX;
    const startWidth = adAccountColumns[index].width;
    const onMouseMove = (e) => {
      const newWidth = startWidth + (e.clientX - startX);
      const minWidth = 50; // Minimum width
      const maxWidth = 350; // Maximum width (adjust as needed)

      // Clamp the new width between min and max values
      const clampedWidth = Math.min(maxWidth, Math.max(minWidth, newWidth));

      const newColumns = [...adAccountColumns];
      newColumns[index].width = clampedWidth;
      setAdAccountColumns(newColumns);

      
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };








  function handleSort(column) {
    console.log(column)
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }

    const sorted = [...sortedAdAccounts].sort((a, b) => {
      const aValue = isNaN(Number(a[column])) ? a[column] : Number(a[column]);
      const bValue = isNaN(Number(b[column])) ? b[column] : Number(b[column]);

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
      } else {
        if (sortOrder === 'asc') {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      }
    });

    setSortedAdAccounts(sorted);
  }






  async function filterAdAccounts(adAccounts, filterStatus) {
    if (filterStatus === 'ANY') {
      return adAccounts;
    } else {
      return adAccounts.filter((adAccount) => adAccount.status === filterStatus);
    }
  }


  useEffect(() => {
    async function fetchData() {
      console.log(adAccounts)
      setFiltedAdAccounts(await filterAdAccounts(adAccounts, filterStatus));
    }
    fetchData();
  }, [adAccounts, filterStatus])


  useEffect(() => {
    setSortedAdAccounts(filteredAdAccounts);
  }, [filteredAdAccounts])





  async function updateAdAccountStatus (adAccount_id, status, omni_business_id) {
    try {
      setUpdateStatusLoading(true)
      const response = await axios.post(`${serverURL}/updateFacebookAdAccountData`, {
        adAccount_id: adAccount_id,
        status: status,
        omni_business_id: omni_business_id
      })
      console.log("AdAccount status updated", response)
      setUpdateStatusAdAccountID('')
      setUpdateStatusLoading(false)
      setRefreshDataTrigger(!refreshDataTrigger)  
    } catch (error) {
      console.log("Error updating adAccount status", error)
      setUpdateStatusLoading(false)
    }
  }


  async function updateAdAccountBudget (adAccount_id, daily_budget, omni_business_id ) {
    try {
      setUpdateBudgetLoading(true)
      const response = await axios.post(`${serverURL}/updateFacebookAdAccountData`, {
        adAccount_id: adAccount_id,
        daily_budget: daily_budget,
        omni_business_id: omni_business_id
      })
      console.log("AdAccount budget updated", response)
      setShowBudgetInput('')
      setUpdateBudgetLoading(false)
      setRefreshDataTrigger(!refreshDataTrigger)  
    } catch (error) {
      console.log("Error updating adAccount budget", error)
      setUpdateBudgetLoading(false)
    }
  }





  return (
    <div className='AdAccountGridContainer'>
      <div className='AdAccountGridHeaderRow'>


          <div className='AdAccountGridDropdownButton' style={{width: 100}}  onClick={() => { setAttributionDropdown(!showAttributionDropdown) }}>
            <div className='AdAccountGridDropdownButtonText'>
              <span>
                {attributionModel == 'any_click' && 'Any Click'}
                {attributionModel == 'linear_all' && 'Linear All'}
                {attributionModel == 'first_click' && 'First Click'}
                {attributionModel == 'last_click' && 'Last Click'}
              </span>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', transform: showAttributionDropdown ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease-in-out'}} >
              <KeyboardArrowDownRounded/>
            </div>
            {showAttributionDropdown && (
              <ClickAwayListener onClickAway={() => setAttributionDropdown(false)}>
                <div className='AdAccountGridDropdownContainer' style={{width: 300}}>
                  <div 
                    className={attributionModel == 'any_click' ? 'AdAccountGridDropdownRowSelected' : 'AdAccountGridDropdownRow'} 
                    onClick={() => { setAttributionModel('any_click'); setAttributionDropdown(false); }}>
                      <img className='AdAccountGridDropdownImage' style={{width: 50, height: 50}} src={linearAll} />
                      <div className='AdAccountGridDropdownRightContainer'>
                        <span className='AdAccountGridDropdownRightTitle'>Any Click</span>
                        <span className='AdAccountGridDropdownRightDescription'>Each channel gets 100% </span>
                      </div>
                  </div>

                  <div 
                    className={attributionModel == 'first_click' ? 'AdAccountGridDropdownRowSelected' : 'AdAccountGridDropdownRow'} 
                    onClick={() => { setAttributionModel('first_click'); setAttributionDropdown(false); }}>
                      <img className='AdAccountGridDropdownImage' style={{width: 50, height: 50}} src={firstClick} />
                      <div className='AdAccountGridDropdownRightContainer'>
                        <span className='AdAccountGridDropdownRightTitle'>First Click</span>
                        <span className='AdAccountGridDropdownRightDescription'> 100% to first channel clicked </span>
                      </div>
                  </div>

                  <div 
                    className={attributionModel == 'last_click' ? 'AdAccountGridDropdownRowSelected' : 'AdAccountGridDropdownRow'} 
                    onClick={() => { setAttributionModel('last_click'); setAttributionDropdown(false); }}>
                      <img className='AdAccountGridDropdownImage' style={{width: 50, height: 50}} src={lastClick} />
                      <div className='AdAccountGridDropdownRightContainer'>
                        <span className='AdAccountGridDropdownRightTitle'>Last Click</span>
                        <span className='AdAccountGridDropdownRightDescription'> 100% credit to last channel clicked </span>
                      </div>
                  </div>

                  <div 
                    className={attributionModel == 'linear_all' ? 'AdAccountGridDropdownRowSelected' : 'AdAccountGridDropdownRow'} 
                    onClick={() => { setAttributionModel('linear_all'); setAttributionDropdown(false); }}>
                      <img className='AdAccountGridDropdownImage' style={{width: 50, height: 50}} src={linearAll} />
                      <div className='AdAccountGridDropdownRightContainer'>
                        <span className='AdAccountGridDropdownRightTitle'>Linear All</span>
                        <span className='AdAccountGridDropdownRightDescription'> Divides credit equally among all channels </span>
                      </div>
                  </div>

                </div>
              </ClickAwayListener>
            )}
          </div>
      
          <div className='AdAccountGridDropdownButton' style={{width: 80}} onClick={() => { setShowAttributionsWindowDropdown(!showAttributionsWindowDropdown) }}>
            <div className='AdAccountGridDropdownButtonText'>
              <span>
                {attributionWindow == '10000 days' && 'Lifetime'}
                {attributionWindow == '28 days' && '28 Days'}
                {attributionWindow == '14 days' && '14 Days'}
                {attributionWindow == '7 days' && '7 Days'}
                {attributionWindow == '1 day' && '1 Day'}
              </span>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', transform: showAttributionsWindowDropdown ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease-in-out'}} >
              <KeyboardArrowDownRounded/>
            </div>
            {showAttributionsWindowDropdown && (
              <ClickAwayListener onClickAway={() => setShowAttributionsWindowDropdown(false)}>
                <div className='AdAccountGridDropdownContainer'>
                  <span className={attributionWindow == '1 day' ? 'AdAccountGridDropdownRowSelected' : 'AdAccountGridDropdownRow'} onClick={() => { setShowAttributionsWindowDropdown(false); setAttributionWindow('1 day') }}>1 Day</span>
                  <span className={attributionWindow == '7 days' ? 'AdAccountGridDropdownRowSelected' : 'AdAccountGridDropdownRow'} onClick={() => { setShowAttributionsWindowDropdown(false); setAttributionWindow('7 days') }}>7 Days</span>
                  <span className={attributionWindow == '14 days' ? 'AdAccountGridDropdownRowSelected' : 'AdAccountGridDropdownRow'} onClick={() => { setShowAttributionsWindowDropdown(false); setAttributionWindow('14 days') }}>14 Days</span>
                  <span className={attributionWindow == '28 days' ? 'AdAccountGridDropdownRowSelected' : 'AdAccountGridDropdownRow'} onClick={() => { setShowAttributionsWindowDropdown(false); setAttributionWindow('28 days') }}>28 Days</span>
                  <span className={attributionWindow == '10000 days' ? 'AdAccountGridDropdownRowSelected' : 'AdAccountGridDropdownRow'} onClick={() => { setShowAttributionsWindowDropdown(false); setAttributionWindow('10000 days') }}>Lifetime</span>
                </div>
              </ClickAwayListener>
            )}
          </div>


        <div className='AdAccountGridDropdownButton' onClick={() => { setShowSelectColumnsPopup(!showSelectColumnsPopup) }}>
          <div className='AdAccountGridDropdownButtonText' style={{marginRight: 6}}>
            {/* <ViewColumnRounded style={{paddingRight: 10}}/> */}
            Views
          </div>                   
        </div>


        {/* <div className='AdAccountGridDropdownButton' onClick={() => { setShowStatusDropdown(!showStatusDropdown) }}>
          <div className='AdAccountGridDropdownButtonText' >
            <span>Status: {filterStatus}</span>
          </div>
          <KeyboardArrowDownRounded style={{paddingLeft: 10}} />
          {showStatusDropdown && (
            <ClickAwayListener onClickAway={() => setShowStatusDropdown(false)}>
              <div className='AdAccountGridDropdownContainer'>
                <span className={filterStatus == 'ANY' ? 'AdAccountGridDropdownRowSelected' : 'AdAccountGridDropdownRow'} onClick={() => { setFilterStatus("ANY"); setShowStatusDropdown(false) }}>Any</span>
                <span className={filterStatus == 'ACTIVE' ? 'AdAccountGridDropdownRowSelected' : 'AdAccountGridDropdownRow'} onClick={() => { setFilterStatus("ACTIVE"); setShowStatusDropdown(false) }}>Active</span>
                <span className={filterStatus == 'PAUSED' ? 'AdAccountGridDropdownRowSelected' : 'AdAccountGridDropdownRow'} onClick={() => { setFilterStatus("PAUSED"); setShowStatusDropdown(false) }}>Paused</span>
                <span className={filterStatus == 'REVIEW' ? 'AdAccountGridDropdownRowSelected' : 'AdAccountGridDropdownRow'} onClick={() => { setFilterStatus("REVIEW"); setShowStatusDropdown(false) }}>In Review</span>
              </div>
            </ClickAwayListener>
          )}
        </div> */}


      </div>



      <div className='AdAccountGridTableContainer' ref={tableContainerRef}>
        {loadingAdAccounts ?
          <div className='AdAccountLoadingContainer'>
            <div className='AdAccountLoadingProgressParent'>
              <div className='AdAccountLoadingProgressChild' style={{width: loadingPercentage + '%'}}></div>
            </div>
            {/* <CircularProgress style={{color: '#635bff', width: 30, height: 30}}/> */}
            <span className='AdAccountLoadingText'>Loading Sources... </span>
          </div>
        :
          <table className='AdAccountGridTable'>
            <HeaderComponent adAccountColumns={adAccountColumns} sortColumn={sortColumn} sortOrder={sortOrder} handleSort={handleSort} startResize={startResize} availableColumns={availableColumns} columnIndexes={columnIndexes} isScrolled={isScrolled} />
            <tbody>
              {sortedAdAccounts?.map((adAccount, index) => (
                <RowComponent
                  key={index}
                  rowIndex={adAccount.adAccount_id}
                  adAccount={adAccount}
                  adAccountColumns={adAccountColumns}
                  selectedAdAccounts={selectedAdAccounts}
                  adjustSelectedAdAccounts={adjustSelectedAdAccounts}
                  setShowDrawer={setShowDrawer}
                  setSelectedAdAccountForPurchasesPopup={setSelectedAdAccountForPurchasesPopup}
                  showBudgetInput={showBudgetInput}
                  setShowBudgetInput={setShowBudgetInput}
                  newBudgetValue={newBudgetValue}
                  setNewBudgetValue={setNewBudgetValue}
                  updateBudgetLoading={updateBudgetLoading}
                  updateAdAccountBudget={updateAdAccountBudget}
                  updateAdAccountStatus={updateAdAccountStatus}
                  setShowPurchasesPopup={setShowPurchasesPopup}
                  availableColumns={availableColumns}
                  columnIndexes={columnIndexes}
                  isScrolled={isScrolled}
                />
              ))}
              {sortedAdAccounts?.length > 0 && (
                <FooterComponent adAccountColumns={adAccountColumns} sortedAdAccounts={sortedAdAccounts} availableColumns={availableColumns} columnIndexes={columnIndexes} isScrolled={isScrolled}/>
              )}
            </tbody>

          </table>
        }
      </div>
    </div>
  );
}

export default AdAccountGrid;