import { useSortable } from '@dnd-kit/sortable';
import CustomizeColumnButton from '../../components/CustomizeColumnsPopup';
import { CSS } from '@dnd-kit/utilities';
import { DragIndicatorRounded } from '@mui/icons-material';

const SortableItem = ({ id, x, isVisible, toggleVisibility, title, description }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
        

        <div style={{ position: 'relative' }}>
            {x.fixed != true && (
                <div {...listeners} style={{ cursor: 'grab', position: 'absolute', left: '-30px', top: '8px', padding: 5 }}>
                    <DragIndicatorRounded style={{ color: '#888' }} />
                </div>
            )}
            <CustomizeColumnButton
                x={x}
                isVisible={isVisible}
                toggleVisibility={toggleVisibility}
                title={title}
                description={description}
            />
        </div>
    </div>
  );
};

export default SortableItem;
