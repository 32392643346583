import * as React from "react";


const NavCollapseIcon = (props) => (


<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 3.375C0 2.77826 0.237053 2.20597 0.65901 1.78401C1.08097 1.36205 1.65326 1.125 2.25 1.125H15.75C16.3467 1.125 16.919 1.36205 17.341 1.78401C17.7629 2.20597 18 2.77826 18 3.375V14.625C18 15.2217 17.7629 15.794 17.341 16.216C16.919 16.6379 16.3467 16.875 15.75 16.875H2.25C1.65326 16.875 1.08097 16.6379 0.65901 16.216C0.237053 15.794 0 15.2217 0 14.625V3.375ZM5.625 2.25V15.75H15.75C16.0484 15.75 16.3345 15.6315 16.5455 15.4205C16.7565 15.2095 16.875 14.9234 16.875 14.625V3.375C16.875 3.07663 16.7565 2.79048 16.5455 2.5795C16.3345 2.36853 16.0484 2.25 15.75 2.25H5.625ZM4.5 2.25H2.25C1.95163 2.25 1.66548 2.36853 1.4545 2.5795C1.24353 2.79048 1.125 3.07663 1.125 3.375V14.625C1.125 14.9234 1.24353 15.2095 1.4545 15.4205C1.66548 15.6315 1.95163 15.75 2.25 15.75H4.5V2.25Z" fill="#989898"/>
</svg>



);


export default NavCollapseIcon;
