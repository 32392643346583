
import './Privacy.css';




function PrivacyPolicy ({ serverURL, darkMode }) {

    const privacyYear = document.querySelector('.PrivacyYear');
    if (privacyYear) {
        privacyYear.textContent = new Date().getFullYear();
    }

    function acceptCookies() {
        alert("You have accepted cookies. Thank you!");
        // In a real site, you'd set a cookie or local storage item here
    }

    function declineCookies() {
        alert("You have declined cookies. Some features may not be available.");
        // In a real site, you'd set a cookie or local storage item here
    }

    function revisitConsent() {
        alert("Revisiting cookie consent preferences. You can update them here.");
        // In a real site, you'd bring up a modal or interface to adjust cookie preferences
    }

  return (
    <>
      
      <body class="PrivacyContainer">

<div class="PrivacyLogo">
    <h1 class="PrivacyH1">Omni OS</h1>
</div>

<div class="PrivacyCookieBanner">
    <p class="PrivacyParagraph">
        This website uses cookies to improve your browsing experience, for analytics, and for marketing purposes. 
        To find out more about the cookies we use, see our Privacy Policy. If you decline, your information will 
        not be tracked when you use our website.
    </p>
    <div class="PrivacyConsentButtons">
        <button class="PrivacyButton" onClick={() => acceptCookies()}>Accept</button>
        <button class="PrivacyButton" onClick={() => declineCookies()}>Decline</button>
    </div>
</div>

<div class="PrivacyRevisitConsent">
    <button class="PrivacyRevisitConsentBtn" onClick={() => revisitConsent()}>Revisit consent</button>
</div>

<h2 class="PrivacyH2">Privacy Notice</h2>
<p class="PrivacyLastUpdated">Last updated: January 23, 2025</p>

<p class="PrivacyParagraph">
    This Privacy Notice describes how Omni OS (hereinafter referred to as “Omni OS,” “we,” “our,” or “us”) collects, 
    stores, uses, and discloses personal data when you interact with us. This may include, but is not limited to, 
    when you visit or interact with any of our linked websites, participate in any of our events, interact with any 
    of our online ads and content, emails, sales and marketing channels, integrations, or any communications under 
    our control (collectively, our “sites” or “Services”).
</p>

<p class="PrivacyParagraph">
    We are committed to transparency and want you to understand how we handle personal data. Please review this 
    Privacy Notice carefully and use the information herein to make informed choices. If you have any concerns or 
    questions about our privacy practices, feel free to contact us at <strong><a class="PrivacyLink" href="mailto:omnimediaapp@gmail.com">omnimediaapp@gmail.com</a></strong>.
</p>

<p class="PrivacyParagraph">
    By accessing our sites, registering for an account, or making a purchase or subscription from us, you are 
    agreeing to the terms set forth in this Privacy Notice. If you do not agree with any portion of this Privacy 
    Notice, please do not use our sites or Services, purchase our products, interact with us, or give us any of 
    your information.
</p>

<p class="PrivacyParagraph">
    Our Services are designed for businesses and are not intended for personal or household use. We treat all 
    personal data covered by this Privacy Notice — including information about our website visitors or any 
    individuals acting on behalf of a business — as pertaining to individuals in their capacity as business 
    representatives.
</p>

<p class="PrivacyParagraph">
    You are not legally required to provide us with any personal data. If you do not wish to provide us with 
    personal data or to have it processed by us or our service providers, please do not provide it and avoid 
    interacting with our Services.
</p>

<p class="PrivacyParagraph">
    Specifically, this Privacy Notice describes our practices regarding:
</p>
<ol class="PrivacyOl">
    <li>Data Collection &amp; Processing</li>
    <li>Data Uses &amp; Lawful Bases for Processing</li>
    <li>Data Storage and Transfer</li>
    <li>How Long We Retain Your Personal Data</li>
    <li>How We Share or Otherwise Disclose Your Personal Data</li>
    <li>Cookies, Data Collection Technologies &amp; Do Not Track</li>
    <li>Communications</li>
    <li>Data Security</li>
    <li>Your Data Subject Rights</li>
    <li>Data Controller/Processor</li>
    <li>Opt-Out of Sale/Sharing for Targeted Advertising</li>
    <li>Additional Notices &amp; Contact Details</li>
</ol>

<div class="PrivacySectionDivider"></div>
<h2 class="PrivacyH2">1. Data Collection &amp; Processing</h2>
<p class="PrivacyParagraph">
    In this Privacy Notice, “personal data” means any information that identifies, relates to, describes, or is 
    reasonably capable of being associated with or linked to an individual. It does not include aggregated or 
    anonymized information that cannot be reasonably linked to an individual. Omni OS does not knowingly collect, 
    use, or disclose sensitive personal data unless strictly required or legally permissible.
</p>

<p class="PrivacyParagraph">
    We collect the following categories of personal data from the following categories of individuals:
</p>

<ul class="PrivacyUl">
    <li>
        <strong>Merchant Data:</strong> Personal data that we collect or process on behalf of our business customers 
        (“merchants” or “customers”) in the course of providing our SaaS-based Services (e.g., if you integrate our 
        Service with platforms like Shopify, Facebook, Google, or other third-party integrations). We process this 
        data under the instructions of the applicable merchant and in accordance with our Data Processing Addendum. 
        This Privacy Notice covers Omni OS’s own data processing practices, but any data processed solely on behalf 
        of a customer is governed by the customer’s privacy policies. Please contact the applicable customer if you 
        have questions about their specific privacy practices.
    </li>
    <li>
        <strong>User Data:</strong> Personal data about individuals who engage with Omni OS on behalf of a business, 
        including administrators, billing contacts, and authorized signatories. This data can include contact 
        information (like name, email, phone number), job titles, and any feedback or preferences shared with us.
    </li>
    <li>
        <strong>Prospect Data:</strong> Personal data relating to visitors to our websites, participants at our 
        events, or potential business partners or customers who interact with Omni OS in any way (e.g., filling out 
        a contact form, requesting a demo, or signing up for marketing materials).
    </li>
</ul>

<p class="PrivacyParagraph">
    The types of personal data we collect may include:
</p>
<ul class="PrivacyUl">
    <li>
        <strong>Usage and device information:</strong> IP address, approximate location (geolocation), browser type, 
        operating system, device type, unique device identifiers, pages visited, session recordings, cookies or 
        similar tracking technologies.
    </li>
    <li>
        <strong>Account information:</strong> When you create an account with Omni OS, we may collect your login 
        credentials (email address, password, or other authentication methods), billing details, and contact 
        information.
    </li>
    <li>
        <strong>Communications data:</strong> Any correspondence you send to us (emails, phone calls, chats, support 
        tickets), feedback, or testimonials.
    </li>
    <li>
        <strong>Transaction data:</strong> Payment details, purchase history, or other information related to the 
        transaction of Services or products.
    </li>
</ul>

<p class="PrivacyParagraph">
    In the past 12 months and to the extent applicable under the California Consumer Privacy Act (CCPA), we have 
    collected the following categories of personal information: Identifiers, Customer Record Information, Internet 
    or Other Electronic Network Activity Information, Professional or Employment-Related Information, Geolocation 
    Data, Commercial Information, Inferences, and Audio/Electronic/Visual information.
</p>

<p class="PrivacyParagraph">
    We collect this personal information from various sources:
</p>
<ul class="PrivacyUl">
    <li>Directly from you, including automatically (e.g., when you visit our sites)</li>
    <li>From our customers (e.g., Merchant Data, User Data provided by the account owner)</li>
    <li>Through integrations with third-party applications or services</li>
    <li>From our service providers who help us operate our business or provide the Services</li>
</ul>

<div class="PrivacySectionDivider"></div>
<h2 class="PrivacyH2">2. Data Uses &amp; Lawful Bases for Processing</h2>

<p class="PrivacyParagraph">
    We use personal data for the following business and commercial purposes (and rely on the listed lawful bases 
    where applicable under relevant data protection laws like the GDPR):
</p>

<ol class="PrivacyOl">
    <li><strong>Provide and maintain Services:</strong> We use personal data to operate, maintain, and improve our 
        SaaS platform, ensure technical functionality, and secure access to the platform.
    </li>
    <li><strong>Communications:</strong> We may send important communications regarding changes to our Services, 
        security alerts, or Service updates. We do this to fulfill our contractual obligations and for legitimate 
        interest in ensuring you are informed.
    </li>
    <li><strong>Billing and account management:</strong> We process personal data to manage billing, invoices, 
        subscription renewals, and to resolve payment-related issues.
    </li>
    <li><strong>Marketing and analytics:</strong> We may use your data to provide promotional communications 
        (subject to your consent, if required by law), analyze engagement, and personalize content or ads. Where 
        required, we rely on your consent or our legitimate interest in growing our business.
    </li>
    <li><strong>Compliance, fraud detection and prevention:</strong> We may use personal data to comply with legal 
        obligations, and to detect or prevent fraud, security threats, or other malicious activities.
    </li>
</ol>

<p class="PrivacyParagraph">
    Where we rely on your consent for processing, you have the right to withdraw that consent at any time by 
    contacting us at <a class="PrivacyLink" href="mailto:omnimediaapp@gmail.com">omnimediaapp@gmail.com</a>.
</p>

<div class="PrivacySectionDivider"></div>
<h2 class="PrivacyH2">3. Data Storage and Transfer</h2>

<p class="PrivacyParagraph">
    We and our authorized service providers maintain, store, and process personal data in various locations, 
    including but not limited to the United States, Canada, the European Economic Area (EEA), and other regions if 
    needed for the proper delivery of our Services or as required by law.
</p>

<p class="PrivacyParagraph">
    While privacy laws differ across jurisdictions, Omni OS is committed to protecting personal data in accordance 
    with this Privacy Notice and customary industry standards, regardless of where the data is processed. We 
    implement adequate safeguards (such as Standard Contractual Clauses, where applicable) to ensure lawful data 
    transfers.
</p>

<p class="PrivacyParagraph">
    If you are located in the EEA, UK, or Switzerland, we may transfer your data to countries deemed by the 
    European Commission or other relevant authorities not to offer an adequate level of data protection. In these 
    cases, we ensure your personal data is protected by entering into EU Standard Contractual Clauses or equivalent 
    measures.
</p>

<p class="PrivacyParagraph">
    Note that Omni OS may also rely on compliance frameworks for data transfers (e.g., Data Privacy Framework or 
    similar mechanisms), as appropriate and if certified.
</p>

<div class="PrivacySectionDivider"></div>
<h2 class="PrivacyH2">4. How Long We Retain Your Personal Data</h2>

<p class="PrivacyParagraph">
    We retain personal data for as long as necessary to fulfill the purposes for which it was collected, comply 
    with legal obligations, resolve disputes, enforce agreements, or protect our legal rights. 
</p>

<p class="PrivacyParagraph">
    The specific duration for retaining different categories of personal data may vary. We use the following 
    criteria to determine the retention period:
</p>

<ul class="PrivacyUl">
    <li>The nature and sensitivity of the personal data</li>
    <li>The purpose for which the personal data was collected</li>
    <li>Legal or regulatory obligations that require us to retain the data</li>
    <li>Limitations periods for potential disputes or lawsuits</li>
</ul>

<p class="PrivacyParagraph">
    Once the applicable retention period expires, we securely delete or anonymize your personal data in accordance 
    with industry-standard practices, unless otherwise required by law or in compliance with our specific 
    agreements with you.
</p>

<div class="PrivacySectionDivider"></div>
<h2 class="PrivacyH2">5. How We Share or Otherwise Disclose Your Personal Data</h2>

<p class="PrivacyParagraph">
    We do not sell, rent, or lease your personal data. We only share or disclose personal data in the following 
    ways:
</p>

<ul class="PrivacyUl">
    <li>
        <strong>Service Providers:</strong> We employ third parties to perform tasks on our behalf (e.g., hosting, 
        data security, analytics, payment processing, marketing, support). These service providers may only access 
        your personal data to perform these tasks and are contractually bound to protect it.
    </li>
    <li>
        <strong>Business Partners and Integrations:</strong> When you choose to connect or integrate our Services 
        with third parties (e.g., Shopify, Facebook, Google Analytics), your personal data may be shared with them 
        to facilitate or enhance the Services. We do not receive or store your passwords for any of these 
        third-party platforms but may use API keys or similar mechanisms to integrate.
    </li>
    <li>
        <strong>Event Sponsors or Co-Hosts:</strong> If you register for or attend an event sponsored or co-hosted 
        by Omni OS and a third party, we may share your registration details with that sponsor, subject to your 
        consent where required.
    </li>
    <li>
        <strong>Customers:</strong> Where we process personal data on behalf of our customers, we disclose that 
        personal data to the relevant customer in accordance with our Data Processing Addendum or other agreements.
    </li>
    <li>
        <strong>Legal Compliance and Protection:</strong> We may disclose or allow access to your personal data if 
        we believe in good faith that it is necessary to comply with laws, enforce our terms, protect our rights or 
        property, or respond to lawful requests from public authorities.
    </li>
    <li>
        <strong>Change in Control:</strong> If Omni OS undergoes a merger, acquisition, or sale of all or a portion 
        of its assets, your personal data may be disclosed in the due diligence process or transferred as part of 
        such a transaction. Any acquirer or successor will be subject to the rights and obligations outlined in 
        this Privacy Notice.
    </li>
    <li>
        <strong>With Your Consent:</strong> We may share your personal data for other purposes not listed here if 
        we provide you with prior notice and you consent.
    </li>
</ul>

<p class="PrivacyParagraph">
    For the purposes of the CCPA, within the last 12 months, we may have disclosed Identifiers, Customer Record 
    Information, Internet or Other Electronic Network Activity Information, Professional or Employment-Related 
    Information, Geolocation Data, Commercial Information, Inferences, and Audio/Electronic/Visual information to 
    service providers, business partners, or through integrations in pursuit of the business and commercial 
    purposes described above.
</p>

<div class="PrivacySectionDivider"></div>
<h2 class="PrivacyH2">6. Cookies, Data Collection Technologies &amp; Do Not Track</h2>

<p class="PrivacyParagraph">
    Omni OS and our service providers use cookies, pixel tags, container tags, and similar technologies 
    (collectively, “cookies”) to provide and improve the Services, analyze usage, and deliver personalized 
    advertising. 
</p>

<p class="PrivacyParagraph">
    A “cookie” is a small text file that is placed on your device when you visit a website. It can be classified as:
</p>

<ul class="PrivacyUl">
    <li><strong>Session Cookies:</strong> Expire after you close your browser</li>
    <li><strong>Persistent Cookies:</strong> Remain on your device for a set period or until you delete them</li>
</ul>

<p class="PrivacyParagraph">
    Examples of how we use cookies include:
</p>

<ul class="PrivacyUl">
    <li>Keeping you logged in</li>
    <li>Remembering your preferences and settings</li>
    <li>Analyzing website traffic and trends</li>
    <li>Delivering and measuring the effectiveness of advertisements</li>
</ul>

<p class="PrivacyParagraph">
    We may use analytics tools such as Google Analytics to track and analyze data regarding the performance of our 
    Services and user behavior. You can learn more about how Google collects and processes data by visiting <a class="PrivacyLink" href="https://www.google.com/policies/privacy/partners">www.google.com/policies/privacy/partners</a>. 
    To opt out of Google Analytics, visit <a class="PrivacyLink" href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.
</p>

<p class="PrivacyParagraph">
    Most browsers allow you to control cookies, including whether to accept or reject them and how to remove them. 
    Please note that disabling cookies may impact certain features of our Services. 
</p>

<p class="PrivacyParagraph">
    Our Services currently do not respond to “Do Not Track” (DNT) signals. However, you can control certain 
    tracking mechanisms using your browser settings or third-party tools.
</p>

<div class="PrivacySectionDivider"></div>
<h2 class="PrivacyH2">7. Communications</h2>

<h3 class="PrivacyH3">Service Communications</h3>
<p class="PrivacyParagraph">
    We may contact you with important information regarding our Services. For example, we may send confirmations, 
    technical or security notices, and updates about changes to our policies or the Services. You cannot opt out of 
    receiving these communications, as they are integral to the Services.
</p>

<h3 class="PrivacyH3">Promotional Communications</h3>
<p class="PrivacyParagraph">
    We may also send you promotional content regarding new features, events, or other opportunities, subject to 
    obtaining any necessary consent under applicable law. To opt out of such promotional communications, you can 
    use the “unsubscribe” link within the communication or contact us at <a class="PrivacyLink" href="mailto:omnimediaapp@gmail.com">omnimediaapp@gmail.com</a>.
</p>

<div class="PrivacySectionDivider"></div>
<h2 class="PrivacyH2">8. Data Security</h2>
<p class="PrivacyParagraph">
    Omni OS implements industry-standard physical, organizational, and technological measures to protect your 
    personal data against unauthorized access, disclosure, alteration, or destruction. However, no security system 
    is completely foolproof. If you have any reason to believe your account or data is no longer secure, please 
    contact us immediately at <a class="PrivacyLink" href="mailto:omnimediaapp@gmail.com">omnimediaapp@gmail.com</a>.
</p>

<div class="PrivacySectionDivider"></div>
<h2 class="PrivacyH2">9. Your Data Subject Rights</h2>
<p class="PrivacyParagraph">
    Depending on your location and applicable law (e.g., the EU or UK GDPR, the California Consumer Privacy Act 
    “CCPA,” or other laws), you may have certain rights regarding your personal data, such as:
</p>

<ul class="PrivacyUl">
    <li><strong>Right to access/know</strong>: You can request more information about the personal data we hold 
        about you and a copy of that data.</li>
    <li><strong>Right to rectify/correct</strong>: You can ask us to correct inaccurate personal data.</li>
    <li><strong>Right to erase/delete</strong>: You can request that we erase your personal data, subject to 
        certain legal exceptions.</li>
    <li><strong>Right to restrict/limit processing</strong>: You can ask us to limit the processing of your 
        personal data in certain circumstances.</li>
    <li><strong>Right to portability</strong>: You have the right to receive a copy of your personal data in a 
        structured, commonly used, and machine-readable format.</li>
    <li><strong>Right to object</strong>: You can object to certain processing, including direct marketing or when 
        we base processing on legitimate interests.</li>
    <li><strong>Right to non-discrimination</strong>: We will not discriminate against you for exercising any of 
        these rights.</li>
</ul>

<p class="PrivacyParagraph">
    To exercise these rights, please contact us at <a class="PrivacyLink" href="mailto:omnimediaapp@gmail.com">omnimediaapp@gmail.com</a>. We may request 
    additional information to verify your identity and process your request. If we cannot comply with your request, 
    we will explain why in our response.
</p>

<p class="PrivacyParagraph">
    If you are located in the EEA, UK, or Switzerland and are dissatisfied with our handling of a complaint, you 
    have the right to contact your local data protection authority.
</p>

<div class="PrivacySectionDivider"></div>
<h2 class="PrivacyH2">10. Data Controller/Processor</h2>
<p class="PrivacyParagraph">
    Under various data protection laws, Omni OS may act as either a “data controller” (or “business” under the 
    CCPA) or “data processor” (or “service provider” under the CCPA).
</p>

<ul class="PrivacyUl">
    <li>
        <strong>Omni OS as Data Controller:</strong> We are the controller of Prospect Data or User Data when we 
        decide why and how the data is processed (e.g., for marketing or operational improvements).
    </li>
    <li>
        <strong>Omni OS as Data Processor:</strong> We may process personal data on behalf of a business customer 
        (Merchant Data) as part of our SaaS Services. In this scenario, our customer is the data controller, and we 
        act in accordance with their instructions as stated in our Data Processing Addendum or similar agreements.
    </li>
</ul>

<p class="PrivacyParagraph">
    If you have questions about how your data is processed in the context of a specific merchant, please contact 
    that merchant directly, as they are responsible for handling data subject rights requests related to Merchant 
    Data.
</p>

<div class="PrivacySectionDivider"></div>
<h2 class="PrivacyH2">11. Opt-Out of Sale/Sharing for Targeted Advertising</h2>

<p class="PrivacyParagraph">
    Under some U.S. state laws (like the CCPA), disclosing certain internet activity and device information with 
    third parties through cookies may be considered a “sale” or “sharing” of personal data for the purpose of 
    targeted advertising. If you would like to opt out of such “sale” or “sharing” (where applicable), you can:
</p>

<ul class="PrivacyUl">
    <li>Use a “Do Not Sell or Share My Personal Information” link or button if provided on our site (e.g., in the footer).</li>
    <li>Adjust your cookie preferences in our cookie banner or via your browser’s Global Privacy Control (GPC) settings.</li>
</ul>

<p class="PrivacyParagraph">
    In the last 12 months, for the purposes of targeted advertising, Omni OS may have “sold” or “shared” certain 
    data, such as Internet or other electronic network activity information, with our analytics and advertising 
    partners. We do so to provide and improve our Services and to personalize user experiences.
</p>

<div class="PrivacySectionDivider"></div>
<h2 class="PrivacyH2">12. Additional Notices &amp; Contact Details</h2>

<h3 class="PrivacyH3">Updates and Amendments</h3>
<p class="PrivacyParagraph">
    We may update this Privacy Notice from time to time by posting a new version on our website or Services. The 
    updated version will become effective as soon as it is posted. If we make significant changes, we may notify 
    you via email or through the Services before these changes take effect.
</p>

<h3 class="PrivacyH3">External Links</h3>
<p class="PrivacyParagraph">
    Our Services may include links to third-party websites or services that we do not own or control. We are not 
    responsible for the privacy practices of these third parties. We encourage you to review their privacy policies 
    to understand their data practices.
</p>

<h3 class="PrivacyH3">Children</h3>
<p class="PrivacyParagraph">
    Our Services are intended for use by businesses and their representatives. We do not knowingly collect personal 
    data from minors under the age where parental consent is required. If you believe we have inadvertently 
    collected such data, please contact us at <a class="PrivacyLink" href="mailto:omnimediaapp@gmail.com">omnimediaapp@gmail.com</a>, and we will promptly 
    delete it.
</p>

<h3 class="PrivacyH3">Contacting Us</h3>
<p class="PrivacyParagraph">
    If you have any questions, comments, or concerns about this Privacy Notice or our data practices, please 
    contact us at:
</p>

<p class="PrivacyParagraph">
    Omni OS <br/>
    Email: <a class="PrivacyLink" href="mailto:omnimediaapp@gmail.com">omnimediaapp@gmail.com</a>
</p>

<p class="PrivacyParagraph">
    We will do our best to respond to your request or inquiry within a reasonable timeframe.
</p>

<div class="PrivacySectionDivider"></div>

<h3 class="PrivacyH3">Thank You</h3>
<p class="PrivacyParagraph">
    Thank you for taking the time to review our Privacy Notice. We are committed to protecting your data and 
    providing a safe, transparent experience with Omni OS.
</p>

<p class="PrivacyParagraph">&copy; <span class="PrivacyYear"></span> Omni OS. All rights reserved.</p>



</body>

    </>
  );
}

export default PrivacyPolicy;