// Analytics.jsx

import { ClickAwayListener, Button, Modal, TextField, FormControlLabel, Checkbox, Tabs, Tab } from '@mui/material';
import DateSelector from '../../components/DateSelector';
import './Analytics.css';
import dayjs from 'dayjs';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import ChartComponent from '../../components/Charts/AnalyticsChart';
import { startOfDay, subDays } from 'date-fns';
import JsxParser from 'react-jsx-parser';
import CalendarIcon from '../../assets/Icons/CalendarIcon';
import shopify_logo from '../../assets/shopify_logo.png';
import Meta_logo from '../../assets/Meta_logo.png';
import tiktok_logo_white_background from '../../assets/tiktok_logo_white_background.png';
import google_ads_logo from '../../assets/google_ads_logo.png';

// Import React Grid Layout components
import GridLayout from 'react-grid-layout';
import { AddchartRounded, AddRounded, BarChartRounded, CheckRounded, CloseRounded, DashboardRounded, DeleteForeverRounded, DensitySmallRounded, DragIndicatorRounded, EditRounded, ExpandMoreRounded, GridViewRounded, HighlightOffRounded, LegendToggleRounded, LineAxisRounded, PieChartRounded, Save, SaveOutlined, ShareRounded, ShowChartRounded, StackedLineChartRounded, StarRounded, TableChartRounded, TextFieldsRounded, WebAssetRounded, WidthWideRounded } from '@mui/icons-material';

// Import Firebase
import { getFirestore, collection, doc, setDoc, getDocs, updateDoc, addDoc, deleteDoc, query, where, writeBatch, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../../FirebaseInit';
import ProfitByDay from '../../components/ProfitByDay';
import BuildMetric from './BuildMetric';
import AnalyticsDropdown from './AnalyticsDropdown';







function ComponentTest() {
  return (
    <div>
      <h3>My New Tile</h3>
      <p>This is the content of the new tile.</p>
    </div>
  );
}






function Analytics({ darkMode, serverURL, setSnackbarInfo }) {
  const bid = localStorage.getItem('bid');
  const uid = localStorage.getItem('uid');

  const [showDateSelectors, setShowDateSelectors] = useState(false);
  const [loadingAnalytics, setLoadingAnalytics] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [drawerContents, setDrawerContents] = useState('personal');

  const [showHeaderDropdown, setShowHeaderDropdown] = useState(false);
  const [customizeLayout, setCustomizeLayout] = useState(false);
  const [showCompareData, setShowCompareData] = useState(false);
  const [showLegend, setShowLegend] = useState(false);
  const [showAxisLabels, setShowAxisLabels] = useState(false); 
  const [showGridLines, setShowGridLines] = useState(false);

  const [finalDateStart, setFinalDateStart] = useState(
    startOfDay(subDays(new Date(), 8))
  );
  const [finalDateEnd, setFinalDateEnd] = useState(
    startOfDay(subDays(new Date(), 1))
  );
  const [finalCompareDateStart, setFinalCompareDateStart] = useState(
    startOfDay(subDays(new Date(), 19))
  );
  const [finalCompareDateEnd, setFinalCompareDateEnd] = useState(
    startOfDay(subDays(new Date(), 9))
  );

  const [dateStart, setDateStart] = useState(startOfDay(subDays(new Date(), 8)));
  const [dateEnd, setDateEnd] = useState(startOfDay(subDays(new Date(), 1)));
  const [compareDateStart, setCompareDateStart] = useState(
    startOfDay(subDays(new Date(), 15))
  );
  const [compareDateEnd, setCompareDateEnd] = useState(
    startOfDay(subDays(new Date(), 9))
  );

  const [dateUpdateTrigger, setDateUpdateTrigger] = useState(false);

  const [showHourly, setShowHourly] = useState(false);
  const [showDaily, setShowDaily] = useState(true);

  const [narrowMode, setNarrowMode] = useState(true);
  const [containerWidth, setContainerWidth] = useState(window.innerWidth);

  const [showChartTypeDropdown, setShowChartTypeDropdown] = useState(false);

  // Firebase initialization
  const auth = getAuth();

  // State for saved reports
  const [savedReports, setSavedReports] = useState([]);
  const [currentReportId, setCurrentReportId] = useState(null);
  const [currentReport, setCurrentReport] = useState(null);

  // State for custom metrics
  const [showCustomMetricPopup, setShowCustomMetricPopup] = useState(false);
  const [customMetricName, setCustomMetricName] = useState('');
  // Instead of a single array, maintain multiple lines.
  // Each element of customMetricLines is an array of { operand, operation }
  const [customMetricLines, setCustomMetricLines] = useState([[]]);
  const [customMetrics, setCustomMetrics] = useState([]);

  const [showSaveReportModal, setShowSaveReportModal] = useState(false);
  const [newReportName, setNewReportName] = useState('');
  const [isDefaultReport, setIsDefaultReport] = useState(false);

  const [businessUsers, setBusinessUsers] = useState([]);
  const [showShareReportPopup, setShowShareReportPopup] = useState(false);
  const [searchUserText, setSearchUserText] = useState('');
  const [sharedWithUsersArray, setSharedWithUsersArray] = useState([]);

  const [showRenameReportPopup, setShowRenameReportPopup] = useState(false);
  const [renameReportText, setRenameReportText] = useState('');

  const [editingTextMetricKey, setEditingTextMetricKey] = useState(null);

  const safeDivide = (numerator, denominator) => {
    return denominator ? numerator / denominator : 0;
  };

  const safeMultiply = (a, b) => {
    return a * b;
  }

  const safeAdd = (a, b) => {
    return a + b;
  }

  const safeSubtract = (a, b) => {
    return a - b;
  }

  const defaultMetrics = [
    // ----- Shopify Metrics -----
    { name: 'Shopify Orders', key: 'shopify_orders', dataVariables: ['shopify_orders'], formulas: [null], type: 'number', chartType: 'line', source: 'shopify', visible: false, premade: true },
    { name: 'Shopify Revenue', key: 'shopify_revenue', dataVariables: ['shopify_revenue'], formulas: [null], type: 'dollar', chartType: 'line', source: 'shopify', visible: false, premade: true },
    { name: 'Shopify Refunds', key: 'shopify_refunds', dataVariables: ['shopify_refunds'], formulas: [null], type: 'dollar', chartType: 'line', source: 'shopify', visible: false, premade: true },
    { name: 'Shopify COGS', key: 'shopify_cogs', dataVariables: ['shopify_cogs'], formulas: [null], type: 'dollar', chartType: 'line', source: 'shopify', visible: false, premade: true },
    { name: 'Shopify Profit', key: 'shopify_profit', dataVariables: ['shopify_profit'], formulas: [null], type: 'dollar', chartType: 'line', source: 'shopify', visible: false, premade: true },
    { name: 'Shopify Transaction Fees', key: 'shopify_transaction_fees', dataVariables: ['shopify_transaction_fees'], formulas: [null], type: 'dollar', chartType: 'line', source: 'shopify', visible: false, premade: true },
    { name: 'Shopify Gross Sales', key: 'shopify_gross_sales', dataVariables: ['shopify_gross_sales'], formulas: [null], type: 'dollar', chartType: 'line', source: 'shopify', visible: false, premade: true },
    { name: 'Shopify Discounts', key: 'shopify_discounts', dataVariables: ['shopify_discounts'], formulas: [null], type: 'dollar', chartType: 'line', source: 'shopify', visible: false, premade: true },
    { name: 'Shopify Shipping', key: 'shopify_shipping', dataVariables: ['shopify_shipping'], formulas: [null], type: 'dollar', chartType: 'line', source: 'shopify', visible: false, premade: true },
    { name: 'Shopify Tax', key: 'shopify_tax', dataVariables: ['shopify_tax'], formulas: [null], type: 'dollar', chartType: 'line', source: 'shopify', visible: false, premade: true },
    { name: 'Omni Custom Expenses', key: 'omni_custom_expenses', dataVariables: ['omni_custom_expenses'], formulas: [null], type: 'dollar', chartType: 'line', source: 'shopify', visible: false, premade: true },
    { name: 'Shopify Avg Order Value', key: 'shopify_avg_order_value', dataVariables: ['shopify_avg_order_value'], formulas: [null], type: 'dollar', chartType: 'line', source: 'shopify', visible: false, premade: true },
    { name: 'Shopify COGS Percentage', key: 'shopify_cogs_percentage', dataVariables: ['shopify_cogs_percentage'], formulas: [null], type: 'percentage', chartType: 'line', source: 'shopify', visible: false, premade: true },
    { name: 'Shopify Profit Margin', key: 'shopify_profit_margin', dataVariables: ['shopify_profit_margin'], formulas: [null], type: 'percentage', chartType: 'line', source: 'shopify', visible: false, premade: true },
    { name: 'Shopify Transaction Fee Percentage', key: 'shopify_transaction_fee_percentage', dataVariables: ['shopify_transaction_fee_percentage'], formulas: [null], type: 'percentage', chartType: 'line', source: 'shopify', visible: false, premade: true },
    { name: 'Shopify Refund Rate', key: 'shopify_refund_rate', dataVariables: ['shopify_refund_rate'], formulas: [null], type: 'percentage', chartType: 'line', source: 'shopify', visible: false, premade: true },
    { name: 'Shopify Discount Rate', key: 'shopify_discount_rate', dataVariables: ['shopify_discount_rate'], formulas: [null], type: 'percentage', chartType: 'line', source: 'shopify', visible: false, premade: true },
    { name: 'Shopify Shipping Percentage', key: 'shopify_shipping_percentage', dataVariables: ['shopify_shipping_percentage'], formulas: [null], type: 'percentage', chartType: 'line', source: 'shopify', visible: false, premade: true },
    { name: 'Shopify Tax Percentage', key: 'shopify_tax_percentage', dataVariables: ['shopify_tax_percentage'], formulas: [null], type: 'percentage', chartType: 'line', source: 'shopify', visible: false, premade: true },
    { name: 'Shopify Avg Discount Per Order', key: 'shopify_avg_discount_per_order', dataVariables: ['shopify_avg_discount_per_order'], formulas: [null], type: 'dollar', chartType: 'line', source: 'shopify', visible: false, premade: true },
    { name: 'Shopify Net Profit Margin', key: 'shopify_net_profit_margin', dataVariables: ['shopify_net_profit_margin'], formulas: [null], type: 'percentage', chartType: 'line', source: 'shopify', visible: false, premade: true },
  
    // ----- Sessions Metrics -----
    { name: 'Omni Sessions', key: 'omni_sessions', dataVariables: ['omni_sessions'], formulas: [null], type: 'number', chartType: 'line', source: 'omni', visible: false, premade: true },
    { name: 'Omni Unique Fingerprints', key: 'omni_unique_fingerprints', dataVariables: ['omni_unique_fingerprints'], formulas: [null], type: 'number', chartType: 'line', source: 'omni', visible: false, premade: true },
    { name: 'Omni Conversion Rate', key: 'omni_conversion_rate', dataVariables: ['omni_conversion_rate'], formulas: [null], type: 'percentage', chartType: 'line', source: 'omni', visible: false, premade: true },
  
    // ----- Facebook Metrics -----
    { name: 'Facebook Spend', key: 'fb_spend', dataVariables: ['fb_spend'], formulas: [null], type: 'dollar', chartType: 'line', source: 'facebook', visible: false, premade: true },
    { name: 'Facebook Impressions', key: 'fb_impressions', dataVariables: ['fb_impressions'], formulas: [null], type: 'number', chartType: 'line', source: 'facebook', visible: false, premade: true },
    { name: 'Facebook Clicks', key: 'fb_clicks', dataVariables: ['fb_clicks'], formulas: [null], type: 'number', chartType: 'line', source: 'facebook', visible: false, premade: true },
    { name: 'Facebook Add to Cart', key: 'fb_add_to_cart', dataVariables: ['fb_add_to_cart'], formulas: [null], type: 'number', chartType: 'line', source: 'facebook', visible: false, premade: true },
    { name: 'Facebook Initiated Checkout', key: 'fb_initiated_checkout', dataVariables: ['fb_initiated_checkout'], formulas: [null], type: 'number', chartType: 'line', source: 'facebook', visible: false, premade: true },
    { name: 'Facebook Landing Page View', key: 'fb_landing_page_view', dataVariables: ['fb_landing_page_view'], formulas: [null], type: 'number', chartType: 'line', source: 'facebook', visible: false, premade: true },
    { name: 'Facebook View Content', key: 'fb_view_content', dataVariables: ['fb_view_content'], formulas: [null], type: 'number', chartType: 'line', source: 'facebook', visible: false, premade: true },
    { name: 'Facebook Page Engagement', key: 'fb_page_engagement', dataVariables: ['fb_page_engagement'], formulas: [null], type: 'number', chartType: 'line', source: 'facebook', visible: false, premade: true },
    { name: 'Facebook Purchase', key: 'fb_purchase', dataVariables: ['fb_purchase'], formulas: [null], type: 'number', chartType: 'line', source: 'facebook', visible: false, premade: true },
    { name: 'Facebook Post Engagement', key: 'fb_post_engagement', dataVariables: ['fb_post_engagement'], formulas: [null], type: 'number', chartType: 'line', source: 'facebook', visible: false, premade: true },
    { name: 'Facebook Video View', key: 'fb_video_view', dataVariables: ['fb_video_view'], formulas: [null], type: 'number', chartType: 'line', source: 'facebook', visible: false, premade: true },
    { name: 'Facebook Post Reaction', key: 'fb_post_reaction', dataVariables: ['fb_post_reaction'], formulas: [null], type: 'number', chartType: 'line', source: 'facebook', visible: false, premade: true },
    { name: 'Facebook Link Click', key: 'fb_link_click', dataVariables: ['fb_link_click'], formulas: [null], type: 'number', chartType: 'line', source: 'facebook', visible: false, premade: true },
    { name: 'Facebook Value Purchase', key: 'fb_value_purchase', dataVariables: ['fb_value_purchase'], formulas: [null], type: 'dollar', chartType: 'line', source: 'facebook', visible: false, premade: true },
    { name: 'Facebook CPM', key: 'fb_cpm', dataVariables: ['fb_cpm'], formulas: [null], type: 'dollar', chartType: 'line', source: 'facebook', visible: false, premade: true },
    { name: 'Facebook CPC', key: 'fb_cpc', dataVariables: ['fb_cpc'], formulas: [null], type: 'dollar', chartType: 'line', source: 'facebook', visible: false, premade: true },
    { name: 'Facebook CTR', key: 'fb_ctr', dataVariables: ['fb_ctr'], formulas: [null], type: 'percentage', chartType: 'line', source: 'facebook', visible: false, premade: true },
    { name: 'Facebook CPA Add to Cart', key: 'fb_cpa_add_to_cart', dataVariables: ['fb_cpa_add_to_cart'], formulas: [null], type: 'dollar', chartType: 'line', source: 'facebook', visible: false, premade: true },
    { name: 'Facebook CPA Purchase', key: 'fb_cpa_purchase', dataVariables: ['fb_cpa_purchase'], formulas: [null], type: 'dollar', chartType: 'line', source: 'facebook', visible: false, premade: true },
    { name: 'Facebook Avg Order Value', key: 'fb_avg_order_value', dataVariables: ['fb_avg_order_value'], formulas: [null], type: 'dollar', chartType: 'line', source: 'facebook', visible: false, premade: true },
    { name: 'Facebook ROAS', key: 'fb_roas', dataVariables: ['fb_roas'], formulas: [null], type: 'number', chartType: 'line', source: 'facebook', visible: false, premade: true },
    { name: 'Facebook CPVV', key: 'fb_cpvv', dataVariables: ['fb_cpvv'], formulas: [null], type: 'dollar', chartType: 'line', source: 'facebook', visible: false, premade: true },
    { name: 'Facebook Post Reaction Rate', key: 'fb_post_reaction_rate', dataVariables: ['fb_post_reaction_rate'], formulas: [null], type: 'percentage', chartType: 'line', source: 'facebook', visible: false, premade: true },
    { name: 'Facebook Link Click Rate', key: 'fb_link_click_rate', dataVariables: ['fb_link_click_rate'], formulas: [null], type: 'percentage', chartType: 'line', source: 'facebook', visible: false, premade: true },
  
    // ----- Google Metrics -----
    { name: 'Google Impressions', key: 'google_impressions', dataVariables: ['google_impressions'], formulas: [null], type: 'number', chartType: 'line', source: 'google', visible: false, premade: true },
    { name: 'Google Clicks', key: 'google_clicks', dataVariables: ['google_clicks'], formulas: [null], type: 'number', chartType: 'line', source: 'google', visible: false, premade: true },
    { name: 'Google Conversions', key: 'google_conversions', dataVariables: ['google_conversions'], formulas: [null], type: 'number', chartType: 'line', source: 'google', visible: false, premade: true },
    { name: 'Google Conversion Value', key: 'google_conversion_value', dataVariables: ['google_conversion_value'], formulas: [null], type: 'dollar', chartType: 'line', source: 'google', visible: false, premade: true },
    { name: 'Google Spend', key: 'google_spend', dataVariables: ['google_spend'], formulas: [null], type: 'dollar', chartType: 'line', source: 'google', visible: false, premade: true },
    { name: 'Google CPM', key: 'google_cpm', dataVariables: ['google_cpm'], formulas: [null], type: 'dollar', chartType: 'line', source: 'google', visible: false, premade: true },
    { name: 'Google CPC', key: 'google_cpc', dataVariables: ['google_cpc'], formulas: [null], type: 'dollar', chartType: 'line', source: 'google', visible: false, premade: true },
    { name: 'Google CTR', key: 'google_ctr', dataVariables: ['google_ctr'], formulas: [null], type: 'percentage', chartType: 'line', source: 'google', visible: false, premade: true },
    { name: 'Google CPA Conversion', key: 'google_cpa_conversion', dataVariables: ['google_cpa_conversion'], formulas: [null], type: 'dollar', chartType: 'line', source: 'google', visible: false, premade: true },
    { name: 'Google Avg Order Value', key: 'google_avg_order_value', dataVariables: ['google_avg_order_value'], formulas: [null], type: 'dollar', chartType: 'line', source: 'google', visible: false, premade: true },
    { name: 'Google ROAS', key: 'google_roas', dataVariables: ['google_roas'], formulas: [null], type: 'number', chartType: 'line', source: 'google', visible: false, premade: true },
    { name: 'Google Click Through Rate', key: 'google_click_through_rate', dataVariables: ['google_click_through_rate'], formulas: [null], type: 'percentage', chartType: 'line', source: 'google', visible: false, premade: true },
    { name: 'Google Conversion Rate', key: 'google_conversion_rate', dataVariables: ['google_conversion_rate'], formulas: [null], type: 'percentage', chartType: 'line', source: 'google', visible: false, premade: true },
    { name: 'Google Conversion Value Per Impression', key: 'google_conversion_value_per_impression', dataVariables: ['google_conversion_value_per_impression'], formulas: [null], type: 'dollar', chartType: 'line', source: 'google', visible: false, premade: true },
    { name: 'Google Conversion Value Per Click', key: 'google_conversion_value_per_click', dataVariables: ['google_conversion_value_per_click'], formulas: [null], type: 'dollar', chartType: 'line', source: 'google', visible: false, premade: true },
  
    // ----- TikTok Metrics -----
    { name: 'TikTok Clicks', key: 'tt_clicks', dataVariables: ['tt_clicks'], formulas: [null], type: 'number', chartType: 'line', source: 'tiktok', visible: false, premade: true },
    { name: 'TikTok Comments', key: 'tt_comments', dataVariables: ['tt_comments'], formulas: [null], type: 'number', chartType: 'line', source: 'tiktok', visible: false, premade: true },
    { name: 'TikTok Shares', key: 'tt_shares', dataVariables: ['tt_shares'], formulas: [null], type: 'number', chartType: 'line', source: 'tiktok', visible: false, premade: true },
    { name: 'TikTok Conversion', key: 'tt_conversion', dataVariables: ['tt_conversion'], formulas: [null], type: 'number', chartType: 'line', source: 'tiktok', visible: false, premade: true },
    { name: 'TikTok Impressions', key: 'tt_impressions', dataVariables: ['tt_impressions'], formulas: [null], type: 'number', chartType: 'line', source: 'tiktok', visible: false, premade: true },
    { name: 'TikTok Engagements', key: 'tt_engagements', dataVariables: ['tt_engagements'], formulas: [null], type: 'number', chartType: 'line', source: 'tiktok', visible: false, premade: true },
    { name: 'TikTok Spend', key: 'tt_spend', dataVariables: ['tt_spend'], formulas: [null], type: 'dollar', chartType: 'line', source: 'tiktok', visible: false, premade: true },
    { name: 'TikTok Likes', key: 'tt_likes', dataVariables: ['tt_likes'], formulas: [null], type: 'number', chartType: 'line', source: 'tiktok', visible: false, premade: true },
    { name: 'TikTok Conversion Value', key: 'tt_conversion_value', dataVariables: ['tt_conversion_value'], formulas: [null], type: 'dollar', chartType: 'line', source: 'tiktok', visible: false, premade: true },
    { name: 'TikTok CPM', key: 'tt_cpm', dataVariables: ['tt_cpm'], formulas: [null], type: 'dollar', chartType: 'line', source: 'tiktok', visible: false, premade: true },
    { name: 'TikTok CPC', key: 'tt_cpc', dataVariables: ['tt_cpc'], formulas: [null], type: 'dollar', chartType: 'line', source: 'tiktok', visible: false, premade: true },
    { name: 'TikTok CTR', key: 'tt_ctr', dataVariables: ['tt_ctr'], formulas: [null], type: 'percentage', chartType: 'line', source: 'tiktok', visible: false, premade: true },
    { name: 'TikTok CPA Conversion', key: 'tt_cpa_conversion', dataVariables: ['tt_cpa_conversion'], formulas: [null], type: 'dollar', chartType: 'line', source: 'tiktok', visible: false, premade: true },
    { name: 'TikTok Avg Order Value', key: 'tt_avg_order_value', dataVariables: ['tt_avg_order_value'], formulas: [null], type: 'dollar', chartType: 'line', source: 'tiktok', visible: false, premade: true },
    { name: 'TikTok ROAS', key: 'tt_roas', dataVariables: ['tt_roas'], formulas: [null], type: 'number', chartType: 'line', source: 'tiktok', visible: false, premade: true },
    { name: 'TikTok Like Rate', key: 'tt_like_rate', dataVariables: ['tt_like_rate'], formulas: [null], type: 'percentage', chartType: 'line', source: 'tiktok', visible: false, premade: true },
    { name: 'TikTok Comment Rate', key: 'tt_comment_rate', dataVariables: ['tt_comment_rate'], formulas: [null], type: 'percentage', chartType: 'line', source: 'tiktok', visible: false, premade: true },
    { name: 'TikTok Share Rate', key: 'tt_share_rate', dataVariables: ['tt_share_rate'], formulas: [null], type: 'percentage', chartType: 'line', source: 'tiktok', visible: false, premade: true },
    { name: 'TikTok Conversion Value Per Click', key: 'tt_conversion_value_per_click', dataVariables: ['tt_conversion_value_per_click'], formulas: [null], type: 'dollar', chartType: 'line', source: 'tiktok', visible: false, premade: true },
  
    // ----- Klaviyo Metrics -----
    { name: 'Klaviyo Recipients', key: 'klaviyo_recipients', dataVariables: ['klaviyo_recipients'], formulas: [null], type: 'number', chartType: 'line', source: 'klaviyo', visible: false, premade: true },
    { name: 'Klaviyo Delivered', key: 'klaviyo_delivered', dataVariables: ['klaviyo_delivered'], formulas: [null], type: 'number', chartType: 'line', source: 'klaviyo', visible: false, premade: true },
    { name: 'Klaviyo Bounced', key: 'klaviyo_bounced', dataVariables: ['klaviyo_bounced'], formulas: [null], type: 'number', chartType: 'line', source: 'klaviyo', visible: false, premade: true },
    { name: 'Klaviyo Failed', key: 'klaviyo_failed', dataVariables: ['klaviyo_failed'], formulas: [null], type: 'number', chartType: 'line', source: 'klaviyo', visible: false, premade: true },
    { name: 'Klaviyo Opens', key: 'klaviyo_opens', dataVariables: ['klaviyo_opens'], formulas: [null], type: 'number', chartType: 'line', source: 'klaviyo', visible: false, premade: true },
    { name: 'Klaviyo Unique Opens', key: 'klaviyo_unique_opens', dataVariables: ['klaviyo_unique_opens'], formulas: [null], type: 'number', chartType: 'line', source: 'klaviyo', visible: false, premade: true },
    { name: 'Klaviyo Clicks', key: 'klaviyo_clicks', dataVariables: ['klaviyo_clicks'], formulas: [null], type: 'number', chartType: 'line', source: 'klaviyo', visible: false, premade: true },
    { name: 'Klaviyo Unique Clicks', key: 'klaviyo_unique_clicks', dataVariables: ['klaviyo_unique_clicks'], formulas: [null], type: 'number', chartType: 'line', source: 'klaviyo', visible: false, premade: true },
    { name: 'Klaviyo Conversions', key: 'klaviyo_conversions', dataVariables: ['klaviyo_conversions'], formulas: [null], type: 'number', chartType: 'line', source: 'klaviyo', visible: false, premade: true },
    { name: 'Klaviyo Unique Conversions', key: 'klaviyo_unique_conversions', dataVariables: ['klaviyo_unique_conversions'], formulas: [null], type: 'number', chartType: 'line', source: 'klaviyo', visible: false, premade: true },
    { name: 'Klaviyo Revenue', key: 'klaviyo_revenue', dataVariables: ['klaviyo_revenue'], formulas: [null], type: 'dollar', chartType: 'line', source: 'klaviyo', visible: false, premade: true },
    { name: 'Klaviyo Unsubscribes', key: 'klaviyo_unsubscribes', dataVariables: ['klaviyo_unsubscribes'], formulas: [null], type: 'number', chartType: 'line', source: 'klaviyo', visible: false, premade: true },
    { name: 'Klaviyo Spam Complaints', key: 'klaviyo_spam_complaints', dataVariables: ['klaviyo_spam_complaints'], formulas: [null], type: 'number', chartType: 'line', source: 'klaviyo', visible: false, premade: true },
    { name: 'Klaviyo Bounce Rate', key: 'klaviyo_bounce_rate', dataVariables: ['klaviyo_bounce_rate'], formulas: [null], type: 'percentage', chartType: 'line', source: 'klaviyo', visible: false, premade: true },
    { name: 'Klaviyo Open Rate', key: 'klaviyo_open_rate', dataVariables: ['klaviyo_open_rate'], formulas: [null], type: 'percentage', chartType: 'line', source: 'klaviyo', visible: false, premade: true },
    { name: 'Klaviyo Click Rate', key: 'klaviyo_click_rate', dataVariables: ['klaviyo_click_rate'], formulas: [null], type: 'percentage', chartType: 'line', source: 'klaviyo', visible: false, premade: true },
    { name: 'Klaviyo Conversion Rate', key: 'klaviyo_conversion_rate', dataVariables: ['klaviyo_conversion_rate'], formulas: [null], type: 'percentage', chartType: 'line', source: 'klaviyo', visible: false, premade: true },
    { name: 'Klaviyo Open to Delivered Rate', key: 'klaviyo_open_to_delivered_rate', dataVariables: ['klaviyo_open_to_delivered_rate'], formulas: [null], type: 'percentage', chartType: 'line', source: 'klaviyo', visible: false, premade: true },
    { name: 'Klaviyo Avg Conversion Value', key: 'klaviyo_avg_conversion_value', dataVariables: ['klaviyo_avg_conversion_value'], formulas: [null], type: 'dollar', chartType: 'line', source: 'klaviyo', visible: false, premade: true },
    { name: 'Klaviyo Revenue Per Recipient', key: 'klaviyo_revenue_per_recipient', dataVariables: ['klaviyo_revenue_per_recipient'], formulas: [null], type: 'dollar', chartType: 'line', source: 'klaviyo', visible: false, premade: true },
    { name: 'Klaviyo Unsubscribe Rate', key: 'klaviyo_unsubscribe_rate', dataVariables: ['klaviyo_unsubscribe_rate'], formulas: [null], type: 'percentage', chartType: 'line', source: 'klaviyo', visible: false, premade: true },
    { name: 'Klaviyo Spam Rate', key: 'klaviyo_spam_rate', dataVariables: ['klaviyo_spam_rate'], formulas: [null], type: 'percentage', chartType: 'line', source: 'klaviyo', visible: false, premade: true },
    { name: 'Klaviyo Click to Delivered Rate', key: 'klaviyo_click_to_delivered_rate', dataVariables: ['klaviyo_click_to_delivered_rate'], formulas: [null], type: 'percentage', chartType: 'line', source: 'klaviyo', visible: false, premade: true },
  
    { name: 'Total CTR', key: 'total_ctr', type: 'number', dataVariables: ['total_ctr'], formulas:['(totals) => safeDivide(totals.total_clicks, totals.total_impressions) || 0'], derived: true, chartType: 'line', source: 'omni', visible: false, premade: false },
    { name: 'Total CPM', key: 'total_cpm', type: 'number', dataVariables: ['total_cpm'], formulas:['(totals) => safeDivide(totals.total_ad_spend, totals.total_impressions) * 1000 || 0'], derived: true, chartType: 'line', source: 'omni', visible: false, premade: false },
    { name: 'MULTILINE TEST', key: 'multiline_test', dataVariables: ['facebook_cpm', 'tiktok_cpm'], formulas: [ '(totals) => safeDivide(totals.total_ad_spend, totals.total_impressions) || 0',  '(totals) => safeDivide(totals.total_clicks, totals.total_impressions) || 0'], type: 'dollar', chartType: 'line', source: 'omni', visible: false, premade: false },
    { name: 'Custom Text', key: 'custom_text1', type: 'text', chartType: 'text', source: null, visible: false, premade: false },
    { name: 'React Component', key: 'react_component1', chartType: 'react_component', component: "<ProfitByDay widthPercent={100} darkMode={darkMode} serverURL={serverURL}/>", source: null, visible: false, premade: false },
  ];
  

  


  // Store metrics in state for reordering
  const [metrics, setMetrics] = useState(defaultMetrics);

  // Initialize data structures with default values
  const initializeData = () => {
    const arrays = { dates: [] };
    const totals = {};
    metrics.forEach((metric) => {
      arrays[metric.key] = [];
      totals[metric.key] = 0;
    });
    return { arrays, totals };
  };

  const [data, setData] = useState(initializeData());
  const [compareData, setCompareData] = useState(initializeData());

  function formatDate(date) {
    return dayjs(date).format('MMM D');
  }

  function calculateComparisonDates() {
    const MS_PER_DAY = 1000 * 60 * 60 * 24;
    const startDate = new Date(Date.parse(dateStart));
    const endDate = new Date(Date.parse(dateEnd));
    const totalDays = Math.round((endDate - startDate) / MS_PER_DAY);

    const compareEnd = new Date(startDate.getTime() - MS_PER_DAY);
    const compareStart = new Date(compareEnd.getTime() - totalDays * MS_PER_DAY);

    setCompareDateStart(compareStart);
    setCompareDateEnd(compareEnd);
  }

  useEffect(() => {
    calculateComparisonDates();
    console.log('rerendering 2')

  }, [dateStart, dateEnd]);






  async function fetchStats(granularity) {
    try {
      // setLoadingAnalytics(true);

      const apiEndpoint = granularity === 'daily' ? 'getDailyStats' : 'getHourlyStats';
      const dateFormat = 'YYYY-MM-DD';

      const fetchData = async (start, end) => {
        const url = `${serverURL}/${apiEndpoint}?omni_business_id=${bid}&dateStart=${dayjs(start).format(
          dateFormat
        )}T00:00:00&dateEnd=${dayjs(end).format(dateFormat)}T23:59:59`;
        console.log(url)
        const response = await axios.get(url);
        return response.data;
      };

      console.log(dateStart, dateEnd, compareDateStart, compareDateEnd)

      const [currentData, compareDataResponse] = await Promise.all([
        fetchData(dateStart, dateEnd),
        fetchData(compareDateStart, compareDateEnd),
      ]);

      const processData = (dataArray) => {
        const arrays = {};
        const totals = {};
      
        metrics.forEach((metric) => {
          if (metric.dataVariables) {
            // Initialize arrays and totals for multi-line metrics
            metric.dataVariables.forEach((variable) => {
              arrays[variable] = [];
              totals[variable] = 0;
            });
          } else {
            arrays[metric.key] = [];
            totals[metric.key] = 0;
          }
        });
      
        metrics.forEach((metric) => {
          if (metric.dataVariables) {
            // Populate arrays and totals for multi-line metrics
            metric.dataVariables.forEach((variable, index) => {
              arrays[variable] = dataArray.map((entry) => {
                const value = parseFloat(entry[variable]);
                return isFinite(value) ? value : 0;
              });
              totals[variable] = arrays[variable].reduce((a, b) => a + b, 0);
            });
      
            // Process derived formulas if they exist
            if (metric.formulas) {
              metric.formulas.forEach((formula, index) => {
                if (formula) {
                  arrays[metric.dataVariables[index]] = dataArray.map((_, idx) => {
                    const pointTotals = {};
                    metrics.forEach((m) => {
                      if (m.dataVariables) {
                        m.dataVariables.forEach((varKey) => {
                          pointTotals[varKey] = arrays[varKey]?.[idx] || 0;
                        });
                      } else {
                        pointTotals[m.key] = arrays[m.key]?.[idx] || 0;
                      }
                    });
                    return eval(`(${formula})`)(pointTotals);
                  });
                }
              });
            }
          } else if (!metric.derived) {
            // Single-line non-derived metrics
            arrays[metric.key] = dataArray.map((entry) => {
              const value = parseFloat(entry[metric.key]);
              return isFinite(value) ? value : 0;
            });
            totals[metric.key] = arrays[metric.key].reduce((a, b) => a + b, 0);
          } else {
            // Single-line derived metrics
            arrays[metric.key] = dataArray.map((_, index) => {
              const pointTotals = {};
              metrics.forEach((m) => {
                pointTotals[m.key] = arrays[m.key]?.[index] || 0;
              });
              return eval(`(${metric.formula})`)(pointTotals);
            });
            totals[metric.key] = arrays[metric.key].reduce((a, b) => a + b, 0);
          }
        });
      
        arrays['dates'] = dataArray.map((entry) =>
          granularity === 'daily' ? dayjs(entry.date).format('YYYY-MM-DD') : entry.hour
        );
      
        return { arrays, totals };
      };
      
      

      setData(processData(currentData));
      setCompareData(processData(compareDataResponse));

      setShowHourly(granularity === 'hourly');
      setShowDaily(granularity === 'daily');
    } catch (error) {
      console.error('Error fetching stats:', error);
    } finally {
      setLoadingAnalytics(false);
      setLoadingPercentage(0);
    }
  }

  useEffect(() => {
    setShowDateSelectors(false);
    setFinalDateStart(dateStart);
    setFinalDateEnd(dateEnd);
    setFinalCompareDateStart(compareDateStart);
    setFinalCompareDateEnd(compareDateEnd);

    console.log('rerendering 1')

    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const totalDays = (dateEnd - dateStart) / millisecondsPerDay;

    const granularity = totalDays < 3 ? 'hourly' : 'daily';
    fetchStats(granularity);
  }, [dateUpdateTrigger, metrics]);



// console.log(data); // Debug log





  const line1Name =
    formatDate(finalDateStart) !== formatDate(finalDateEnd)
      ? `${formatDate(finalDateStart)} - ${formatDate(finalDateEnd)}`
      : formatDate(finalDateStart);

  const line2Name =
    formatDate(finalCompareDateStart) !== formatDate(finalCompareDateEnd)
      ? `${formatDate(finalCompareDateStart)} - ${formatDate(finalCompareDateEnd)}`
      : formatDate(finalCompareDateEnd);

  // Custom initialLayout with adjusted sizes
  const initialLayout = [
    // Row 0
    { i: 'orders', x: 0, y: 0, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
    { i: 'revenue', x: 3, y: 0, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
    { i: 'net_profit', x: 6, y: 0, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
    { i: 'cogs', x: 9, y: 0, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
  
    // Row 1
    { i: 'facebook_spend', x: 0, y: 3, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
    { i: 'refunds', x: 3, y: 3, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
    { i: 'sessions', x: 6, y: 3, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
    { i: 'cvr', x: 9, y: 3, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
  
    // Row 2
    { i: 'gross_profit', x: 0, y: 6, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
    { i: 'facebook_cpm', x: 3, y: 6, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
    { i: 'facebook_ctr', x: 6, y: 6, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
    { i: 'facebook_impressions', x: 9, y: 6, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
  
    // Row 3
    { i: 'facebook_clicks', x: 0, y: 9, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
    { i: 'aov', x: 3, y: 9, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
    { i: 'overall_roas', x: 6, y: 9, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
    { i: 'google_spend', x: 9, y: 9, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
  
    // Row 4
    { i: 'google_clicks', x: 0, y: 12, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
    { i: 'google_impressions', x: 3, y: 12, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
    { i: 'google_ctr', x: 6, y: 12, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
    { i: 'google_cpm', x: 9, y: 12, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
  
    // Row 5
    { i: 'tiktok_spend', x: 0, y: 15, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
    { i: 'tiktok_clicks', x: 3, y: 15, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
    { i: 'tiktok_impressions', x: 6, y: 15, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
    { i: 'tiktok_ctr', x: 9, y: 15, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
  
    // Row 6
    { i: 'tiktok_cpm', x: 0, y: 18, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
    { i: 'total_ad_spend', x: 3, y: 18, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
    { i: 'total_clicks', x: 6, y: 18, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
    { i: 'total_impressions', x: 9, y: 18, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
  
    // Row 7
    { i: 'total_ctr', x: 0, y: 21, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
    { i: 'total_cpm', x: 3, y: 21, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
    { i: 'total_transaction_fee', x: 6, y: 21, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
    { i: 'total_discounts', x: 9, y: 21, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
  
    // Row 8
    { i: 'total_shipping', x: 0, y: 24, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
    { i: 'total_tax', x: 3, y: 24, w: 3, h: 3, minW: 3, maxW: 3, minH: 1, maxH: 12 },
  
    // Custom Text
    { i: 'custom_text1', x: 0, y: 27, w: 12, h: 1, minW: 12, maxW: 12, minH: 1, maxH: 12 },
  ];
  
  

  const [layout, setLayout] = useState(initialLayout);

  // Handle layout change
  const onLayoutChange = (newLayout) => {
    setLayout(newLayout);
  };







  // Ref for the container to measure its width
  const containerRef = useRef(null);
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth - 250);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial size

    console.log('rerendering 3')


    return () => window.removeEventListener('resize', handleResize);
  }, []);









  function addCustomMetric() {
    if (!customMetricName) {
      // setSnackbarInfo({
      //   open: true,
      //   title: 'Name your chart',
      //   message: 'Please provide a name for your chart.',
      //   type: 'error',
      // });
      return;
    }
  
    // Ensure there's at least one non-empty line
    const nonEmptyLines = customMetricLines.filter(line => line.length > 0);
    if (nonEmptyLines.length === 0) {
      // setSnackbarInfo({
      //   open: true,
      //   title: 'Add to your formula',
      //   message: 'Please add at least one line with metrics.',
      //   type: 'error',
      // });
      return;
    }
  
    const key = customMetricName.toLowerCase().replace(/\s+/g, '_');
    if (metrics.some((metric) => metric.key === key)) {
      // setSnackbarInfo({
      //   open: true,
      //   title: 'Try using a different name',
      //   message: 'A metric with this name already exists.',
      //   type: 'error',
      // });
      return;
    }
  
    // Construct dataVariables and formulas for each line
    const dataVariables = [];
    const formulas = [];
  
    nonEmptyLines.forEach((line, index) => {
      // Example formula construction: (totals) => totals.var1 + totals.var2 - totals.var3 ...
      // Start with the first operand
      let formulaString = '';
      line.forEach((operandObj, operandIndex) => {
        if (operandIndex === 0) {
          // First operand, no leading operation
          formulaString += `totals.${operandObj.operand}`;
        } else {
          // Subsequent operands with operations
          formulaString += ` ${operandObj.operation} totals.${operandObj.operand}`;
        }
      });
  
      dataVariables.push(`${key}_line${index + 1}`); // For uniqueness per line
      formulas.push(`(totals) => (${formulaString}) || 0`);
    });
  
    const newMetric = {
      name: customMetricName,
      key: key,
      type: 'number',
      dataVariables: dataVariables,
      formulas: formulas,
      derived: true,
      chartType: 'line',  // or another chart type that supports multiple lines
      source: 'omni',
      visible: true,
    };
  
    setCustomMetrics([...customMetrics, newMetric]);
    setMetrics([...metrics, newMetric]);
    setLayout([...layout, { i: key, x: 0, y: Infinity, w: 2, h: 3 }]);
  
    // setSnackbarInfo({
    //   open: true,
    //   title: 'Custom Metric Created',
    //   message: 'Your multi-line chart has been added successfully.',
    //   type: 'success',
    // });
  
    // Reset the form
    setCustomMetricName('');
    setCustomMetricLines([[]]);
    setShowCustomMetricPopup(false);
  }
  


  //console.log(" Metrics:", metrics); // Debug log




  const t = { name: 'Total CPM', key: 'total_cpm', type: 'number', dataVariables: ['total_cpm'], formulas:['(totals) => safeDivide(totals.total_ad_spend, totals.total_impressions) * 1000 || 0'], derived: true, chartType: 'line', source: 'omni', visible: true }




  

  // Load saved reports on component mount
  useEffect(() => {
    async function fetchSavedReports() {
      try {
        const reportsCollection = collection(db, 'analyticsReports');
        const q = query(reportsCollection, where('uid', "array-contains", uid));
        const querySnapshot = await getDocs(q);
        const reports = [];
        querySnapshot.forEach((doc) => {
          reports.push({ id: doc.id, ...doc.data() });
        });

        setSavedReports(reports);

        // Check if there's a default report in the user's document
        const userDoc = await getDoc(doc(db, 'users', uid));
        const userData = userDoc.data();
        const defaultReportId = userData?.defaultReportId;

        if (defaultReportId) {
          const defaultReport = reports.find((report) => report.id === defaultReportId);
          if (defaultReport) {
            setCurrentReportId(defaultReport.id);
            loadReport(defaultReport);
          }
        } else if (reports.length > 0) {
          // If no default, load the first report
          setCurrentReportId(reports[0].id);
          loadReport(reports[0]);
        }

      } catch (error) {
        console.error('Error fetching saved reports:', error);
      }
    }
    console.log('rerendering 4')


    fetchSavedReports();
  }, []);




  function loadReport(report) {

    setShowAxisLabels(report.showAxisLabels || false);
    setShowGridLines(report.showGridLines || false);
    setShowLegend(report.showLegend || false);
    setShowCompareData(report.showCompareData || []);

    const restoredMetrics = restoreMetrics(report.metrics || defaultMetrics); // Restore formulas
  
    // Combine defaultMetrics and restoredMetrics, giving precedence to restoredMetrics
    const mergedMetrics = defaultMetrics.map((defaultMetric) => {
      const savedMetric = restoredMetrics.find((m) => m.key === defaultMetric.key);
      return savedMetric ? { ...defaultMetric, ...savedMetric } : defaultMetric;
    });
  
    // Now add any custom metrics that are not in defaultMetrics
    restoredMetrics.forEach((metric) => {
      if (!defaultMetrics.some((m) => m.key === metric.key)) {
        mergedMetrics.push(metric);
      }
    });
  
    setMetrics(mergedMetrics);
    setLayout(report.layout || initialLayout);
    setSharedWithUsersArray(report.uid || []);
  }
  
  



  const sanitizeMetrics = (metrics) =>
    metrics.map((metric) => ({
      ...metric,
      formula: metric.formula ? metric.formula.toString() : '',
  }));


  const restoreMetrics = (savedMetrics) => {
    console.log("Restoring Metrics:", savedMetrics); // Log the input metrics
    return savedMetrics.map((metric) => {
      try {
        if (!metric.formula) {
          // console.warn(`Missing formula for metric: ${metric.key || metric.name}`);
          return { ...metric, formula: undefined };
        }
        const restoredMetric = {
          ...metric,
          formula: eval(`(${metric.formula})`), // Convert string back into a function
        };
        return restoredMetric;
      } catch (error) {
        console.error(
          `Failed to restore formula for metric ${metric.key || metric.name}:`,
          error
        );
        return { ...metric, formula: undefined };
      }
    });
  };  

  
  
  
  

  function saveReport() {
    if (currentReportId) {
      const reportDoc = doc(db, 'analyticsReports', currentReportId);
  
      const cleanedLayout = layout.map((item) => ({
        i: item.i || '',
        x: item.x ?? 0,
        y: item.y ?? 0,
        w: item.w ?? 1,
        h: item.h ?? 1,
      }));
  
      const sanitizedMetrics = sanitizeMetrics(metrics); // Serialize formulas

      console.log({
        layout: cleanedLayout,
        metrics: sanitizedMetrics,
        customMetrics: customMetrics,
        updatedAt: new Date(),
        showAxisLabels: showAxisLabels,
        showGridLines: showGridLines,
        showLegend: showLegend,
        showCompareData: showCompareData,
      })

      updateDoc(reportDoc, {
        layout: cleanedLayout,
        metrics: sanitizedMetrics,
        customMetrics: customMetrics,
        updatedAt: new Date(),
        showAxisLabels: showAxisLabels,
        showGridLines: showGridLines,
        showLegend: showLegend,
        showCompareData: showCompareData,
        narrowMode: narrowMode,
        public: false,
        rating: 5,
        installs: 0,
        
      })
        .then(() => {
          // setSnackbarInfo({
          //   open: true,
          //   title: 'Report updated',
          //   message: 'Your report has been updated successfully.',
          //   type: 'success',
          // });
        })
        .catch((error) => {
          console.error('Error updating report:', error);
        });
    } else {
      setShowSaveReportModal(true);
    }
  }
  



  async function setReportAsDefault(reportId) {
    try {
      // Update the user's document with the default report ID
      const userDoc = doc(db, 'users', uid);
      await updateDoc(userDoc, { defaultReportId: reportId });

      // Update local state
      setSavedReports((prevReports) =>
        prevReports.map((report) =>
          report.id === reportId ? { ...report, isDefault: true } : { ...report, isDefault: false }
        )
      );

      // setSnackbarInfo({open: true, title: 'Default report updated', message: 'The default report has been updated.', type: 'success'});
    } catch (error) {
      console.error('Error setting report as default:', error);
      // setSnackbarInfo({open: true, title: 'Error updating default report', message: 'Failed to update default report. Please try again.', type: 'error'});
    }
  }
  

  function createNewReport() {
    if (!newReportName) {
      // setSnackbarInfo({open: true, title: 'Name your report', message: 'Please provide a name for your report.', type: 'error'});
      return;
    }

    const cleanedLayout = layout.map((item) => ({
      i: item.i || '', // Ensure the layout item has an identifier
      x: item.x ?? 0, // Default x-position if undefined
      y: item.y ?? 0, // Default y-position if undefined
      w: item.w ?? 1, // Default width if undefined
      h: item.h ?? 1, // Default height if undefined
    }));

    const newReport = {
      uid: [uid],
      name: newReportName,
      layout: cleanedLayout,
      customMetrics: customMetrics,
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    console.log('Creating new report:', newReport);

    addDoc(collection(db, 'analyticsReports'), newReport)
      .then((docRef) => {
        const savedReport = { id: docRef.id, ...newReport };
        setSavedReports([...savedReports, savedReport]);
        setCurrentReportId(docRef.id);
        setShowSaveReportModal(false);

        if (isDefaultReport) {
          setReportAsDefault(docRef.id);
        }

        // setSnackbarInfo({open: true, title: 'Report saved', message: 'Your report has been saved successfully.', type: 'success'});
      })
      .catch((error) => {
        console.error('Error saving report:', error);
      });
  }

  function deleteReport() {
    if (!currentReportId) return;

    if (window.confirm('Are you sure you want to delete this report?')) {
      const reportDoc = doc(db, 'analyticsReports', currentReportId);
      deleteDoc(reportDoc)
        .then(() => {
          // setSnackbarInfo({open: true, title: 'Report deleted', message: 'Your report has been deleted successfully.', type: 'success'});
          // Remove report from savedReports
          setSavedReports((prevReports) => prevReports.filter((report) => report.id !== currentReportId));
          // Clear current report
          setCurrentReportId(null);
          setCurrentReport(null);
          setLayout(initialLayout);
          setCustomMetrics([]);
          setMetrics(defaultMetrics);
        })
        .catch((error) => {
          console.error('Error deleting report:', error);
        });
    }
  }

  function createNewReportFromScratch() {
    // Clear current report state
    setCurrentReportId(null);
    setLayout(initialLayout);
    setCustomMetrics([]);
    setMetrics(defaultMetrics);
    setNewReportName('');
    setIsDefaultReport(false);
  }
  
  



  const handleDeleteChart = (key) => {
    setMetrics((prevMetrics) =>
      prevMetrics.map((metric) =>
        metric.key === key ? { ...metric, visible: false } : metric
      )
    );
  };
  


  const handleRestoreChart = (key) => {
    const restoredMetric = metrics.find((metric) => metric.key === key);
    if (!restoredMetric) return;
  
    setMetrics((prevMetrics) =>
      prevMetrics.map((metric) =>
        metric.key === key ? { ...metric, visible: true } : metric
      )
    );
  
    setLayout((prevLayout) => {
      // Check if the layout already includes the item
      const existingItem = prevLayout.find((item) => item.i === key);
      if (existingItem) {
        return prevLayout; // No need to add again if already exists
      }
  
      // Find the first available grid position
      const cols = 6; // Number of columns in the grid
      const rowHeight = 1; // Adjust based on your layout
      const occupiedSpaces = new Set();
  
      // Mark existing grid spaces as occupied
      prevLayout.forEach((item) => {
        for (let x = item.x; x < item.x + item.w; x++) {
          for (let y = item.y; y < item.y + item.h; y++) {
            occupiedSpaces.add(`${x},${y}`);
          }
        }
      });
  
      // Find the first available position
      let newX = 0;
      let newY = 0;
      outerLoop: for (let y = 0; y < Infinity; y++) {
        for (let x = 0; x < cols; x++) {
          if (!occupiedSpaces.has(`${x},${y}`)) {
            newX = x;
            newY = y;
            break outerLoop;
          }
        }
      }
  
      // Add the restored item to the layout
      return [
        ...prevLayout,
        {
          i: key,
          x: newX,
          y: newY,
          w: 2, // Default width (adjust as needed)
          h: 3, // Default height (adjust as needed)
        },
      ];
    });
  };
  
  

  const handleChartTypeChange = (metricKey, newChartType) => {
    setMetrics((prevMetrics) =>
      prevMetrics.map((metric) =>
        metric.key === metricKey ? { ...metric, chartType: newChartType } : metric
      )
    );
  };
  




  async function getBusinessUsers () {
    const q = query(collection(db, "users"), where("businessIDs", "array-contains", bid));

    const usersArrayTemp = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      usersArrayTemp.push({
        id: doc.id,
        name: data.firstName + ' ' + data.lastName,
        hexCode: data.hexCode,
        initials: data.firstName.charAt(0) + data.lastName.charAt(0),
      });
    });
    setBusinessUsers(usersArrayTemp);
  }

  useEffect(() => {
    getBusinessUsers();
    console.log('rerendering 5')

  }, []);
  


  async function adjustUserSelection (userID) {
    //updated sharedWithUsersArray when user is selected
    const userIndex = sharedWithUsersArray.indexOf(userID);
    if (userIndex === -1) {
      setSharedWithUsersArray([...sharedWithUsersArray, userID]);
    } else {
      setSharedWithUsersArray(sharedWithUsersArray.filter((user) => user !== userID));
    }
  }



  async function shareReport () {
    try {
      const reportDoc = doc(db, 'analyticsReports', currentReportId);
      await updateDoc(reportDoc, {
        uid: sharedWithUsersArray,
      });
      setShowShareReportPopup(false);
      // setSnackbarInfo({open: true, title: 'Report shared', message: 'Your report has been shared successfully.', type: 'success'});
    } catch (error) {
      console.error('Error sharing report:', error);
      // setSnackbarInfo({open: true, title: 'Error sharing report', message: 'Failed to share report. Please try again.', type: 'error'});
    }
  }



  async function renameReport () {
    try {
      const reportDoc = doc(db, 'analyticsReports', currentReportId);
      await updateDoc(reportDoc, {
        name: renameReportText,
      });
      setShowRenameReportPopup(false);
      // Update the report name in the savedReports state
      setSavedReports((prevReports) =>
        prevReports.map((report) =>
          report.id === currentReportId ? { ...report, name: renameReportText } : report
        )
      );

      // setSnackbarInfo({open: true, title: 'Report renamed', message: 'Your report has been renamed successfully.', type: 'success'});
    } catch (error) {
      console.error('Error renaming report:', error);
      // setSnackbarInfo({open: true, title: 'Error renaming report', message: 'Failed to rename report. Please try again.', type: 'error'});
    }
  }
  



  useEffect(() => {
    const handleKeyDown = (event) => {
        // Check for Cmd + Shift key combinations
        if (event.metaKey || event.ctrlKey) {
            if (event.shiftKey) {
                switch (event.key.toLowerCase()) {
                    case 's': // Save Report
                        event.preventDefault();
                        console.log('Save Report triggered!');
                        saveReport();              
                        break;
                    case 'd': // Set as Default
                        event.preventDefault();
                        console.log('Set as Default triggered!');
                        currentReportId && setReportAsDefault(currentReportId);
                        break;
                    case 'r': // Rename Report
                        event.preventDefault();
                        console.log('Rename Report triggered!');
                        setShowRenameReportPopup(true);
                        break;
                    case 'c': // Toggle Compare Data
                        event.preventDefault();
                        console.log('Toggle Compare Data triggered!');
                        setShowCompareData((prev) => !prev);
                        break;
                    case 'k': // Toggle Legend
                        event.preventDefault();
                        console.log('Toggle Legend triggered!');
                        setShowLegend((prev) => !prev);
                        break;
                    case 'l': // Toggle Axis Labels
                        event.preventDefault();
                        console.log('Toggle Axis Labels triggered!');
                        setShowAxisLabels((prev) => !prev);
                        break;
                    case 'g': // Toggle Grid Lines
                        event.preventDefault();
                        console.log('Toggle Grid Lines triggered!');
                        setShowGridLines((prev) => !prev);
                        break;
                    case 'p': // Customize Layout
                        event.preventDefault();
                        console.log('Customize Layout triggered!');
                        setCustomizeLayout((prev) => !prev);
                        break;
                    case 'a': // Toggle Narrow/Wide Mode
                        event.preventDefault();
                        console.log('Toggle Narrow/Wide Mode triggered!');
                        setNarrowMode((prev) => !prev);
                        break;
                    case 'm': // Create Chart
                        event.preventDefault();
                        console.log('Create Chart triggered!');
                        setShowCustomMetricPopup(true);
                        break;
                    case 'backspace': // Delete Report
                        event.preventDefault();
                        console.log('Delete Report triggered!');
                        currentReportId && deleteReport();
                        break;
                    default:
                        break;
                }
            }
        }
    };

    window.addEventListener('keydown', handleKeyDown);

    console.log('rerendering 5')


    return () => {
        window.removeEventListener('keydown', handleKeyDown);
    };
}, [currentReportId, showCompareData, showLegend, showAxisLabels, showGridLines, customizeLayout, narrowMode, metrics, layout, customMetrics, renameReportText]);







async function changeTextBlockContents(newTextValue, metricKey) {
  setMetrics((prevMetrics) => {
    const updatedMetrics = prevMetrics.map((metric) =>
      metric.key === metricKey ? { ...metric, name: newTextValue } : metric
    );
    return updatedMetrics;
  });
}



async function addTextBlock() {
  //get random number for key
  const randomKey = Math.floor(Math.random() * 1000000000);
  const newTextBlock = {
    key: `text_block_${randomKey}`,
    name: 'New Text Block',
    type: 'text',
    visible: true,
    chartType: 'text',
  };
  //add new text block to metrics
  setMetrics([...metrics, newTextBlock]);
  //add new text block to layout
  setLayout([...layout, { i: newTextBlock.key, x: 0, y: Infinity, w: 12, h: 1 }]);
}



// console.log('metrics', metrics);
// console.log('data', data);
  
  

  return (
    <>
      {loadingAnalytics ? (
        <div className='dashboardLoadingContainer'>
          <div className='dashboardLoadingProgressParent'>
            <div
              className='dashboardLoadingProgressChild'
              style={{ width: loadingPercentage + '%' }}
            ></div>
          </div>
          <span className='dashboardLoadingText'>Loading Analytics... </span>
        </div>
      ) : (
        <div className={`analyticsContainer ${darkMode ? 'darkMode' : ''}`} ref={containerRef}>
          <div className='analyticsLeftDrawer'>

            <div className='analyticsLeftDrawerHeaderRow'>
              <span className='analyticsLeftDrawerHeaderTitle'>Reports</span>
              <div className='analyticsLeftDrawerHeaderButton' onClick={() => setShowSaveReportModal(true)}>
                <AddRounded style={{height: 20, width: 20, paddingRight: 5}}/>
              </div>
            </div>


            <div className='analyticsLeftDrawerHeaderSectionsRow'>
              <span className={drawerContents == 'personal' ? 'analyticsLeftDrawerHeaderSectionTitleSelected' : 'analyticsLeftDrawerHeaderSectionTitle'} onClick={() => {setDrawerContents('personal')}}>Personal</span>
              <span className={drawerContents == 'shared' ? 'analyticsLeftDrawerHeaderSectionTitleSelected' : 'analyticsLeftDrawerHeaderSectionTitle'} onClick={() => {setDrawerContents('shared')}}>Shared</span>
            </div>


            <div className='analyticsLeftDrawerTabs'>

            <span className='analyticsLeftDrawerTabsSectionTitle'>PINS</span>
              {savedReports.map((report) => (
                report.isPinned && (
                  <div
                    key={report.id}
                    className={currentReportId === report.id ? 'analyticsLeftDrawerTabSelected' : 'analyticsLeftDrawerTab'}
                    onClick={() => {
                      setCurrentReportId(report.id);
                      loadReport(report);
                    }}
                  >
                    {report.reportIcon ?
                      <img src={report.reportIcon} alt='report icon' style={{height: 16, width: 16, marginRight: 10}}/>
                      :
                      <div style={{width: 8, height: 8, borderRadius: '50%', backgroundColor: '#' + report.reportColor, marginRight: 14, marginLeft: 4}}></div>
                    }
                    {report.name}
                  </div>
                )
              ))}
            



            <span className='analyticsLeftDrawerTabsSectionTitle'>DEFAULT</span>
              {savedReports.map((report) => (
                report.isDefault && (
                  <div
                    key={report.id}
                    className={currentReportId === report.id ? 'analyticsLeftDrawerTabSelected' : 'analyticsLeftDrawerTab'}
                    onClick={() => {
                      setCurrentReportId(report.id);
                      loadReport(report);
                    }}
                  >
                    {report.reportIcon ?
                      <img src={report.reportIcon} alt='report icon' style={{height: 16, width: 16, marginRight: 10}}/>
                    :
                      <div style={{width: 8, height: 8, borderRadius: '50%', backgroundColor: '#' + report.reportColor, marginRight: 18, marginRight: 14, marginLeft: 4}}></div>
                    }
                    {report.name}
                  </div>
                )
              ))}



            <span className='analyticsLeftDrawerTabsSectionTitle'>CUSTOM</span>
              {savedReports.map((report) => (
                !report.isDefault && !report.isPinned && (
                  <div
                    key={report.id}
                    className={currentReportId === report.id ? 'analyticsLeftDrawerTabSelected' : 'analyticsLeftDrawerTab'}
                    onClick={() => {
                      setCurrentReportId(report.id);
                      loadReport(report);
                    }}
                    >
                    {report.reportIcon ?
                      <img src={report.reportIcon} alt='report icon' style={{height: 16, width: 16, marginRight: 10}}/>
                      :
                      <div style={{width: 8, height: 8, borderRadius: '50%', backgroundColor: '#' + report.reportColor, marginRight: 18, marginRight: 14, marginLeft: 4}}></div>
                    }
                    {report.name}
                    </div>
                  )
                  ))}


                </div>
                   

              

                </div>




                




                {!showCustomMetricPopup && (
                  <div className='analyticsHeaderRow'>
                    <div className='analyticsHeaderRowLeft'>
                      <AnalyticsDropdown 
                        showHeaderDropdown={showHeaderDropdown}
                        setShowHeaderDropdown={setShowHeaderDropdown}
                        saveReport={saveReport}
                        currentReportId={currentReportId}
                        setReportAsDefault={setReportAsDefault}
                        deleteReport={deleteReport}
                        setShowRenameReportPopup={setShowRenameReportPopup}
                        setShowShareReportPopup={setShowShareReportPopup}
                        showCompareData={showCompareData}
                        setShowCompareData={setShowCompareData}
                        showLegend={showLegend}
                        setShowLegend={setShowLegend}
                        showAxisLabels={showAxisLabels}
                        setShowAxisLabels={setShowAxisLabels}
                        showGridLines={showGridLines}
                        setShowGridLines={setShowGridLines}
                        customizeLayout={customizeLayout}
                        setCustomizeLayout={setCustomizeLayout}
                        narrowMode={narrowMode}
                        setNarrowMode={setNarrowMode}
                        setShowCustomMetricPopup={setShowCustomMetricPopup}
                      />
                      <div className='analyticsHeaderRowAddMetricButtonBorder' onClick={() => setShowCustomMetricPopup(true)}>
                        <div className='analyticsHeaderRowAddMetricButton'>
                          <div className='analyticsHeaderRowAddMetricButtonIcon'>
                            <AddRounded style={{height: 20, width: 20}}/>
                          </div>
                          <div className='analyticsHeaderRowAddMetricButtonText'>Create Chart</div>
                        </div>
                      </div>
                    </div>

                    {/* DATE SELECTOR */}
                    <DateSelector setShowDateSelectors={setShowDateSelectors} showDateSelectors={showDateSelectors} dateStart={dateStart} setDateStart={setDateStart} dateEnd={dateEnd} setDateEnd={setDateEnd} dateUpdateTrigger={dateUpdateTrigger} setDateUpdateTrigger={setDateUpdateTrigger} />
                  </div>
                )}










          {/* Save Report Modal */}

          <div className={showSaveReportModal ? 'analyticsCustomMetricPopupBackground' : 'analyticsCustomMetricPopupBackgroundHidden'}>
            <div className='analyticsCustomMetricPopup'>
              <div className='analyticsCustomMetricPopupTopContainer'>
                <div className='analyticsCustomMetricPopupTopSearchIcon'>
                  {/* SVG Icon */}
                  <svg
                    width='20px'
                    height='20px'
                    viewBox='0 -0.5 25 25'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M5.11413 8.35688C4.75894 8.56999 4.64377 9.03069 4.85688 9.38587C5.06999 9.74106 5.53069 9.85623 5.88587 9.64312L5.11413 8.35688ZM10.5 6L10.95 5.4C10.7061 5.21704 10.3756 5.19999 10.1141 5.35688L10.5 6ZM14.5 9L14.05 9.6C14.3236 9.80522 14.7014 9.79932 14.9685 9.58565L14.5 9ZM19.9685 5.58565C20.292 5.32689 20.3444 4.85493 20.0857 4.53148C19.8269 4.20803 19.3549 4.15559 19.0315 4.41435L19.9685 5.58565ZM17.75 19C17.75 19.4142 18.0858 19.75 18.5 19.75C18.9142 19.75 19.25 19.4142 19.25 19H17.75ZM19.25 11C19.25 10.5858 18.9142 10.25 18.5 10.25C18.0858 10.25 17.75 10.5858 17.75 11H19.25ZM9.75 19C9.75 19.4142 10.0858 19.75 10.5 19.75C10.9142 19.75 11.25 19.4142 11.25 19H9.75ZM11.25 11C11.25 10.5858 10.9142 10.25 10.5 10.25C10.0858 10.25 9.75 10.5858 9.75 11H11.25ZM13.75 19C13.75 19.4142 14.0858 19.75 14.5 19.75C14.9142 19.75 15.25 19.4142 15.25 19H13.75ZM15.25 14C15.25 13.5858 14.9142 13.25 14.5 13.25C14.0858 13.25 13.75 13.5858 13.75 14H15.25ZM5.75 19C5.75 19.4142 6.08579 19.75 6.5 19.75C6.91421 19.75 7.25 19.4142 7.25 19H5.75ZM7.25 14C7.25 13.5858 6.91421 13.25 6.5 13.25C6.08579 13.25 5.75 13.5858 5.75 14H7.25ZM5.88587 9.64312L10.8859 6.64312L10.1141 5.35688L5.11413 8.35688L5.88587 9.64312ZM10.05 6.6L14.05 9.6L14.95 8.4L10.95 5.4L10.05 6.6ZM14.9685 9.58565L19.9685 5.58565L19.0315 4.41435L14.0315 8.41435L14.9685 9.58565ZM19.25 19V11H17.75V19H19.25ZM11.25 19V11H9.75V19H11.25ZM15.25 19V14H13.75V19H15.25ZM7.25 19V14H5.75V19H7.25Z'
                      fill='#000000'
                    />
                  </svg>
                </div>
                <input
                  className='analyticsCustomMetricPopupTopInput'
                  placeholder='Enter the name of your report'
                  value={newReportName}
                  onChange={(e) => {
                    setNewReportName(e.target.value);
                  }}
                />
                <span
                  className='analyticsCustomMetricPopupTopESCButton'
                  onClick={() => {
                    setShowSaveReportModal(false);
                  }}
                >
                  esc
                </span>
              </div>

              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={isDefaultReport}
                    onChange={(e) => setIsDefaultReport(e.target.checked)}
                    name='isDefaultReport'
                    color='primary'
                  />
                }
                label='Set as default report'
              /> */}
              <Button variant='contained' onClick={createNewReport}>
                Save
              </Button>
            </div>
        </div>










          {/* SHARE REPORT POPUP */}
          <div className={showShareReportPopup ? 'analyticsSharePopupBackground' : 'analyticsSharePopupBackgroundHidden'}>
            <div className='analyticsSharePopup'>
              <div className='analyticsSharePopupTopContainer'>
                <div className='analyticsSharePopupTopSearchIcon'>
                  {/* SVG Icon */}
                  <svg width="25px" height="25px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M17.5004 17.2342C17.4691 16.3071 16.6977 15.5777 15.7703 15.5986C14.8429 15.6194 14.105 16.3826 14.1154 17.3102C14.1259 18.2377 14.8808 18.9842 15.8084 18.9842C16.7587 18.9677 17.516 18.1845 17.5004 17.2342Z" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M11.5774 11.9841C11.6007 13.41 10.4642 14.585 9.03836 14.6091C7.6129 14.5845 6.47696 13.4096 6.50035 11.9841C6.47696 10.5587 7.6129 9.38378 9.03836 9.35913C10.4642 9.38323 11.6007 10.5583 11.5774 11.9841Z" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M16.6544 6.73414C16.6693 7.34862 16.35 7.92293 15.8202 8.23456C15.2904 8.54619 14.6333 8.54619 14.1035 8.23456C13.5737 7.92293 13.2544 7.34862 13.2694 6.73414C13.2544 6.11966 13.5737 5.54536 14.1035 5.23372C14.6333 4.92209 15.2904 4.92209 15.8202 5.23372C16.35 5.54536 16.6693 6.11966 16.6544 6.73414Z" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M14.1046 16.688C14.4426 16.9275 14.9107 16.8478 15.1502 16.5099C15.3898 16.1719 15.31 15.7038 14.9721 15.4643L14.1046 16.688ZM11.4721 12.9833C11.1342 12.7437 10.666 12.8235 10.4265 13.1614C10.187 13.4993 10.2667 13.9675 10.6046 14.207L11.4721 12.9833ZM14.2009 8.44267C14.5033 8.15959 14.519 7.68498 14.2359 7.38259C13.9528 7.08019 13.4782 7.06454 13.1758 7.34761L14.2009 8.44267ZM10.5298 9.82461C10.2274 10.1077 10.2118 10.5823 10.4948 10.8847C10.7779 11.1871 11.2525 11.2027 11.5549 10.9197L10.5298 9.82461ZM14.9721 15.4643L11.4721 12.9833L10.6046 14.207L14.1046 16.688L14.9721 15.4643ZM13.1758 7.34761L10.5298 9.82461L11.5549 10.9197L14.2009 8.44267L13.1758 7.34761Z" fill="#000000"/>
                  </svg>
                </div>
                <input
                  className='analyticsSharePopupTopInput'
                  placeholder='Search Employees'
                  value={searchUserText}
                  onChange={(e) => {
                    setSearchUserText(e.target.value);
                  }}
                />
                <span
                  className='analyticsSharePopupTopESCButton'
                  onClick={() => {
                    setShowShareReportPopup(false);
                  }}
                >
                  esc
                </span>
              </div>

              <div className='analyticsSharePopupUserList'>
                {businessUsers
                  .filter((user) => user.name.toLowerCase().includes(searchUserText.toLowerCase()))
                  .map((user) => (
                    <div key={user.id} className='analyticsSharePopupUserRow' style={{backgroundColor: sharedWithUsersArray.includes(user.id) && '#f4f4f4'}} onClick={() => {adjustUserSelection(user.id)}}>
                      <div className='analyticsSharePopupUserAvatar' style={{color: user.hexCode, backgroundColor: user.hexCode + '30'}}>{user.initials}</div>
                      <span className='analyticsSharePopupUserName'>{user.name}</span>
                      {user.id == uid && <span className='analyticsSharePopupMeTag'>Me</span>}
                    </div>
                ))}
              </div>


              <div className='analyticsSharePopupBottomContainer'>
                <span className='analyticsSharePopupButton' onClick={() => {shareReport()}}>Share</span>
              </div>

            </div>
        </div>










          {/* RENAME REPORT POPUP */}
          <div className={showRenameReportPopup ? 'analyticsRenamePopupBackground' : 'analyticsRenamePopupBackgroundHidden'}>
            <div className='analyticsRenamePopup'>
              <div className='analyticsRenamePopupTopContainer'>
                <div className='analyticsRenamePopupTopSearchIcon'>
                  {/* SVG Icon */}
                  <svg
                    width='20px'
                    height='20px'
                    viewBox='0 -0.5 25 25'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M5.11413 8.35688C4.75894 8.56999 4.64377 9.03069 4.85688 9.38587C5.06999 9.74106 5.53069 9.85623 5.88587 9.64312L5.11413 8.35688ZM10.5 6L10.95 5.4C10.7061 5.21704 10.3756 5.19999 10.1141 5.35688L10.5 6ZM14.5 9L14.05 9.6C14.3236 9.80522 14.7014 9.79932 14.9685 9.58565L14.5 9ZM19.9685 5.58565C20.292 5.32689 20.3444 4.85493 20.0857 4.53148C19.8269 4.20803 19.3549 4.15559 19.0315 4.41435L19.9685 5.58565ZM17.75 19C17.75 19.4142 18.0858 19.75 18.5 19.75C18.9142 19.75 19.25 19.4142 19.25 19H17.75ZM19.25 11C19.25 10.5858 18.9142 10.25 18.5 10.25C18.0858 10.25 17.75 10.5858 17.75 11H19.25ZM9.75 19C9.75 19.4142 10.0858 19.75 10.5 19.75C10.9142 19.75 11.25 19.4142 11.25 19H9.75ZM11.25 11C11.25 10.5858 10.9142 10.25 10.5 10.25C10.0858 10.25 9.75 10.5858 9.75 11H11.25ZM13.75 19C13.75 19.4142 14.0858 19.75 14.5 19.75C14.9142 19.75 15.25 19.4142 15.25 19H13.75ZM15.25 14C15.25 13.5858 14.9142 13.25 14.5 13.25C14.0858 13.25 13.75 13.5858 13.75 14H15.25ZM5.75 19C5.75 19.4142 6.08579 19.75 6.5 19.75C6.91421 19.75 7.25 19.4142 7.25 19H5.75ZM7.25 14C7.25 13.5858 6.91421 13.25 6.5 13.25C6.08579 13.25 5.75 13.5858 5.75 14H7.25ZM5.88587 9.64312L10.8859 6.64312L10.1141 5.35688L5.11413 8.35688L5.88587 9.64312ZM10.05 6.6L14.05 9.6L14.95 8.4L10.95 5.4L10.05 6.6ZM14.9685 9.58565L19.9685 5.58565L19.0315 4.41435L14.0315 8.41435L14.9685 9.58565ZM19.25 19V11H17.75V19H19.25ZM11.25 19V11H9.75V19H11.25ZM15.25 19V14H13.75V19H15.25ZM7.25 19V14H5.75V19H7.25Z'
                      fill='#000000'
                    />
                  </svg>
                </div>
                <input
                  className='analyticsRenamePopupTopInput'
                  placeholder='Enter the new name of your report'
                  value={renameReportText}
                  onChange={(e) => {
                    setRenameReportText(e.target.value);
                  }}
                />
                <span
                  className='analyticsRenamePopupTopESCButton'
                  onClick={() => {
                    setShowRenameReportPopup(false);
                  }}
                >
                  esc
                </span>
              </div>


              <div className='analyticsRenamePopupBottomContainer'>
                <span className='analyticsRenamePopupButton' onClick={() => {renameReport()}}>Rename</span>
              </div>

            </div>
        </div>


    
    







          {/* Custom Layout Popup OVER NAV */}
          {/* <div className={customizeLayout ? 'analyticsCustomizeLayoutPopup' : 'analyticsCustomizeLayoutPopupHidden'}>
            <span className='analyticsCustomizeLayoutPopupTitle'>Chart Library</span>
            <span onClick={() => {addTextBlock()}}>ADD TEXT BLOCK</span>

            <div className='analyticsRemovedChartsList'>
              {metrics
                .filter((metric) => !metric.visible)
                .map((metric) => (
                  <div key={metric.key} className='analyticsRemovedChartsListItem' onClick={() => handleRestoreChart(metric.key)}>
                    <div className='analyticsRemovedChartsListItemIconsRow'>


                      
                      {metric.chartType === 'bar' && <BarChartRounded style={{ height: 20, width: 20, color: '#635bff' }} />}
                      {metric.chartType === 'line' && <ShowChartRounded style={{ height: 20, width: 20, color: '#635bff' }} />}
                      {metric.chartType === 'pie' && <PieChartRounded style={{ height: 20, width: 20, color: '#635bff' }} />}
                      {metric.chartType === 'table' && <TableChartRounded style={{ height: 20, width: 20, color: '#635bff' }} />} 
                      {metric.chartType === 'text' && <TextFieldsRounded style={{ height: 20, width: 20, color: '#635bff' }} />}
                      {metric.chartType === 'react_component' && <WebAssetRounded style={{ height: 20, width: 20, color: '#635bff' }} />}
                     


                    </div>
                    <span>{metric.name}</span>
                  </div>
                ))}
            </div>
          </div> */}











          {/* Custom Metric Popup */}
          {/* <div className={showCustomMetricPopup ? 'analyticsCustomMetricPopupBackgroundHidden' : 'analyticsCustomMetricPopupBackgroundHidden'}>
            <div className='analyticsCustomMetricPopup'>
              <div className='analyticsCustomMetricPopupTopContainer'>
                <div className='analyticsCustomMetricPopupTopSearchIcon'>
                </div>
                <input
                  className='analyticsCustomMetricPopupTopInput'
                  placeholder='Enter the name of your chart'
                  value={customMetricName}
                  onChange={(e) => { setCustomMetricName(e.target.value); }}
                />
                <span
                  className='analyticsCustomMetricPopupTopESCButton'
                  onClick={() => { setShowCustomMetricPopup(false); }}
                >
                  esc
                </span>
              </div>

              {['shopify', 'omni', 'facebook', 'google', 'tiktok'].map((source) => (
                <div key={source} className='analyticsCustomMetricPopupMetricRow'>
                  <img
                    src={
                      source === 'shopify'
                      ? shopify_logo
                      : source === 'omni'
                      ? shopify_logo
                      : source === 'facebook'
                      ? Meta_logo
                      : source === 'google'
                      ? google_ads_logo
                      : tiktok_logo_white_background
                    }
                    alt={`${source} Logo`}
                    style={{ width: 20, height: 20, paddingRight: 10 }}
                  />
                  {metrics
                    .filter((metric) => metric.source === source)
                    .map((metric) => (
                      <div
                        key={metric.key}
                        className='analyticsCustomMetricPopupMetricButton'
                        onClick={() => {
                          // Add selected operand to the currently active line
                          setCustomMetricLines((prevLines) => {
                            // For simplicity, add to the last line being edited
                            const newLines = [...prevLines];
                            const currentLineIndex = newLines.length - 1;
                            // Prevent duplicates in the same line
                            if (!newLines[currentLineIndex].some(op => op.operand === metric.key)) {
                              newLines[currentLineIndex] = [
                                ...newLines[currentLineIndex],
                                { operand: metric.key, operation: '+' }
                              ];
                            }
                            return newLines;
                          });
                        }}
                      >
                        {metric.name}
                      </div>
                    ))}
                </div>
              ))}

              {customMetricLines.map((line, lineIndex) => (
                <div key={lineIndex} className='analyticsCustomMetricPopupFormulaRow'>
                  {line.length === 0 && <div>Select metrics to include in your formula line {lineIndex + 1}</div>}
                  {line?.map((item, itemIndex) => (
                    <div key={itemIndex} className='analyticsCustomMetricPopupFormulaOperand'>
                      {itemIndex > 0 && (
                        <select
                          className='analyticsCustomMetricPopupFormulaOperationDropdown'
                          value={item.operation}
                          onChange={(e) => {
                            setCustomMetricLines(prevLines => {
                              const newLines = [...prevLines];
                              newLines[lineIndex][itemIndex].operation = e.target.value;
                              return newLines;
                            });
                          }}
                        >
                          <option value='+'>+</option>
                          <option value='-'>−</option>
                          <option value='*'>×</option>
                          <option value='/'>÷</option>
                        </select>
                      )}
                      <div className='analyticsCustomMetricPopupFormulaMetric'>
                        {metrics.find((m) => m.key === item.operand)?.name || item.operand}
                        <span
                          className='analyticsCustomMetricPopupFormulaMetricRemoveButton'
                          onClick={() => {
                            setCustomMetricLines(prevLines => {
                              const newLines = [...prevLines];
                              newLines[lineIndex] = newLines[lineIndex].filter((_, i) => i !== itemIndex);
                              return newLines;
                            });
                          }}
                        >
                          <CloseRounded style={{ width: 10, height: 10, color: '#635bff' }} />
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              ))}

              <div className='analyticsCustomMetricPopupBottomRow'>
                <div
                  className='analyticsCustomMetricPopupDoneButton'
                  onClick={() => addCustomMetric()}
                >
                  <span>Create</span>
                </div>
                <div
                  className='analyticsCustomMetricPopupAddLineButton'
                  onClick={() => {
                    setCustomMetricLines((prev) => [...prev, []]);
                  }}
                  style={{
                    marginLeft: '10px',
                    backgroundColor: '#eee',
                    padding: '5px 10px',
                    borderRadius: '4px',
                    cursor: 'pointer'
                  }}
                >
                  Add Line
                </div>
              </div>
            </div>
          </div>
 */}










          {showCustomMetricPopup && (
            <BuildMetric showCustomMetricPopup={showCustomMetricPopup} setShowCustomMetricPopup={setShowCustomMetricPopup} metrics={metrics} customMetricLines={customMetricLines} setCustomMetricLines={setCustomMetricLines} 
            data={data} compareData={compareData} showDaily={showDaily} showHourly={showHourly} line1Name={line1Name} line2Name={line2Name} showLegend={showLegend} showAxisLabels={showAxisLabels} 
            showGridLines={showGridLines} showCompareData={showCompareData} darkMode={darkMode} />
          )}








              
          {/* Grid Layout */}
          <div className='analyticsGridLayoutFlexContainer' style={{opacity: showCustomMetricPopup ? 0 : 100}}>
            <div className='analyticsGridLayoutContainer'>
              <div style={{width: narrowMode && '60%', marginLeft: narrowMode && '20%', paddingBottom: 120, paddingTop: 80}}>
                <GridLayout
                  className='analyticsGridLayout'
                  layout={layout}
                  cols={12}
                  rowHeight={75}
                  width={narrowMode ? containerWidth * 0.6 : containerWidth}
                  onLayoutChange={onLayoutChange}
                  draggableHandle='.analyticsChartContainerDragHandle'
                  isResizable={customizeLayout}
                >
                  {metrics
                    .filter((metric) => metric.visible)
                    .map((metric) => (
                      <div key={metric.key} data-grid={layout.find((l) => l.i === metric.key)}>

                          <div className='analyticsChartContainer' style={{boxShadow: metric.chartType == 'text' && 'none', backgroundColor: metric.chartType == 'text' && '#ffffff00', border: metric.chartType == 'text' && 'none'}}>
                            {customizeLayout && (
                              <div className='analyticsChartContainerDragHandle' style={{ cursor: 'move', padding: '5px' }}>
                                <DragIndicatorRounded style={{ height: 20, width: 20, color: '#aaa' }} />
                              </div>
                            )}
                            {customizeLayout && (
                              <div className='analyticsChartContainerDeleteIcon' style={{ padding: '5px' }} onClick={() => handleDeleteChart(metric.key)}>
                                <HighlightOffRounded style={{ height: 20, width: 20, color: '#aaa' }} />
                              </div>
                            )}
                            {customizeLayout && (
                              <div className="analyticsChartContainerChartTypeDropdownContainer">
                                {/* Trigger to open dropdown */}
                                <div
                                  className="analyticsChartContainerChartTypeDropdown"
                                  style={{ padding: "5px" }}
                                  onClick={() => setShowChartTypeDropdown(metric.key)}
                                >
                                  {metric.chartType === "bar" && <BarChartRounded style={{ height: 20, width: 20 }} />}
                                  {metric.chartType === "line" && <ShowChartRounded style={{ height: 20, width: 20 }} />}
                                  {metric.chartType === "table" && <GridViewRounded style={{ height: 20, width: 20 }} />}
                                </div>

                                {/* ClickAwayListener wrapping only the dropdown */}
                                {showChartTypeDropdown === metric.key && (
                                  <ClickAwayListener
                                    onClickAway={(event) => {
                                      if (!event.target.closest(".analyticsChartContainerChartTypeDropdownContent")) {
                                        setShowChartTypeDropdown(false);
                                      }
                                    }}
                                  >
                                    <div
                                      className="analyticsChartContainerChartTypeDropdownContent"
                                      onClick={(event) => event.stopPropagation()} // Prevent closure when interacting with the dropdown
                                    >
                                      <div onClick={() => {handleChartTypeChange(metric.key, "bar"); setShowChartTypeDropdown(false)}}>
                                        <BarChartRounded style={{ height: 20, width: 20 }} />
                                      </div>
                                      <div onClick={() => {handleChartTypeChange(metric.key, "line"); setShowChartTypeDropdown(false)}}>
                                        <ShowChartRounded style={{ height: 20, width: 20 }} />
                                      </div>
                                      <div onClick={() => {handleChartTypeChange(metric.key, "table"); setShowChartTypeDropdown(false)}}>
                                        <GridViewRounded style={{ height: 20, width: 20 }} />
                                      </div>
                                    </div>
                                  </ClickAwayListener>
                                )}
                              </div>


                            )}
                            {metric.chartType === 'bar' && (
                              <ChartComponent
                                chartName={metric.name}
                                multiLineData={metric.dataVariables?.map((varKey) => data.arrays[varKey]) || []}
                                multiLineDataNames={metric.dataVariables?.map((varKey) => metrics.find((m) => m.key === varKey)?.name) || []}
                                line1Data={data.arrays[metric.key] || []}
                                line2Data={compareData.arrays[metric.key]}
                                categories={data.arrays['dates']}
                                yAxisIsNumber={true}
                                xAxisIsDate={showDaily}
                                xAxisIsTime={showHourly}
                                chartHeight={'80%'}
                                total={data.totals[metric.key]}
                                compareTotal={compareData.totals[metric.key]}
                                line1Name={line1Name}
                                line2Name={line2Name}
                                titleType={metric.type}
                                chartType='bar'
                                showLegend={showLegend}
                                showAxisLabels={showAxisLabels}
                                showGridLines={showGridLines}
                                showCompareData={showCompareData}
                                darkMode={darkMode}
                              />
                            )}
                            {metric.chartType === 'line' && (
                              <ChartComponent
                                chartName={metric.name}
                                multiLineData={metric.dataVariables?.map((varKey) => data.arrays[varKey]) || []}
                                multiLineDataNames={metric.dataVariables?.map((varKey) => metrics.find((m) => m.key === varKey)?.name) || []}
                                line1Data={data.arrays[metric.dataVariables[0].key] || []}
                                line2Data={compareData.arrays[metric.key]}
                                categories={data.arrays['dates']}
                                yAxisIsNumber={true}
                                xAxisIsDate={showDaily}
                                xAxisIsTime={showHourly}
                                chartHeight={'80%'}
                                total={data.totals[metric.key]}
                                compareTotal={compareData.totals[metric.key]}
                                line1Name={line1Name}
                                line2Name={line2Name}
                                titleType={metric.type}
                                chartType='line'
                                showLegend={showLegend}
                                showAxisLabels={showAxisLabels}
                                showGridLines={showGridLines}
                                showCompareData={showCompareData}
                                darkMode={darkMode}
                              />
                            )}

                            {metric.chartType === 'table' && (
                              <div className="analyticsTableContainer">

                                <table className="analyticsTable">
                                  <thead>
                                    <tr>
                                      <th>Date</th>
                                      <th>Previous</th>
                                      <th>Current</th>
                                      <th>% Change</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data.arrays['dates'].map((date, index) => {
                                      const currentValue = data.arrays[metric.key][index];
                                      const previousValue = compareData.arrays[metric.key][index];
                                      const percentageChange =
                                        previousValue !== 0
                                          ? ((currentValue - previousValue) / previousValue) * 100
                                          : null; // Avoid division by zero

                                      return (
                                        <tr key={index}>
                                          <td>{date}</td>
                                          <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(previousValue)}</td>
                                          <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(currentValue)}</td>
                                          <td>
                                            {percentageChange !== null
                                              ? `${percentageChange.toFixed(2)}%`
                                              : "N/A"}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                    <tr>
                                      <td>Total</td>
                                      <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(compareData.totals[metric.key])}</td>
                                      <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(data.totals[metric.key])}</td>
                                      <td>
                                        {compareData.totals[metric.key] !== 0
                                          ? (
                                              new Intl.NumberFormat('en-US', { style: 'percent' }).format(((data.totals[metric.key] - compareData.totals[metric.key]) /
                                                compareData.totals[metric.key]) 
                                            ))
                                          : "N/A"}
                                      </td>
                                    </tr>

                                  </tbody>
                                </table>

                              </div>
                            )}

                            {metric.chartType === 'text' && (
                              <div className='analyticsGridTextContainer' style={{border: customizeLayout && '1px solid #e7e7e7'}}>
                                {editingTextMetricKey == metric.key && customizeLayout ? 
                                  <div className='analyticsGridTextContainerRow'>
                                    <input className='analyticsGridTextContainerInput' type='text' value={metric.name} onChange={(e) => changeTextBlockContents(e.target.value, metric.key)} />
                                    <span className='analyticsGridTextContainerDoneIcon' onClick={() => {setEditingTextMetricKey(null)}}><CheckRounded style={{width: 20, height: 20, color: '#666'}}/></span>
                                  </div>
                                  :
                                  <div className='analyticsGridTextContainerRow'>
                                    <span className='analyticsGridTextContainerName'>{metric.name}</span>
                                    {customizeLayout && <span className='analyticsGridTextContainerEditIcon' onClick={() => {setEditingTextMetricKey(metric.key)}}><EditRounded style={{width: 20, height: 20, color: '#666'}}/></span>}
                                  </div>
                                }
                                
                              </div>
                            )}

                            {metric.chartType === 'react_component' && (
                              <JsxParser
                                  components={{ ProfitByDay }}
                                  bindings={{ darkMode, serverURL }}
                                  jsx={metric.component}
                              />
                            )}



                          </div>
                    





                      

                      </div>
                    ))}
                </GridLayout>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Analytics;
