
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { SearchRounded } from '@mui/icons-material';


function SearchPopup ({navigate, serverURL, showSearchPopup, setShowSearchPopup}) {

    const [searchResults, setSearchResults] = useState({ navigation: [], fb_ad_account: [], fb_campaign: [], fb_adset: [], fb_ad: [], shopify_products: [] })
    const [searchText, setSearchText] = useState('')
    const bid = localStorage.getItem('bid');


    useEffect(() => {
        // Set a timeout to delay the API call by 2 seconds
        const timeoutId = setTimeout(() => {
            if (searchText !== '') {
                searchDatabase();
            } else {
                setSearchResults({ navigation: [], fb_ad_account: [], fb_campaign: [], fb_adset: [], fb_ad: [], shopify_products: [] });
            }
        }, 500);

        // Cleanup the timeout if the searchText changes before 2 seconds
        return () => clearTimeout(timeoutId);
    }, [searchText]);

    async function searchDatabase() {
        try {
            console.log('searching DB');
            const response = await axios.get(`${serverURL}/search?omni_business_id=${bid}&searchString=${searchText}`);
            console.log(response.data);
            setSearchResults(response.data);
        } catch (error) {
            console.log('ERROR searching DB', error);
        }
    }

    useEffect(() => {
        // Function that will be triggered on 'Enter' key press
        if (showSearchPopup) {
            const handleKeyPress = (event) => {
                if (event.key === 'Enter') {
                    console.log('Enter key pressed');
                    if (searchResults.navigation.length > 0) {
                        console.log('Navigating to:', searchResults.navigation[0].url);
                        // If there are navigation results, navigate to the first result
                        setShowSearchPopup(false);
                        setSearchText('');
                        navigate(searchResults.navigation[0].url);
                    }
                }
            };

            // Add event listener for keydown
            window.addEventListener('keydown', handleKeyPress);

            // Cleanup the event listener when the component unmounts
            return () => {
                window.removeEventListener('keydown', handleKeyPress);
            };
        }
    }, [searchResults, showSearchPopup]);




    const searchTextInputRef = useRef(null);

    function handleKeyPress(event) {
        // Check if the Shift key is pressed
        if (event.shiftKey) {
            return; // Exit the function if Shift is pressed
        }
    
        if (event.keyCode === 27) {
            // ESC key pressed
            setShowSearchPopup(false);
        } else if (event.keyCode === 75 && event.metaKey) {
            // Cmd+K (Mac) or Ctrl+K (Windows/Linux)
            setShowSearchPopup(true);
            setTimeout(() => {
                searchTextInputRef.current?.focus();
            }, 200);
        }
    }
    


    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        // Remove event listener when the component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);



    return (
        <div className={showSearchPopup ? 'navSearchPopupBackground' : 'navSearchPopupBackgroundHidden'}>
            <div className='navSearchPopup'>
                <div className='navSearchTopContainer'>
                    <div className='navSearchTopSearchIcon'>
                        <SearchRounded />
                    </div>
                    <input className='navSearchTopInput' placeholder='Search...' ref={searchTextInputRef} value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                    <span className='navSearchTopESCButton' onClick={() => { setShowSearchPopup(false) }}>esc</span>
                </div>
                {(searchResults.navigation.length != 0 || searchResults.fb_ad_account.length != 0 || searchResults.fb_campaign.length != 0 || searchResults.fb_adset.length != 0 || searchResults.fb_ad.length != 0 || searchResults.shopify_products.length != 0) && (
                <div className='navSearchResultsContainer'>
                    {searchResults.navigation.length > 0 && <div className='navSearchResultsCategory'>Navigation</div>}
                    {searchResults.navigation.map((x, index) => (
                        <div className='navSearchResult' onClick={() => { setShowSearchPopup(false); setSearchText(''); navigate(x.url); }}>
                            <span className='navSearchResultTitle'>{x.name}</span>
                            <span className='navSearchResultDescription'>{x.url}</span>
                            {index == 0 && (<span className='navSearchResultEnterTag'>Enter</span>)}
                        </div>
                    ))}
                    {searchResults.fb_ad_account.length > 0 && <div className='navSearchResultsCategory'>Facebook Ad Accounts</div>}
                    {searchResults.fb_ad_account.map((x, index) => (
                        <div className='navSearchResult'>
                            <span className='navSearchResultTitle'>{x.name}</span>
                            <span className='navSearchResultDescription'>{x.status}</span>
                        </div>
                    ))}
                    {searchResults.fb_campaign.length > 0 && <div className='navSearchResultsCategory'>Facebook Campaigns</div>}
                    {searchResults.fb_campaign.map((x, index) => (
                        <div className='navSearchResult'>
                            <span className='navSearchResultTitle'>{x.name}</span>
                            <span className='navSearchResultDescription'>{x.status}</span>
                        </div>
                    ))}
                    {searchResults.fb_adset.length > 0 && <div className='navSearchResultsCategory'>Facebook Ad Sets</div>}
                    {searchResults.fb_adset.map((x, index) => (
                        <div className='navSearchResult'>
                            <span className='navSearchResultTitle'>{x.name}</span>
                            <span className='navSearchResultDescription'>{x.status}</span>
                        </div>
                    ))}
                    {searchResults.fb_ad.length > 0 && <div className='navSearchResultsCategory'>Facebook Ads</div>}
                    {searchResults.fb_ad.map((x, index) => (
                        <div className='navSearchResult'>
                            <span className='navSearchResultTitle'>{x.name}</span>
                            <span className='navSearchResultDescription'>{x.status}</span>
                        </div>
                    ))}
                    {searchResults.shopify_products.length > 0 && <div className='navSearchResultsCategory'>Shopify Products</div>}
                    {searchResults.shopify_products.map((x, index) => (
                        <div className='navSearchResult' onClick={() => { setShowSearchPopup(false); setSearchText(''); navigate('/Products'); }}>
                            <span className='navSearchResultTitle'>{x.title}</span>
                            <span className='navSearchResultDescription'>{x.description.slice(0, 80)}</span>
                        </div>
                    ))}
                </div>
                )}

            </div>
        </div>
    )
}





export default SearchPopup;