import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, updateDoc, setDoc, getDoc } from 'firebase/firestore';
import axios from 'axios';


function ProcessFacebookRedirect({serverURL}) {
    const bid = localStorage.getItem('bid');
    const navigate = useNavigate();

    const appID = '854522159611705';
    const redirectURI = `https://localhost:3011/process-facebook-redirect`;

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        if (code) {
            saveCodeToDB(code);
        } else {
            // If no code is present, redirect to Facebook auth
            redirectToFacebookAuth();
        }
    }, []); 

    function redirectToFacebookAuth() {
        const scope = 'ads_management,ads_read,business_management,pages_read_engagement,pages_show_list,read_insights';
        const url = `https://www.facebook.com/v20.0/dialog/oauth?client_id=${appID}&redirect_uri=${redirectURI}&scope=${scope}&response_type=code`;
        window.location.href = url;
    }
    

    async function saveCodeToDB(code) {
        const db = getFirestore();
        const businessRef = doc(db, 'businesses', bid);

        try {
            const docSnapshot = await getDoc(businessRef);
            if (docSnapshot.exists()) {
                await updateDoc(businessRef, {
                    facebookOAuthCode: code,
                    facebookOAuthURL: redirectURI,
                    facebookOAuthAccessTokenNeverUsed: true
                });
            
                console.log('Code updated in Firestore successfully');
            } else {
                await setDoc(businessRef, {
                    facebookOAuthCode: code,
                    facebookOAuthURL: redirectURI,
                    facebookOAuthAccessTokenNeverUsed: true
                });
                console.log('Code saved to Firestore successfully');
            }
            await fetchFacebookRefreshToken();

            navigate('/facebook-accounts');
        } catch (error) {
            console.error('Error saving/updating code in Firestore:', error);
        }
    }



    async function fetchFacebookRefreshToken() {
        try {
            const response = await axios.post(`${serverURL}/getFacebookRefreshToken`, {
                omniBusinessID: bid
            });
            console.log('Token fetched:', response);
        } catch (error) {
            console.error('Error fetching Facebook refresh token:', error);
        }
    }
    

    return <div>Loading...</div>;
}

export default ProcessFacebookRedirect;
