import './AccountSupplier.css';
import react, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CircularProgress, ClickAwayListener, Tooltip } from '@mui/material';
import { CheckRounded, CloseRounded, ContentCopyRounded, KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import { addDoc, collection, doc, getCountFromServer, getDoc, getDocs, limit, onSnapshot, orderBy, query, serverTimestamp, setDoc, updateDoc, where } from 'firebase/firestore';
import {db} from '../FirebaseInit';
import Meta_logo from '../assets/Meta_logo.png';
import tiktok_logo_white_background from '../assets/tiktok_logo_white_background.png';
import google_ads_logo from '../assets/google_ads_logo.png';
import snapchat_logo from '../assets/snapchat_logo.png';
import shopify_logo from '../assets/shopify_logo.png';


function AccountSupplier({ serverURL, darkMode, setSnackbarInfo }) {
    const navigate = useNavigate()
    const uid = localStorage.getItem('uid');

    const [statusFilter, setStatusFilter] = useState('pending');
    const [requests, setRequests] = useState([]);
    const [leftColumnContents, setLeftColumnContents] = useState('requests');
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [selectedRequestMaxSpend, setSelectedRequestMaxSpend] = useState(null);
    const [allBusinessRequests, setAllBusinessRequests] = useState([]);
    const [allBusinessTopups, setAllBusinessTopups] = useState([]);
    const [showRejectionReasonDropdown, setShowRejectionReasonDropdown] = useState(false);
    const [newMessageText, setNewMessageText] = useState('');
    const [topups, setTopups] = useState([]);
    const [conversations, setConversations] = useState([]);
    const [conversationMessages, setConversationMessages] = useState([]);




    function generateRandomId() {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = 'c_';
    
        const charactersLength = characters.length;
        for (let i = 0; i < 30; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }





    async function getRequests () {
        try {
            const q = query(collection(db, "accountRequests"), orderBy('createdAt', 'desc'));

            const requestsTemp = [];
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                requestsTemp.push(doc.data());
            });

            setRequests(requestsTemp);


        } catch (error) {
            console.error(error);
            setSnackbarInfo({ open: true, title: 'Error', message: 'An error occurred while fetching requests', type: 'error' });
        }
    }


    async function getTopups () {
        try {
            const q = query(collection(db, "accountTopups"), orderBy('timestamp', 'desc'));

            const topupsTemp = [];
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                topupsTemp.push(doc.data());
            });

            setTopups(topupsTemp);

        } catch (error) {
            console.error(error);
            setSnackbarInfo({ open: true, title: 'Error', message: 'An error occurred while fetching top-ups', type: 'error' });
        }
    }

    useEffect(() => {
        getRequests();
        getTopups();
    }, [])


    useEffect(() => {
        function getConversations() {
            const messagesRef = collection(db, 'conversations');
            const q = query(messagesRef, where('participants', 'array-contains', uid), orderBy('updated_at', 'desc'));
    
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const conversationsTemp = querySnapshot.docs.map(doc => doc.data());
                console.log('Real-time conversations: ', conversationsTemp);
                setConversations(conversationsTemp);
            });

    
            return unsubscribe;
        }
        const unsubscribe = getConversations();
        return unsubscribe;
    
    }, [uid]); 



    useEffect(() => {
        if (selectedRequest && selectedRequest.conversationID) {
            console.log('steve')
            function getMessages() {
                const messagesRef = collection(db, 'conversations', selectedRequest?.conversationID, 'messages');
                const q = query(messagesRef, orderBy('timestamp', 'desc'));
        
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const messagesTemp = querySnapshot.docs.map(doc => doc.data());
                    console.log('Real-time messages: ', messagesTemp);
                    setConversationMessages(messagesTemp);
                });
        
                return unsubscribe;
            }
            const unsubscribe = getMessages();
            return unsubscribe;
        }
    }, [selectedRequest]); 
    


    function timeAgo(date) {
        // Ensure the input date is a valid Date object
        if (!(date instanceof Date)) {
            date = new Date(date); // Convert to Date if it's not already
        }
    
        const seconds = Math.floor((new Date() - date) / 1000);
        const daysAgo = Math.floor(seconds / 86400);
    
        // Check if the date is within the last 7 days
        if (daysAgo <= 7) {
            const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            const dayOfWeek = dayNames[date.getDay()];
            return dayOfWeek; // Return the full day name
        }
    
        let interval = Math.floor(seconds / 31536000);
        if (interval > 1) {
            return interval + " years ago";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
            return interval + " months ago";
        }
        if (daysAgo > 1) {
            return daysAgo + " days ago";
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
            return interval + " hours ago";
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
            return interval + " minutes ago";
        }
        return "now";
    }
    
    
    


    async function getSelectedRequestMaxSpend() {
        const reportedSpends = selectedRequest.last30;
        let maxSpend = 0;

        const spendValues = [reportedSpends.facebookSpendTotal, reportedSpends.googleSpendTotal, reportedSpends.tiktokSpendTotal, reportedSpends.snapchatSpendTotal];

        for (let i = 0; i < spendValues.length; i++) {
            if (spendValues[i] > maxSpend) {
                maxSpend = spendValues[i];
            }
        }
        setSelectedRequestMaxSpend(maxSpend);
    }

    async function getAllRequestsFromSelectedBusiness () {
        try {
            const q = query(collection(db, "accountRequests"), where("omniBusinessID", "==", selectedRequest.omniBusinessID));

            const requestsTemp = [];
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                requestsTemp.push(doc.data());
            });

            setAllBusinessRequests(requestsTemp);

        } catch (error) {
            console.error(error);
            setSnackbarInfo({ open: true, title: 'Error', message: 'An error occurred while fetching requests', type: 'error' });
        }
    }



    async function getSelectedRequestTopupHistory() {
        try {
            const q = query(collection(db, "accountTopups"), where("omniBusinessID", "==", selectedRequest.omniBusinessID));

            const topupsTemp = [];
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                topupsTemp.push({topupID: doc.id, ...doc.data()});
            });

            setAllBusinessTopups(topupsTemp);

        } catch (error) {
            console.error(error);
            setSnackbarInfo({ open: true, title: 'Error', message: 'An error occurred while fetching requests', type: 'error' });
        }
    }


    useEffect(() => {
        if (selectedRequest) {
            getSelectedRequestMaxSpend();
            getAllRequestsFromSelectedBusiness();
            getSelectedRequestTopupHistory()
        }
    }, [selectedRequest])


  


    async function markTopupAsApproved (topup) {
        try {
            await updateDoc(doc(db, "accountTopups", topup.topupID), {
                status: 'approved',
                approvedAt: serverTimestamp()
            }, { merge: true });

            getSelectedRequestTopupHistory();

        } catch (error) {
            console.error(error);
            setSnackbarInfo({ open: true, title: 'Error', message: 'An error occurred while approving the top-up', type: 'error' });
        }
    }


    async function markTopupAsRejected (topup) {
        try {
            await updateDoc(doc(db, "accountTopups", topup.topupID), {
                status: 'rejected',
                rejectedAt: serverTimestamp()
            }, { merge: true });

            getSelectedRequestTopupHistory();

        } catch (error) {
            console.error(error);
            setSnackbarInfo({ open: true, title: 'Error', message: 'An error occurred while rejecting the top-up', type: 'error' });
        }
    }


    async function markRequestAsRejected (reason) {
        try {
            await updateDoc(doc(db, "accountRequests", selectedRequest.requestID), {
                status: 'rejected',
                reviewTimestamp: serverTimestamp(),
                rejectionReason: reason
            }, { merge: true });

            getRequests();
            //set selected request to the one that was just updated
            setSelectedRequest({ ...selectedRequest, status: 'rejected', reviewTimestamp: serverTimestamp(), rejectionReason: reason });

        } catch (error) {
            console.error(error);
            setSnackbarInfo({ open: true, title: 'Error', message: 'An error occurred while rejecting the request', type: 'error' });
        }
    }



    async function markRequestAsApproved () {
        try {
            await updateDoc(doc(db, "accountRequests", selectedRequest.requestID), {
                status: 'approved',
                reviewTimestamp: serverTimestamp(),
            }, { merge: true });

            getRequests();
            //set selected request to the one that was just updated
            setSelectedRequest({ ...selectedRequest, status: 'approved', reviewTimestamp: serverTimestamp() });

        } catch (error) {
            console.error(error);
            setSnackbarInfo({ open: true, title: 'Error', message: 'An error occurred while approving the request', type: 'error' });
        }
    }






    async function createConversation() {
        try {
            const conversationID = generateRandomId();
            const conversationData = {
                participants: [uid, selectedRequest.omniBusinessID],
                last_message: null,
                created_at: new Date(),
                updated_at: new Date(),
            };
            await setDoc(doc(db, 'conversations', conversationID), conversationData);

            await updateDoc(doc(db, 'accountRequests', selectedRequest.requestID), {
                conversationID: conversationID,
            }, { merge: true });

            console.log('Conversation created successfully');
            setSelectedRequest({ ...selectedRequest, conversationID: conversationID });
            return conversationID; 
        } catch (error) {
            console.error('Error creating conversation: ', error);
        }
    }



    async function sendMessage() {
        try {
            let conversationID = selectedRequest.conversationID;
            if (!selectedRequest.requestID) {
                setSnackbarInfo({ open: true, title: 'Error', message: 'Please select a request to send a message', type: 'error' });
                return;
            }
            if (conversationID == null) {
                conversationID = await createConversation();
            }
            const messageData = {
                sender_id: uid,
                message: newMessageText,
                timestamp: new Date(),
                message_type: 'text', 
                status: 'sent', // You can update this later with 'delivered' or 'read'
                metadata: {},
            };
            await addDoc(collection(db, 'conversations', conversationID, 'messages'), messageData);
            await updateConversationLastMessage(conversationID);
            setNewMessageText('');
            console.log('Message sent successfully');
        } catch (error) {
            console.error('Error sending message: ', error);
        }
    }



    async function updateConversationLastMessage(conversationID) {
        try {
          const conversationRef = doc(db, 'conversations', conversationID);
          await updateDoc(conversationRef, {
            last_message: {
              text: newMessageText,
              sender_id: uid,
              timestamp: new Date(),
            },
            updated_at: new Date(),
          });
          console.log('Conversation last message updated');
        } catch (error) {
          console.error('Error updating conversation: ', error);
        }
    }



    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter' && newMessageText.length > 0) {
                sendMessage();
            }
        };

        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [newMessageText]);




    return (
        <div className='AccountSupplierContainer'>
            <div className='AccountSupplierLeft'>

                <span className='AccountSupplierLeftTitle'>Your Inbox</span>

                <div className='AccountSupplierLeftHeader'>
                    <div className='AccountSupplierLeftHeaderButtonRow' style={{backgroundColor: leftColumnContents == 'requests' && '#e6ecfe'}} onClick={() => {setLeftColumnContents('requests')}}>
                        <div className='AccountSupplierLeftHeaderButtonRowIconContainer' style={{backgroundColor: '#ebf7f2'}}>
                            <svg width="25px" height="25px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M14.9769 14H10.0229C8.56488 14.0724 7.2731 14.963 6.68693 16.3C5.97993 17.688 7.39093 19 9.03193 19H15.9679C17.6099 19 19.0209 17.688 18.3129 16.3C17.7268 14.963 16.435 14.0724 14.9769 14Z" stroke="#13b981" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M15.4999 8C15.4999 9.65685 14.1568 11 12.4999 11C10.8431 11 9.49994 9.65685 9.49994 8C9.49994 6.34315 10.8431 5 12.4999 5C13.2956 5 14.0587 5.31607 14.6213 5.87868C15.1839 6.44129 15.4999 7.20435 15.4999 8Z" stroke="#13b981" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                        <span className='AccountSupplierLeftHeaderButtonRowText'>Requests</span>
                        <span className='AccountSupplierLeftHeaderButtonRowCount'>{requests.length}</span>
                    </div>
                    <div className='AccountSupplierLeftHeaderButtonRow' style={{backgroundColor: leftColumnContents == 'topups' && '#e6ecfe'}} onClick={() => {setLeftColumnContents('topups')}}>
                        <div className='AccountSupplierLeftHeaderButtonRowIconContainer' style={{backgroundColor: '#fef6e7'}}>
                            <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7255 17.1019C11.6265 16.8844 11.4215 16.7257 11.1734 16.6975C10.9633 16.6735 10.7576 16.6285 10.562 16.5636C10.4743 16.5341 10.392 16.5019 10.3158 16.4674L10.4424 16.1223C10.5318 16.1622 10.6239 16.1987 10.7182 16.2317L10.7221 16.2331L10.7261 16.2344C11.0287 16.3344 11.3265 16.3851 11.611 16.3851C11.8967 16.3851 12.1038 16.3468 12.2629 16.2647L12.2724 16.2598L12.2817 16.2544C12.5227 16.1161 12.661 15.8784 12.661 15.6021C12.661 15.2955 12.4956 15.041 12.2071 14.9035C12.062 14.8329 11.8559 14.7655 11.559 14.6917C11.2545 14.6147 10.9987 14.533 10.8003 14.4493C10.6553 14.3837 10.5295 14.279 10.4161 14.1293C10.3185 13.9957 10.2691 13.7948 10.2691 13.5319C10.2691 13.2147 10.3584 12.9529 10.5422 12.7315C10.7058 12.5375 10.9381 12.4057 11.2499 12.3318C11.4812 12.277 11.6616 12.1119 11.7427 11.8987C11.8344 12.1148 12.0295 12.2755 12.2723 12.3142C12.4751 12.3465 12.6613 12.398 12.8287 12.4677L12.7122 12.8059C12.3961 12.679 12.085 12.6149 11.7841 12.6149C10.7848 12.6149 10.7342 13.3043 10.7342 13.4425C10.7342 13.7421 10.896 13.9933 11.1781 14.1318L11.186 14.1357L11.194 14.1393C11.3365 14.2029 11.5387 14.2642 11.8305 14.3322C12.1322 14.4004 12.3838 14.4785 12.5815 14.5651L12.5856 14.5669L12.5897 14.5686C12.7365 14.6297 12.8624 14.7317 12.9746 14.8805L12.9764 14.8828L12.9782 14.8852C13.0763 15.012 13.1261 15.2081 13.1261 15.4681C13.1261 15.7682 13.0392 16.0222 12.8604 16.2447C12.7053 16.4377 12.4888 16.5713 12.1983 16.6531C11.974 16.7163 11.8 16.8878 11.7255 17.1019Z" fill="#f59e0b"/>
                                <path d="M11.9785 18H11.497C11.3893 18 11.302 17.9105 11.302 17.8V17.3985C11.302 17.2929 11.2219 17.2061 11.1195 17.1944C10.8757 17.1667 10.6399 17.115 10.412 17.0394C10.1906 16.9648 9.99879 16.8764 9.83657 16.7739C9.76202 16.7268 9.7349 16.6312 9.76572 16.5472L10.096 15.6466C10.1405 15.5254 10.284 15.479 10.3945 15.5417C10.5437 15.6262 10.7041 15.6985 10.8755 15.7585C11.131 15.8429 11.3762 15.8851 11.611 15.8851C11.8129 15.8851 11.9572 15.8628 12.0437 15.8181C12.1302 15.7684 12.1735 15.6964 12.1735 15.6021C12.1735 15.4929 12.1158 15.411 12.0004 15.3564C11.8892 15.3018 11.7037 15.2422 11.4442 15.1777C11.1104 15.0933 10.8323 15.0039 10.6098 14.9096C10.3873 14.8103 10.1936 14.6514 10.0288 14.433C9.86396 14.2096 9.78156 13.9092 9.78156 13.5319C9.78156 13.095 9.91136 12.7202 10.1709 12.4074C10.4049 12.13 10.7279 11.9424 11.1401 11.8447C11.2329 11.8227 11.302 11.7401 11.302 11.6425V11.2C11.302 11.0895 11.3893 11 11.497 11H11.9785C12.0862 11 12.1735 11.0895 12.1735 11.2V11.6172C12.1735 11.7194 12.2487 11.8045 12.3471 11.8202C12.7082 11.8777 13.0255 11.9866 13.2989 12.1469C13.3765 12.1924 13.4073 12.2892 13.3775 12.3756L13.0684 13.2725C13.0275 13.3914 12.891 13.4417 12.7812 13.3849C12.433 13.2049 12.1007 13.1149 11.7841 13.1149C11.4091 13.1149 11.2216 13.2241 11.2216 13.4425C11.2216 13.5468 11.2773 13.6262 11.3885 13.6809C11.4998 13.7305 11.6831 13.7851 11.9386 13.8447C12.2682 13.9192 12.5464 14.006 12.773 14.1053C12.9996 14.1996 13.1953 14.356 13.3602 14.5745C13.5291 14.7929 13.6136 15.0908 13.6136 15.4681C13.6136 15.8851 13.4879 16.25 13.2365 16.5628C13.0176 16.8354 12.7145 17.0262 12.3274 17.1353C12.2384 17.1604 12.1735 17.2412 12.1735 17.3358V17.8C12.1735 17.9105 12.0862 18 11.9785 18Z" fill="#f59e0b"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M9.59235 5H13.8141C14.8954 5 14.3016 6.664 13.8638 7.679L13.3656 8.843L13.2983 9C13.7702 8.97651 14.2369 9.11054 14.6282 9.382C16.0921 10.7558 17.2802 12.4098 18.1256 14.251C18.455 14.9318 18.5857 15.6958 18.5019 16.451C18.4013 18.3759 16.8956 19.9098 15.0182 20H8.38823C6.51033 19.9125 5.0024 18.3802 4.89968 16.455C4.81587 15.6998 4.94656 14.9358 5.27603 14.255C6.12242 12.412 7.31216 10.7565 8.77823 9.382C9.1696 9.11054 9.63622 8.97651 10.1081 9L10.0301 8.819L9.54263 7.679C9.1068 6.664 8.5101 5 9.59235 5Z" stroke="#f59e0b" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M13.2983 9.75C13.7125 9.75 14.0483 9.41421 14.0483 9C14.0483 8.58579 13.7125 8.25 13.2983 8.25V9.75ZM10.1081 8.25C9.69391 8.25 9.35812 8.58579 9.35812 9C9.35812 9.41421 9.69391 9.75 10.1081 9.75V8.25ZM15.9776 8.64988C16.3365 8.44312 16.4599 7.98455 16.2531 7.62563C16.0463 7.26671 15.5878 7.14336 15.2289 7.35012L15.9776 8.64988ZM13.3656 8.843L13.5103 9.57891L13.5125 9.57848L13.3656 8.843ZM10.0301 8.819L10.1854 8.08521L10.1786 8.08383L10.0301 8.819ZM8.166 7.34357C7.80346 7.14322 7.34715 7.27469 7.1468 7.63722C6.94644 7.99976 7.07791 8.45607 7.44045 8.65643L8.166 7.34357ZM13.2983 8.25H10.1081V9.75H13.2983V8.25ZM15.2289 7.35012C14.6019 7.71128 13.9233 7.96683 13.2187 8.10752L13.5125 9.57848C14.3778 9.40568 15.2101 9.09203 15.9776 8.64988L15.2289 7.35012ZM13.2209 8.10709C12.2175 8.30441 11.1861 8.29699 10.1854 8.08525L9.87486 9.55275C11.0732 9.80631 12.3086 9.81521 13.5103 9.57891L13.2209 8.10709ZM10.1786 8.08383C9.47587 7.94196 8.79745 7.69255 8.166 7.34357L7.44045 8.65643C8.20526 9.0791 9.02818 9.38184 9.88169 9.55417L10.1786 8.08383Z" fill="#f59e0b"/>
                            </svg>
                        </div>
                        <span className='AccountSupplierLeftHeaderButtonRowText'>Top-ups</span>
                        <span className='AccountSupplierLeftHeaderButtonRowCount'>{topups.length}</span>
                    </div>
                    <div className='AccountSupplierLeftHeaderButtonRow' style={{backgroundColor: leftColumnContents == 'messages' && '#e6ecfe'}} onClick={() => {setLeftColumnContents('messages')}}>
                        <div className='AccountSupplierLeftHeaderButtonRowIconContainer' style={{backgroundColor: '#fff0f3'}}>
                            <svg width="25px" height="25px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M5.5 12C5.49988 14.613 6.95512 17.0085 9.2741 18.2127C11.5931 19.4169 14.3897 19.2292 16.527 17.726L19.5 18V12C19.5 8.13401 16.366 5 12.5 5C8.63401 5 5.5 8.13401 5.5 12Z" stroke="#fb7385" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                        <span className='AccountSupplierLeftHeaderButtonRowText'>Messages</span>
                        <span className='AccountSupplierLeftHeaderButtonRowCount'>{conversations.length}</span>
                    </div>
                </div>


                {leftColumnContents === 'requests' && (
                    <div style={{width: '100%'}}>
                        <span className='AccountSupplierLeftRequestSectionTitle'>Your Requests</span>
                        {requests.map((x, index) => (
                            <div key={index} className='AccountSupplierRequestRow' style={{backgroundColor: selectedRequest?.requestID == x.requestID && '#f1f1f1', fontWeight: selectedRequest?.requestID == x.requestID && 600}} onClick={() => {setSelectedRequest(x);}}>
                                {x.platform == 'facebook' && <div className='AccountSupplierRequestRowLogoContainer'><img src={Meta_logo} className='AccountSupplierRequestRowLogo' /></div>}
                                {x.platform == 'tiktok' && <div className='AccountSupplierRequestRowLogoContainer'><img src={tiktok_logo_white_background} className='AccountSupplierRequestRowLogo' /></div>}
                                {x.platform == 'google' && <div className='AccountSupplierRequestRowLogoContainer'><img src={google_ads_logo} className='AccountSupplierRequestRowLogo' /></div>}
                                {x.platform == 'snapchat' && <div className='AccountSupplierRequestRowLogoContainer'><img src={snapchat_logo} className='AccountSupplierRequestRowLogo' /></div>}
                    
                                <div style={{width: '100%'}}>
                                        <span className='AccountSupplierRequestRowBusinessName'>{x.businessName}</span>
                                        {x.platform == 'facebook' && <span className='AccountSupplierRequestRowSpend'>Spent {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.last30.facebookSpendTotal)} in last 30 days</span>}
                                        {x.platform == 'tiktok' && <span className='AccountSupplierRequestRowSpend'>Spent {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.last30.tiktokSpendTotal)} in last 30 days</span>}
                                        {x.platform == 'google' && <span className='AccountSupplierRequestRowSpend'>Spent {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.last30.googleSpendTotal)} in last 30 days</span>}
                                        {x.platform == 'snapchat' && <span className='AccountSupplierRequestRowSpend'>Spent {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.last30.snapchatSpendTotal)} in last 30 days</span>}
                                    <div className='AccountSupplierRequestRowStatusRow'>
                                        {x.status == 'approved' && <div className='AccountSupplierRequestRowStatusIndicatorDot' style={{backgroundColor: '#1dca23'}}></div>}
                                        {x.status == 'pending' && <div className='AccountSupplierRequestRowStatusIndicatorDot' style={{backgroundColor: '#e7a65b'}}></div>}
                                        {x.status == 'rejected' && <div className='AccountSupplierRequestRowStatusIndicatorDot' style={{backgroundColor: '#c8383c'}}></div>}
                                        <span className='AccountSupplierRequestRowStatus'>{x.status} · </span>
                                        <span className='AccountSupplierRequestRowStatus'>{x.timezone.split(' ')[1]} ·</span>
                                        <span className='AccountSupplierRequestRowStatus'>{x.currency}</span>
                                    </div>
                                    
                                </div>
                                
                                
                                <span className='AccountSupplierRequestRowTimestamp'>{timeAgo(x.createdAt.seconds * 1000)}</span>
                            </div>
                        ))}
                    </div>
                )}
                {leftColumnContents === 'topups' && (
                    <div style={{width: '100%'}}>
                        {topups.map((x, index) => (
                        <div key={index} className='AccountSupplierTopupRow' style={{backgroundColor: selectedRequest?.requestID == x.requestID && '#f1f1f1'}} onClick={() => {}}>

                            {x.platform == 'facebook' && <div className='AccountSupplierTopupRowLogoContainer'><img src={Meta_logo} className='AccountSupplierTopupRowLogo' /></div>}
                            {x.platform == 'tiktok' && <div className='AccountSupplierTopupRowLogoContainer'><img src={tiktok_logo_white_background} className='AccountSupplierTopupRowLogo' /></div>}
                            {x.platform == 'google' && <div className='AccountSupplierTopupRowLogoContainer'><img src={google_ads_logo} className='AccountSupplierTopupRowLogo' /></div>}
                            {x.platform == 'snapchat' && <div className='AccountSupplierTopupRowLogoContainer'><img src={snapchat_logo} className='AccountSupplierTopupRowLogo' /></div>}
                
                            <div>
                                <span className='AccountSupplierTopupRowTitle'>{x.accountID.slice(4)}</span>
                                <span className='AccountSupplierTopupRowMiddleTitle'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.amount)} USD</span>
                                <div className='AccountSupplierTopupRowStatusContainer'>
                                    {x.status == 'approved' && <div className='AccountSupplierTopupRowStatusIndicatorDot' style={{backgroundColor: '#1dca23'}}></div>}
                                    {x.status == 'pending' && <div className='AccountSupplierTopupRowStatusIndicatorDot' style={{backgroundColor: '#e7a65b'}}></div>}
                                    {x.status == 'rejected' && <div className='AccountSupplierTopupRowStatusIndicatorDot' style={{backgroundColor: '#c8383c'}}></div>}
                                    <span className='AccountSupplierTopupRowStatus'>{x.status}</span>
                                </div>
                            </div>

                            <div className='AccountSupplierTopupRowReviewRow'>
                                {/* <div className='AccountSupplierTopupRowReviewRowCopyAccountID'>
                                    <ContentCopyRounded style={{width: 20, height: 20, color: '#666'}}/>
                                </div> */}
                                <div className='AccountSupplierTopupRowReviewRowReject' onClick={() => {markTopupAsRejected(x);}}>
                                    <CloseRounded style={{width: 20, height: 20, color: '#fb7385'}}/>
                                </div>
                                <div className='AccountSupplierTopupRowReviewRowApprove' onClick={() => {markTopupAsApproved(x);}}>
                                    <CheckRounded style={{width: 20, height: 20, color: '#13b981'}}/>
                                </div>
                            </div>

                            
                            
                            <span className='AccountSupplierTopupRowTimestamp'>{timeAgo(x.timestamp?.seconds * 1000)}</span>
                        </div>
                    ))}
                    </div>
                )}
                {leftColumnContents === 'messages' && (
                    <div style={{width: '100%'}}>
                        {requests.map((x, index) => (
                            <div key={index} className='AccountSupplierRequestRow'>
                                <span>{x.status}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>




















            <div className='AccountSupplierMiddle'>
                <div className='AccountSupplierMiddleHeader'>
                    <span className='AccountSupplierMiddleHeaderTitle'>{selectedRequest?.businessName}</span>
                    {selectedRequest?.status == 'pending' && (
                        <div className='AccountSupplierMiddleHeaderRightRow'>
                            <div className='AccountSupplierMiddleHeaderRightRowApproveButton' onClick={() => {markRequestAsRejected('bm_history')}}>
                                <div className='AccountSupplierMiddleHeaderRightRowApproveButtonIndicatorDot' style={{backgroundColor: '#c8383c'}}></div>
                                Reject for BM history
                            </div>

                            <div className='AccountSupplierMiddleHeaderRightRowApproveButton' onClick={() => {markRequestAsRejected('page')}}>
                                <div className='AccountSupplierMiddleHeaderRightRowApproveButtonIndicatorDot' style={{backgroundColor: '#c8383c'}}></div>
                                Reject for Incomplete Page
                            </div>

                            <div className='AccountSupplierMiddleHeaderRightRowApproveButton' onClick={() => {markRequestAsRejected('other')}}>
                                <div className='AccountSupplierMiddleHeaderRightRowApproveButtonIndicatorDot' style={{backgroundColor: '#c8383c'}}></div>
                                Reject for Other Reason
                            </div>



                            <div className='AccountSupplierMiddleHeaderRightRowApproveButton' onClick={() => {markRequestAsApproved()}}>
                                <div className='AccountSupplierMiddleHeaderRightRowApproveButtonIndicatorDot'></div>
                                Mark as Complete
                            </div>
                        </div>
                        )}
                </div>

                <div className='AccountSupplierMiddleMessagesSectionScrollContainer'>
                    <div className='AccountSupplierMiddleMessagesSectionContainer'>
                        {conversationMessages.map((x, index) => (
                            <div key={index} className='AccountSupplierMiddleMessageRow' style={{justifyContent: x.sender_id == uid ? 'flex-end' : 'flex-start'}}>
                                <div className='AccountSupplierMiddleMessageRowMessage'>
                                    <span>{x.message}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='AccountSupplierMiddleNewMessageInputRowBottom'>
                    <input className='AccountSupplierMiddleNewMessageInput' placeholder='Type a message...' value={newMessageText} onChange={(e) => {setNewMessageText(e.target.value)}}/>
                    <div className='AccountSupplierMiddleNewMessageInputButton' style={{backgroundColor: newMessageText.length == 0 && '#f0f0f0'}} onClick={() => {sendMessage();}}>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-2xl"><path fillRule="evenodd" clipRule="evenodd" d="M15.1918 8.90615C15.6381 8.45983 16.3618 8.45983 16.8081 8.90615L21.9509 14.049C22.3972 14.4953 22.3972 15.2189 21.9509 15.6652C21.5046 16.1116 20.781 16.1116 20.3347 15.6652L17.1428 12.4734V22.2857C17.1428 22.9169 16.6311 23.4286 15.9999 23.4286C15.3688 23.4286 14.8571 22.9169 14.8571 22.2857V12.4734L11.6652 15.6652C11.2189 16.1116 10.4953 16.1116 10.049 15.6652C9.60265 15.2189 9.60265 14.4953 10.049 14.049L15.1918 8.90615Z" fill="#fff"></path></svg>
                    </div>
                </div>
            </div>

























            <div className='AccountSupplierRight'>
                <span className='AccountSupplierRightTitle'>At a glance (last 30 days)</span>
                <div className='AccountSupplierRightSpendCard'>
                    {/* <span className='AccountSupplierRightSpendCardTitle'>Analytics (last 30 days)</span> */}
                    {/* <span className='AccountSupplierRightSpendCardDate'>Last 30 days</span> */}

                    {selectedRequest?.last30?.facebookSpendTotal > 0 && (
                        <div className='AccountSupplierRightSpendCardPlatformRow'>
                            <span className='AccountSupplierRightSpendCardPlatformTitle'><img src={Meta_logo} className='AccountSupplierRequestRowLogo' /></span>
                            <div className='AccountSupplierRightSpendCardPlatformChartParent'>
                                <div className='AccountSupplierRightSpendCardPlatformChartChild' style={{width: (selectedRequest?.last30?.facebookSpendTotal / selectedRequestMaxSpend) * 100 + "%"}}></div>
                            </div>
                            <span className='AccountSupplierRightSpendCardPlatformValue'>{selectedRequest?.last30?.facebookSpendTotal ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(selectedRequest?.last30?.facebookSpendTotal) : '$0.00'}</span>
                        </div>
                    )}

                    {selectedRequest?.last30?.googleSpendTotal > 0 && (
                    <div className='AccountSupplierRightSpendCardPlatformRow'>
                        <span className='AccountSupplierRightSpendCardPlatformTitle'><img src={tiktok_logo_white_background} className='AccountSupplierRequestRowLogo' /></span>
                        <div className='AccountSupplierRightSpendCardPlatformChartParent'>
                            <div className='AccountSupplierRightSpendCardPlatformChartChild' style={{width: (selectedRequest?.last30?.googleSpendTotal / selectedRequestMaxSpend) * 100 + "%"}}></div>
                        </div>
                        <span className='AccountSupplierRightSpendCardPlatformValue'>{selectedRequest?.last30?.googleSpendTotal ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(selectedRequest?.last30?.googleSpendTotal) : '$0.00'}</span>
                    </div>
                    )}

                    {selectedRequest?.last30?.tiktokSpendTotal > 0 && (
                    <div className='AccountSupplierRightSpendCardPlatformRow'>
                        <span className='AccountSupplierRightSpendCardPlatformTitle'><img src={google_ads_logo} className='AccountSupplierRequestRowLogo' /></span>
                        <div className='AccountSupplierRightSpendCardPlatformChartParent'>
                            <div className='AccountSupplierRightSpendCardPlatformChartChild' style={{width: (selectedRequest?.last30?.tiktokSpendTotal / selectedRequestMaxSpend) * 100 + "%"}}></div>
                        </div>
                        <span className='AccountSupplierRightSpendCardPlatformValue'>{selectedRequest?.last30?.tiktokSpendTotal ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(selectedRequest?.last30?.tiktokSpendTotal) : '$0.00'}</span>
                    </div>
                    )}

                    {selectedRequest?.last30?.snapchatSpendTotal > 0 && (
                        <div className='AccountSupplierRightSpendCardPlatformRow'>
                            <span className='AccountSupplierRightSpendCardPlatformTitle'><img src={snapchat_logo} className='AccountSupplierRequestRowLogo' /></span>
                            <div className='AccountSupplierRightSpendCardPlatformChartParent'>
                                <div className='AccountSupplierRightSpendCardPlatformChartChild' style={{width: (selectedRequest?.last30?.snapchatSpendTotal / selectedRequestMaxSpend) * 100 + "%"}}></div>
                            </div>
                            <span className='AccountSupplierRightSpendCardPlatformValue'>{selectedRequest?.last30?.snapchatSpendTotal ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(selectedRequest?.last30?.snapchatSpendTotal) : '$0.00'}</span>
                        </div>
                    )}
                </div>
                


                <div style={{width: '100%', padding: 5}}>
                    <span className='AccountSupplierRightTitle'>Top-ups from {selectedRequest?.businessName}</span>
                    {allBusinessTopups.map((x, index) => (
                        <div key={index} className='AccountSupplierTopupRow' style={{backgroundColor: selectedRequest?.requestID == x.requestID && '#f1f1f1'}} onClick={() => {}}>

                            {x.platform == 'facebook' && <div className='AccountSupplierTopupRowLogoContainer'><img src={Meta_logo} className='AccountSupplierTopupRowLogo' /></div>}
                            {x.platform == 'tiktok' && <div className='AccountSupplierTopupRowLogoContainer'><img src={tiktok_logo_white_background} className='AccountSupplierTopupRowLogo' /></div>}
                            {x.platform == 'google' && <div className='AccountSupplierTopupRowLogoContainer'><img src={google_ads_logo} className='AccountSupplierTopupRowLogo' /></div>}
                            {x.platform == 'snapchat' && <div className='AccountSupplierTopupRowLogoContainer'><img src={snapchat_logo} className='AccountSupplierTopupRowLogo' /></div>}
                
                            <div>
                                <span className='AccountSupplierTopupRowTitle'>Account ID: {x.accountID.slice(4)}</span>
                                <span className='AccountSupplierTopupRowMiddleTitle'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.amount)} USD</span>
                                <div className='AccountSupplierTopupRowStatusContainer'>
                                    {x.status == 'approved' && <div className='AccountSupplierTopupRowStatusIndicatorDot' style={{backgroundColor: '#1dca23'}}></div>}
                                    {x.status == 'pending' && <div className='AccountSupplierTopupRowStatusIndicatorDot' style={{backgroundColor: '#e7a65b'}}></div>}
                                    {x.status == 'rejected' && <div className='AccountSupplierTopupRowStatusIndicatorDot' style={{backgroundColor: '#c8383c'}}></div>}
                                    <span className='AccountSupplierTopupRowStatus'>{x.status}</span>
                                </div>
                            </div>

                            <div className='AccountSupplierTopupRowReviewRow'>
                                {/* <div className='AccountSupplierTopupRowReviewRowCopyAccountID'>
                                    <ContentCopyRounded style={{width: 20, height: 20, color: '#666'}}/>
                                </div> */}
                                <div className='AccountSupplierTopupRowReviewRowReject' onClick={() => {markTopupAsRejected(x);}}>
                                    <CloseRounded style={{width: 20, height: 20, color: '#fb7385'}}/>
                                </div>
                                <div className='AccountSupplierTopupRowReviewRowApprove' onClick={() => {markTopupAsApproved(x);}}>
                                    <CheckRounded style={{width: 20, height: 20, color: '#13b981'}}/>
                                </div>
                            </div>

                            
                            
                            <span className='AccountSupplierTopupRowTimestamp'>{timeAgo(x.timestamp?.seconds * 1000)}</span>
                        </div>
                    ))}
                </div>





                <div style={{width: '100%', padding: 5}}>
                    <span className='AccountSupplierRightTitle'>All requests from {selectedRequest?.businessName}</span>
                    {allBusinessRequests.map((x, index) => (
                        <div key={index} className='AccountSupplierRequestRow' style={{backgroundColor: selectedRequest?.requestID == x.requestID && '#f1f1f1', fontWeight: selectedRequest?.requestID == x.requestID && 600}} onClick={() => {setSelectedRequest(x);}}>
                            {x.platform == 'facebook' && <div className='AccountSupplierRequestRowLogoContainer'><img src={Meta_logo} className='AccountSupplierRequestRowLogo' /></div>}
                            {x.platform == 'tiktok' && <div className='AccountSupplierRequestRowLogoContainer'><img src={tiktok_logo_white_background} className='AccountSupplierRequestRowLogo' /></div>}
                            {x.platform == 'google' && <div className='AccountSupplierRequestRowLogoContainer'><img src={google_ads_logo} className='AccountSupplierRequestRowLogo' /></div>}
                            {x.platform == 'snapchat' && <div className='AccountSupplierRequestRowLogoContainer'><img src={snapchat_logo} className='AccountSupplierRequestRowLogo' /></div>}
                
                            <div style={{width: '100%'}}>
                                    <span className='AccountSupplierRequestRowBusinessName'>{x.businessName}</span>
                                    {x.platform == 'facebook' && <span className='AccountSupplierRequestRowSpend'>Spent {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.last30.facebookSpendTotal)} in last 30 days</span>}
                                    {x.platform == 'tiktok' && <span className='AccountSupplierRequestRowSpend'>Spent {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.last30.tiktokSpendTotal)} in last 30 days</span>}
                                    {x.platform == 'google' && <span className='AccountSupplierRequestRowSpend'>Spent {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.last30.googleSpendTotal)} in last 30 days</span>}
                                    {x.platform == 'snapchat' && <span className='AccountSupplierRequestRowSpend'>Spent {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.last30.snapchatSpendTotal)} in last 30 days</span>}
                                <div className='AccountSupplierRequestRowStatusRow'>
                                    {x.status == 'approved' && <div className='AccountSupplierRequestRowStatusIndicatorDot' style={{backgroundColor: '#1dca23'}}></div>}
                                    {x.status == 'pending' && <div className='AccountSupplierRequestRowStatusIndicatorDot' style={{backgroundColor: '#e7a65b'}}></div>}
                                    {x.status == 'rejected' && <div className='AccountSupplierRequestRowStatusIndicatorDot' style={{backgroundColor: '#c8383c'}}></div>}
                                    <span className='AccountSupplierRequestRowStatus'>{x.status} · </span>
                                    <span className='AccountSupplierRequestRowStatus'>{x.timezone.split(' ')[1]} ·</span>
                                    <span className='AccountSupplierRequestRowStatus'>{x.currency}</span>
                                </div>
                                
                            </div>
                            
                            
                            <span className='AccountSupplierRequestRowTimestamp'>{timeAgo(x.createdAt.seconds * 1000)}</span>
                        </div>
                    ))}
                </div>



                <div style={{width: '100%', padding: 5}}>
                    <span className='AccountSupplierRightTitle'>Pages</span>
                    {selectedRequest?.pages?.map((x, index) => { return (
                        <div className='AccountSupplierRightPageRow'>
                            <div className='AccountSupplierRightPageRowLogoContainer'><img src={Meta_logo} className='AccountSupplierRequestRowLogo' /></div>
                            <span className='AccountSupplierRightPageRowName'>{x?.name}</span>
                            <a href={'https://www.facebook.com/profile.php?id=' + x?.pageID} target='_blank' className='AccountSupplierRightPageRowLink'>
                                <div className='AccountSupplierRightPageRowOpenLinkIconContainer'>
                                    <svg width="25" height="25" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-2xl"><path fillRule="evenodd" clipRule="evenodd" d="M15.1918 8.90615C15.6381 8.45983 16.3618 8.45983 16.8081 8.90615L21.9509 14.049C22.3972 14.4953 22.3972 15.2189 21.9509 15.6652C21.5046 16.1116 20.781 16.1116 20.3347 15.6652L17.1428 12.4734V22.2857C17.1428 22.9169 16.6311 23.4286 15.9999 23.4286C15.3688 23.4286 14.8571 22.9169 14.8571 22.2857V12.4734L11.6652 15.6652C11.2189 16.1116 10.4953 16.1116 10.049 15.6652C9.60265 15.2189 9.60265 14.4953 10.049 14.049L15.1918 8.90615Z" fill="#fff"></path></svg>
                                </div>
                            </a>
                        </div>
                    )})}
                </div>


                <div style={{width: '100%', padding: 5}}>
                    <span className='AccountSupplierRightTitle'>Domains</span>
                    {selectedRequest?.domains?.map((x, index) => { return (
                        <div className='AccountSupplierRightPageRow'>
                            <div className='AccountSupplierRightPageRowLogoContainer'><img src={shopify_logo} className='AccountSupplierRequestRowLogo' /></div>
                            <span className='AccountSupplierRightPageRowName'>{x}</span>
                            <a href={"https://" + x} target='_blank' className='AccountSupplierRightPageRowLink'>
                                <div className='AccountSupplierRightPageRowOpenLinkIconContainer'>
                                    <svg width="25" height="25" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-2xl"><path fillRule="evenodd" clipRule="evenodd" d="M15.1918 8.90615C15.6381 8.45983 16.3618 8.45983 16.8081 8.90615L21.9509 14.049C22.3972 14.4953 22.3972 15.2189 21.9509 15.6652C21.5046 16.1116 20.781 16.1116 20.3347 15.6652L17.1428 12.4734V22.2857C17.1428 22.9169 16.6311 23.4286 15.9999 23.4286C15.3688 23.4286 14.8571 22.9169 14.8571 22.2857V12.4734L11.6652 15.6652C11.2189 16.1116 10.4953 16.1116 10.049 15.6652C9.60265 15.2189 9.60265 14.4953 10.049 14.049L15.1918 8.90615Z" fill="#fff"></path></svg>
                                </div>
                            </a>
                        </div>
                    )})}
                </div>



              


                


            </div>
        </div>
    );
}

export default AccountSupplier;