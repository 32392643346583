import React, { useState, useEffect } from 'react';
import './ProductSearchModal.css'
import axios
 from 'axios';
import TextInput from './TextInput';
import { AddRounded, CheckRounded, CloseRounded, LinkOffRounded, LinkRounded } from '@mui/icons-material';

const ProductSearchModal = ({ serverURL, showSelectProductModal, setShowSelectProductModal, selectedProducts, setSelectedProducts, searchProductText, setSearchProductText, titleText, submitFunction }) => {
  const [products, setAllProducts] = useState([])
  const [filteredResults, setFilteredResults] = useState([])


  const uid = localStorage.getItem('uid');
  const bid = localStorage.getItem('bid');

  async function getProducts () {
    try {
      const apiUrl = `${serverURL}/getShopifyProductsWithVariants?omni_business_id=${bid}` ;
      const response = await axios.get(apiUrl);
      console.log(response.data)
      setAllProducts(response.data)
      setFilteredResults(response.data)
    } catch (error) {
      console.log('ERROR loading products Products.js', error)
    }
  }




    useEffect(() => {
      setTimeout(() => {
        getProducts()
      }, [1000])
    }, [uid]);

    

  function filterResults () {
    let filteredResultsArray = []
    for (let i = 0; i < products.length; i++) {
      if (((products[i].product_title).toLowerCase()).includes(searchProductText.toLowerCase())) {
        filteredResultsArray.push(products[i]);
      }
    }
    setFilteredResults(filteredResultsArray)
  }

  useEffect(() => {
    filterResults();
  }, [searchProductText]); 




  function adjustProductSelection (product_id) {
    if (selectedProducts.includes(product_id)) {
      setSelectedProducts(selectedProducts.filter((x) => x !== product_id))
    } else {
      setSelectedProducts([...selectedProducts, product_id ])
    }
  }



  

  return (
    <div className={showSelectProductModal ? "productSearchModalOverlay" : "productSearchModalOverlayHidden"} onClick={() => {setShowSelectProductModal(false)}}>
      <div className='productSearchModalOrdersContainer' onClick={(e) => e.stopPropagation()}>
        <div>

        <div className='ProductSearchModalProductSearchContainer'>
          <div className='ProductSearchModalLeftHeaderRow'>
            <span className='ProductSearchModalCloseIcon' onClick={() => {setShowSelectProductModal(false)}}><CloseRounded style={{width: 20, height: 20}}/></span>
            <span className='ProductSearchModalTitle'>{titleText}</span>
          </div>
          <TextInput placeholder="Search Products" type="text" value={searchProductText} onChange={setSearchProductText} widthPercent={0} widthPixels={300}/>
        </div>

        </div>
          <div  className='productSearchModalOrdersTableContainer'>
            <table className='productSearchModalOrdersTable'>
              <thead>
                <tr>
                  <td><div style={{minHeight: 30}}></div></td>
                  <td style={{width: 20}}></td>
                  <td>Product</td>
                  <td>Ads</td>
                  <td>Status</td>
                  <td>Variants</td>
                  <td>Price</td>
                  <td>COGS</td>

                </tr>
              </thead>
              <tbody>
                {filteredResults.map((x) => { return ( 
                  <tr className={ selectedProducts.includes(x.product_id) ? 'productSearchModalOrderRow psm_selected' : 'productSearchModalOrderRow'} key={x.product_id} onClick={() => {adjustProductSelection(x.product_id);}}>
                    <td style={{width: 20}}>
                        {selectedProducts.includes(x.product_id) && (
                          <div className='productSearchModalOrderRowCheckmark'><CheckRounded style={{width: 16, height: 16, color: '#fff'}}/></div>
                        )}
                      </td>
                    <td><img className='productSearchModalOrderRowImage' src={x?.images[0]?.img_src}/></td>
                    <td>{(x.product_title).slice(0,30)}</td>
                    <td>2</td>
                    <td><div className={x.status == 'ACTIVE' ? 'productSearchModalOrderRowActive': 'productSearchModalOrderRowInactive'}></div></td>
                    <td>{((x.variants).length)}</td>
                    <td>{(x.variants[0].price * 1 || 0)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                    <td className='productSearchModalOrderRowRightColumn'>
                      {(x.variants.cogs * 1 || 0)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                    </td>
                  </tr>
                )})}
                  <tr className='productSearchModalOrderRowBottom'> 
                  <td colSpan={2}>
                      <div className='productSearchModalOrderRowBottomLeftButton'>
                        {filteredResults.length} Products
                      </div>
                    </td>
                    <td colSpan={5}>
                      <div className='productSearchModalOrderRowBottomRight'>
                          
                      </div>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>

          <span className='ProductSearchModalFAB' onClick={() => {submitFunction()}}>
            <AddRounded/>
          </span>
      </div>
    </div>
  );
};

export default ProductSearchModal;
