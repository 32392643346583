import { Check } from '@mui/icons-material';
import React from 'react';
import { FaMeta, FaFloppyDisk } from "react-icons/fa6";

const CustomizeColumnButton = ({ x, isVisible, toggleVisibility, title, description }) => {


  return (
    <div
      className={'adsColumnsPopupContentColumn'}
      onClick={toggleVisibility}
      key={x.key}
    >

      {x.key?.includes('tp') ? <FaMeta style={{ fontSize: 22, color: '#0081FB', marginRight: 10 }} /> : <FaFloppyDisk style={{ fontSize: 22, color: '#000', marginRight: 10 }} />}
      <div>
        <span className='adsColumnsPopupContentColumnTitle'>{title}</span>
        <span className='adsColumnsPopupContentColumnDescription'>{description}</span>
      </div>
      {/* <div className='adsColumnsPopupContentColumnSelectedCheckBackground' style={{ opacity: isVisible ? 100 : 0 }}>
        <Check style={{ width: 20, color: '#fff' }} />
      </div> */}
    </div>
  );
};

export default CustomizeColumnButton;