


import React, { useEffect, useState } from 'react';
import TextInput from '../../components/TextInput';
import BuildMetricDropdown from './BuildMetricDropdown';
import ChartComponent from '../../components/Charts/AnalyticsChart';
import { AddRounded, CloseRounded, DeleteForeverRounded, ExpandMoreRounded } from '@mui/icons-material';
import ColorPicker from '../../components/ColorPicker';
import BuildMetricDisplayOptions from './BuildMetricDisplayOptions';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { AnalyticsDivideIcon, AnalyticsMultiplyIcon, AnalyticsParenthesisCloseIcon, AnalyticsParenthesisOpenIcon, AnalyticsPlusIcon, AnalyticsSubtractIcon } from '../../assets/Icons/AnalyticsOperationsIcons.js';
import Meta_logo from '../../assets/Meta_logo.png';
import google_ads_logo from '../../assets/google_ads_logo.png';
import shopify_logo from '../../assets/shopify_logo.png';
import omni_logo_clear_background from '../../assets/omni_logo_clear_background.png';
import tiktok_logo from '../../assets/tiktok_logo_white_background.png';
import klaviyo_logo from '../../assets/klaviyo_logo.png';
import { processFormula } from './FormulaProcessor.js';
import { AnalyticsAreaChartIcon, AnalyticsHorizontalBarChartIcon, AnalyticsLineChartIcon, AnalyticsPieChartIcon, AnalyticsTableChartIcon, AnalyticsVerticalBarChartIcon } from '../../assets/Icons/AnalyticsChartIcons.js';


function BuildMetric({showCustomMetricPopup, setShowCustomMetricPopup, metrics, data, compareData, showDaily, showHourly, line1Name, line2Name, showLegend, showAxisLabels, showGridLines, showCompareData, darkMode}) {
  const [showSourcesDropdown, setShowSourcesDropdown] = useState(-1);
  const [selectedChartType, setSelectedChartType] = useState('line');
  const [horizontalBars, setHorizontalBars] = useState(false);
  const [expandedMetric, setExpandedMetric] = useState('');
  const [color, setColor] = useState('#0C08FF');
  const [transparencyHex, setTransparencyHex] = useState('FF');
  const [showDisplayOptionsMenu, setShowDisplayOptionsMenu] = useState(false);
  const [isFormulaValid, setIsFormulaValid] = useState(true);
  const [metricNames, setMetricNames] = useState({});
  const [lineIndexState, setLineIndexState] = useState(0);
  // const [selectedMetric, setSelectedMetric] = useState({
  //   name: "Facebook Impressions",
  //   key: "fb_impressions",
  //   lines: [
  //     {
  //       name: "Facebook Impressions",
  //       color: "#FD60B9",
  //       dataVariables: [
  //           "fb_impressions"
  //       ],
  //       formula: ['fb_impressions'],
  //     },
  //     {
  //       name: "Facebook Clicks",
  //       color: "#50B7FF",
  //       dataVariables: [
  //           "fb_clicks"
  //       ],
  //       formula: ['fb_clicks'],
  //     },
  //   ],
  //   type: "number",
  //   chartType: "line",
  //   source: "facebook",
  //   visible: false,
  //   premade: true
  // });
  const [selectedMetric, setSelectedMetric] = useState({
    name: "Shopify Orders",
    key: "shopify_orders",
    lines: [
      {
        name: "Shopify Orders",
        color: "#FD60B9",
        dataVariables: [
            "shopify_orders"
        ],
        formula: ['shopify_orders'],
      },
    ],
    type: "number",
    chartType: "line",
    horizontalBars: true,
    showGridLines: true,
    showAxisLabels: true,
    showLegend: true,
    showCompareData: true,
    source: "shopify",
    visible: false,
    premade: true
  });




  







  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
  
    if (!destination) return;
  
    const sourceDroppable = source.droppableId;
    const destinationDroppable = destination.droppableId;
  
    let updatedFormula = Array.from(selectedMetric.lines[lineIndexState].formula);
  
    // If dragging from operations to operationCanvas
    if (sourceDroppable === 'operations' && destinationDroppable === 'operationCanvas') {
      const operation = draggableId; // Directly use draggableId as the operation
      // Prevent operations or closing parenthesis from being the first item
      if (destination.index === 0) {
        if (isOperation(operation) || operation === ')') {
          return;
        }
      }
  
      // Insert the operation at the destination index
      updatedFormula.splice(destination.index, 0, operation);
    }
    // If moving within operationCanvas
    else if (sourceDroppable === 'operationCanvas' && destinationDroppable === 'operationCanvas') {
      const [movedItem] = updatedFormula.splice(source.index, 1);
      updatedFormula.splice(destination.index, 0, movedItem);
    }
  
    // Update the formula in the state without immediate validation
    setSelectedMetric((prev) => {
      const updatedMetric = { ...prev };
      updatedMetric.lines[lineIndexState].formula = updatedFormula;
      return updatedMetric;
    });
  
    // Validate the updated formula and update isFormulaValid
    const isValid = validateFormula(updatedFormula);
    setIsFormulaValid(isValid);
  };
  
  
  
  
  
  const validateFormula = (formula) => {
    let openParenthesisCount = 0;
  
    for (let i = 0; i < formula.length; i++) {
      const item = formula[i];
      const prevItem = formula[i - 1];
      const nextItem = formula[i + 1];
  
      if (item === '(') openParenthesisCount++;
      if (item === ')') openParenthesisCount--;
  
      if (openParenthesisCount < 0) return false; // More closing than opening
  
      if (i > 0) {
        if (isMetric(item) && isMetric(prevItem)) return false; // No consecutive metrics
        if (isOperation(item) && isOperation(prevItem)) return false; // No consecutive operations
        if (item === '(' && prevItem === ')') return false; // No closing before opening
        if (item === ')' && prevItem === '(') return false; // Empty parentheses
      }
  
      if (item === '(' && (!nextItem || nextItem === ')')) return false; // Empty or invalid contents
    }
  
    return openParenthesisCount === 0; // All parentheses must be closed
  };
  
  
  
  // Helper to identify operations, metrics, and parentheses
  const isOperation = (item) => ['+', '-', '*', '/'].includes(item);
  const isMetric = (item) => !isOperation(item) && item !== '(' && item !== ')';



  const removeSource = (index) => {
    // Create a copy of the current formula 
    const updatedFormula = Array.from(selectedMetric.lines[lineIndexState].formula);
    
    // Create a copy of the current dataVariables
    const updatedDataVariables = Array.from(selectedMetric.lines[lineIndexState].dataVariables);

    // Remove the item at the specified index
    updatedFormula.splice(index, 1);
    updatedDataVariables.splice(index, 1);
    
    // Update the state with the new formula
    setSelectedMetric((prev) => {
      const updatedMetric = { ...prev };
      updatedMetric.lines[lineIndexState].dataVariables = updatedDataVariables;
      updatedMetric.lines[lineIndexState].formula = updatedFormula;
      return updatedMetric;
    });
    
    // Re-validate the updated formula
    const isValid = validateFormula(updatedFormula);
    setIsFormulaValid(isValid);
  };
  
  


  useEffect(() => {
    // console.log('dataVariables', selectedMetric.lines[0].dataVariables)
    // console.log('formula', selectedMetric.lines[0].formula)
    // console.log('data', data)
  }, [selectedMetric])

  useEffect(() => {
    // const result = processFormula(data.arrays, selectedMetric.lines[0].formula)
    // console.log('STEVE', data.arrays, selectedMetric.lines?.map((line) => line.formula)[0] || [] )
    // const result = processFormula(data, selectedMetric.lines?.map((line) => line.formula) || [] )
    // console.log('result', result)
  }, [selectedMetric])


  useEffect(() => {

    const metricNamesTemp = {};
    for (let i = 0; i < metrics.length; i++) {
      metricNamesTemp[metrics[i].key] = metrics[i].name;
    }

    setMetricNames(metricNamesTemp);

  }, [metrics])




  function formatMetricNameInCanvas (item) {
    if (item.length === 1 && !Array.isArray(item)) {
      if (item === '+') return <AnalyticsPlusIcon />;
      if (item === '-') return <AnalyticsSubtractIcon />;
      if (item === '*') return <AnalyticsMultiplyIcon />;
      if (item === '/') return <AnalyticsDivideIcon />;
      if (item === '(') return <AnalyticsParenthesisOpenIcon />;
      if (item === ')') return <AnalyticsParenthesisCloseIcon />;
    } else {
      let displayName = metricNames[item] || item;
      if (displayName.includes('Facebook')) {
        displayName = displayName.replace('Facebook', '');
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={Meta_logo} alt='Facebook Logo' style={{ width: 20, height: 20, paddingRight: 10 }} />
            <div>{displayName}</div>
          </div>
        )
      } else if (displayName.includes('Google')) {
        displayName = displayName.replace('Google', '');
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={google_ads_logo} alt='Google Logo' style={{ width: 20, height: 20, paddingRight: 10 }} />
            <div>{displayName}</div>
          </div>
        )
      } else if (displayName.includes('Shopify')) {
        displayName = displayName.replace('Shopify', '');
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={shopify_logo} alt='Shopify Logo' style={{ width: 20, height: 20, paddingRight: 10 }} />
            <div>{displayName}</div>
          </div>
        )
      } else if (displayName.includes('Omni')) {
        displayName = displayName.replace('Omni', '');
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={omni_logo_clear_background} alt='Omni Logo' style={{ width: 20, height: 20, paddingRight: 10 }} />
            <div>{displayName}</div>
          </div>
        )
      } else if (displayName.includes('TikTok')) {
        displayName = displayName.replace('TikTok', '');
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={tiktok_logo} alt='TikTok Logo' style={{ width: 20, height: 20, paddingRight: 10 }} />
            <div>{displayName}</div>
          </div>
        )
      } else if (displayName.includes('Klaviyo')) {
        displayName = displayName.replace('Klaviyo', '');
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={klaviyo_logo} alt='Klaviyo Logo' style={{ width: 20, height: 20, paddingRight: 10 }} />
            <div>{displayName}</div>
          </div>
        )
      }
    }
  }





  function changeChartName (text) {
    setSelectedMetric((prev) => {
      const updatedMetric = { ...prev };
      updatedMetric.name = text;
      return updatedMetric;
    });
  }

  function changeMetricName (text) {
    setSelectedMetric((prev) => {
      const updatedMetric = { ...prev };
      updatedMetric.lines[lineIndexState ].name = text;
      return updatedMetric;
    });
  }

  function changeMetricColor (color) {
    setSelectedMetric((prev) => {
      const updatedMetric = { ...prev };
      updatedMetric.lines[0].color = color;
      return updatedMetric;
    });
  }

  function changeChartType (type) {
    setSelectedMetric((prev) => {
      const updatedMetric = { ...prev };
      updatedMetric.chartType = type;
      return updatedMetric;
    });
  }

  function changeHorizontalBars(value) {
    // Doesnt look like this would be necessary but fixes an apex charts bug with horizontal bars
    setSelectedMetric((prev) => {
      const updatedMetric = { ...prev };
      updatedMetric.horizontalBars = false;
      return updatedMetric;
    });

    setTimeout(() => {
      setSelectedMetric((prev) => {
        const updatedMetric = { ...prev };
        updatedMetric.horizontalBars = value;
        return updatedMetric;
      });
    }, 500); // 500ms delay
  }
  


  // console.log(selectedMetric)


  useEffect(() => {
    if (selectedMetric.lines.length > 1 && selectedMetric.showCompareData) {
      // set show compare data to false 
      setSelectedMetric((prev) => {
        const updatedMetric = { ...prev };
        updatedMetric.showCompareData = false;
        return updatedMetric;
      });
    }
  }, [selectedMetric])



  return (
    <div className='BuildMetricContainer'>
      <div className='BuildMetricContainerLeft'>
        <div className='BuildMetricContainerLeftChartPreviewCard'>

          {selectedMetric.lines[0].dataVariables && selectedMetric.chartType === 'line' && (
            <ChartComponent
              chartName={selectedMetric.name}
              multiLineData={selectedMetric.lines.map((line) => processFormula(data.arrays, line.formula || []) ) }
              multiLineDataNames={selectedMetric.lines.map((line) => line.name)}
              line1Data={data.arrays[selectedMetric.lines[0].dataVariables[0].key] || []}
              line2Data={compareData.arrays[selectedMetric.key]}
              categories={data.arrays['dates']}
              yAxisIsNumber={true}
              xAxisIsDate={showDaily}
              xAxisIsTime={showHourly}
              chartHeight={'80%'}
              total={data.totals[selectedMetric.key]}
              compareTotal={compareData.totals[selectedMetric.key]}
              line1Name={line1Name}
              line2Name={line2Name}
              titleType={selectedMetric.type}
              chartType='line'
              showLegend={selectedMetric.showLegend}
              showAxisLabels={selectedMetric.showAxisLabels}
              showGridLines={selectedMetric.showGridLines}
              showCompareData={selectedMetric.showCompareData}
              colors={selectedMetric.lines.map((line) => line.color)}
              darkMode={darkMode}
            />
          )}

          {selectedMetric.lines[0].dataVariables && selectedMetric.chartType === 'area' && (
            <ChartComponent
              chartName={selectedMetric.name}
              multiLineData={selectedMetric.lines.map((line) => processFormula(data.arrays, line.formula || []) ) }
              multiLineDataNames={selectedMetric.lines.map((line) => line.name)}
              line1Data={data.arrays[selectedMetric.lines[0].dataVariables[0].key] || []}
              line2Data={compareData.arrays[selectedMetric.key]}
              categories={data.arrays['dates']}
              yAxisIsNumber={true}
              xAxisIsDate={showDaily}
              xAxisIsTime={showHourly}
              chartHeight={'80%'}
              total={data.totals[selectedMetric.key]}
              compareTotal={compareData.totals[selectedMetric.key]}
              line1Name={line1Name}
              line2Name={line2Name}
              titleType={selectedMetric.type}
              chartType='area'
              showLegend={selectedMetric.showLegend}
              showAxisLabels={selectedMetric.showAxisLabels}
              showGridLines={selectedMetric.showGridLines}
              showCompareData={selectedMetric.showCompareData}
              colors={selectedMetric.lines.map((line) => line.color)}
              darkMode={darkMode}
            />
          )}

          {selectedMetric.lines[0].dataVariables && selectedMetric.chartType === 'bar' && (
            <ChartComponent
              chartName={selectedMetric.name}
              multiLineData={selectedMetric.lines.map((line) => processFormula(data.arrays, line.formula || []) ) }
              multiLineDataNames={selectedMetric.lines.map((line) => line.name)}
              line1Data={data.arrays[selectedMetric.lines[0].dataVariables[0].key] || []}
              line2Data={compareData.arrays[selectedMetric.key]}
              categories={data.arrays['dates']}
              yAxisIsNumber={true}
              xAxisIsDate={showDaily}
              xAxisIsTime={showHourly}
              chartHeight={'80%'}
              total={data.totals[selectedMetric.key]}
              compareTotal={compareData.totals[selectedMetric.key]}
              line1Name={line1Name}
              line2Name={line2Name}
              titleType={selectedMetric.type}
              chartType='bar'
              showLegend={selectedMetric.showLegend}
              showAxisLabels={selectedMetric.showAxisLabels}
              showGridLines={selectedMetric.showGridLines}
              showCompareData={selectedMetric.showCompareData}
              horizontalBars={selectedMetric.horizontalBars}
              colors={selectedMetric.lines.map((line) => line.color)}
              darkMode={darkMode}
            />
          )}
        </div>
      </div>


      <div className='BuildMetricContainerRight'>
        <div className='BuildMetricContainerRightHeaderRow'>
          <span className='BuildMetricContainerRightHeaderTitle'>Metric Builder</span>
          <BuildMetricDisplayOptions showDisplayOptionsMenu={showDisplayOptionsMenu} setShowDisplayOptionsMenu={setShowDisplayOptionsMenu} selectedMetric={selectedMetric} setSelectedMetric={setSelectedMetric} />
        </div>

        <div className='BuildMetricContainerRightContentContainer'>
          <span className='BuildMetricContainerRightSectionLabel'>Metric Name</span>
          <TextInput placeholder='Enter Metric Name' type='text' value={selectedMetric.name} onChange={changeChartName} widthPercent={100} marginBottom={10}/>



          <span className='BuildMetricContainerRightSectionLabel'>Metric Type</span>
          <div className='BuildMetricContainerRightMetricTypeGrid'>

            {/* LINE */}
            <div className='BuildMetricContainerRightMetricTypeCard' onClick={() => changeChartType('line')} style={{backgroundColor: selectedMetric.chartType === 'line' && '#FD6488'}}>
              <AnalyticsLineChartIcon selectedChartType={selectedMetric.chartType} />
            </div>

            {/* HORIZONTAL BAR */}
            <div className='BuildMetricContainerRightMetricTypeCard' onClick={() => {changeChartType('bar'); changeHorizontalBars(true); }} style={{backgroundColor: (selectedMetric.chartType === 'bar' && selectedMetric.horizontalBars == true) && '#50B7FF'}}>
              <AnalyticsHorizontalBarChartIcon selectedChartType={selectedMetric.chartType} horizontalBars={selectedMetric.horizontalBars} />
            </div>

            {/* PIE */}
            <div className='BuildMetricContainerRightMetricTypeCard' onClick={() => changeChartType('pie')} style={{backgroundColor: selectedMetric.chartType === 'pie' && '#4749FF'}}>
              <AnalyticsPieChartIcon selectedChartType={selectedMetric.chartType} />
            </div>
            
            {/* VERITCAL BAR */}
            <div className='BuildMetricContainerRightMetricTypeCard' onClick={() => {changeChartType('bar'); changeHorizontalBars(false); }} style={{backgroundColor: (selectedMetric.chartType == 'bar' && selectedMetric.horizontalBars == false) && '#A967D5'}}>
             <AnalyticsVerticalBarChartIcon selectedChartType={selectedMetric.chartType} horizontalBars={selectedMetric.horizontalBars} />
            </div>

            {/* AREA LINE */}
            <div className='BuildMetricContainerRightMetricTypeCard' onClick={() => changeChartType('area')} style={{backgroundColor: selectedMetric.chartType === 'area' && '#FF6B3F'}}>
             <AnalyticsAreaChartIcon selectedChartType={selectedMetric.chartType} />
            </div>

            {/* TABLE */}
            <div className='BuildMetricContainerRightMetricTypeCard' onClick={() => changeChartType('table')} style={{backgroundColor: selectedMetric.chartType === 'table' && '#FD60B9'}}>
             <AnalyticsTableChartIcon selectedChartType={selectedMetric.chartType} />
            </div>

          </div>












          <span className='BuildMetricContainerRightSectionLabel'>Lines</span>

          {selectedMetric.lines.map((line, lineIndex) => (
            <div className={lineIndexState == lineIndex ? 'BuildMetricContainerRightMetricsSection Expanded' : 'BuildMetricContainerRightMetricsSection Collapsed'} onClick={() => setLineIndexState(lineIndex == lineIndexState ? -1 : lineIndex)}>

              <div className='BuildMetricContainerRightMetricsSectionHeaderRow'>
                <div className='BuildMetricContainerRightMetricsSectionHeaderRowLeft'>
                  <div className='BuildMetricContainerRightMetricsColorPickerContainerMain'> 
                    <div className='BuildMetricContainerRightMetricsColorPickerContainer'> 
                      <ColorPicker color={selectedMetric.lines[lineIndex].color} setColor={changeMetricColor}  /> 
                    </div>
                  </div>
                  <div style={{paddingLeft: 40}}>
                    <TextInput placeholder='Line Name' type='text' value={selectedMetric.lines[lineIndex].name} onChange={changeMetricName} widthPercent={100} marginBottom={0}/>
                  </div>
                </div>
                <div 
                  className='BuildMetricContainerRightMetricsExpandArrowContainer' 
                  onClick={() => {
                    setSelectedMetric((prev) => ({
                      ...prev,
                      lines: prev.lines.filter((_, index) => index !== lineIndex),
                    }));
                  }}
                > 
                  <DeleteForeverRounded style={{height: 20, width: 20}} />
                </div>
                <div className='BuildMetricContainerRightMetricsExpandArrowContainer' style={{transform: lineIndexState == lineIndex && 'rotate(180deg)'}}> 
                  <ExpandMoreRounded style={{height: 20, width: 20}}/> 
                </div>
              </div>

              
                <span className='BuildMetricContainerRightSectionLabel'>Sources</span>

                {selectedMetric.lines[lineIndex].dataVariables.map((x, index) => (
                  <BuildMetricDropdown showSourcesDropdown={showSourcesDropdown} setShowSourcesDropdown={setShowSourcesDropdown} metrics={metrics} selectedMetric={selectedMetric} setSelectedMetric={setSelectedMetric} dataVariableIndex={index} metricNames={metricNames} lineIndex={lineIndex} />
                ))}

                {selectedMetric && (
                  <div 
                    className='BuildMetricContainerRightMetricsSectionAddSourceButton' 
                    onClick={() => {
                      //add another data variables to the current line in the selected metric
                      setSelectedMetric(prevState => ({
                        ...prevState, // Copy the entire selectedMetric object
                        lines: prevState.lines.map((line, index) => 
                          index === lineIndex // UDPATE FOR lineIndex
                            ? {
                                ...line, // Copy the specific line object
                                dataVariables: [...line.dataVariables, 'fb_spend'], // Add the new dataVariable
                                formula: [...line.formula, '+', 'fb_spend'] // Add the new dataVariable to the formula
                              }
                            : line // Keep other lines unchanged
                        ),
                      }));

                      //setShowSourcesDropdown(true)
                    }}>
                    <AddRounded style={{height: 20, width: 20, paddingRight: 10, paddingLeft: 10}}/>
                    <div className='BuildMetricContainerRightMetricsSectionAddSourceButtonText'>Add Source</div>
                  </div>
                )}


                
                
                
                <DragDropContext onDragEnd={onDragEnd}>
                  <span className="BuildMetricContainerRightSectionLabel">Operations</span>

                  {/* Operations Row */}
                  <Droppable droppableId="operations" direction="horizontal" isDropDisabled={true}>
                    {(provided) => (
                      <div className="BuildMetricContainerRightMetricsSectionOperationRow" ref={provided.innerRef} {...provided.droppableProps} >
                        {/* ADD BUTTON */}
                        <Draggable draggableId="+" index={0}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="BuildMetricContainerRightMetricsSectionOperationButton" >
                              <AnalyticsPlusIcon />
                            </div>
                          )}
                        </Draggable>

                        {/* SUBTRACT BUTTON */}
                        <Draggable draggableId="-" index={1}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="BuildMetricContainerRightMetricsSectionOperationButton" >
                              <AnalyticsSubtractIcon />
                            </div>
                          )}
                        </Draggable>

                        {/* MULTIPLY BUTTON */}
                        <Draggable draggableId="*" index={2}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="BuildMetricContainerRightMetricsSectionOperationButton" >
                              <AnalyticsMultiplyIcon />
                            </div>
                          )}
                        </Draggable>

                        {/* DIVIDE BUTTON */}
                        <Draggable draggableId="/" index={3}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="BuildMetricContainerRightMetricsSectionOperationButton" >
                              <AnalyticsDivideIcon />
                            </div>
                          )}
                        </Draggable>

                        {/* Add parenthesis buttons in similar draggable wrappers */}
                        {/* PARENTHESIS OPEN BUTTON */}
                        <Draggable draggableId="(" index={4}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="BuildMetricContainerRightMetricsSectionOperationButton" >
                              <AnalyticsParenthesisOpenIcon />
                            </div>
                          )}
                        </Draggable>

                        {/* PARENTHESIS CLOSE BUTTON */}
                        <Draggable draggableId=")" index={5}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="BuildMetricContainerRightMetricsSectionOperationButton" >
                              <AnalyticsParenthesisCloseIcon />
                            </div>
                          )}
                        </Draggable>


                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>

                  {/* Formula Canvas */}
                  <Droppable droppableId="operationCanvas" direction="horizontal">
                    {(provided) => (
                      <div className={`BuildMetricContainerRightMetricsSectionOperationCanvas ${!isFormulaValid ? 'invalid' : ''}`} ref={provided.innerRef} {...provided.droppableProps} >
                        <div className='BuildMetricContainerRightMetricsSectionOperationCanvasValidityIndicator'>
                          {isFormulaValid ? 'No Errors' : "Error"}
                        </div>
                        {selectedMetric?.lines[lineIndex]?.formula?.map((item, index) => (
                          <Draggable key={`${item}-${index}`} draggableId={`canvas-${item}-${index}`} index={index} >
                            {(provided) => (
                              <div 
                                ref={provided.innerRef} 
                                {...provided.draggableProps} 
                                {...provided.dragHandleProps} 
                                className="BuildMetricContainerRightMetricsSectionOperationCanvasItem" 
                              >
                                
                                {formatMetricNameInCanvas(item)}
                                {/* Remove Button */}
                                <div 
                                  className="BuildMetricContainerRightMetricsSectionOperationCanvasItemRemoveContainer"
                                  onClick={(e) => { 
                                    e.stopPropagation(); // Prevent triggering drag
                                    removeSource(index); 
                                  }}
                                  title="Remove this item"
                                  role="button"
                                  aria-label="Remove item"
                                  tabIndex={0}
                                  onKeyPress={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                      removeSource(index);
                                    }
                                  }}
                                >
                                  <CloseRounded className="BuildMetricContainerRightMetricsSectionOperationCanvasItemRemove" />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>

                </DragDropContext>


            </div>

          ))}




          {selectedMetric && (
            <div 
              className='BuildMetricContainerRightMetricsSectionAddSourceButton' 
              onClick={() => {
                //add a new line to selectedMetric
                setSelectedMetric(prevState => ({
                  ...prevState, // Copy the entire selectedMetric object
                  lines: [...prevState.lines, {
                    name: "New Line",
                    color: "#FD60B9",
                    dataVariables: ['fb_impressions'],
                    formula: ['fb_impressions'],
                  }]
                }));
              }}
            >
              <AddRounded style={{height: 20, width: 20, paddingRight: 10, paddingLeft: 10}}/>
              <div className='BuildMetricContainerRightMetricsSectionAddSourceButtonText'>Add Line</div>
            </div>
          )}




        </div>


        <div className='BuildMetricContainerRightBottomButtonRow'>
          <div className='BuildMetricContainerRightBottomCancelButton' onClick={() => {setShowCustomMetricPopup(false)}}>Cancel</div>
          <div className='BuildMetricContainerRightBottomSaveButton'>Save</div>
        </div>
        
      </div>
    </div>
  );
}


export default BuildMetric;