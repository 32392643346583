import react, { useState, useEffect } from 'react';
import axios from 'axios';
import './ProfitByDay.css'
import dayjs from 'dayjs';
import { TrendingUpRounded } from '@mui/icons-material';

function ProfitByDay({widthPercent, darkMode, serverURL}) {
    const uid = localStorage.getItem('uid');
    const bid = localStorage.getItem('bid');


    const getStartOfDay = (date) => {
        const startOfDay = new Date(date);
        startOfDay.setHours(0, 0, 0, 0); // Set time to 00:00:00.000
        return startOfDay;
    };
    
    const getDateDaysAgo = (days) => {
        const date = new Date();
        date.setDate(date.getDate() - days); // Subtract the specified number of days
        return date;
    };

    const [dateStart, setDateStart] = useState(getStartOfDay(getDateDaysAgo(17)));
    const [dateEnd, setDateEnd] = useState(getStartOfDay(getDateDaysAgo(0)));
    const [allData, setAllData] = useState([])
    const [maxProfitPerDay, setMaxProfitPerDay] = useState(0)






    async function getDailyStats () {
        try {
  
            const API_URL = `${serverURL}/getDailyStats?omni_business_id=${bid}&dateStart=${dateStart.toISOString(0,10).slice(0,10)}&dateEnd=${dateEnd.toISOString(0,10).slice(0,10)}` ;
            console.log('API_URL', API_URL)
            const response = await axios.get(API_URL)
            const API_RESPONSE = response.data[0]
            setAllData(response.data)
    
            const data = response.data;
            const dates = data.map(entry => (new Date(entry.date).toISOString().slice(0, 10)));
            const dailyOrders = data.map(entry => (entry.orders * 1));
            const dailyRevenue = data.map(entry => (entry.revenue * 1));
            const dailyFacebookSpend = data.map(entry => (entry.facebook_spend * 1));
            const dailyNetProfit = data.map(entry => (entry.net_profit * 1));

            const maxProfit = Math.max(...dailyNetProfit)
            setMaxProfitPerDay(maxProfit)
            
            
  
        } catch (error) {
            console.log('ERROR loading stats profitByDay.js', error)
        }
    }
  
    useEffect(() => {
      if (uid !== '') {
        getDailyStats();
      }
    }, [uid]);


    return ( 
      
        <div className={'ProfitByDayCardContainer ' + (darkMode && ' darkModeCard')} style={{width: widthPercent + '%'}}>
           <span className='ProfitByDayTitle'>Net Profit</span>
           <span className='ProfitByDayDescription'>Here's how you're doing over the last 14 days</span>
           
           <div className='ProfitByDayContentContainer'>
                <table className={darkMode ? 'ProfitByDayTableDarkMode' : 'ProfitByDayTable'}>
                    <tbody>
                        <tr>
                            <td>Date</td>
                            {/* <td>Revenue</td>
                            <td>Spend</td> */}
                            <td>ROAS</td>
                            <td>Net Profit</td>
                            <td>Trend</td>
                        </tr>
                        {allData.map((x, index) => { return (
                            <tr key={index}>
                                <td>{dayjs(x.date).format('ddd, MMM D')}</td>
                                {/* <td>{x.revenue}</td>
                                <td>{x.facebook_spend}</td> */}
                                <td>{(x.overall_roas)?.toFixed(1)}</td>
                                <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.net_profit)}</td>
                                <td>
                                    <div className='ProfitByDayTrendParent'>
                                        <div className='ProfitByDayTrendChild' style={{width: (x.net_profit / maxProfitPerDay) * 100}}>

                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )})}
                    </tbody>
                </table>
            </div>
        </div>
        
    );
}

export default ProfitByDay;