import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import axios from 'axios';

const stripePromise = loadStripe('pk_test_51QdCFFP5GwB60y7wYwva5rXhRcz2X3P0dIDx4jC1C6dsFzz4gT4DcgSZNogPJxUwO6Pu9zp9SB7X4dMW7N7IV68J00i9pvh5xJ'); // Replace with your Stripe Publishable Key

const CheckoutForm = () => {
  const bid = localStorage.getItem('bid');
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    try {
      // Step 1: Create Payment Method
      const cardElement = elements.getElement(CardElement);
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        console.error(error);
        setMessage(error.message);
        setLoading(false);
        return;
      }

      // Step 2: Call Backend to Create Subscription
      const response = await axios.post('https://api.omnimedia.app/createSubscription', {
        omni_business_id: bid, // Replace with your business ID
        tier: 'basic', // Replace with the chosen tier
        paymentMethodId: paymentMethod.id,
      });

      const { clientSecret } = response.data;

      // Step 3: Confirm the Payment Intent
      const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(clientSecret);

      if (confirmError) {
        console.error(confirmError);
        setMessage(confirmError.message);
        setLoading(false);
        return;
      }

      // Payment Successful
      setMessage('Subscription successful!');
    } catch (apiError) {
      console.error('API error:', apiError);
      setMessage('Error creating subscription. Please try again.');
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit" disabled={!stripe || loading}>
        {loading ? 'Processing...' : 'Subscribe'}
      </button>
      {message && <p>{message}</p>}
    </form>
  );
};

const StripeBillingTest = () => (
  <Elements stripe={stripePromise}>
    <CheckoutForm />
  </Elements>
);

export default StripeBillingTest;
