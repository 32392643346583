import React, { useState, useRef, useEffect } from 'react';
import Switch from "react-switch";
import './CampaignGrid.css';
import { CheckRounded, KeyboardArrowDownRounded, KeyboardArrowUpRounded, SolarPower, ViewColumn, ViewColumnRounded, ViewWeek } from '@mui/icons-material';
import { dummyTesting } from '../../AdsManager'
import EditIcon from '../../../../assets/Icons/EditIcon';
import TextInput from '../../../../components/TextInput';
import axios from 'axios';
import { CircularProgress, ClickAwayListener } from '@mui/material';
import { FaMeta, FaFloppyDisk } from "react-icons/fa6";
import { db } from '../../../../FirebaseInit';
import { doc, getDoc } from 'firebase/firestore';
import firstClick from '../../../../assets/attributionModels/firstClick.png';
import lastClick from '../../../../assets/attributionModels/lastClick.png';
import linearAll from '../../../../assets/attributionModels/linearAll.png';
import linearPaid from '../../../../assets/attributionModels/linearPaid.png';
import uShaped from '../../../../assets/attributionModels/uShaped.png';
import dayjs from 'dayjs';
import Meta_logo from '../../../../assets/Meta_logo.png';
import klaviyo_logo from '../../../../assets/klaviyo_logo.png';
import google_ads_logo from '../../../../assets/google_ads_logo.png';
import tiktok_logo_white_background from '../../../../assets/tiktok_logo_white_background.png';




function formatTimeElapsed(seconds) {
  // format time like hh:mm:ss with each always being 2 digits and no decimals for seconds
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const formattedSeconds = Math.floor(seconds % 60);
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${formattedSeconds.toString().padStart(2, '0')}`;
}



function HeaderComponent ({campaignColumns, sortColumn, sortOrder, handleSort, startResize, availableColumns, columnIndexes, source, isScrolled}) {
  return (
    <thead>
      <tr>
        {campaignColumns.map((col, index) => {
          const columnIndex = columnIndexes[col.key] 
          if (!columnIndex) return null;

          const isSorted = sortColumn === col.variableName;
          const sortIcon = isSorted ? (
            sortOrder === 'asc' ? (
              <KeyboardArrowUpRounded fontSize='small' style={{ color: '#888' }} />
            ) : (
              <KeyboardArrowDownRounded fontSize='small' style={{ color: '#888' }} />
            )
          ) : null;


          const tpLogo = () => {
            if (source == 'fb') {
              return <img src={Meta_logo} style={{width: 25, borderRadius: 5}}/> 
            } else if (source == 'google') {
              return <img src={google_ads_logo} style={{width: 25, borderRadius: 5}}/>
            } else if (source == 'tiktok') {
              return <img src={tiktok_logo_white_background} style={{width: 25, borderRadius: 5}}/>
            } else if (source == 'klaviyo') {
              return <img src={klaviyo_logo} style={{width: 25, borderRadius: 5}}/>
            }
          }

          return (
            <th
              key={col.key}
              style={{ width: col.width }}
              onClick={() => col.sortable && handleSort(col.variableName)}
            >
              <div 
                className={`CampaignGridTableHeaderContainer ${(col.key == 'name_meta' || col.key == 'tp_status') && 'CampaignGridTableSticky'}`}
                style={{boxShadow: isScrolled && index == 1 ? '0px 0px 7px #00000050' : 'none', borderRight: '1px solid #e7e7e7', boxSizing: 'border-box', width: 'calc(100% + 1px)'}}>
                {/* {col.key?.includes('omni') && <FaFloppyDisk className='CampaignGridHeaderOmniIcon'/>} */}
                {col.key?.includes('tp') ? tpLogo() : <FaFloppyDisk className='CampaignGridHeaderOmniIcon'/>}
                <div style={{paddingLeft: 10}}>{col.title}</div>
                <div className='CampaignGridTableHeaderSortIcon'>{sortIcon}</div>
                <div
                  className="resizer"
                  onMouseDown={(e) => {
                    e.stopPropagation(); // Prevents the event from bubbling up to the <th>
                    startResize(index)(e);
                  }}
                  onClick={(e) => e.stopPropagation()} // Prevents click event propagation
                ></div>
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
}






function RowComponent ({ rowIndex, campaign, campaignColumns, columnWidths, selectedCampaigns, adjustSelectedCampaigns, setShowDrawer, setSelectedCampaignForPurchasesPopup, showBudgetInput, setShowBudgetInput, 
  newBudgetValue, setNewBudgetValue, updateBudgetLoading, updateCampaignBudget, updateCampaignStatus, setShowPurchasesPopup, availableColumns, columnIndexes, isScrolled }) {
  return (
    <tr key={rowIndex}>
      {campaignColumns.map((col, index) => {
        const columnIndex = columnIndexes[col.key] 
        if (!columnIndex) return null;


        const style = { width: col.width };

        // If a custom cell renderer is provided, use it
        if (col.renderCell) {
          return col.renderCell({campaign, columnWidths, selectedCampaigns, adjustSelectedCampaigns, setShowDrawer, setSelectedCampaignForPurchasesPopup, showBudgetInput, setShowBudgetInput, 
                                newBudgetValue, setNewBudgetValue, updateBudgetLoading, updateCampaignBudget, updateCampaignStatus, setShowPurchasesPopup, index, isScrolled})
        }

        // Default cell rendering
        const cellData = campaign[col.dataKey];

        return (
          <td key={col.key} style={style}>
            {cellData}
          </td>
        );
      })}
    </tr>
  );
}






function FooterComponent({ campaignColumns, sortedCampaigns, availableColumns, columnIndexes, isScrolled }) {
  return (
    <tr className='sticky-footer-row'>
      {campaignColumns.map((col, index) => {
        const columnIndex = columnIndexes[col.key] 
        if (!columnIndex) return null;

        let footerValue = '';
        

        if (col.footerOperation == 'sum') { 
          footerValue = sortedCampaigns.reduce((accumulator, x) => { return accumulator + Number(x[col.variableName] || 0) }, 0)
        } else if (col.footerOperation == 'divide') {
          const numerator = sortedCampaigns.reduce((accumulator, x) => { return accumulator + Number(x[col.footerNumerator] || 0) }, 0)
          const denominator = sortedCampaigns.reduce((accumulator, x) => { return accumulator + Number(x[col.footerDenominator] || 0) }, 0)
          footerValue = numerator / denominator
        } else if (col.footerOperation == false) {
          footerValue = col.footer;
        }

        if (col.multiplyBy) {
          footerValue = footerValue * col.multiplyBy;
        }

        if (col.footerDataType == 'currency') {
          footerValue = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(footerValue);
        } else if (col.footerDataType == 'percent') {
          footerValue = new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(footerValue);
        } else if (col.footerDataType == 'number') {
          footerValue = new Intl.NumberFormat('en-US').format(footerValue);
        } else if (col.footerDataType == 'time') {
          footerValue = formatTimeElapsed(footerValue);
        } else { 
          footerValue = footerValue;
        }


        return (
          <td key={col.key} style={{ width: col.width }}>
            <div className='CampaignGridTableSticky' style={{ paddingLeft: 10, boxShadow: isScrolled && index == 1 ? '0px 0px 7px #00000050' : 'none'  }}>
              {footerValue}
            </div>
          </td>
        );
      })}
    </tr>
  );
}









function CampaignGrid({ selectedCampaigns, adjustSelectedCampaigns, campaigns, setShowDrawer, setSelectedCampaignForPurchasesPopup, selectedColumns, filterStatus, serverURL, pythonServerURL, omni_business_id, 
  refreshDataTrigger, setRefreshDataTrigger, availableColumns, columnIndexes, setShowAttributionsWindowDropdown, showAttributionsWindowDropdown, setAttributionWindow, attributionWindow,
  setShowSelectColumnsPopup, showSelectColumnsPopup, setAttributionDropdown, showAttributionDropdown, attributionModel, setAttributionModel, setShowStatusDropdown, 
  showStatusDropdown, setFilterStatus, setShowPurchasesPopup, campaignColumns, setCampaignColumns, setSelectedAdLevel, source, setSnackbarInfo}) {

  const bid = localStorage.getItem('bid')


  const [switchStates, setSwitchStates] = useState({});
  const [filteredCampaigns, setFiltedCampaigns] = useState(campaigns);
  const [sortedCampaigns, setSortedCampaigns] = useState(campaigns);
  const [sortColumn, setSortColumn] = useState('status');
  const [sortOrder, setSortOrder] = useState('asc');


  const [showBudgetInput, setShowBudgetInput] = useState('');
  const [newBudgetValue, setNewBudgetValue] = useState('');
  const [updateBudgetLoading, setUpdateBudgetLoading] = useState(false);
  const [updateStatusLoading, setUpdateStatusLoading] = useState(false);
  const [updateStatusCampaignID, setUpdateStatusCampaignID] = useState('');

  const [isScrolled, setIsScrolled] = useState(false);
  const tableContainerRef = useRef(null);



  useEffect(() => {
    const columnData = [
      { key: 'tp_status', variableName: 'tp_status', icon: 'none', title: 'Status', description: 'On/Off', sortable: true, width: 100, fixed: true, footerOperation: false, footer: '',
        renderCell: ({campaign, adjustSelectedCampaigns, selectedCampaigns, setNewBudgetValue, showBudgetInput, newBudgetValue, updateBudgetLoading, updateCampaignBudget, setShowBudgetInput, updateCampaignStatus, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <div className='CampaignGridTableSticky' >
              <div className='CampaignGridSelectedAdCheckboxColumnRow'>
                {selectedCampaigns.includes(campaign?.tp_id) ? (
                  <div className='CampaignGridSelectedAdCheckboxContainer' onClick={() => adjustSelectedCampaigns(campaign?.tp_id)}>
                    <div className='CampaignGridSelectedAdCheckboxChecked'>
                      <CheckRounded style={{ width: 10 }} />
                    </div>
                  </div>
                ) : (
                  <div className='CampaignGridSelectedAdCheckboxContainer' onClick={() => adjustSelectedCampaigns(campaign?.tp_id)}>
                    <div className='CampaignGridSelectedAdCheckbox'>
                      <CheckRounded style={{ width: 10 }} />
                    </div>
                  </div>
                )}
                <div style={{ marginTop: 5 }} onClick={() => updateCampaignStatus(campaign?.tp_id, campaign?.tp_status === 'ACTIVE' || campaign?.tp_status === 'ENABLE' || campaign?.tp_status === 'ENABLED' ? 'PAUSED' : 'ACTIVE', bid, campaign?.account_id)}>
                  <Switch
                    onChange={() => {}}
                    checked={campaign?.tp_status === 'ACTIVE' || campaign?.tp_status === 'ENABLE' || campaign?.tp_status === 'ENABLED'}
                    offColor="#d9d9d9"
                    onColor="#635bff"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    width={35}
                    height={20}
                    handleDiameter={15}
                  />
                </div>
              </div>
            </div>
          </td>
        ),
      
      },
      { key: 'tp_name', variableName: 'tp_name', icon: 'none', title: 'Campaign', description: 'Campaign Name', sortable: true, width: 300, fixed: true, footerOperation: false, footer: `${campaigns.length} Campaigns`,
        renderCell: ({campaign, index, isScrolled}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <div className='CampaignGridTableSticky' style={{ paddingLeft: 10, boxShadow: isScrolled ? '0px 0px 7px #00000050' : 'none' }} onClick={() => { adjustSelectedCampaigns(campaign?.tp_id); setSelectedAdLevel(2); }}>
              <span style={{maxWidth: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{campaign.tp_name}</span>
            </div>
          </td>
        ),
      },
      { key: 'tp_daily_budget', variableName: 'tp_daily_budget', icon: 'none', title: 'Daily Budget', description: 'Daily Budget', sortable: true, width: 150, fixed: false, footerOperation: 'sum', footerDataType: 'currency',  
        renderCell: ({campaign, adjustSelectedCampaigns, setNewBudgetValue, showBudgetInput, newBudgetValue, updateBudgetLoading, updateCampaignBudget, setShowBudgetInput, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <div className='CampaignGridBudgetRow'>
              {showBudgetInput === campaign?.tp_id ? (
                <ClickAwayListener onClickAway={() => setShowBudgetInput('')}>
                  <div className='CampaignGridBudgetRowInputContainer'>
                    <span className='CampaignGridBudgetRowInputDollarSign'>$</span>
                    <input
                      className='CampaignGridBudgetRowInput'
                      type="number"
                      value={newBudgetValue}
                      onChange={(e) => setNewBudgetValue(e.target.value)}
                    />
                  </div>
                </ClickAwayListener>
              ) : (
                <>
                  {campaign?.tp_daily_budget === '' ? (
                    <span className='CampaignGridBudgetRowAdsetBudget'>Adset budget</span>
                  ) : (
                    <>
                      {
                        (typeof campaign.tp_daily_budget == "object" && campaign?.tp_daily_budget?.length > 1) ? (
                          <span className='CampaignGridBudgetRowAdsetBudget'>Multiple budgets</span>
                        )
                      : (
                          <span className='CampaignGridBudgetRowValue'>
                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(campaign.tp_daily_budget)}
                          </span>
                        )
                      }
                    </>
                  )}
                </>
              )}
              {showBudgetInput === campaign?.tp_id ? (
                updateBudgetLoading ? (
                  <div className='CampaignGridBudgetRowEditIcon'>
                    <CircularProgress style={{ height: 17, width: 17, color: '#888' }} />
                  </div>
                ) : (
                  <div className='CampaignGridBudgetRowEditIcon' onClick={() => {console.log(campaign); updateCampaignBudget(campaign?.tp_id, newBudgetValue, bid, campaign?.account_id, campaign?.tp_budget_ids) }}>
                    <CheckRounded style={{ color: '#888', width: 14, height: 14 }} />
                  </div>
                )
              ) : (
                campaign.daily_budget !== '' && (
                  <div className='CampaignGridBudgetRowEditIcon' onClick={() => { setShowBudgetInput(campaign?.tp_id); setNewBudgetValue(Number(campaign.tp_daily_budget)); }}>
                    <EditIcon color='#999' />
                  </div>
                )
              )}
            </div>
          </td>
        ),
      },
      { key: 'tp_delivery', variableName: 'tp_status', title: 'Delivery', description: 'Ad status', sortable: true, width: 120, footerOperation: false, 
        renderCell: ({campaign, index}) => {
          const statusColor = campaign.tp_status === 'ACTIVE' ? '#3acf12' : '#e7e7e7';
          return (
            <td key={campaign.key + "_" + index} style={campaign.width}>
              <div className='CampaignGridDeliveryStatusRow'>
                <div className='CampaignGridDeliveryStatusIcon' style={{ backgroundColor: statusColor }}></div>
              </div>
            </td>
          );
        },
      },
      { key: 'omni_bounce_rate', variableName: 'omni_bounce_rate', title: 'Bounce Rate', description: 'Sessions that only viewed 1 page', sortable: true, width: 150, footerOperation: 'divide', footerNumerator: 'omni_bounce_count', footerDenominator: 'omni_sessions', footerDataType: 'percent',
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.omni_bounce_rate != 0 && campaign.omni_bounce_rate != null ? new Intl.NumberFormat('en-US', { style: 'percent' }).format(campaign.omni_bounce_rate) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_outbound_ctr', variableName: 'tp_outbound_ctr', title: 'Outbound CTR', description: 'Link click CTR ', sortable: true, width: 150, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.tp_outbound_ctr != 0 && campaign.tp_outbound_ctr != null ? new Intl.NumberFormat('en-US').format(campaign.tp_outbound_ctr) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_outbound_clicks', variableName: 'tp_outbound_clicks', title: 'Outbound Clicks', description: 'Link click count', sortable: true, width: 160, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.tp_outbound_clicks != 0 && campaign.tp_outbound_clicks != null ? new Intl.NumberFormat('en-US').format(campaign.tp_outbound_clicks) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_aov', variableName: 'tp_aov', title: 'AOV', description: 'Average order value', sortable: true, width: 120, footerOperation: 'divide', footerNumerator: 'tp_revenue', footerDenominator: 'tp_orders', footerDataType: 'currency',
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.tp_aov != 0 && campaign.tp_aov != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(campaign.tp_aov) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_cpa', variableName: 'tp_cpa', title: 'CPA', description: 'Cost per acquisition', sortable: true, width: 120, footerOperation: 'divide', footerNumerator: 'tp_spend', footerDenominator: 'tp_orders', footerDataType: 'currency', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.tp_cpa != 0 && campaign.tp_cpa != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(campaign.tp_cpa) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_cpm', variableName: 'tp_cpm', title: 'CPM', description: 'Cost per thousand impressions', sortable: true, width: 120, footerOperation: 'divide', footerNumerator: 'tp_spend', footerDenominator: 'tp_impressions', multiplyBy: 1000, footerDataType: 'currency',
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.tp_cpm != 0 && campaign.tp_cpm != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(campaign.tp_cpm) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_ctr', variableName: 'tp_ctr', title: 'CTR', description: 'Click-through rate', sortable: true, width: 120, footerOperation: 'divide', footerNumerator: 'tp_clicks', footerDenominator: 'tp_impressions', footerDataType: 'percent', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.tp_ctr != 0 && campaign.tp_ctr != null ? new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(campaign.tp_ctr) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_cpc', variableName: 'tp_cpc', title: 'CPC', description: 'Cost per click', sortable: true, width: 120, footerOperation: 'divide', footerNumerator: 'tp_spend', footerDenominator: 'tp_clicks', footerDataType: 'currency', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.tp_cpc != 0 && campaign.tp_cpc != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(campaign.tp_cpc) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_impressions', variableName: 'tp_impressions', title: 'Impressions', description: 'Number of impressions', sortable: true, width: 150, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.tp_impressions != 0 && campaign.tp_impressions != null ? new Intl.NumberFormat( 'en-US' ).format(campaign.tp_impressions) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_clicks', variableName: 'tp_clicks', title: 'Clicks', description: 'Total clicks', sortable: true, width: 120, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.tp_clicks != 0 && campaign.tp_clicks != null ? new Intl.NumberFormat( 'en-US' ).format(campaign.tp_clicks) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_roas', variableName: 'tp_roas', title: 'ROAS', description: 'Return on ad spend', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'tp_revenue', footerDenominator: 'tp_spend', footerDataType: 'number', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.tp_roas != 0 && campaign.tp_roas != null ? new Intl.NumberFormat('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(campaign.tp_roas) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_cvr', variableName: 'tp_cvr', title: 'CVR', description: 'Conversion rate', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'tp_orders', footerDenominator: 'tp_link_click', footerDataType: 'percent',  
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.tp_cvr != 0 && campaign.tp_cvr != null ? new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(campaign.tp_cvr) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_avg_session_duration', variableName: 'omni_avg_session_duration', title: 'AVG SD', description: 'Average session duration (in seconds)', sortable: true, width: 120, footerOperation: 'divide', footerNumerator: 'omni_total_session_duration', footerDenominator: 'omni_sessions', footerDataType: 'time',
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.omni_avg_session_duration != 0 && campaign.omni_avg_session_duration != null ? formatTimeElapsed(campaign.omni_avg_session_duration) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_avg_page_views_per_session', variableName: 'omni_avg_page_views_per_session', title: 'AVG VPS', description: 'Average pages viewed per session', sortable: true, width: 120, footerOperation: 'divide', footerNumerator: 'omni_page_viewed', footerDenominator: 'omni_sessions', footerDataType: 'number', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.omni_avg_page_views_per_session != 0 && campaign.omni_avg_page_views_per_session != null ? new Intl.NumberFormat( 'en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 } ).format(campaign.omni_avg_page_views_per_session) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_new_visitor_percent', variableName: 'omni_new_visitor_percent', title: 'NV %', description: 'Percentage of visitors who are new', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'omni_new_visitor', footerDenominator: 'omni_unique_sessions', footerDataType: 'percent',
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.omni_new_visitor_percent != 0 && campaign.omni_new_visitor_percent != null ? new Intl.NumberFormat('en-US', { style: 'percent' }).format(campaign.omni_new_visitor_percent) : '-'}</span>
          </td>
        ),
      },
      { key: 'revenue_diff', variableName: 'revenue_diff', title: 'Revenue diff', description: 'Difference in revenue between Omni and Meta', sortable: true, width: 150, footerOperation: 'sum', footerDataType: 'currency', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.revenue_diff != 0 && campaign.revenue_diff != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(campaign.revenue_diff) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_revenue', variableName: 'tp_revenue', title: 'CV', description: 'Conversion Value', sortable: true, width: 130, footerOperation: 'sum', footerDataType: 'currency', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.tp_revenue != 0 && campaign.tp_revenue != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(campaign.tp_revenue) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_profit', variableName: 'omni_profit', title: 'Profit', description: 'Revenue - Ads - COGS', sortable: true, width: 130, footerOperation: 'sum', footerDataType: 'currency', 
        renderCell: ({campaign, index}) => {
          const profit = Number(campaign.omni_profit);
          const backgroundColor = profit > 0 ? '#b5ffcf' : profit === 0 ? '#ffffff00' : '#ffaead';
          return (
            <td key={campaign.key + "_" + index} style={{width: campaign.width, backgroundColor: backgroundColor}}>
              {profit && profit != 0 ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(profit) : '-'}
            </td>
          );
        },
      },
      { key: 'omni_cogs', variableName: 'omni_cogs', title: 'COGS', description: 'Cost of goods sold', sortable: true, width: 120, footerOperation: 'sum', footerDataType: 'currency', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.omni_cogs != 0 && campaign.omni_cogs != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(campaign.omni_cogs) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_new_customer_cvr', variableName: 'omni_new_customer_cvr', title: 'NC CVR', description: 'Conversion rate for new customers', sortable: true, width: 120, footerOperation: 'divide', footerNumerator: 'omni_new_customer_orders', footerDenominator: 'omni_new_visitor', footerDataType: 'percent', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.omni_new_customer_cvr != 0 && campaign.omni_new_customer_cvr != null ? new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(campaign.omni_new_customer_cvr) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_cvr', variableName: 'omni_cvr', title: 'CVR', description: 'Conversion rate', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'omni_orders', footerDenominator: 'omni_unique_sessions', footerDataType: 'percent', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.omni_cvr != 0 && campaign.omni_cvr != null ? new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(campaign.omni_cvr) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_aov', variableName: 'omni_aov', title: 'AOV', description: 'Average order value', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'omni_revenue', footerDenominator: 'omni_orders', footerDataType: 'currency', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.omni_aov != 0 && campaign.omni_aov != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(campaign.omni_aov) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_cpa', variableName: 'omni_cpa', title: 'CPA', description: 'Cost per acquisition', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'tp_spend', footerDenominator: 'tp_orders', footerDataType: 'currency', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.omni_cpa != 0 && campaign.omni_cpa != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(campaign.omni_cpa) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_cost_per_atc', variableName: 'omni_cost_per_atc', title: 'Cost per ATC', description: 'Average cost per add-to-cart', sortable: true, width: 150, footerOperation: 'divide', footerNumerator: 'tp_spend', footerDenominator: 'omni_add_to_cart', footerDataType: 'currency', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.omni_cost_per_atc != 0 && campaign.omni_cost_per_atc != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(campaign.omni_cost_per_atc) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_add_to_cart', variableName: 'omni_add_to_cart', title: 'ATC', description: 'Total number of add-to-cart', sortable: true, width: 110, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.omni_add_to_cart != 0 && campaign.omni_add_to_cart != null ? new Intl.NumberFormat( 'en-US' ).format(campaign.omni_add_to_cart) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_new_visitor_cost', variableName: 'omni_new_visitor_cost', title: 'New visitor cost', description: 'Cost per new visitors', sortable: true, width: 160, footerOperation: 'divide', footerNumerator: 'tp_spend', footerDenominator: 'omni_new_visitor', footerDataType: 'currency', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.omni_new_visitor_cost != 0 && campaign.omni_new_visitor_cost != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(campaign.omni_new_visitor_cost) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_new_visitor', variableName: 'omni_new_visitor', title: 'New visitors', description: 'Total new visitors', sortable: true, width: 150, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.omni_new_visitor != 0 && campaign.omni_new_visitor != null ? new Intl.NumberFormat( 'en-US' ).format(campaign.omni_new_visitor) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_cost_per_visitor', variableName: 'omni_cost_per_visitor', title: 'Cost per visitor', description: 'Average cost to acquire a visitor', sortable: true, width: 150, footerOperation: 'divide', footerNumerator: 'tp_spend', footerDenominator: 'omni_unique_sessions', footerDataType: 'currency', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.omni_cost_per_visitor != 0 && campaign.omni_cost_per_visitor != null? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(campaign.omni_cost_per_visitor) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_unique_sessions', variableName: 'omni_unique_sessions', title: 'Unique sessions', description: 'Count of unique sessions', sortable: true, width: 160, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.omni_unique_sessions != 0 && campaign.omni_unique_sessions != null ? new Intl.NumberFormat( 'en-US' ).format(campaign.omni_unique_sessions) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_sessions', variableName: 'omni_sessions', title: 'Sessions', description: 'Total number of sessions', sortable: true, width: 130, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.omni_sessions != 0 && campaign.omni_sessions != null ? new Intl.NumberFormat( 'en-US' ).format(campaign.omni_sessions) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_new_customer_revenue', variableName: 'omni_new_customer_revenue', title: 'NCV', description: 'New customer revenue', sortable: true, width: 130, footerOperation: 'sum', footerDataType: 'currency', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.omni_new_customer_revenue != 0 && campaign.omni_new_customer_revenue != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(campaign.omni_new_customer_revenue) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_revenue', variableName: 'omni_revenue', title: 'CV', description: 'Conversion Value', sortable: true, width: 130, footerOperation: 'sum', footerDataType: 'currency', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.omni_revenue != 0 && campaign.omni_revenue != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(campaign.omni_revenue) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_roas', variableName: 'omni_roas', title: 'ROAS', description: 'Return on ad spend', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'omni_revenue', footerDenominator: 'tp_spend', footerDataType: 'number', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.omni_roas != 0 && campaign.omni_roas != null ? new Intl.NumberFormat( 'en-US' ).format(campaign.omni_roas) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_new_customer_roas', variableName: 'omni_new_customer_roas', title: 'NC ROAS', description: 'New customer return on ad spend', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'omni_new_customer_revenue', footerDenominator: 'tp_spend', footerDataType: 'number', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.omni_new_customer_roas != 0 && campaign.omni_new_customer_roas != null ? new Intl.NumberFormat( 'en-US' ).format(campaign.omni_new_customer_roas) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_orders', variableName: 'tp_orders', title: 'Purchases', description: 'Total number of Purchases', sortable: true, width: 120, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.tp_orders != 0 && campaign.tp_orders != null ? new Intl.NumberFormat( 'en-US' ).format(campaign.tp_orders) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_new_customer_order_percent', variableName: 'omni_new_customer_order_percent', title: 'NCO %', description: 'Percentage of purchases from new customers', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'omni_new_customer_orders', footerDenominator: 'omni_orders', footerDataType: 'percent', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.omni_new_customer_order_percent != 0 && campaign.omni_new_customer_order_percent != null ? new Intl.NumberFormat('en-US', { style: 'percent' }).format(campaign.omni_new_customer_order_percent) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_new_customer_orders', variableName: 'omni_new_customer_orders', title: 'NCP', description: 'Purchases from new customers', sortable: true, width: 110, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.omni_new_customer_orders != 0 && campaign.omni_new_customer_orders != null ? new Intl.NumberFormat( 'en-US' ).format(campaign.omni_new_customer_orders) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_new_customer_cpa', variableName: 'omni_new_customer_cpa', title: 'NC CPA', description: 'Cost per acquisition', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'tp_spend', footerDenominator: 'omni_new_customer_orders', footerDataType: 'currency', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.omni_new_customer_cpa != 0 && campaign.omni_new_customer_cpa != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(campaign.omni_new_customer_cpa) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_orders', variableName: 'omni_orders', title: 'Purchases', description: 'Total number of purchases', sortable: true, width: 120, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width} onClick={() => { setSelectedCampaignForPurchasesPopup(campaign); setShowPurchasesPopup(true); }}>
            <span>{campaign.omni_orders != 0 && campaign.omni_orders != null ? new Intl.NumberFormat('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 1}).format(campaign.omni_orders) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_spend', variableName: 'tp_spend', title: 'Spend', description: 'Total advertising spend', sortable: true, width: 130, footerOperation: 'sum', footerDataType: 'currency', 
        renderCell: ({campaign, index}) => (
          <td key={campaign.key + "_" + index} style={campaign.width}>
            <span>{campaign.tp_spend != 0 && campaign.tp_spend != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(campaign.tp_spend) : '-'}</span>
          </td>
        ),
      },

    ];





    function sortColumnData(columnData, columnIndexes) {
      return columnData.sort((a, b) => {
        const indexA = columnIndexes[a.key] !== undefined ? columnIndexes[a.key] : Infinity;
        const indexB = columnIndexes[b.key] !== undefined ? columnIndexes[b.key] : Infinity;
        return indexA - indexB;
      });
    }
    
    const sortedColumnData = sortColumnData(columnData, columnIndexes);
    

    setCampaignColumns(sortedColumnData)
    // setCampaignColumns(columnDataInOrder);

  }, [columnIndexes, campaigns]);

  




  const handleScroll = () => {
    const scrollLeft = tableContainerRef.current.scrollLeft;
    setIsScrolled(scrollLeft > 0);
  };

  useEffect(() => {
    const tableContainer = tableContainerRef.current;
    tableContainer.addEventListener('scroll', handleScroll);

    return () => {
      tableContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);







  const startResize = (index) => (e) => {
    console.log('resize')
    const startX = e.clientX;
    const startWidth = campaignColumns[index].width;
    const onMouseMove = (e) => {
      const newWidth = startWidth + (e.clientX - startX);
      const minWidth = 50; // Minimum width
      const maxWidth = 350; // Maximum width (adjust as needed)

      // Clamp the new width between min and max values
      const clampedWidth = Math.min(maxWidth, Math.max(minWidth, newWidth));

      const newColumns = [...campaignColumns];
      newColumns[index].width = clampedWidth;
      setCampaignColumns(newColumns);

      
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };








  function handleSort(column) {
    console.log(column)
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }

    const sorted = [...sortedCampaigns].sort((a, b) => {
      const aValue = isNaN(Number(a[column])) ? a[column] : Number(a[column]);
      const bValue = isNaN(Number(b[column])) ? b[column] : Number(b[column]);

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
      } else {
        if (sortOrder === 'asc') {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      }
    });

    setSortedCampaigns(sorted);
  }





  useEffect(() => {
    const handleKeyDown = (event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            console.log('Escape key pressed')
            setShowBudgetInput('');
        }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
        window.removeEventListener('keydown', handleKeyDown);
    };
}, []); 





  async function filterCampaigns(campaigns, filterStatus) {
    if (filterStatus === 'ANY') {
      return campaigns;
    } else {
      return campaigns.filter((campaign) => campaign.status === filterStatus);
    }
  }


  useEffect(() => {
    async function fetchData() {
      console.log(campaigns)
      setFiltedCampaigns(await filterCampaigns(campaigns, filterStatus));
    }
    fetchData();
  }, [campaigns, filterStatus])


  useEffect(() => {
    setSortedCampaigns(filteredCampaigns);
  }, [filteredCampaigns])





  async function updateCampaignStatus (tp_id, status, omni_business_id, ad_account_id) {
    try {
      setUpdateStatusLoading(true)
      // setSnackbarInfo({ open: true, title: 'Publishing', message: 'Publishing changes', type: 'loading' });
      if (source == 'fb') {
        const response = await axios.post(`${serverURL}/updateFacebookCampaignData`, {
          campaign_id: tp_id,
          status: status,
          omni_business_id: omni_business_id
        })
        console.log("Campaign status updated", response)
      } else if (source == 'tiktok') {
        const response = await axios.post(`${serverURL}/updateTikTokCampaignData`, {
          omni_business_id: omni_business_id,
          campaign_ids: [tp_id],
          status: status.toLowerCase(),
          ad_account_id: ad_account_id
        })
        console.log("Campaign status updated", response)
      } else if (source == 'google') {
        const response = await axios.post(`${serverURL}/updateGoogleCampaignBudget`, {  // CHANGE THIS TO UPDATE STATUS
          omniBusinessID: omni_business_id,
          campaign_ids: [tp_id],
          newBudgetAmount: newBudgetValue,
        })
        console.log("Campaign status updated", response)
      }

      setUpdateStatusCampaignID('')
      setUpdateStatusLoading(false)
      setRefreshDataTrigger(!refreshDataTrigger)  
    } catch (error) {
      console.log("Error updating campaign status", error)
      setUpdateStatusLoading(false)
    }
    // setSnackbarInfo({ open: true, title: 'Published', message: 'Campaign status updated', type: 'success' });
  }


  async function updateCampaignBudget (tp_id, daily_budget, omni_business_id, ad_account_id, tp_budget_ids ) {
    try {
      setUpdateBudgetLoading(true)
      // setSnackbarInfo({ open: true, title: 'Publishing', message: 'Publishing budget', type: 'loading' });

      if (source == 'fb') {
        const response = await axios.post(`${serverURL}/updateFacebookCampaignData`, {
          campaign_id: tp_id,
          daily_budget: daily_budget,
          omni_business_id: omni_business_id
        })
        console.log("Campaign budget updated", response)
      } else if (source == 'tiktok') {
        const response = await axios.post(`${serverURL}/updateTikTokCampaignData`, {
          omni_business_id: omni_business_id,
          campaign_ids: [tp_id],
          budget: tp_budget_ids,
          ad_account_id: ad_account_id
          //name: 'UPDATED NAME'
        })
        console.log("Campaign budget updated", response)
      } else if (source == 'google') {

        console.log({ 
          omniBusinessID: omni_business_id,
          budgetID: tp_budget_ids[0],
          newBudgetAmount: newBudgetValue,
        })

        const response = await axios.post(`${pythonServerURL}/api/updateGoogleCampaignBudget`, { 
          omniBusinessID: omni_business_id,
          budgetID: tp_budget_ids[0],
          newBudgetAmount: newBudgetValue,
        })
        console.log("Campaign budget updated", response)
      }
      setShowBudgetInput('')
      setUpdateBudgetLoading(false)
      setRefreshDataTrigger(!refreshDataTrigger)  
    } catch (error) {
      console.log("Error updating campaign budget", error)
      setUpdateBudgetLoading(false)
    }
    // setSnackbarInfo({ open: true, title: 'Published', message: 'Campaign budget updated', type: 'success' });
  }





  return (
    <div className='CampaignGridContainer'>
      <div className='CampaignGridHeaderRow'>


          <div className='CampaignGridDropdownButton' style={{width: 100}}  onClick={() => { setAttributionDropdown(!showAttributionDropdown) }}>
            <div className='CampaignGridDropdownButtonText'>
              <span>
                {attributionModel == 'any_click' && 'Any Click'}
                {attributionModel == 'linear_all' && 'Linear All'}
                {attributionModel == 'first_click' && 'First Click'}
                {attributionModel == 'last_click' && 'Last Click'}
              </span>
            </div>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', transform: showAttributionDropdown ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease-in-out'}} >
                <KeyboardArrowDownRounded/>
              </div>
              {showAttributionDropdown && (
              <ClickAwayListener onClickAway={() => setAttributionDropdown(false)}>
                <div className='CampaignGridDropdownContainer' style={{width: 300}}>
                  <div 
                    className={attributionModel == 'any_click' ? 'CampaignGridDropdownRowSelected' : 'CampaignGridDropdownRow'} 
                    onClick={() => { setAttributionModel('any_click'); setAttributionDropdown(false); }}>
                      <img className='CampaignGridDropdownImage' style={{width: 50, height: 50}} src={linearAll} />
                      <div className='CampaignGridDropdownRightContainer'>
                        <span className='CampaignGridDropdownRightTitle'>Any Click</span>
                        <span className='CampaignGridDropdownRightDescription'>Each channel gets 100% </span>
                      </div>
                  </div>

                  <div 
                    className={attributionModel == 'first_click' ? 'CampaignGridDropdownRowSelected' : 'CampaignGridDropdownRow'} 
                    onClick={() => { setAttributionModel('first_click'); setAttributionDropdown(false); }}>
                      <img className='CampaignGridDropdownImage' style={{width: 50, height: 50}} src={firstClick} />
                      <div className='CampaignGridDropdownRightContainer'>
                        <span className='CampaignGridDropdownRightTitle'>First Click</span>
                        <span className='CampaignGridDropdownRightDescription'> 100% to first channel clicked </span>
                      </div>
                  </div>

                  <div 
                    className={attributionModel == 'last_click' ? 'CampaignGridDropdownRowSelected' : 'CampaignGridDropdownRow'} 
                    onClick={() => { setAttributionModel('last_click'); setAttributionDropdown(false); }}>
                      <img className='CampaignGridDropdownImage' style={{width: 50, height: 50}} src={lastClick} />
                      <div className='CampaignGridDropdownRightContainer'>
                        <span className='CampaignGridDropdownRightTitle'>Last Click</span>
                        <span className='CampaignGridDropdownRightDescription'> 100% credit to last channel clicked </span>
                      </div>
                  </div>

                  <div 
                    className={attributionModel == 'linear_all' ? 'CampaignGridDropdownRowSelected' : 'CampaignGridDropdownRow'} 
                    onClick={() => { setAttributionModel('linear_all'); setAttributionDropdown(false); }}>
                      <img className='CampaignGridDropdownImage' style={{width: 50, height: 50}} src={linearAll} />
                      <div className='CampaignGridDropdownRightContainer'>
                        <span className='CampaignGridDropdownRightTitle'>Linear All</span>
                        <span className='CampaignGridDropdownRightDescription'> Divides credit equally among all channels </span>
                      </div>
                  </div>

                </div>
              </ClickAwayListener>
            )}
          </div>
      
          <div className='CampaignGridDropdownButton' style={{width: 80}} onClick={() => { setShowAttributionsWindowDropdown(!showAttributionsWindowDropdown) }}>
            <div className='CampaignGridDropdownButtonText'>
              <span>
                {attributionWindow == '10000 days' && 'Lifetime'}
                {attributionWindow == '28 days' && '28 Days'}
                {attributionWindow == '14 days' && '14 Days'}
                {attributionWindow == '7 days' && '7 Days'}
                {attributionWindow == '1 day' && '1 Day'}
              </span>
            </div>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', transform: showAttributionsWindowDropdown ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease-in-out'}} >
                <KeyboardArrowDownRounded/>
              </div>
              {showAttributionsWindowDropdown && (
              <ClickAwayListener onClickAway={() => setShowAttributionsWindowDropdown(false)}>
                <div className='CampaignGridDropdownContainer'>
                  <span className={attributionWindow == '1 day' ? 'CampaignGridDropdownRowSelected' : 'CampaignGridDropdownRow'} onClick={() => { setShowAttributionsWindowDropdown(false); setAttributionWindow('1 day') }}>1 Day</span>
                  <span className={attributionWindow == '7 days' ? 'CampaignGridDropdownRowSelected' : 'CampaignGridDropdownRow'} onClick={() => { setShowAttributionsWindowDropdown(false); setAttributionWindow('7 days') }}>7 Days</span>
                  <span className={attributionWindow == '14 days' ? 'CampaignGridDropdownRowSelected' : 'CampaignGridDropdownRow'} onClick={() => { setShowAttributionsWindowDropdown(false); setAttributionWindow('14 days') }}>14 Days</span>
                  <span className={attributionWindow == '28 days' ? 'CampaignGridDropdownRowSelected' : 'CampaignGridDropdownRow'} onClick={() => { setShowAttributionsWindowDropdown(false); setAttributionWindow('28 days') }}>28 Days</span>
                  <span className={attributionWindow == '10000 days' ? 'CampaignGridDropdownRowSelected' : 'CampaignGridDropdownRow'} onClick={() => { setShowAttributionsWindowDropdown(false); setAttributionWindow('10000 days') }}>Lifetime</span>
                </div>
              </ClickAwayListener>
            )}
          </div>


        <div className='CampaignGridDropdownButton' onClick={() => { setShowSelectColumnsPopup(!showSelectColumnsPopup) }}>
          <div className='CampaignGridDropdownButtonText' style={{marginRight: 6}}>
            {/* <ViewColumnRounded style={{paddingRight: 10}}/> */}
            Views
          </div>                   
        </div>


        {/* <div className='CampaignGridDropdownButton' onClick={() => { setShowStatusDropdown(!showStatusDropdown) }}>
          <div className='CampaignGridDropdownButtonText' >
            <span>Status: {filterStatus}</span>
          </div>
          <KeyboardArrowDownRounded style={{paddingLeft: 10}} />
          {showStatusDropdown && (
            <ClickAwayListener onClickAway={() => setShowStatusDropdown(false)}>
              <div className='CampaignGridDropdownContainer'>
                <span className={filterStatus == 'ANY' ? 'CampaignGridDropdownRowSelected' : 'CampaignGridDropdownRow'} onClick={() => { setFilterStatus("ANY"); setShowStatusDropdown(false) }}>Any</span>
                <span className={filterStatus == 'ACTIVE' ? 'CampaignGridDropdownRowSelected' : 'CampaignGridDropdownRow'} onClick={() => { setFilterStatus("ACTIVE"); setShowStatusDropdown(false) }}>Active</span>
                <span className={filterStatus == 'PAUSED' ? 'CampaignGridDropdownRowSelected' : 'CampaignGridDropdownRow'} onClick={() => { setFilterStatus("PAUSED"); setShowStatusDropdown(false) }}>Paused</span>
                <span className={filterStatus == 'REVIEW' ? 'CampaignGridDropdownRowSelected' : 'CampaignGridDropdownRow'} onClick={() => { setFilterStatus("REVIEW"); setShowStatusDropdown(false) }}>In Review</span>
              </div>
            </ClickAwayListener>
          )}
        </div> */}


      </div>


      <div className='CampaignGridTableContainer' ref={tableContainerRef}>
      <table className='CampaignGridTable'>
    
        <HeaderComponent campaignColumns={campaignColumns} sortColumn={sortColumn} sortOrder={sortOrder} handleSort={handleSort} startResize={startResize} availableColumns={availableColumns} columnIndexes={columnIndexes} source={source} isScrolled={isScrolled}/>
     
        <tbody>
          {sortedCampaigns.map((campaign, index) => (
            <RowComponent
              key={index}
              rowIndex={campaign.tp_id}
              campaign={campaign}
              campaignColumns={campaignColumns}
              selectedCampaigns={selectedCampaigns}
              adjustSelectedCampaigns={adjustSelectedCampaigns}
              setShowDrawer={setShowDrawer}
              setSelectedCampaignForPurchasesPopup={setSelectedCampaignForPurchasesPopup}
              showBudgetInput={showBudgetInput}
              setShowBudgetInput={setShowBudgetInput}
              newBudgetValue={newBudgetValue}
              setNewBudgetValue={setNewBudgetValue}
              updateBudgetLoading={updateBudgetLoading}
              updateCampaignBudget={updateCampaignBudget}
              updateCampaignStatus={updateCampaignStatus}
              setShowPurchasesPopup={setShowPurchasesPopup}
              availableColumns={availableColumns}
              columnIndexes={columnIndexes}
              isScrolled={isScrolled}
            />
          ))}
      
          {sortedCampaigns.length > 0 && (
            <FooterComponent campaignColumns={campaignColumns} sortedCampaigns={sortedCampaigns} availableColumns={availableColumns} columnIndexes={columnIndexes} isScrolled={isScrolled}/>
          )}
        </tbody>

      </table>
    </div>
    </div>
  );
}

export default CampaignGrid;