import '../App.css';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import react, { useState, useEffect } from 'react';

import { arrayUnion, addDoc, collection, doc, setDoc, where, getDocs, updateDoc, query, getCountFromServer } from 'firebase/firestore';
import { db } from '../FirebaseInit';
import { useNavigate, useLocation } from 'react-router-dom';
import TextInput from '../components/TextInput';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import axios from 'axios';




function SignUp() {
  const navigate = useNavigate()

  const [step, setStep] = useState(0);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [businessName, setBusinessName] = useState('');



  const [invitedUserData, setInvitedUserData] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState(null);

  const [inviteToken, setInviteToken] = useState(null);



  function generateRandomId(type) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    if (type == 'uid') {
      result = "u_";
    } else {
      result = "b_";
    }
    const charactersLength = characters.length;
    for (let i = 0; i < 30; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }



  function getTokenFromURL() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('token');
  }
  

  useEffect(() => {
    const tokenFromQuery = getTokenFromURL();
    setInviteToken(tokenFromQuery);

    if (tokenFromQuery) {

      async function getInvitedUserData (token) {
        const q = query(collection(db, 'users'), where('inviteToken', '==', token));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            setInvitedUserData(doc.data());
            setFirstName(doc.data().firstName);
            setLastName(doc.data().lastName);
            setEmail(doc.data().email);
          });
        } else {
          console.log('Invite Token not found in users collection');
        }
      };
      getInvitedUserData(tokenFromQuery);
    }
  }, [db])




  useEffect(() => {
    if (invitedUserData) {
      setEmail(invitedUserData.email);
    }
  }, [invitedUserData])


  async function emailSignUp() {
    try {
      const auth = getAuth();
  
      // Check if the user already exists using the token
      const q = query(collection(db, 'users'), where('inviteToken', '==', inviteToken));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        // If user exists, get the user document ID
        const userDocId = querySnapshot.docs[0].id;
        const userRef = doc(db, "users", userDocId);
  
        // Proceed to create the user with email and password
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
  
        // Prepare user data to be updated
        const invitedUserData = {
          firstName: firstName,
          lastName: lastName,
          inviteToken: null,
          createdAt: new Date(),
        };
   
        // Update the user's data in Firestore
        await updateDoc(userRef, invitedUserData);
  
        // Store token and navigate to dashboard
        const idToken = await user.getIdToken();
        localStorage.setItem('firebase_token', idToken);
        setAlertMessage("Submission Successful");
        setAlertType("success");
        navigate('/Dashboard');
      } else {
        // If the token does not exist, show error message
        setAlertMessage("Token has been expired");
        setAlertType("error");
        navigate('/Login');
      }
    } catch (error) {
      // Handle any errors during the process
      const errorMessage = error.message;
      console.error("Error during sign-up process:", errorMessage);
      setAlertMessage("Token has been Expired, Please Login: " + errorMessage);
      setAlertType("error");
    } finally {
      // Clear alert messages after a timeout
      setTimeout(() => {
        setAlertMessage(null);
        setAlertType(null);
      }, 4000);
    }
  }
  

  async function signUp() {
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in
        setStep(1)
        const user = userCredential.user;
        const userID = "u_" + user.uid;
        console.log("UID: " + userID)
        localStorage.setItem('uid', userID);
        const usersCollection = collection(db, 'users');
        await setDoc(doc(usersCollection, userID), {
          firstName: '',
          lastName: '',
          email: email,
          userID: userID,
          createdAt: new Date(),
          type: 'emailAuth',
          businessIDs: [],
          timezone: getUserTimezoneObject()
        });


        const businessID = generateRandomId('bid');
        localStorage.setItem('bid', businessID);

        const businessCollection = doc(db, 'businesses', businessID);
        setDoc(businessCollection, {
          businessName: '',
          userIDs: [userID],
          createdAt: new Date(),
          businessEmail: email,
          ownerFirstName: firstName,
          ownerLastName: lastName,
          timezone: getUserTimezoneObject(),
        })



        const deviceInfo = await getDeviceInfo()

        const q = query(collection(db, "userDevices"), where("browser", "==", deviceInfo.browser), where("os", "==", deviceInfo.os), where("ip", "==", deviceInfo.ip), where("location", "==", deviceInfo.location), where("userID", "==", userID));
        const snapshot = await getCountFromServer(q);
        const matchedDevices = snapshot.data().count;  

        if (matchedDevices == 0) {
          const userDeviceCollection = collection(db, 'userDevices');
          addDoc(userDeviceCollection, {
            browser: deviceInfo.browser,
            os: deviceInfo.os,
            ip: deviceInfo.ip,
            location: deviceInfo.location,
            createdAt: new Date(),
            userID: userID
          })
        }



        console.log("BID: ", businessID);
        const userDocRef = doc(db, 'users', userID);
        await updateDoc(userDocRef, {
          businessIDs: arrayUnion(businessID),
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage)
        // ..
      });
  }

 






  async function updateName() {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const userID = localStorage.getItem('uid');
      const bid = localStorage.getItem('bid');

      try {

        const userDocRef = doc(db, 'users', userID);
        // Update the user's first name and last name
        await setDoc(userDocRef, {
          firstName: firstName,
          lastName: lastName,
        }, { merge: true }); // Use merge option to update only the specified fields


        const businessDocRef = doc(db, 'businesses', bid);
        // Update the user's first name and last name
        await setDoc(businessDocRef, {
          businessName: businessName,
          ownerFirstName: firstName,
          ownerLastName: lastName,
        }, { merge: true });

        console.log('User name updated successfully');
      } catch (error) {
        console.error('Error updating user name:', error);
      }
    } else {
      console.error('User not authenticated'); // Handle the case where the user is not authenticated
    }
  }


  function getUserTimezoneObject() {
    const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const offsetMinutes = new Date().getTimezoneOffset();
    const offsetHours = -offsetMinutes / 60; // Convert minutes to hours and reverse the sign
  
    // Format offset as GMT±HH:MM
    const offsetFormatted = `GMT${offsetHours >= 0 ? "+" : ""}${offsetHours.toString().padStart(2, "0")}:00`;
  
    return {
      name: `(${offsetFormatted}) ${timezoneName}`,
      offset: offsetHours
    };
  }



  
  async function getDeviceInfo() {
    // Get browser and OS info from userAgent
    const userAgent = navigator.userAgent;
    let browser = "Unknown Browser";
    let os = "Unknown OS";
  
    if (userAgent.includes("Firefox")) {
      browser = "Firefox";
    } else if (userAgent.includes("Edg")) {
      browser = "Edge";
    } else if (userAgent.includes("Chrome") && !userAgent.includes("Edg")) {
      browser = "Chrome";
    } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
      browser = "Safari";
    }
  
    if (userAgent.includes("Windows")) {
      os = "Windows";
    } else if (userAgent.includes("Mac OS")) {
      os = "Mac OS";
    } else if (userAgent.includes("Linux")) {
      os = "Linux";
    } else if (userAgent.includes("Android")) {
      os = "Android";
    } else if (userAgent.includes("iPhone")) {
      os = "iOS";
    }
  
    // Fetch IP and location data
    try {
      const response = await axios.get("https://ipapi.co/json/");
      const data = await response.data;
  
      return {
        ip: data.ip,
        location: `${data.city}, ${data.region}, ${data.country_name}`,
        browser: browser,
        os: os
      };
    } catch (error) {
      console.error("Failed to fetch IP/location data:", error);
      return {
        ip: "Unavailable",
        location: "Unavailable",
        browser: browser,
        os: os
      };
    }
  }
  


  





  return (
    <>
      {inviteToken ? (
        <>
          <Snackbar
            open={alertMessage !== null}
            autoHideDuration={7000}
            onClose={() => {
              setAlertMessage(null);
              setAlertType(null);
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            {alertMessage && (
              <Alert variant="filled" onClose={() => { setAlertMessage(null); setAlertType(null); }} severity={alertType}>
                 {alertMessage}
              </Alert>
            )}
          </Snackbar>
          <div className=''>
            <div className='loginLeft'>
              <span className='loginLeftLogo'>Omni AI</span>
              <span className='loginWelcomeText'>Hi, Welcome {email}</span>
            </div>
            <div className='loginRight'>
              <div className='loginSubContainer'>
                <span className='loginRightText' style={{marginBottom: 20}}>Complete Registration</span>
                <TextInput placeholder='First Name' type='text' value={firstName} onChange={setFirstName} widthPercent={100} widthPixels={0} marginBottom={20} />
                <TextInput placeholder='Last Name' type='text' value={lastName} onChange={setLastName} widthPercent={100} widthPixels={0} marginBottom={20} />
                <TextInput placeholder='Email' type='text' value={email} onChange={setEmail} widthPercent={100} widthPixels={0} marginBottom={20} />
                <TextInput placeholder='Password' type='password' value={password} onChange={setPassword} widthPercent={100} widthPixels={0} marginBottom={20} />
                <span className="loginButtonContainer" style={{marginTop: 20, width: 'calc(100% - 30px)'}} onClick={() => { emailSignUp() }} >Submit</span>

              </div>
            </div>
          </div>
        </>

      ) : (
        <>
          <div className='loginContainer'>
            <div className='loginLeft'>
              <span className='loginLeftLogo'>Omni AI</span>
              <span className='loginWelcomeText'>Hi, Welcome to Omni AI</span>
            </div>
            <div className='loginRight'>
              {step === 0 && (
                <div className='loginSubContainer'>
                  <span className='loginRightText'>Create an Omni AI account</span>
                  <span className='loginRightCreateText'>Already a user? <span className='loginRightCreateLink' onClick={() => { navigate('/Login') }}>Log in</span></span>
                  <input className='loginInput' type='email' placeholder='Email' value={email} onChange={(event) => setEmail(event.target.value)} />
                  <input className='loginInput' type='password' placeholder='Password' value={password} onChange={(event) => setPassword(event.target.value)} />
                  <button className='buttonContainer' onClick={() => { signUp() }}>Sign up</button>
                </div>
              )}

              {step === 1 && (
                <div className='loginSubContainer'>
                  <span className='welcomeText'>Welcome to Omni!</span>
                  <span className='welcomeSubText'>Add your account details</span>
                  <input className='loginInput' type='text' placeholder='Business Name' value={businessName} onChange={(event) => setBusinessName(event.target.value)} />
                  <input className='loginInput' type='text' placeholder='First Name' value={firstName} onChange={(event) => setFirstName(event.target.value)} />
                  <input className='loginInput' type='text' placeholder='Last Name' value={lastName} onChange={(event) => setLastName(event.target.value)} />
                  <button className='buttonContainer' onClick={() => { updateName() }}>Continue</button>
                </div>
              )}

            
            </div>
          </div>
        </>
      )}
    </>
  );


}

export default SignUp;














