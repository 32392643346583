import React, { useState, useRef, useEffect } from 'react';
import Switch from "react-switch";
import './AdsetGrid.css';
import { CheckRounded, KeyboardArrowDownRounded, KeyboardArrowUpRounded, SolarPower, ViewColumn, ViewColumnRounded, ViewWeek } from '@mui/icons-material';
import { dummyTesting } from '../../AdsManager'
import EditIcon from '../../../../assets/Icons/EditIcon';
import TextInput from '../../../../components/TextInput';
import axios from 'axios';
import { CircularProgress, ClickAwayListener } from '@mui/material';
import { FaMeta, FaFloppyDisk } from "react-icons/fa6";
import { db } from '../../../../FirebaseInit';
import { doc, getDoc } from 'firebase/firestore';
import firstClick from '../../../../assets/attributionModels/firstClick.png';
import lastClick from '../../../../assets/attributionModels/lastClick.png';
import linearAll from '../../../../assets/attributionModels/linearAll.png';
import linearPaid from '../../../../assets/attributionModels/linearPaid.png';
import uShaped from '../../../../assets/attributionModels/uShaped.png';
import dayjs from 'dayjs';
import Meta_logo from '../../../../assets/Meta_logo.png';
import klaviyo_logo from '../../../../assets/klaviyo_logo.png';
import google_ads_logo from '../../../../assets/google_ads_logo.png';
import tiktok_logo_white_background from '../../../../assets/tiktok_logo_white_background.png';




function formatTimeElapsed(seconds) {
  // format time like hh:mm:ss with each always being 2 digits and no decimals for seconds
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const formattedSeconds = Math.floor(seconds % 60);
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${formattedSeconds.toString().padStart(2, '0')}`;
}




function HeaderComponent ({adsetColumns, sortColumn, sortOrder, handleSort, startResize, availableColumns, columnIndexes, source, isScrolled}) {
  return (
    <thead>
      <tr>
        {adsetColumns?.map((col, index) => {
          const columnIndex = columnIndexes[col.key] 
          if (!columnIndex) return null;

          const isSorted = sortColumn === col.variableName;
          const sortIcon = isSorted ? (
            sortOrder === 'asc' ? (
              <KeyboardArrowUpRounded fontSize='small' style={{ color: '#888' }} />
            ) : (
              <KeyboardArrowDownRounded fontSize='small' style={{ color: '#888' }} />
            )
          ) : null;

          const tpLogo = () => {
            if (source == 'fb') {
              return <img src={Meta_logo} style={{width: 25, borderRadius: 5}}/> 
            } else if (source == 'google') {
              return <img src={google_ads_logo} style={{width: 25, borderRadius: 5}}/>
            } else if (source == 'tiktok') {
              return <img src={tiktok_logo_white_background} style={{width: 25, borderRadius: 5}}/>
            } else if (source == 'klaviyo') {
              return <img src={klaviyo_logo} style={{width: 25, borderRadius: 5}}/>
            }
          }

          return (
            <th
              key={col.key}
              style={{ width: col.width }}
              onClick={() => col.sortable && handleSort(col.variableName)}
            >
              <div 
                className={`AdsetGridTableHeaderContainer ${(col.key == 'name_meta' || col.key == 'tp_status') && 'AdsetGridTableSticky'}`}
                style={{boxShadow: isScrolled && index == 1 ? '0px 0px 7px #00000050' : 'none', borderRight: '1px solid #e7e7e7', boxSizing: 'border-box', width: 'calc(100% + 1px)'}}>
                {/* {col.key?.includes('omni') && <FaFloppyDisk className='AdsetGridHeaderOmniIcon'/>} */}
                {col.key?.includes('tp') ? tpLogo() : <FaFloppyDisk className='AdsetGridHeaderOmniIcon'/>}
                <div style={{paddingLeft: 10}}>{col.title}</div>
                <div className='AdsetGridTableHeaderSortIcon'>{sortIcon}</div>
                <div
                  className="resizer"
                  onMouseDown={(e) => {
                    e.stopPropagation(); // Prevents the event from bubbling up to the <th>
                    startResize(index)(e);
                  }}
                  onClick={(e) => e.stopPropagation()} // Prevents click event propagation
                ></div>
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
}






function RowComponent ({ rowIndex, adset, adsetColumns, columnWidths, selectedAdsets, adjustSelectedAdsets, setShowDrawer, setSelectedAdsetForPurchasesPopup, showBudgetInput, setShowBudgetInput, 
  newBudgetValue, setNewBudgetValue, updateBudgetLoading, updateAdsetBudget, updateAdsetStatus, setShowPurchasesPopup, availableColumns, columnIndexes, isScrolled }) {
  return (
    <tr key={rowIndex}>
      {adsetColumns?.map((col, index) => {
        const columnIndex = columnIndexes[col.key] 
        if (!columnIndex) return null;


        const style = { width: col.width };

        // If a custom cell renderer is provided, use it
        if (col.renderCell) {
          return col.renderCell({adset, columnWidths, selectedAdsets, adjustSelectedAdsets, setShowDrawer, setSelectedAdsetForPurchasesPopup, showBudgetInput, setShowBudgetInput, 
                                newBudgetValue, setNewBudgetValue, updateBudgetLoading, updateAdsetBudget, updateAdsetStatus, setShowPurchasesPopup, index, isScrolled})
        }

        // Default cell rendering
        const cellData = adset[col.dataKey];

        return (
          <td key={col.key} style={style}>
            {cellData}
          </td>
        );
      })}
    </tr>
  );
}






function FooterComponent({ adsetColumns, sortedAdsets, availableColumns, columnIndexes, isScrolled }) {
  return (
    <tr className='sticky-footer-row'>
      {adsetColumns?.map((col, index) => {
        const columnIndex = columnIndexes[col.key] 
        if (!columnIndex) return null;

        let footerValue = '';
        

        if (col.footerOperation == 'sum') { 
          footerValue = sortedAdsets.reduce((accumulator, x) => { return accumulator + Number(x[col.variableName] || 0) }, 0)
        } else if (col.footerOperation == 'divide') {
          const numerator = sortedAdsets.reduce((accumulator, x) => { return accumulator + Number(x[col.footerNumerator] || 0) }, 0)
          const denominator = sortedAdsets.reduce((accumulator, x) => { return accumulator + Number(x[col.footerDenominator] || 0) }, 0)
          footerValue = numerator / denominator
        } else if (col.footerOperation == false) {
          footerValue = col.footer;
        }

        if (col.multiplyBy) {
          footerValue = footerValue * col.multiplyBy;
        }

        if (col.footerDataType == 'currency') {
          footerValue = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(footerValue);
        } else if (col.footerDataType == 'percent') {
          footerValue = new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(footerValue);
        } else if (col.footerDataType == 'number') {
          footerValue = new Intl.NumberFormat('en-US').format(footerValue);
        } else if (col.footerDataType == 'time') {
          footerValue = formatTimeElapsed(footerValue);
        } else { 
          footerValue = footerValue;
        }


        return (
          <td key={col.key} style={{ width: col.width }}>
            <div className='AdsetGridTableSticky' style={{ paddingLeft: 10, boxShadow: isScrolled && index == 1 ? '0px 0px 7px #00000050' : 'none'  }}>
              {footerValue}
            </div>
          </td>
        );
      })}
    </tr>
  );
}









function AdsetGrid({ selectedCampaigns, selectedAdsets, adjustSelectedAdsets, adsets, setShowDrawer, setSelectedAdsetForPurchasesPopup, selectedColumns, filterStatus, serverURL, omni_business_id, 
  refreshDataTrigger, setRefreshDataTrigger, availableColumns, columnIndexes, setShowAttributionsWindowDropdown, showAttributionsWindowDropdown, setAttributionWindow, attributionWindow,
  setShowSelectColumnsPopup, showSelectColumnsPopup, setAttributionDropdown, showAttributionDropdown, attributionModel, setAttributionModel, setShowStatusDropdown, 
  showStatusDropdown, setFilterStatus, setShowPurchasesPopup, adsetColumns, setAdsetColumns, setSelectedAdLevel, source, setSnackbarInfo}) {

  const bid = localStorage.getItem('bid')


  const [switchStates, setSwitchStates] = useState({});
  const [filteredAdsets, setFilteredAdsets] = useState(adsets);
  const [sortedAdsets, setSortedAdsets] = useState(adsets);
  const [sortColumn, setSortColumn] = useState('status');
  const [sortOrder, setSortOrder] = useState('asc');


  const [showBudgetInput, setShowBudgetInput] = useState('');
  const [newBudgetValue, setNewBudgetValue] = useState('');
  const [updateBudgetLoading, setUpdateBudgetLoading] = useState(false);
  const [updateStatusLoading, setUpdateStatusLoading] = useState(false);
  const [updateStatusAdsetID, setUpdateStatusAdsetID] = useState('');


  const [isScrolled, setIsScrolled] = useState(false);
  const tableContainerRef = useRef(null);


  useEffect(() => {
    const columnData = [
      { key: 'tp_status', variableName: 'tp_status', icon: 'none', title: 'Status', description: 'On/Off', sortable: true, width: 100, fixed: true, footerOperation: false, footer: '',
        renderCell: ({adset, adjustSelectedAdsets, setNewBudgetValue, showBudgetInput, newBudgetValue, updateBudgetLoading, updateAdsetBudget, setShowBudgetInput, updateAdsetStatus, index, selectedAdsets}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <div className='AdsetGridTableSticky' >
              <div className='AdsetGridSelectedAdCheckboxColumnRow'>
                {selectedAdsets.includes(adset?.tp_id) ? (
                  <div className='AdsetGridSelectedAdCheckboxContainer' onClick={() => adjustSelectedAdsets(adset?.tp_id)}>
                    <div className='AdsetGridSelectedAdCheckboxChecked'>
                      <CheckRounded style={{ width: 10 }} />
                    </div>
                  </div>
                ) : (
                  <div className='AdsetGridSelectedAdCheckboxContainer' onClick={() => adjustSelectedAdsets(adset?.tp_id)}>
                    <div className='AdsetGridSelectedAdCheckbox'>
                      <CheckRounded style={{ width: 10 }} />
                    </div>
                  </div>
                )}
                <div style={{ marginTop: 5 }} onClick={() => updateAdsetStatus(adset?.tp_id, (adset?.tp_status === 'ACTIVE' || adset?.tp_status === 'ENABLE' || adset?.tp_status === 'ENABLED') ? 'PAUSED' : 'ACTIVE', bid, adset.account_id)}>
                  <Switch
                    onChange={() => {}}
                    checked={adset?.tp_status === 'ACTIVE' || adset?.tp_status === 'ENABLE' || adset?.tp_status === 'ENABLED'}
                    offColor="#d9d9d9"
                    onColor="#635bff"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    width={35}
                    height={20}
                    handleDiameter={15}
                  />
                </div>
              </div>
            </div>
          </td>
        ),
      
      },
      { key: 'tp_name', variableName: 'tp_name', icon: 'none', title: 'Adset', description: 'Adset Name', sortable: true, width: 300, fixed: true, footerOperation: false, footer: `${adsets.length} Adsets`,
        renderCell: ({adset, index, isScrolled}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <div className='AdsetGridTableSticky' style={{ paddingLeft: 10, boxShadow: isScrolled ? '0px 0px 7px #00000050' : 'none' }} onClick={() => { adjustSelectedAdsets(adset?.tp_id); setSelectedAdLevel(2); }}>
              <span style={{maxWidth: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{adset.tp_name}</span>
            </div>
          </td>
        ),
      },
      { key: 'tp_daily_budget', variableName: 'tp_daily_budget', icon: 'none', title: 'Daily Budget', description: 'Daily Budget', sortable: true, width: 150, fixed: true, footerOperation: 'sum', footerDataType: 'currency',  
        renderCell: ({adset, adjustSelectedAdsets, setNewBudgetValue, showBudgetInput, newBudgetValue, updateBudgetLoading, updateAdsetBudget, setShowBudgetInput, updateAdsetStatus, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <div className='AdsetGridBudgetRow'>
              {showBudgetInput === adset?.tp_id ? (
                <ClickAwayListener onClickAway={() => setShowBudgetInput('')}>
                  <div className='AdsetGridBudgetRowInputContainer'>
                    <span className='AdsetGridBudgetRowInputDollarSign'>$</span>
                    <input
                      className='AdsetGridBudgetRowInput'
                      type="number"
                      value={newBudgetValue}
                      onChange={(e) => setNewBudgetValue(e.target.value)}
                    />
                  </div>
                </ClickAwayListener>
              ) : (
                <>
                  {!adset.tp_daily_budget ? (
                    <span className='AdsetGridBudgetRowAdsetBudget'>Campaign budget</span>
                  ) : (
                    <span className='AdsetGridBudgetRowValue'>
                      {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adset.tp_daily_budget)}
                    </span>
                  )}
                </>
              )}
              {showBudgetInput === adset?.tp_id ? (
                updateBudgetLoading ? (
                  <div className='AdsetGridBudgetRowEditIcon'>
                    <CircularProgress style={{ height: 17, width: 17, color: '#888' }} />
                  </div>
                ) : (
                  <div className='AdsetGridBudgetRowEditIcon' onClick={() => {console.log(adset); updateAdsetBudget(adset?.tp_id, newBudgetValue, bid, adset.account_id) }}>
                    <CheckRounded style={{ color: '#888', width: 14, height: 14 }} />
                  </div>
                )
              ) : (
                adset.daily_budget !== '' && (
                  <div className='AdsetGridBudgetRowEditIcon' onClick={() => { setShowBudgetInput(adset?.tp_id); setNewBudgetValue(Number(adset.tp_daily_budget)); }}>
                    <EditIcon color='#888' />
                  </div>
                )
              )}
            </div>
          </td>
        ),
      },
      { key: 'tp_delivery', variableName: 'tp_status', title: 'Delivery', description: 'Ad status', sortable: true, width: 120, footerOperation: false, 
        renderCell: ({adset, index}) => {
          const statusColor = adset.tp_status === 'ACTIVE' ? '#3acf12' : '#e7e7e7';
          return (
            <td key={adset.key + "_" + index} style={adset.width}>
              <div className='AdsetGridDeliveryStatusRow'>
                <div className='AdsetGridDeliveryStatusIcon' style={{ backgroundColor: statusColor }}></div>
              </div>
            </td>
          );
        },
      },
      { key: 'omni_bounce_rate', variableName: 'omni_bounce_rate', title: 'Bounce Rate', description: 'Sessions that only viewed 1 page', sortable: true, width: 150, footerOperation: 'divide', footerNumerator: 'omni_bounce_count', footerDenominator: 'omni_sessions', footerDataType: 'percent', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.omni_bounce_rate != 0 && adset.omni_bounce_rate != null ? new Intl.NumberFormat('en-US', { style: 'percent' }).format(adset.omni_bounce_rate) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_outbound_ctr', variableName: 'tp_outbound_ctr', title: 'Outbound CTR', description: 'Link click CTR ', sortable: true, width: 150, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.tp_outbound_ctr != 0 && adset.tp_outbound_ctr != null ? new Intl.NumberFormat('en-US').format(adset.tp_outbound_ctr) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_outbound_clicks', variableName: 'tp_outbound_clicks', title: 'Outbound Clicks', description: 'Link click count', sortable: true, width: 160, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.tp_outbound_clicks != 0 && adset.tp_outbound_clicks != null ? new Intl.NumberFormat('en-US').format(adset.tp_outbound_clicks) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_aov', variableName: 'tp_aov', title: 'AOV', description: 'Average order value', sortable: true, width: 120, footerOperation: 'divide', footerNumerator: 'tp_revenue', footerDenominator: 'tp_orders', footerDataType: 'currency',
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.tp_aov != 0 && adset.tp_aov != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adset.tp_aov) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_cpa', variableName: 'tp_cpa', title: 'CPA', description: 'Cost per acquisition', sortable: true, width: 120, footerOperation: 'divide', footerNumerator: 'tp_spend', footerDenominator: 'tp_orders', footerDataType: 'currency', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.tp_cpa != 0 && adset.tp_cpa != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adset.tp_cpa) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_cpm', variableName: 'tp_cpm', title: 'CPM', description: 'Cost per thousand impressions', sortable: true, width: 120, footerOperation: 'divide', footerNumerator: 'tp_spend', footerDenominator: 'tp_impressions', multiplyBy: 1000, footerDataType: 'currency',
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.tp_cpm != 0 && adset.tp_cpm != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adset.tp_cpm) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_ctr', variableName: 'tp_ctr', title: 'CTR', description: 'Click-through rate', sortable: true, width: 120, footerOperation: 'divide', footerNumerator: 'tp_clicks', footerDenominator: 'tp_impressions', footerDataType: 'percent', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.tp_ctr != 0 && adset.tp_ctr != null ? new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(adset.tp_ctr) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_cpc', variableName: 'tp_cpc', title: 'CPC', description: 'Cost per click', sortable: true, width: 120, footerOperation: 'divide', footerNumerator: 'tp_spend', footerDenominator: 'tp_clicks', footerDataType: 'currency', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.tp_cpc != 0 && adset.tp_cpc != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adset.tp_cpc) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_impressions', variableName: 'tp_impressions', title: 'Impressions', description: 'Number of impressions', sortable: true, width: 150, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.tp_impressions != 0 && adset.tp_impressions != null ? new Intl.NumberFormat( 'en-US' ).format(adset.tp_impressions) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_clicks', variableName: 'tp_clicks', title: 'Clicks', description: 'Total clicks', sortable: true, width: 120, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.tp_clicks != 0 && adset.tp_clicks != null ? new Intl.NumberFormat( 'en-US' ).format(adset.tp_clicks) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_roas', variableName: 'tp_roas', title: 'ROAS', description: 'Return on ad spend', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'value_purchase', footerDenominator: 'spend', footerDataType: 'number',  
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.tp_roas != 0 && adset.tp_roas != null ? new Intl.NumberFormat('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(adset.tp_roas) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_cvr', variableName: 'tp_cvr', title: 'CVR', description: 'Conversion rate', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'tp_orders', footerDenominator: 'tp_link_click', footerDataType: 'percent',  
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.tp_cvr != 0 && adset.tp_cvr != null ? new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(adset.tp_cvr) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_avg_session_duration', variableName: 'omni_avg_session_duration', title: 'AVG SD', description: 'Average session duration (in seconds)', sortable: true, width: 120, footerOperation: 'divide', footerNumerator: 'omni_total_session_duration', footerDenominator: 'omni_sessions', footerDataType: 'time', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.omni_avg_session_duration != 0 && adset.omni_avg_session_duration != null ? formatTimeElapsed(adset.omni_avg_session_duration) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_avg_page_views_per_session', variableName: 'omni_avg_page_views_per_session', title: 'AVG VPS', description: 'Average pages viewed per session', sortable: true, width: 120, footerOperation: 'divide', footerNumerator: 'omni_page_viewed', footerDenominator: 'omni_sessions', footerDataType: 'number', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.omni_avg_page_views_per_session != 0 && adset.omni_avg_page_views_per_session != null ? new Intl.NumberFormat( 'en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 } ).format(adset.omni_avg_page_views_per_session) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_new_visitor_percent', variableName: 'omni_new_visitor_percent', title: 'NV %', description: 'Percentage of visitors who are new', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'omni_new_visitor', footerDenominator: 'omni_unique_sessions', footerDataType: 'percent',
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.omni_new_visitor_percent != 0 && adset.omni_new_visitor_percent != null ? new Intl.NumberFormat('en-US', { style: 'percent' }).format(adset.omni_new_visitor_percent) : '-'}</span>
          </td>
        ),
      },
      { key: 'revenue_diff', variableName: 'revenue_diff', title: 'Revenue diff', description: 'Difference in revenue between Omni and Meta', sortable: true, width: 150, footerOperation: 'sum', footerDataType: 'currency', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.revenue_diff != 0 && adset.revenue_diff != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adset.revenue_diff) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_revenue', variableName: 'tp_revenue', title: 'CV', description: 'Conversion Value', sortable: true, width: 130, footerOperation: 'sum', footerDataType: 'currency', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.tp_revenue != 0 && adset.tp_revenue != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adset.tp_revenue) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_profit', variableName: 'omni_profit', title: 'Profit', description: 'Revenue - Ads - COGS', sortable: true, width: 130, footerOperation: 'sum', footerDataType: 'currency', 
        renderCell: ({adset, index}) => {
          const profit = Number(adset.omni_profit);
          const backgroundColor = profit > 0 ? '#b5ffcf' : profit === 0 ? '#ffffff00' : '#ffaead';
          return (
            <td key={adset.key + "_" + index} style={{width: adset.width, backgroundColor: backgroundColor}}>
              {profit && profit != 0 ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(profit) : '-'}
            </td>
          );
        },
      },
      { key: 'omni_cogs', variableName: 'omni_cogs', title: 'COGS', description: 'Cost of goods sold', sortable: true, width: 120, footerOperation: 'sum', footerDataType: 'currency', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.omni_cogs != 0 && adset.omni_cogs != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adset.omni_cogs) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_new_customer_cvr', variableName: 'omni_new_customer_cvr', title: 'NC CVR', description: 'Conversion rate for new customers', sortable: true, width: 120, footerOperation: 'divide', footerNumerator: 'omni_new_customer_orders', footerDenominator: 'omni_new_visitor', footerDataType: 'percent', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.omni_new_customer_cvr != 0 && adset.omni_new_customer_cvr != null ? new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(adset.omni_new_customer_cvr) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_cvr', variableName: 'omni_cvr', title: 'CVR', description: 'Conversion rate', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'omni_orders', footerDenominator: 'omni_unique_sessions', footerDataType: 'percent', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.omni_cvr != 0 && adset.omni_cvr != null ? new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(adset.omni_cvr) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_aov', variableName: 'omni_aov', title: 'AOV', description: 'Average order value', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'omni_revenue', footerDenominator: 'omni_orders', footerDataType: 'currency', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.omni_aov != 0 && adset.omni_aov != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adset.omni_aov) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_cpa', variableName: 'omni_cpa', title: 'CPA', description: 'Cost per acquisition', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'tp_spend', footerDenominator: 'tp_orders', footerDataType: 'currency', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.omni_cpa != 0 && adset.omni_cpa != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adset.omni_cpa) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_cost_per_atc', variableName: 'omni_cost_per_atc', title: 'Cost per ATC', description: 'Average cost per add-to-cart', sortable: true, width: 150, footerOperation: 'divide', footerNumerator: 'tp_spend', footerDenominator: 'omni_add_to_cart', footerDataType: 'currency', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.omni_cost_per_atc != 0 && adset.omni_cost_per_atc != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adset.omni_cost_per_atc) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_add_to_cart', variableName: 'omni_add_to_cart', title: 'ATC', description: 'Total number of add-to-cart', sortable: true, width: 110, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.omni_add_to_cart != 0 && adset.omni_add_to_cart != null ? new Intl.NumberFormat( 'en-US' ).format(adset.omni_add_to_cart) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_new_visitor_cost', variableName: 'omni_new_visitor_cost', title: 'New visitor cost', description: 'Cost per new visitors', sortable: true, width: 160, footerOperation: 'divide', footerNumerator: 'tp_spend', footerDenominator: 'omni_new_visitor', footerDataType: 'currency', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.omni_new_visitor_cost != 0 && adset.omni_new_visitor_cost != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adset.omni_new_visitor_cost) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_new_visitor', variableName: 'omni_new_visitor', title: 'New visitors', description: 'Total new visitors', sortable: true, width: 150, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.omni_new_visitor != 0 && adset.omni_new_visitor != null ? new Intl.NumberFormat( 'en-US' ).format(adset.omni_new_visitor) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_cost_per_visitor', variableName: 'omni_cost_per_visitor', title: 'Cost per visitor', description: 'Average cost to acquire a visitor', sortable: true, width: 150, footerOperation: 'divide', footerNumerator: 'tp_spend', footerDenominator: 'omni_unique_sessions', footerDataType: 'currency', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.omni_cost_per_visitor != 0 && adset.omni_cost_per_visitor != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adset.omni_cost_per_visitor) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_unique_sessions', variableName: 'omni_unique_sessions', title: 'Unique sessions', description: 'Count of unique sessions', sortable: true, width: 160, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.omni_unique_sessions != 0 && adset.omni_unique_sessions != null ? new Intl.NumberFormat( 'en-US' ).format(adset.omni_unique_sessions) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_sessions', variableName: 'omni_sessions', title: 'Sessions', description: 'Total number of sessions', sortable: true, width: 130, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.omni_sessions != 0 && adset.omni_sessions != null ? new Intl.NumberFormat( 'en-US' ).format(adset.omni_sessions) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_new_customer_revenue', variableName: 'omni_new_customer_revenue', title: 'NCV', description: 'New customer revenue', sortable: true, width: 130, footerOperation: 'sum', footerDataType: 'currency', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.omni_new_customer_revenue != 0 && adset.omni_new_customer_revenue != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adset.omni_new_customer_revenue) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_revenue', variableName: 'omni_revenue', title: 'CV', description: 'Conversion Value', sortable: true, width: 130, footerOperation: 'sum', footerDataType: 'currency', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.omni_revenue != 0 && adset.omni_revenue != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adset.omni_revenue) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_roas', variableName: 'omni_roas', title: 'ROAS', description: 'Return on ad spend', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'tp_revenue', footerDenominator: 'tp_spend', footerDataType: 'number',  
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.omni_roas != 0 && adset.omni_roas != null ? new Intl.NumberFormat( 'en-US' ).format(adset.omni_roas) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_new_customer_roas', variableName: 'omni_new_customer_roas', title: 'NC ROAS', description: 'New customer return on ad spend', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'omni_new_customer_revenue', footerDenominator: 'tp_spend', footerDataType: 'number', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.omni_new_customer_roas != 0 && adset.omni_new_customer_roas != null ? new Intl.NumberFormat( 'en-US' ).format(adset.omni_new_customer_roas) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_orders', variableName: 'tp_orders', title: 'Purchases', description: 'Total number of Purchases', sortable: true, width: 120, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.tp_orders != 0 && adset.tp_orders != null ? new Intl.NumberFormat( 'en-US' ).format(adset.tp_orders) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_new_customer_order_percent', variableName: 'omni_new_customer_order_percent', title: 'NCO %', description: 'Percentage of purchases from new customers', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'omni_new_customer_orders', footerDenominator: 'omni_orders', footerDataType: 'percent', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.omni_new_customer_order_percent != 0 && adset.omni_new_customer_order_percent != null ? new Intl.NumberFormat('en-US', { style: 'percent' }).format(adset.omni_new_customer_order_percent) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_new_customer_orders', variableName: 'omni_new_customer_orders', title: 'NCP', description: 'Purchases from new customers', sortable: true, width: 110, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.omni_new_customer_orders != 0 && adset.omni_new_customer_orders != null ? new Intl.NumberFormat( 'en-US' ).format(adset.omni_new_customer_orders) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_new_customer_cpa', variableName: 'omni_new_customer_cpa', title: 'NC CPA', description: 'Cost per acquisition', sortable: true, width: 110, footerOperation: 'divide', footerNumerator: 'tp_spend', footerDenominator: 'omni_new_customer_orders', footerDataType: 'currency', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.omni_new_customer_cpa != 0 && adset.omni_new_customer_cpa != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adset.omni_new_customer_cpa) : '-'}</span>
          </td>
        ),
      },
      { key: 'omni_orders', variableName: 'omni_orders', title: 'Purchases', description: 'Total number of purchases', sortable: true, width: 120, footerOperation: 'sum', footerDataType: 'number', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width} onClick={() => { setSelectedAdsetForPurchasesPopup(adset); setShowPurchasesPopup(true); }}>
            <span>{adset.omni_orders != 0 && adset.omni_orders != null ? new Intl.NumberFormat('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 1}).format(adset.omni_orders) : '-'}</span>
          </td>
        ),
      },
      { key: 'tp_spend', variableName: 'tp_spend', title: 'Spend', description: 'Total advertising spend', sortable: true, width: 130, footerOperation: 'sum', footerDataType: 'currency', 
        renderCell: ({adset, index}) => (
          <td key={adset.key + "_" + index} style={adset.width}>
            <span>{adset.tp_spend != 0 && adset.tp_spend != null ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adset.tp_spend) : '-'}</span>
          </td>
        ),
      },

    ];





    function sortColumnData(columnData, columnIndexes) {
      return columnData?.sort((a, b) => {
        const indexA = columnIndexes[a?.key] !== undefined ? columnIndexes[a?.key] : Infinity;
        const indexB = columnIndexes[b.key] !== undefined ? columnIndexes[b.key] : Infinity;
        return indexA - indexB;
      });
    }
    
    const sortedColumnData = sortColumnData(columnData, columnIndexes);
    

    setAdsetColumns(sortedColumnData)

  }, [columnIndexes, adsets]);





  const handleScroll = () => {
    const scrollLeft = tableContainerRef.current.scrollLeft;
    setIsScrolled(scrollLeft > 0);
  };

  useEffect(() => {
    const tableContainer = tableContainerRef.current;
    tableContainer.addEventListener('scroll', handleScroll);

    return () => {
      tableContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);
  





  const startResize = (index) => (e) => {
    console.log('resize')
    const startX = e.clientX;
    const startWidth = adsetColumns[index].width;
    const onMouseMove = (e) => {
      const newWidth = startWidth + (e.clientX - startX);
      const minWidth = 50; // Minimum width
      const maxWidth = 350; // Maximum width (adjust as needed)

      // Clamp the new width between min and max values
      const clampedWidth = Math.min(maxWidth, Math.max(minWidth, newWidth));

      const newColumns = [...adsetColumns];
      newColumns[index].width = clampedWidth;
      setAdsetColumns(newColumns);

      
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };








  function handleSort(column) {
    console.log(column)
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }

    const sorted = [...sortedAdsets].sort((a, b) => {
      const aValue = isNaN(Number(a[column])) ? a[column] : Number(a[column]);
      const bValue = isNaN(Number(b[column])) ? b[column] : Number(b[column]);

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
      } else {
        if (sortOrder === 'asc') {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      }
    });

    setSortedAdsets(sorted);
  }





  useEffect(() => {
    const handleKeyDown = (event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            console.log('Escape key pressed')
            setShowBudgetInput('');
        }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
        window.removeEventListener('keydown', handleKeyDown);
    };
}, []); 





  async function filterAdsets(adsets, filterStatus) {
    if (filterStatus === 'ANY') {
      return adsets;
    } else {
      return adsets.filter((adset) => adset.status === filterStatus);
    }
  }


  useEffect(() => {
    async function fetchData() {
      console.log(adsets)
      setFilteredAdsets(await filterAdsets(adsets, filterStatus));
    }
    fetchData();
  }, [adsets, filterStatus])


  useEffect(() => {
    setSortedAdsets(filteredAdsets);
  }, [filteredAdsets])


  console.log(selectedAdsets)


  async function updateAdsetStatus (tp_id, status, omni_business_id, ad_account_id) {
    try {
      setUpdateStatusLoading(true)
      setSnackbarInfo({ open: true, title: 'Publishing', message: 'Publishing changes', type: 'loading' });
      if (source == 'fb') {
        const response = await axios.post(`${serverURL}/updateFacebookAdsetData`, {
          adset_id: tp_id,
          status: status,
          omni_business_id: omni_business_id
        })
        console.log("Adset status updated", response)
      } else if (source == 'tiktok') {
        const response = await axios.post(`${serverURL}/updateTikTokAdgroupData`, {
          omni_business_id: omni_business_id,
          adgroup_ids: [tp_id],
          status: status.toLowerCase(),
          ad_account_id: ad_account_id
        })
        console.log("Adgroup status updated", response)
      }
      setUpdateStatusAdsetID('')
      setUpdateStatusLoading(false)
      setRefreshDataTrigger(!refreshDataTrigger)  
    } catch (error) {
      console.log("Error updating adset status", error)
      setUpdateStatusLoading(false)
    }
    setSnackbarInfo({ open: true, title: 'Published', message: 'Adset status updated', type: 'success' });
  }


  async function updateAdsetBudget (tp_id, daily_budget, omni_business_id, ad_account_id ) {
    try {
      setUpdateBudgetLoading(true)
      setSnackbarInfo({ open: true, title: 'Publishing', message: 'Publishing budget', type: 'loading' });
      if (source == 'fb') {
        const response = await axios.post(`${serverURL}/updateFacebookAdsetData`, {
          adset_id: tp_id,
          daily_budget: daily_budget,
          omni_business_id: omni_business_id
        })
        console.log("Adset budget updated", response)
      } else if (source == 'tiktok') {
        const response = await axios.post(`${serverURL}/updateTikTokAdgroupData`, {
          omni_business_id: omni_business_id,
          adgroup_ids: [tp_id],
          budget: daily_budget,
          ad_account_id: ad_account_id
        })
        console.log("Adgroup status updated", response)
      }
      setShowBudgetInput('')
      setUpdateBudgetLoading(false)
      setRefreshDataTrigger(!refreshDataTrigger)  
    } catch (error) {
      console.log("Error updating adset budget", error)
      setUpdateBudgetLoading(false)
    }
    setSnackbarInfo({ open: true, title: 'Published', message: 'Adset budget updated', type: 'success' });
  }





  return (
    <div className='AdsetGridContainer'>
      <div className='AdsetGridHeaderRow'>


          <div className='AdsetGridDropdownButton' style={{width: 100}}  onClick={() => { setAttributionDropdown(!showAttributionDropdown) }}>
            <div className='AdsetGridDropdownButtonText'>
              <span>
                {attributionModel == 'any_click' && 'Any Click'}
                {attributionModel == 'linear_all' && 'Linear All'}
                {attributionModel == 'first_click' && 'First Click'}
                {attributionModel == 'last_click' && 'Last Click'}
              </span>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', transform: showAttributionDropdown ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease-in-out'}} >
              <KeyboardArrowDownRounded/>
            </div>
            {showAttributionDropdown && (
              <ClickAwayListener onClickAway={() => setAttributionDropdown(false)}>
                <div className='AdsetGridDropdownContainer' style={{width: 300}}>
                  <div 
                    className={attributionModel == 'any_click' ? 'AdsetGridDropdownRowSelected' : 'AdsetGridDropdownRow'} 
                    onClick={() => { setAttributionModel('any_click'); setAttributionDropdown(false); }}>
                      <img className='AdsetGridDropdownImage' style={{width: 50, height: 50}} src={linearAll} />
                      <div className='AdsetGridDropdownRightContainer'>
                        <span className='AdsetGridDropdownRightTitle'>Any Click</span>
                        <span className='AdsetGridDropdownRightDescription'>Each channel gets 100% </span>
                      </div>
                  </div>

                  <div 
                    className={attributionModel == 'first_click' ? 'AdsetGridDropdownRowSelected' : 'AdsetGridDropdownRow'} 
                    onClick={() => { setAttributionModel('first_click'); setAttributionDropdown(false); }}>
                      <img className='AdsetGridDropdownImage' style={{width: 50, height: 50}} src={firstClick} />
                      <div className='AdsetGridDropdownRightContainer'>
                        <span className='AdsetGridDropdownRightTitle'>First Click</span>
                        <span className='AdsetGridDropdownRightDescription'> 100% to first channel clicked </span>
                      </div>
                  </div>

                  <div 
                    className={attributionModel == 'last_click' ? 'AdsetGridDropdownRowSelected' : 'AdsetGridDropdownRow'} 
                    onClick={() => { setAttributionModel('last_click'); setAttributionDropdown(false); }}>
                      <img className='AdsetGridDropdownImage' style={{width: 50, height: 50}} src={lastClick} />
                      <div className='AdsetGridDropdownRightContainer'>
                        <span className='AdsetGridDropdownRightTitle'>Last Click</span>
                        <span className='AdsetGridDropdownRightDescription'> 100% credit to last channel clicked </span>
                      </div>
                  </div>

                  <div 
                    className={attributionModel == 'linear_all' ? 'AdsetGridDropdownRowSelected' : 'AdsetGridDropdownRow'} 
                    onClick={() => { setAttributionModel('linear_all'); setAttributionDropdown(false); }}>
                      <img className='AdsetGridDropdownImage' style={{width: 50, height: 50}} src={linearAll} />
                      <div className='AdsetGridDropdownRightContainer'>
                        <span className='AdsetGridDropdownRightTitle'>Linear All</span>
                        <span className='AdsetGridDropdownRightDescription'> Divides credit equally among all channels </span>
                      </div>
                  </div>

                </div>
              </ClickAwayListener>
            )}
          </div>
      
          <div className='AdsetGridDropdownButton' style={{width: 80}} onClick={() => { setShowAttributionsWindowDropdown(!showAttributionsWindowDropdown) }}>
            <div className='AdsetGridDropdownButtonText'>
              <span>
                {attributionWindow == '10000 days' && 'Lifetime'}
                {attributionWindow == '28 days' && '28 Days'}
                {attributionWindow == '14 days' && '14 Days'}
                {attributionWindow == '7 days' && '7 Days'}
                {attributionWindow == '1 day' && '1 Day'}
              </span>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', transform: showAttributionsWindowDropdown ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease-in-out'}} >
              <KeyboardArrowDownRounded/>
            </div>
            {showAttributionsWindowDropdown && (
              <ClickAwayListener onClickAway={() => setShowAttributionsWindowDropdown(false)}>
                <div className='AdsetGridDropdownContainer'>
                  <span className={attributionWindow == '1 day' ? 'AdsetGridDropdownRowSelected' : 'AdsetGridDropdownRow'} onClick={() => { setShowAttributionsWindowDropdown(false); setAttributionWindow('1 day') }}>1 Day</span>
                  <span className={attributionWindow == '7 days' ? 'AdsetGridDropdownRowSelected' : 'AdsetGridDropdownRow'} onClick={() => { setShowAttributionsWindowDropdown(false); setAttributionWindow('7 days') }}>7 Days</span>
                  <span className={attributionWindow == '14 days' ? 'AdsetGridDropdownRowSelected' : 'AdsetGridDropdownRow'} onClick={() => { setShowAttributionsWindowDropdown(false); setAttributionWindow('14 days') }}>14 Days</span>
                  <span className={attributionWindow == '28 days' ? 'AdsetGridDropdownRowSelected' : 'AdsetGridDropdownRow'} onClick={() => { setShowAttributionsWindowDropdown(false); setAttributionWindow('28 days') }}>28 Days</span>
                  <span className={attributionWindow == '10000 days' ? 'AdsetGridDropdownRowSelected' : 'AdsetGridDropdownRow'} onClick={() => { setShowAttributionsWindowDropdown(false); setAttributionWindow('10000 days') }}>Lifetime</span>
                </div>
              </ClickAwayListener>
            )}
          </div>


        <div className='AdsetGridDropdownButton' onClick={() => { setShowSelectColumnsPopup(!showSelectColumnsPopup) }}>
          <div className='AdsetGridDropdownButtonText' style={{marginRight: 6}}>
            {/* <ViewColumnRounded style={{paddingRight: 10}}/> */}
            Views
          </div>                   
        </div>


        {/* <div className='AdsetGridDropdownButton' onClick={() => { setShowStatusDropdown(!showStatusDropdown) }}>
          <div className='AdsetGridDropdownButtonText' >
            <span>Status: {filterStatus}</span>
          </div>
          <KeyboardArrowDownRounded style={{paddingLeft: 10}} />
          {showStatusDropdown && (
            <ClickAwayListener onClickAway={() => setShowStatusDropdown(false)}>
              <div className='AdsetGridDropdownContainer'>
                <span className={filterStatus == 'ANY' ? 'AdsetGridDropdownRowSelected' : 'AdsetGridDropdownRow'} onClick={() => { setFilterStatus("ANY"); setShowStatusDropdown(false) }}>Any</span>
                <span className={filterStatus == 'ACTIVE' ? 'AdsetGridDropdownRowSelected' : 'AdsetGridDropdownRow'} onClick={() => { setFilterStatus("ACTIVE"); setShowStatusDropdown(false) }}>Active</span>
                <span className={filterStatus == 'PAUSED' ? 'AdsetGridDropdownRowSelected' : 'AdsetGridDropdownRow'} onClick={() => { setFilterStatus("PAUSED"); setShowStatusDropdown(false) }}>Paused</span>
                <span className={filterStatus == 'REVIEW' ? 'AdsetGridDropdownRowSelected' : 'AdsetGridDropdownRow'} onClick={() => { setFilterStatus("REVIEW"); setShowStatusDropdown(false) }}>In Review</span>
              </div>
            </ClickAwayListener>
          )}
        </div> */}


      </div>


      <div className='AdsetGridTableContainer' ref={tableContainerRef}>
      <table className='AdsetGridTable'>
    
        <HeaderComponent adsetColumns={adsetColumns} sortColumn={sortColumn} sortOrder={sortOrder} handleSort={handleSort} startResize={startResize} availableColumns={availableColumns} columnIndexes={columnIndexes} source={source} isScrolled={isScrolled}/>
     
        <tbody>
          {sortedAdsets.map((adset, index) => (
            <RowComponent
              key={index}
              rowIndex={adset.tp_id}
              adset={adset}
              adsetColumns={adsetColumns}
              selectedAdsets={selectedAdsets}
              adjustSelectedAdsets={adjustSelectedAdsets}
              setShowDrawer={setShowDrawer}
              setSelectedAdsetForPurchasesPopup={setSelectedAdsetForPurchasesPopup}
              showBudgetInput={showBudgetInput}
              setShowBudgetInput={setShowBudgetInput}
              newBudgetValue={newBudgetValue}
              setNewBudgetValue={setNewBudgetValue}
              updateBudgetLoading={updateBudgetLoading}
              updateAdsetBudget={updateAdsetBudget}
              updateAdsetStatus={updateAdsetStatus}
              setShowPurchasesPopup={setShowPurchasesPopup}
              availableColumns={availableColumns}
              columnIndexes={columnIndexes}
              isScrolled={isScrolled}
            />
          ))}
      
          {sortedAdsets.length > 0 && (
            <FooterComponent adsetColumns={adsetColumns} sortedAdsets={sortedAdsets} availableColumns={availableColumns} columnIndexes={columnIndexes} isScrolled={isScrolled}/>
          )}
        </tbody>

      </table>
    </div>
    </div>
  );
}

export default AdsetGrid;