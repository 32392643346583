import './Products.css';
import { getAuth, signOut } from "firebase/auth";
import react, { useState, useEffect } from 'react';
import { collection, doc, getDoc } from 'firebase/firestore';
import {db} from '../FirebaseInit';
import { useNavigate } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import axios from 'axios';
import StoreOverview from '../components/StoreOverview';
import { AddRounded, CreateRounded } from '@mui/icons-material';
import ProductDrawer from '../components/ProductDrawer';


function Products({serverURL}) {
  const uid = localStorage.getItem('uid');
  const bid = localStorage.getItem('bid');


  const [allProducts, setAllProducts] = useState([])
  const [loadingProducts, setLoadingProducts] = useState(true)
  const [loadingPercentage, setLoadingPercentage] = useState(0)
  const [selectedProductID, setSelectedProductID] = useState('')
  const [showDrawer, setShowDrawer] = useState(false)



  async function getProducts () {
    setLoadingPercentage(100)
    try {
      const apiUrl = `${serverURL}/getShopifyProductsFromDB?omni_business_id=${bid}` ;
      const response = await axios.get(apiUrl);
      console.log(response.data)
      setAllProducts(response.data)
      setLoadingProducts(false)

    } catch (error) {

      console.log('ERROR loading products Products.js', error)
    } finally {
      setLoadingProducts(false)
      setLoadingPercentage(0)
    }

  }

  useEffect(() => {
    if (uid !== '') {
      getProducts()
    }
  }, [uid]);

  


  
  
  
  return (
    <div className='productsContainer'>

      <ProductDrawer
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        selectedProductID={selectedProductID}
        setSelectedProductID={setSelectedProductID}
        serverURL={serverURL}
      />


      {loadingProducts ?
        <div className='productsLoadingContainer'>
          <div className='productsLoadingProgressParent'>
            <div className='productsLoadingProgressChild' style={{width: loadingPercentage + '%'}}></div>
          </div>
          {/* <CircularProgress style={{color: '#635bff', width: 30, height: 30}}/> */}
          <span className='productsLoadingText'>Loading Shopify Products... </span>
        </div>
      :
        <div className='productsTableContainer'>
          <table className='productsTable'>
            <thead>
              <tr>
                <td></td>
                <td>Product</td>
                <td>Status</td>
                <td>Price</td>
                <td>Cost</td>
                <td><div style={{display: 'block', padding: '10px 0px'}}>Variants</div></td>
                <td>Revenue</td>
                <td>Ads</td>
                <td>Ad Budget</td>

              </tr>
            </thead>
            <tbody>
              {allProducts.map((x) => { return ( 
                <tr className='productsRow' key={x.product_id} onClick={() => { setSelectedProductID(x.product_id); setShowDrawer(true)}}>
                  <td><img className='productsRowImage' src={x?.img_src}/></td>
                  <td>
                    <span className='productsRowTitle'>{x.product_title}</span>
                    <span className='productsRowDescription'>{x.description}</span>
                  </td>
                  <td><span className={x.status == 'ACTIVE' ? 'productsRowActive': 'productsRowInactive'}>{x.status}</span></td>

                  <td>
                    {
                      x.min_price == x.max_price ? 
                        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.min_price)
                      : 
                        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.min_price) + " - " + new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.max_price)
                    }
                  </td>
                  <td>
                    {
                      x.min_cogs == x.max_cogs ? 
                        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.min_cogs)
                    : 
                        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.min_cogs) + " - " + new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.max_cogs)
                    }
                  </td>
                  <td>{x.variant_count}</td>
                  <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.total_revenue)}</td>
                  <td>{x.fb_ad_count}</td>
                  <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.total_daily_budget / 100)}</td>
                </tr>
              )})}
                <tr className='productsRowBottom'> 
                <td colSpan={2}>
                    <div className='productsRowBottomLeftButton'>
                      {allProducts.length} Products
                    </div>
                  </td>
                  <td colSpan={5}>
                    <div className='productsRowBottomRight'>
                      
                    </div>
                  </td>
                </tr>
            </tbody>
          </table>
        </div>

      }

    </div>
  );
}

export default Products;